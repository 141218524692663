import BaseFrame from './BaseFrame'
import { renderListOptionsInput } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameOtherSupport extends BaseFrame {
    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.others" /></h2>
                <div className="row">
                    {renderListOptionsInput({
                        name: "interestmeeting",
                        label: "inscriptions.others.interestmeeting",
                        description_html: (
                            <p>
                                <FormattedMessage id="inscriptions.others.interestmeeting.description.before" />
                                 <a href="https://event.bigfestival.com.br/" target='_blank'>
                                    <FormattedMessage id="inscriptions.others.interestmeeting.description.link" />
                                </a>.
                            </p>
                        ),
                        type: "radio",
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("festival", e),
                        value: this.fieldValueModel("festival", "interestmeeting"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderListOptionsInput({
                        name: "interestmeetingdiscount",
                        label: "inscriptions.others.interestmeetingdiscount",
                        description_html: (
                            <p>
                                <FormattedMessage id="inscriptions.others.interestmeetingdiscount.description.before" />
                                 <a href="https://event.bigfestival.com.br/" target='_blank'>
                                    <FormattedMessage id="inscriptions.others.interestmeetingdiscount.description.link" />
                                </a>.
                            </p>
                        ),
                        type: "radio",
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("festival", e),
                        value: this.fieldValueModel("festival", "interestmeetingdiscount"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameOtherSupport)