/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import jwt_decode from "jwt-decode";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
});

function UserNew(props) {
  const intl = useIntl();
  const history = useHistory();
  const user = jwt_decode(localStorage.getItem("token"));
  const [isErrorRequest, setIsErrorRequest] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const dados = new FormData();
    console.log("data.type", parseInt(data.type));
    dados.append("type", parseInt(data.type));
    dados.append("email", data.email);
    dados.append("name", data.name);
    dados.append("password", data.password);

    let url = `/api/v1/account/create/`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsErrorRequest(false);
        history.push(`/users/`);
      })
      .catch((e) => {
        setIsErrorRequest(true);
        console.log("e", e.response);
      });
  };

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/users"} title="Usuários">
                <FormattedMessage id="app.breadcrumb.users" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.users.new" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function getLabelRole(roleId) {
    switch (roleId) {
      case 1:
        return "Usuário";

      case 5:
        return "Jurado";

      case 10:
        return "Admin";
    }
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="meus-dados" />
                </h1>

                <div className="basic-form">
                  <form
                    className="form-custom"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label>
                          <FormattedMessage id="component.my-account-form.input-label-permission" />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          {...register("type")}
                        >
                          {user.type == 10 && (
                            <option value={0}>
                              {intl.formatMessage({
                                id: "admin",
                              })}
                            </option>
                          )}
                          <option value={1}>
                            {intl.formatMessage({
                              id: "user",
                            })}
                          </option>
                        </select>
                      </div>
                      <div
                        className={`form-group col-md-12 ${
                          errors?.name ? "input-error" : ""
                        }`}
                      >
                        <label>
                          <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          {...register("name")}
                        />
                      </div>
                      <div
                        className={`form-group col-md-6 ${
                          errors?.email ? "input-error" : ""
                        }`}
                      >
                        <label>E-mail</label>
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          {...register("email")}
                        />
                      </div>
                      <div
                        className={`form-group col-md-6 ${
                          errors?.password ? "input-error" : ""
                        }`}
                      >
                        <label>Senha</label>
                        <input
                          type="password"
                          className="form-control form-control-sm"
                          {...register("password")}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group content-btn col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={(e) => {
                            e.preventDefault();

                            history.push("/users");
                          }}
                        >
                          <FormattedMessage id="component.my-account-form.btn-cancel" />
                        </button>

                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </div>
                    {isErrorRequest && (
                      <div className="form-group col-12 alert">
                        {/* <div className="alert alert-success">Dados salvos com sucesso!</div> */}
                        <div className="alert alert-danger">
                          Falha ao criar usuário
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(UserNew));
