import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Slider from "@mui/material/Slider";

const schemaSecionadorFechado = yup.object().shape({
  gameplay: yup.string().required(),
  gameplayShare: yup.boolean().required(),
  gameplayComment: yup.string().required(),
  narrativa: yup.string().required(),
  narrativaShare: yup.boolean().required(),
  narrativaComment: yup.string().required(),
  arte: yup.string().required(),
  arteShare: yup.boolean().required(),
  arteComment: yup.string().required(),
  som: yup.string().required(),
  somShare: yup.boolean().required(),
  somComment: yup.string().required(),
  inovacao: yup.string().required(),
  inovacaoShare: yup.boolean().required(),
  inovacaoComment: yup.string().required(),
});

const geneteValidate = (game) => {
  var validate = {};

  const formSchema = {
    nota: yup.string().required(),
    share: yup.boolean().required(),
    comment: yup.string().required(),
  };

  const gameSchemaGenerate = (id) => {
    return {
      [`game_${id}`]: yup.object().shape(formSchema),
    };
  };

  game.team.cat_avaliadas.forEach((cat) => {
    validate[`${cat}`] = yup
      .object()
      .shape(gameSchemaGenerate(game.inscription.id));
  });

  return validate;
};

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

function GamesRatedAdmin({ game }) {
  const intl = useIntl();
  const schemaJuriFechado = yup.object().shape(geneteValidate(game));
  const [isShow, setIsShow] = useState(true);
  const history = useHistory();
  const user = jwt_decode(localStorage.getItem("token"));
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      game.user.type == 4 ? schemaJuriFechado : schemaSecionadorFechado
    ),
  });

  const onSubmit = (data) => {
    const dados = new FormData();

    if (game.group_user == 4) {
      dados.append("ratingJuri", JSON.stringify(data));
      dados.append("event_id", game.event_id);
      dados.append("game_id", game.inscription.id);
      dados.append("team_id", game.team.id);
    } else {
      dados.append("gameplay", data.gameplay);
      dados.append("gameplayShare", data.gameplayShare);
      dados.append("gameplayComment", data.gameplayComment);
      dados.append("narrativa", data.narrativa);
      dados.append("narrativaShare", data.narrativaShare);
      dados.append("narrativaComment", data.narrativaComment);
      dados.append("arte", data.arte);
      dados.append("arteShare", data.arteShare);
      dados.append("arteComment", data.arteComment);
      dados.append("som", data.som);
      dados.append("somShare", data.somShare);
      dados.append("somComment", data.somComment);
      dados.append("inovacao", data.inovacao);
      dados.append("inovacaoShare", data.inovacaoShare);
      dados.append("inovacaoComment", data.inovacaoComment);
      dados.append("event_id", game.event_id);
      dados.append("game_id", game.inscription.id);
      dados.append("event_id", game.event_id);
      dados.append("team_id", game.team.id);
    }

    let url = `/api/v1/rating/game-admin/${game.id}`;

    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert(intl.formatMessage({ id: "rating.save" }));
        } else {
          alert(intl.formatMessage({ id: "rating.save.fail" }));
          console.log("Não salvou", res);
        }
      })
      .catch((e) => {
        if (e.response.status == 411) {
          alert(intl.formatMessage({ id: "inscriptions.edit.validate.date" }));
        } else {
          alert(intl.formatMessage({ id: "inscriptions.edit.response.fail" }));
        }
        console.log("e", e.response);
      });
  };

  useEffect(() => {
    if (game.group_user == 4) {
      Object.keys(game.rating_juri).forEach(function (key) {
        Object.keys(game.rating_juri[key]).forEach(function (keyGame) {
          game.rating_juri[key][keyGame] = {
            comment: `${game.rating_juri[key][keyGame].comment}`,
            nota: parseInt(game.rating_juri[key][keyGame].nota),
            share: `${game.rating_juri[key][keyGame].share}`,
          };
        });
        setValue(key, game.rating_juri[key]);
      });
    } else {
      setValue("gameplay", `${game.gameplay}`);
      setValue("gameplayShare", `${game.gameplayShare}`);
      setValue("gameplayComment", `${game.gameplayComment}`);
      setValue("narrativa", `${game.narrativa}`);
      setValue("narrativaShare", `${game.narrativaShare}`);
      setValue("narrativaComment", `${game.narrativaComment}`);
      setValue("arte", `${game.arte}`);
      setValue("arteShare", `${game.arteShare}`);
      setValue("arteComment", `${game.arteComment}`);
      setValue("som", `${game.som}`);
      setValue("somShare", `${game.somShare}`);
      setValue("somComment", `${game.somComment}`);
      setValue("inovacao", `${game.inovacao}`);
      setValue("inovacaoShare", `${game.inovacaoShare}`);
      setValue("inovacaoComment", `${game.inovacaoComment}`);
    }
  }, []);

  return (
    isShow && (
      <div className={`row`}>
        <div className="col-12">
          <div className="row">
            <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
              <Link
                to={{
                  pathname: `/games/detail/${game.inscription.id}`,
                  state: {
                    game: game.inscription,
                  },
                }}
                title="Detalhe Jogo"
              >
                <div className="image-event">
                  <img
                    src={game.inscription.image}
                    alt={game.inscription.name}
                  />
                </div>
              </Link>
            </div>
            <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
              <div className="info">
                <div className="row w-100">
                  <Link
                    to={{
                      pathname: `/games/detail/${game.inscription.id}`,
                      state: {
                        game: game.inscription,
                      },
                    }}
                    title="Detalhe Jogo"
                  >
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h3>{game.inscription.name}</h3>
                      <p>{game.inscription.description}</p>
                    </div>
                  </Link>
                  <div className="col-12">
                    <form
                      className="form-custom"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        {console.log("game", game)}
                        {game.user.type == 4 ? (
                          <>
                            {game.team.cat_avaliadas.map((cat, index) => {
                              return (
                                <>
                                  <div className="form-group col-md-12 mb-4">
                                    <div
                                      className={`${
                                        errors?.[cat]?.[
                                          `game_${game.inscription.id}`
                                        ]?.nota
                                          ? "input-error"
                                          : ""
                                      }`}
                                    >
                                      <h3>
                                        <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />
                                        {cat}
                                      </h3>
                                      <div
                                        className="container-slider"
                                        style={{ maxWidth: 300 }}
                                      >
                                        <Controller
                                          control={control}
                                          name={`${cat}.game_${game.inscription.id}.nota`}
                                          defaultValue={[0, 5]}
                                          min={0}
                                          max={5}
                                          render={({
                                            field: { value, onChange },
                                          }) => (
                                            <Slider
                                              step={1}
                                              min={0}
                                              max={5}
                                              marks={marks}
                                              onChange={onChange}
                                              value={value}
                                              disabled={
                                                user.id !== game.user.id
                                              }
                                            />
                                          )}
                                        />
                                        <span className="slider-range-value">
                                          {console.log(watch(`${cat}`))}
                                          {watch(
                                            `${cat}.game_${game.inscription.id}.nota`
                                          )}
                                        </span>
                                        <i className="fa fa-star btn-outline-warning"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-12 mb-4">
                                    <div
                                      className={`${
                                        errors?.[cat]?.[
                                          `game_${game.inscription.id}`
                                        ]?.share
                                          ? "input-error"
                                          : ""
                                      }`}
                                    >
                                      <h3>
                                        <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                      </h3>

                                      <div className="content-radio">
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            className="form-control form-control-sm"
                                            name="categoria-extra"
                                            value={true}
                                            {...register(
                                              `${cat}.game_${game.inscription.id}.share`,
                                              {
                                                value: "false",
                                              }
                                            )}
                                            disabled={user.id !== game.user.id}
                                          />
                                          <FormattedMessage id="sim" />
                                        </div>
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            className="form-control form-control-sm"
                                            name="categoria-extra"
                                            value={false}
                                            {...register(
                                              `${cat}.game_${game.inscription.id}.share`
                                            )}
                                            disabled={user.id !== game.user.id}
                                          />
                                          <FormattedMessage id="nao" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-12 mb-4">
                                    <div
                                      className={`${
                                        errors?.[cat]?.[
                                          `game_${game.inscription.id}`
                                        ]?.comment
                                          ? "input-error"
                                          : ""
                                      }`}
                                    >
                                      <h3>
                                        <FormattedMessage id="deixe-seu-comentario" />
                                      </h3>
                                      <textarea
                                        placeholder={
                                          watch(
                                            `${cat}.game_${game.inscription.id}.share`
                                          ) == "true"
                                            ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                            : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                                        }
                                        className="form-control form-control-sm feedback-categoria"
                                        {...register(
                                          `${cat}.game_${game.inscription.id}.comment`
                                        )}
                                        disabled={user.id !== game.user.id}
                                      ></textarea>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className={`form-group col-md-12 mb-4`}>
                              <div
                                className={`${
                                  errors?.gameplay ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation." />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("gameplay")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("gameplay")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("gameplay")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.gameplayShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("gameplayShare", {
                                        value: "false",
                                      })}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("gameplayShare")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.gameplayComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder={
                                    watch(`gameplayShare`) == "true"
                                      ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                      : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                                  }
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("gameplayComment")}
                                  disabled={user.id !== game.user.id}
                                />
                              </div>
                            </div>

                            <div className={`form-group col-md-12 mb-4`}>
                              <div
                                className={`${
                                  errors?.narrativa ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.narrative" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("narrativa")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("narrativa")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("narrativa")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.narrativaShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("narrativaShare", {
                                        value: "false",
                                      })}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("narrativaShare")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.narrativaComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder={
                                    watch(`narrativaShare`) == "true"
                                      ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                      : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                                  }
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("narrativaComment")}
                                  disabled={user.id !== game.user.id}
                                />
                              </div>
                            </div>
                            {/* Arte */}
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.arte ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.art" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("arte")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("arte")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("arte")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.arteShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("arteShare", {
                                        value: "false",
                                      })}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("arteShare")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.arteComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder={
                                    watch(`arteShare`) == "true"
                                      ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                      : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                                  }
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("arteComment")}
                                  disabled={user.id !== game.user.id}
                                />
                              </div>
                            </div>
                            {/* Fim Arte */}
                            {/* Som */}
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.som ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.sound" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("som")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("som")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("som")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.somShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("somShare", {
                                        value: "false",
                                      })}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("somShare")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.somComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder={
                                    watch(`somShare`) == "true"
                                      ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                      : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                                  }
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("somComment")}
                                  disabled={user.id !== game.user.id}
                                />
                              </div>
                            </div>
                            {/* Fim Som */}
                            {/* inovacao */}
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.inovacao ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.innovation" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("inovacao")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("inovacao")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("inovacao")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.inovacaoShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("inovacaoShare", {
                                        value: "false",
                                      })}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("inovacaoShare")}
                                      disabled={user.id !== game.user.id}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.inovacaoComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder={
                                    watch(`inovacaoShare`) == "true"
                                      ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                      : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                                  }
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("inovacaoComment")}
                                  disabled={user.id !== game.user.id}
                                />
                              </div>
                            </div>
                            {/* Fim inovacao */}
                          </>
                        )}
                      </div>

                      <div className="form-row">
                        <div className="form-group content-btn col-12 d-flex justify-content-end">
                          {user.id !== game.user.id ? (
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                history.push("/games-rated");
                              }}
                            >
                              <FormattedMessage id="account.login.previous" />
                            </button>
                          ) : (
                            <>
                              <button
                                className="btn btn-outline-primary"
                                onClick={(e) => {
                                  history.push("/games-rated");
                                }}
                              >
                                <FormattedMessage id="component.my-account-form.btn-cancel" />
                              </button>
                              <button type="submit" className="btn btn-primary">
                                <FormattedMessage id="editar" />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default injectIntl(GamesRatedAdmin);
