/* eslint-disable default-case */
import axios from "axios";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory, useParams } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { templateLanguage } from "./defaultJson";

const schema = yup.object().shape({
  name: yup.string().required(),
  terms: yup.string().required(),
  active: yup.string().required(),
});

function LanguageEdit(props) {
  const intl = useIntl();
  const history = useHistory();
  let { id } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      active: "true",
    },
  });

  const watchTermsFile = watch("terms_file");
  const watchTerms = watch("terms");
  const watchName = watch("name");

  useEffect(() => {
    if (watchTermsFile && watchTermsFile.length) {
      const fileReader = new FileReader();
      fileReader.readAsText(watchTermsFile[0], "UTF-8");
      fileReader.onload = (e) => {
        setValue("terms", JSON.stringify(JSON.parse(e.target.result)));
      };
    }
  }, [watchTermsFile]);

  useEffect(() => {
    getLanguage();
  }, []);

  const getLanguage = async () => {
    try {
      let url = `/api/v1/language/${id}`;

      const response = await axios.get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      console.log("response", response);
      setValue("name", response.data.name);
      setValue("terms", JSON.stringify(response.data.terms));
      setValue("active", response.data.active.toString());
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.open("/", "_self");
      } else {
        history.push("/settings/languages");
      }
    }
  };

  function downloadTextFile(text, name) {
    const a = document.createElement("a");
    const type = name.split(".").pop();
    a.href = URL.createObjectURL(
      new Blob([text], { type: `text/${type === "txt" ? "plain" : type}` })
    );
    a.download = name;
    a.click();
  }

  const onSubmit = (data) => {
    const dados = new FormData();
    dados.append("name", data.name);
    dados.append("terms", data.terms);
    dados.append("active", data.active);

    let url = `/api/v1/languages/edit/${id}`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          history.push("/settings/languages");
        } else {
          alert("Falha ao criar idioma");
          console.log("Não salvou", res);
        }
      });
  };

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/settings/languages"} title="Idiomas">
                <FormattedMessage id="idiomas" />
              </Link>
            </li>
            <li className="breadcrumb-item active">{watchName && watchName}</li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="novo-idioma" />
                </h1>

                <div class="basic-form">
                  <form
                    className="form-custom"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div class="form-row botton-line">
                      <div
                        class={`form-group col-12 ${
                          errors?.name ? "input-error" : ""
                        }`}
                      >
                        <label>
                          <FormattedMessage id="nome-do-idioma" />
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          {...register("name")}
                        />
                      </div>
                      <div
                        class={`form-group col-6 ${
                          errors?.terms ? "input-error" : ""
                        }`}
                      >
                        <label>
                          <FormattedMessage id="upload-os-termos" />
                        </label>
                        <input
                          type="file"
                          class="form-control form-control-sm"
                          accept="application/JSON"
                          {...register("terms_file")}
                        />
                      </div>
                      <div class="form-group col-6">
                        <label>
                          <FormattedMessage id="status-do-idioma" />
                        </label>
                        <select
                          class="form-control form-control-sm"
                          {...register("active")}
                        >
                          <option value={"true"}>
                            {intl.formatMessage({
                              id: "selecione-o-status-active",
                            })}
                          </option>
                          <option value={"false"}>
                            {intl.formatMessage({
                              id: "selecione-o-status-deactivate",
                            })}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group content-btn col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/settings/languages");
                          }}
                        >
                          <FormattedMessage id="component.my-account-form.btn-cancel" />
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={() => {
                            downloadTextFile(watchTerms, "example.json");
                          }}
                        >
                          <FormattedMessage id="download-dos-termos" />
                        </button>
                        <button type="submit" class="btn btn-primary">
                          <FormattedMessage id="editar" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(LanguageEdit));
