import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory, NavLink } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl } from "react-intl";
// import BannherEvento1 from "../../assets/images/v2/banner-chamadas-2022.jpg";
import NoImageChamada from "../../assets/images/v2/event/image-event-test.jpg";

function InscriptionsChamadas({ intl }) {
  const locale = intl.locale;
  const [eventos, setEventos] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);

  useEffect(() => {
    let url = "/api/v1/events?canInscription=true";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let eventos = res.data.filter((e) => e?.active);
        setEventos(eventos);
        setIsLoadingEvents(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLinks = () => {
      const descriptionsLink = document.querySelectorAll('.descriptionsLink')

      descriptionsLink.forEach(descriptionsLink => {
        const links = descriptionsLink.querySelectorAll('a')
        links.forEach(link => {
          link.setAttribute('rel', 'noopener noreferrer');
          link.setAttribute('target', '_blank');
          link.addEventListener('click', function(event) {
            event.stopPropagation();
          });
        });
      })
    };

    document.addEventListener('DOMNodeInserted', handleLinks);
    handleLinks();

    return () => {
      document.removeEventListener('DOMNodeInserted', handleLinks);
    };
  }, []);

  function traducaoSiglaLocale(locale1, locale2) {
    let l1 = locale1;
    let l2 = locale2;

    if (locale1.indexOf("-") >= 0) l1 = locale1.split("-")[0];

    if (locale2.indexOf("-") >= 0) l2 = locale2.split("-")[0];

    return l1 == l2;
  }

  const getInscriptionUrl = (evento) => {
    if (evento?.active && moment(evento.deadline).diff(moment()) > 0) {
      return `/inscription/new/${evento.id}`;
    }

    return "#";
  };

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <FormattedMessage id="inscriptions.calls" />
              </li>
            </ol>
          </div>
        </div>
        <div className="row chamadas">
          {isLoadingEvents ? (
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <FormattedMessage id="loading" />
                </div>
              </div>
            </div>
          ) : (
            <>
              {eventos.length !== 0 ? (
                eventos.map((evento, key) => {
                  let localeData = evento.translations.filter((e) =>
                    traducaoSiglaLocale(e.locale, locale)
                  );
                  localeData = localeData.length > 0 ? localeData[0] : null;
                  return (
                    <div className="col-lg-6" key={key}>
                      <div className="card">
                        <div className="card-body">
                          <a
                            href={`/inscription/${evento.id}/`}
                            title={evento.name}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                moment(evento.deadline).format("YYYY-MM-DD") <
                                moment(new Date()).format("YYYY-MM-DD")
                              ) {
                                alert(
                                  intl.formatMessage({
                                    id: "inscriptions.deadline",
                                  })
                                );
                              } else {
                                window.open(
                                  `/inscription/new/${evento.id}`,
                                  "_self"
                                );
                              }
                            }}
                          >
                            <div className="content-chamada">
                              <figure>
                                <img
                                  src={
                                    evento.image ? evento.image : NoImageChamada
                                  }
                                  alt={evento.name}
                                />
                              </figure>
                              <div className="text-chamada">
                                <h3>{evento.name}</h3>
                                <div className="descriptionsLink"
                                  dangerouslySetInnerHTML={{
                                    __html: localeData?.description,
                                  }}
                                />
                                <h4>
                                  <FormattedMessage id="inscriptions.game.limit" />
                                </h4>
                                <p>
                                  {moment(evento.limit_existence_game).format(
                                    "DD/MMMM/YYYY"
                                  )}
                                </p>
                                <h4>
                                  <FormattedMessage id="inscriptions.game.deadline" />
                                </h4>
                                <p>
                                  {moment(evento.deadline).format(
                                    "DD/MMMM/YYYY"
                                  )}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-body">
                      <p>
                        <FormattedMessage id="dashboard.event.empty-calls-inscriptions" />
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(InscriptionsChamadas));
