import axios from "axios";
import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { useHistory, Link } from "react-router-dom";
import "./Account.css";
import imgLogo from "../../assets/images/v2/logo-big-new-preto.svg";
import imgVoltar from "../../assets/images/v2/arrow-left.svg";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schemaSendEmail = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("E-mail é obrigatório"),
});

function ForgotPassword() {
  const intl = useIntl();
  const history = useHistory();
  const [codeSended, setCodeSended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorRequest, setIsErrorRequest] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSendEmail),
  });

  useEffect(() => {
    console.log("blabas");
  }, []);

  const sendEmail = async (data) => {
    setIsLoading(true);
    setIsErrorRequest(false);
    let url = `/api/v1/account/forgot-password/send`;
    const response = await axios.post(url, data);

    if (response.data.auth) {
      setCodeSended(true);
    } else {
      setIsErrorRequest(true);
    }

    setIsLoading(false);
  };

  const resetPassword = async (data) => {
    setIsLoading(true);
    let url = `/api/v1/account/forgot-password/reset`;
    const response = await axios.post(url, data);

    if (response.data.auth) history.push(`/`);

    setIsLoading(false);
  };

  const renderFunction = () => {
    console.log("codeSended", codeSended);
    if (codeSended) return renderValidateCode();
    else return renderSendCode();
  };

  const renderSendCode = () => {
    return (
      <form className="m-t-30" onSubmit={handleSubmit(sendEmail)}>
        <FormattedMessage id="account.forgot-password.input-label-login">
          {(message) => (
            <div className={`form-group ${errors?.email ? "input-error" : ""}`}>
              <label>{message}</label>
              <input
                type="email"
                name="email"
                className="form-control form-control-sm"
                placeholder={intl.formatMessage({
                  id: "account.forgot-password.input-placeholder-login",
                })}
                {...register("email")}
              />
            </div>
          )}
        </FormattedMessage>

        <div className="text-center m-b-15 m-t-15">
          {isLoading ? (
            <div
              className="btn btn-primary"
              style={{ position: "relative", height: "40px" }}
            >
              <svg className="circular" viewBox="25 25 50 50">
                <circle
                  className="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary">
              <FormattedMessage id="account.forgot-password.btn-send" />
            </button>
          )}
        </div>
        {isErrorRequest && (
          <div className="form-group col-12 alert">
            {/* <div className="alert alert-success">Dados salvos com sucesso!</div> */}
            <div className="alert alert-danger">
              <FormattedMessage id={"forgot-password.fail-forgot"} />
            </div>
          </div>
        )}
      </form>
    );
  };

  const renderValidateCode = () => {
    return (
      <form className="m-t-30" onSubmit={handleSubmit(resetPassword)}>
        <FormattedMessage id="account.forgot-password.input-label-email">
          {(message) => (
            <div className="form-group">
              <label>{message}</label>
              <input
                type="text"
                name="email"
                readOnly={true}
                className="form-control form-control-sm"
                {...register("email")}
              />
            </div>
          )}
        </FormattedMessage>
        <FormattedMessage id="account.forgot-password.input-label-code">
          {(message) => (
            <div className="form-group">
              <label>{message}</label>
              <input
                type="text"
                name="code"
                className="form-control form-control-sm"
                placeholder={intl.formatMessage({
                  id: "account.forgot-password.input-placeholder-code",
                })}
                {...register("code")}
              />
            </div>
          )}
        </FormattedMessage>
        <FormattedMessage id="account.forgot-password.input-label-new-password">
          {(message) => (
            <div className="form-group">
              <label>{message}</label>
              <input
                type="password"
                name="password"
                className="form-control form-control-sm"
                placeholder={intl.formatMessage({
                  id: "account.forgot-password.input-placeholder-new-password",
                })}
                {...register("password")}
              />
            </div>
          )}
        </FormattedMessage>

        <div className="text-center m-b-15 m-t-15">
          {isLoading ? (
            <div
              className="btn btn-primary"
              style={{ position: "relative", height: "40px" }}
            >
              <svg className="circular" viewBox="25 25 50 50">
                <circle
                  className="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary">
              <FormattedMessage id="account.forgot-password.btn-changed-password" />
            </button>
          )}
        </div>
      </form>
    );
  };

  return (
    <div className="login-screen">
      <div className="login-bg h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-xl-6">
              <div className="form-input-content">
                <div className="card">
                  <div className="card-body">
                    <header>
                      <div className="btn-back">
                        <Link to={"/"} title="Voltar">
                          <img src={imgVoltar} alt="Voltar" />
                          <FormattedMessage id="account.login.previous" />
                        </Link>
                      </div>
                      <div className="logo text-center">
                        <a href="/">
                          <img src={imgLogo} alt="BIG Festival" />
                        </a>
                      </div>
                      <h1 className="text-center m-t-30 card-title">
                        <FormattedMessage id="account.home.forgot-password" />
                      </h1>
                    </header>
                    {renderFunction()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(ForgotPassword);
