import BaseFrame from './BaseFrame'
import { renderTextInput,
    renderSelect,
    renderTextTagsInput,
    renderTextArea } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameGameAccess extends BaseFrame {

    constructor(props) {
        super(props)

        this.state = {
            show_code: false,
            show_url: false
        }
    }

    onValueChanged = () => {
        let stateChanged = false
        
        const show_code = (this.props.inscription.gameAccess?.accesstype == 0
            && this.props.inscription.gameAccess?.accesstype != null
            && this.props.inscription.gameAccess?.accesstype != undefined
            && this.props.inscription.gameAccess?.accesstype !== "")
        const show_url = this.props.inscription.gameAccess?.accesstype >= 10

        stateChanged = stateChanged || this.state.show_code !== show_code
        stateChanged = stateChanged || this.state.show_url !== show_url

        if (stateChanged)
            this.setState({
                show_code,
                show_url
            })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.access" /></h2>
                <div className="row">
                    {renderSelect({
                        name: "accesstype",
                        label: "inscriptions.access.access",
                        description: "inscriptions.access.access.description",
                        placeholder: "select",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("gameAccess", e),
                        value: this.fieldValueModel("gameAccess", "accesstype"),
                        options: [
                            {value: 0, label: "inscriptions.access.access.key"},
                            {value: 10, label: "inscriptions.access.access.download"},
                            {value: 20, label: "inscriptions.access.access.url"},
                        ]
                    })}

                    {this.state.show_code && renderTextTagsInput({
                        name: "keycodes",
                        label: "inscriptions.access.keycodes",
                        description: "inscriptions.access.keycodes.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("gameAccess", e),
                        value: this.fieldValueModel("gameAccess", "keycodes"),
                    })}

                    {this.state.show_code && renderTextInput({
                        name: "storecode",
                        label: "inscriptions.access.storecode",
                        description: "inscriptions.access.storecode.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("gameAccess", e),
                        value: this.fieldValueModel("gameAccess", "storecode"),
                    })}

                    {this.state.show_url && renderTextInput({
                        name: "gameurl",
                        label: "inscriptions.access.gameurl",
                        description: "inscriptions.access.gameurl.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("gameAccess", e),
                        value: this.fieldValueModel("gameAccess", "gameurl"),
                    })}

                    {renderTextArea({
                        name: "information",
                        label: "inscriptions.access.information",
                        description: "inscriptions.access.information.description",
                        onChange: (e) => this.onChangeFieldModel("gameAccess", e),
                        value: this.fieldValueModel("gameAccess", "information"),
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameGameAccess)