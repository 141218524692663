import axios from 'axios'
import React, { Component } from 'react'
import { withRouter } from "react-router"
import ReCAPTCHA from "react-google-recaptcha"
import Accordion from 'react-bootstrap/Accordion'
import { FormattedMessage, injectIntl } from "react-intl"

import Genres from '../../components/Genre'
import FrameReadonlyProfile from './FrameReadonlyProfile'
import FrameReadonlyTerms from './FrameReadonlyTerms'
import FrameReadonlyGame from './FrameReadonlyGame'
import FrameReadonlyPlatform from './FrameReadonlyPlatform'
import FrameReadonlyDetails from './FrameReadonlyDetails'
import FrameReadonlyAccess from './FrameReadonlyAccess'
import FrameReadonlyFestival from './FrameReadonlyFestival'
import FrameReadonlyStudio from './FrameReadonlyStudio'
import FrameReadonlyOthers from './FrameReadonlyOthers'

import validators from '../../components/validatorControl'

import validateData from "./validate.json"

class FrameSend extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ok: false,
            isValidate: false,
            token: "",
            message: "",
            validation: {}
        }

        this.fieldMap = {
            "profile": "profile",
            "terms": "term",
            "gamedata": "game",
            "platform": "game",
            "details": "game",
            "access": "gameAccess",
            "festival": "festival",
            "publisher": "studio",
            "others": "festival"
        }
    }

    componentDidMount() {
        this.validate()
    }

    validate = () => {
        let ok = true
        let itensNotOk = {}

        for (const [areaKey, area] of Object.entries(validateData)) {
            let item = this.props.inscription

            if (this.fieldMap[areaKey])
                item = this.props.inscription[this.fieldMap[areaKey]]

            for (const [fieldKey, validator ] of Object.entries(area)) {
                let log = false
                
                if (fieldKey === "ownershipauthorized")
                    log = true

                try {
                    if (validator.required === 1) {
                        const itemOk =  item
                                        && fieldKey in item
                                        && item[fieldKey] !== null 
                                        && item[fieldKey] !== undefined 
                                        && item[fieldKey] !== ""
    
                        if (!itemOk) {
                            ok = false
                            
                            if (!itensNotOk[areaKey]) itensNotOk[areaKey] = []
                            itensNotOk[areaKey].push(fieldKey)
                        }
                    }
                    else if (validator.required === 2) {
                        let okParent = false
    
                        if (item && validator.field in item && Array.isArray(item[validator.field])) {
                            okParent = item[validator.field].includes(validator.value)
                        }
                        else if (item && validator.field in item && Array.isArray(validator.value)) {
                            okParent = validator.value.includes(item[validator.field])
                        }
                        else if (item && validator.field in item) {
                            okParent = item[validator.field] === validator.value
                        }
    
                        if (okParent) {
                            const itemOk = item[fieldKey] !== null && item[fieldKey] !== undefined && item[fieldKey] !== ""
                            
                            if (!itemOk) {
                                ok = false
                                
                                if (!itensNotOk[areaKey]) itensNotOk[areaKey] = []
                                itensNotOk[areaKey].push(fieldKey)
                            }
                        }
                    }

                    if (validator.validator) {
                        if (item 
                            && (fieldKey in item)
                            && item[fieldKey] !== null 
                            && item[fieldKey] !== undefined 
                            && item[fieldKey] !== "") {    

                            const itemOk = validators[validator.validator](item[fieldKey], validator, item, this.props.event)
        
                            if (!itemOk) {
                                ok = false
                                
                                if (!itensNotOk[areaKey]) itensNotOk[areaKey] = []
                                itensNotOk[areaKey].push(fieldKey)
                            }
                        }
                    }
                }
                catch (e) {
                    console.log(e)
                    if (!itensNotOk[areaKey]) itensNotOk[areaKey] = []
                    itensNotOk[areaKey].push(fieldKey)
                    ok = false
                }
            }   
        }

        this.setState({ ok, validation: itensNotOk, isValidate: true })
    }

    setToken = (token) => {
        this.setState({token})
    }

    sendConfirm = (e) => {
        e.preventDefault()

        const url = `/api/v1/inscription/confirm/${this.props.inscription.id}`
        const item = {
            token: this.state.token
        }

        axios.post(url, item, { headers: {
            'x-access-token': localStorage.getItem("token")
        }})
        .then ((result) => {
            if (result.status === 200 && result.data.updated === true) {
                this.props.history.push(`/inscriptions/`)
            }
            else {
                this.setState({message: "inscriptions.send.tokenincorrect"})
            }
        })
    }

    sendBackToEdit = (e) => {
        e.preventDefault()

        const url = `/api/v1/inscription/${this.props.inscription.id}/reopen`
        const item = {
            token: this.state.token
        }

        axios.post(url, item, { headers: {
            'x-access-token': localStorage.getItem("token")
        }})
        .then ((result) => {
            if (result.status === 200 && result.data.updated === true) {
                window.location.reload()
            }
            else {
                this.setState({message: "inscriptions.send.tokenincorrect"})
            }
        })
    }

    renderOk() {
        return (
            <div className="col-12 form-group">
                <h3><FormattedMessage id="inscriptions.send.ok" /></h3>
                <ReCAPTCHA sitekey="6LcuzJUaAAAAALQTS6n9NeVXxFNrWpYZY7loztLW" onChange={this.setToken} />
                <p><FormattedMessage id="inscriptions.send.ok.description" /></p>
                <button className="btn btn-dark" type="button" onClick={this.sendConfirm}>
                    <FormattedMessage id="inscriptions.send.ok.button" />
                </button>
            </div>
        )
    }

    navigate(e, key) {
        e.preventDefault()
        const keysOrder = Object.keys(validateData)
        this.props.moveFrame?.(keysOrder.indexOf(key))
    }

    renderNotOk() {
        return (
            <div className="col-12 form-group">
                <p className="notok"><FormattedMessage id="inscriptions.send.notok" /></p>
            </div>
        )
    }

    isFrameOk(key) {
        return !this.state.validation[key]
    }

    fieldsNotOk(key) {
        return this.state.validation[key]
    }

    renderBackStatus() {
        return (
            <div className="col-12 form-group">
                <h3><FormattedMessage id="inscriptions.send.backStatus" /></h3>
                <ReCAPTCHA sitekey="6LcuzJUaAAAAALQTS6n9NeVXxFNrWpYZY7loztLW" onChange={this.setToken} />
                <p><FormattedMessage id="inscriptions.send.backStatus.description" /></p>
                <button className="btn btn-dark" type="button" onClick={this.sendBackToEdit}>
                    <FormattedMessage id="inscriptions.send.backStatus.button" />
                </button>
            </div>
        )
    }

    renderFrames() {
        return (
            <div className="col-12 resume">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className={`${this.isFrameOk("profile") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.profile" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "profile")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyProfile profile={this.props.inscription.profile} highlight={this.state.validation.profile} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className={`${this.isFrameOk("terms") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.terms" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "terms")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyTerms term={this.props.inscription.term} highlight={this.state.validation.terms} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className={`${this.isFrameOk("publisher") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.publisher" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "publisher")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyStudio studio={this.props.inscription.studio} highlight={this.state.validation.publisher} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={`${this.isFrameOk("gamedata") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.gamedata" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "gamedata")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyGame game={this.props.inscription.game} highlight={this.state.validation.gamedata} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={`${this.isFrameOk("platform") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.platform" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "platform")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyPlatform game={this.props.inscription.game} highlight={this.state.validation.platform} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header className={`${this.isFrameOk("details") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.details" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "details")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Genres>
                                <FrameReadonlyDetails game={this.props.inscription.game} highlight={this.state.validation.details} />
                            </Genres>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header className={`${this.isFrameOk("access") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.access" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "access")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyAccess gameAccess={this.props.inscription.gameAccess} highlight={this.state.validation.access} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header className={`${this.isFrameOk("festival") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.festival" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "festival")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyFestival festival={this.props.inscription.festival} highlight={this.state.validation.festival} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header className={`${this.isFrameOk("others") ? "frame-ok" : "frame-nok"}`}>
                            <FormattedMessage id="inscriptions.send.others" />
                            {this.props.canEdit && (
                                <button type='button' className="btn btn-link" onClick={(e) => this.navigate(e, "others")}>
                                    <FormattedMessage id="edit" />
                                </button>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                            <FrameReadonlyOthers festival={this.props.inscription.festival} highlight={this.state.validation.others} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        )
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.send.title" /></h2>
                <div className="row">
                    {!this.state.ok && this.renderNotOk() }
                    {this.state.isValidate && this.renderFrames()}
                    {this.state.ok 
                    && [0, 3].includes(this.props.inscription.status)
                    && this.props.canEdit
                    && this.renderOk()}
                    { [1, 2].includes(this.props.inscription.status)
                    && this.props.canBackStatus
                    && this.renderBackStatus()}
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(FrameSend))