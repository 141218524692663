import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import jwt_decode from "jwt-decode";
import ReactExport from "react-export-excel";
import { Country, State, City } from "country-state-city";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function GamesReports() {
  const [games, setGames] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [rating, setRating] = useState(-1);
  const [event, setEvent] = useState(-1);
  const [eventList, setEventList] = useState([]);
  const [status, setStatus] = useState(-1);
  const [orderBy, setOrderBy] = useState("a-z");
  const [gamesVisible, setGamesVisile] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    loadGames();
    loadEvents();
  }, []);

  useEffect(() => {
    var filtered = games;
    if (searchUserName != "") {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(searchUserName.toLowerCase())
      );
    }

    if (rating != -1) {
      filtered = filtered.filter((item) => item.rating.toString() == rating);
    }

    if (event != -1) {
      console.log("event", event);
      filtered = filtered.filter((item) => item.event_id == event);
    }

    if (status != -1) {
      if (status == "finalist") {
        filtered = filtered.filter((item) => {
          if (item.inscription.finalist != null) {
            if (item.inscription.finalist.length != 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      } else if (status == "awards") {
        filtered = filtered.filter((item) => {
          if (item.inscription.award != null) {
            if (item.inscription.award.length != 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      } else {
        filtered = filtered.filter((item) => item.is_valid);
      }
    }

    setGamesVisile(filtered);
  }, [searchUserName, rating, event, status]);

  function sortBy(arr, o = 1) {
    return [...arr].sort((a, b) => a.name.localeCompare(b.name) * o);
  }

  function loadGames() {
    let url = "/api/v1/inscritions/games/reports/";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let filtered = res.data;
        console.log("games", filtered);
        setGames(filtered);
        setGamesVisile(filtered);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadEvents() {
    let url = "/api/v1/events";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        setEventList(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"} title="Dashboard">
                  <FormattedMessage id="app.breadcrumb.dashboard" />
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/"} title="Reports">
                  <FormattedMessage id="app.breadcrumb.reports" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.reports-games" />
              </li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <form className="form-custom content-filter">
                  <div className="filter">
                    <div className="form-row">
                      <div className="col-12">
                        <h2 className="card-title">
                          <FormattedMessage id="calls.registered.filter.title" />
                        </h2>
                      </div>
                      <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={searchUserName}
                          onChange={({ target }) =>
                            setSearchUserName(target.value)
                          }
                          placeholder="Nome do jogo"
                        />
                      </div>
                      <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="games.list.filter.rating" />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          value={rating}
                          onChange={({ target }) => setRating(target.value)}
                        >
                          <option value={-1}>
                            {intl.formatMessage({ id: "todos-os-jogos" })}
                          </option>
                          <option value={true}>
                            {intl.formatMessage({ id: "avaliados" })}
                          </option>
                          <option value={false}>
                            {intl.formatMessage({ id: "nao-avaliados" })}
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="app.menu.calls" />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          value={event}
                          onChange={({ target }) => setEvent(target.value)}
                        >
                          <option value={-1}>Todas as chamadas</option>
                          {eventList.length != 0 &&
                            eventList.map((e, i) => {
                              return (
                                <option key={i} value={e.id}>
                                  {e.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="inscriptions.list.status" />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          value={status}
                          onChange={({ target }) => setStatus(target.value)}
                        >
                          <option value={-1}>
                            {intl.formatMessage({ id: "todos-os-jogos" })}
                          </option>
                          <option value={"finalist"}>
                            {intl.formatMessage({
                              id: "dashboard.game.finalist",
                            })}
                          </option>
                          <option value={"awards"}>
                            {intl.formatMessage({
                              id: "ganhadores",
                            })}
                          </option>
                          <option value={"is_valid"}>
                            {intl.formatMessage({
                              id: "jogos-invalidos",
                            })}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="todos-os-jogos" />
                </h1>

                {isLoading ? (
                  <p>
                    <FormattedMessage id="loading" />
                  </p>
                ) : (
                  <>
                    <form className="form-custom content-filter">
                      <div className="filter">
                        <div className="form-row justify-content-between">
                          <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12"></div>
                          <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label>
                              <FormattedMessage id="calls.inscriptions.event.finalist.filter-order" />
                            </label>
                            <select
                              className="form-control form-control-sm"
                              value={orderBy}
                              onChange={({ target }) =>
                                setOrderBy(target.value)
                              }
                            >
                              <option value={"a-z"}>A - Z</option>
                              <option value={"z-a"}>Z - A</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="table-responsive">
                      {gamesVisible.length != 0 ? (
                        <table className="table verticle-middle table-lista-big">
                          <thead>
                            <tr>
                              <th scope="col">
                                <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="dashboard.game.country" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="chamada" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="dashboard.game.platforms" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="qtd.-de-vezes-finalista" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="acoes" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortBy(
                              gamesVisible,
                              orderBy == "a-z" ? 1 : -1
                            ).map((game, index) => {
                              return (
                                <tr key={index}>
                                  <td>{game.name}</td>
                                  <td>
                                    {game.form123
                                      ? game.form123[
                                          "País do estúdio que desenvolveu o jogo"
                                        ]
                                      : "---"}
                                  </td>
                                  <td>{game.event.name}</td>
                                  <td>
                                    {game.form123
                                      ? game.form123.Plataformas
                                      : "---"}
                                  </td>
                                  {/* <td>---</td> */}
                                  <td>
                                    {game.finalist !== null
                                      ? game.finalist.length != 0
                                        ? game.finalist.length
                                        : "0"
                                      : "0"}
                                  </td>
                                  <td>
                                    <span>
                                      <Link
                                        to={{
                                          pathname: `/games/detail/${game.id}`,
                                          state: {
                                            game,
                                          },
                                        }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Visualizar"
                                      >
                                        <i class="fa fa-eye color-muted m-r-5"></i>
                                      </Link>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p>
                          <FormattedMessage id="nao-existe-jogos" />
                        </p>
                      )}
                    </div>
                  </>
                )}

                {gamesVisible.length != 0 && (
                  <div className="form-row">
                    <div className="form-group col-12">
                      <ExcelFile
                        element={
                          <button className="btn btn-primary">
                            <FormattedMessage id="exportar-relatorio" />
                          </button>
                        }
                      >
                        <ExcelSheet data={gamesVisible} name="Games Relatório">
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "calls.inscriptions.event.finalist.filter-name",
                            })}
                            value="name"
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "dashboard.game.country",
                            })}
                            value={(col) =>
                              col.form123
                                ? col.form123[
                                    "País do estúdio que desenvolveu o jogo"
                                  ]
                                : "---"
                            }
                          />
                          <ExcelColumn
                            label={intl.formatMessage({ id: "chamada" })}
                            value={(col) => col.event.name}
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "nome-do-responsavel",
                            })}
                            value={(col) => col.user.profile.name}
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "account.forgot-password.input-label-email",
                            })}
                            value={(col) => col.user.login}
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "component.my-account-form.input-label-phone-whatsapp",
                            })}
                            value={(col) =>
                              `${
                                col.user.profile.country &&
                                Country.getCountryByCode(
                                  col.user.profile.country
                                ).phonecode
                              } ${
                                col.user.profile.phoneWhatsapp &&
                                col.user.profile.phoneWhatsapp
                              }`
                            }
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "avaliacoes-dos-selecionadores",
                            })}
                            value={(col) =>
                              JSON.stringify(col.avaliacoesSelecionadores)
                            }
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "avaliacoes-dos-juris",
                            })}
                            value={(col) => JSON.stringify(col.avaliacoesJuri)}
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "categorias-finalista",
                            })}
                            value={(col) =>
                              `${col.finalist && col.finalist.toString()}`
                            }
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "categorias-vencedoras",
                            })}
                            value={(col) =>
                              `${col.award && col.award.toString()}`
                            }
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "dashboard.game.platforms",
                            })}
                            value={(col) =>
                              col.form123 ? col.form123.Plataformas : "---"
                            }
                          />
                          <ExcelColumn
                            label={intl.formatMessage({
                              id: "link-para-detalhes-do-jogo-no-123-form",
                            })}
                            value={(col) =>
                              `${col.form123edit && col.form123edit}`
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(GamesReports));
