import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl } from "react-intl";

function NotFoundPage(props) {
  const history = useHistory();

  useEffect(() => {
    history.push("/");
  }, []);

  return <></>;
}

export default injectIntl(withAuth(NotFoundPage));
