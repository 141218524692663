import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import moment from "moment";

function ModalTesteFilter({ filterActive }) {
  const intl = useIntl();
  const [categoriasDeFinalista, setCategoriasDeFinalista] = useState([]);
  const [categoriasDeFinalistaOptions, setCategoriasDeFinalistaOptions] =
    useState([]);
  const [categoriasDeVencedores, setCategoriasDeVencedores] = useState([]);
  const [categoriasDeVencedoresOptions, setCategoriasDeVencedoresOptions] =
    useState([]);
  const [status, setStatus] = useState([]);
  const statusOptions = [
    {
      value: "finalist",
      label: intl.formatMessage({
        id: "dashboard.game.finalist",
      }),
    },
    {
      value: "awards",
      label: intl.formatMessage({
        id: "ganhadores",
      }),
    },
  ];
  const [plataformas, setPlataformas] = useState([]);
  const [plataformasOptions, setPlataformasOptions] = useState([]);
  const [genero, setGenero] = useState([]);
  const generoOptions = [
    { label: "Gênero(s)-Arcade", value: "Gênero(s)-Arcade" },
    { label: "Gênero(s)-Arena", value: "Gênero(s)-Arena" },
    { label: "Gênero(s)-Auto-runner", value: "Gênero(s)-Auto-runner" },
    { label: "Gênero(s)-Aventura", value: "Gênero(s)-Aventura" },
    { label: "Gênero(s)-Aventura Tática", value: "Gênero(s)-Aventura Tática" },
    { label: "Gênero(s)-Ação", value: "Gênero(s)-Ação" },
    {
      label: "Gênero(s)-Baseado em Texto",
      value: "Gênero(s)-Baseado em Texto",
    },
    { label: "Gênero(s)-Battle Royale", value: "Gênero(s)-Battle Royale" },
    { label: "Gênero(s)-Beat Em Up", value: "Gênero(s)-Beat Em Up" },
    { label: "Gênero(s)-Cartas", value: "Gênero(s)-Cartas" },
    { label: "Gênero(s)-Casual", value: "Gênero(s)-Casual" },
    { label: "Gênero(s)-Caça ao Tesouro", value: "Gênero(s)-Caça ao Tesouro" },
    { label: "Gênero(s)-Corrida", value: "Gênero(s)-Corrida" },
    { label: "Gênero(s)-Dedução Social", value: "Gênero(s)-Dedução Social" },
    { label: "Gênero(s)-Educacional", value: "Gênero(s)-Educacional" },
    { label: "Gênero(s)-Esporte", value: "Gênero(s)-Esporte" },
    { label: "Gênero(s)-Estratégia", value: "Gênero(s)-Estratégia" },
    { label: "Gênero(s)-FPS", value: "Gênero(s)-FPS" },
    { label: "Gênero(s)-Família", value: "Gênero(s)-Família" },
    { label: "Gênero(s)-Fantasia", value: "Gênero(s)-Fantasia" },
    { label: "Gênero(s)-Horror/Terror", value: "Gênero(s)-Horror/Terror" },
    { label: "Gênero(s)-Luta", value: "Gênero(s)-Luta" },
    { label: "Gênero(s)-Narrativa", value: "Gênero(s)-Narrativa" },
    { label: "Gênero(s)-Party Game", value: "Gênero(s)-Party Game" },
    { label: "Gênero(s)-Plataforma", value: "Gênero(s)-Plataforma" },
    { label: "Gênero(s)-Point and Click", value: "Gênero(s)-Point and Click" },
    { label: "Gênero(s)-Puzzle", value: "Gênero(s)-Puzzle" },
    { label: "Gênero(s)-RPG", value: "Gênero(s)-RPG" },
    {
      label: "Gênero(s)-Realidade Virtual",
      value: "Gênero(s)-Realidade Virtual",
    },
    { label: "Gênero(s)-Ritmo", value: "Gênero(s)-Ritmo" },
    { label: "Gênero(s)-Roguelite", value: "Gênero(s)-Roguelite" },
    { label: "Gênero(s)-Side-Scrolling", value: "Gênero(s)-Side-Scrolling" },
    { label: "Gênero(s)-Simulação", value: "Gênero(s)-Simulação" },
    { label: "Gênero(s)-Sobrevivência", value: "Gênero(s)-Sobrevivência" },
    { label: "Gênero(s)-Social Game", value: "Gênero(s)-Social Game" },
    { label: "Gênero(s)-Tabuleiro", value: "Gênero(s)-Tabuleiro" },
    { label: "Gênero(s)-Tiro", value: "Gênero(s)-Tiro" },
    { label: "Gênero(s)-Trivia", value: "Gênero(s)-Trivia" },
    { label: "Gênero(s)-Type'Em Up", value: "Gênero(s)-Type'Em Up" },
    { label: "Gênero(s)-Visual Novel", value: "Gênero(s)-Visual Novel" },
  ];
  const [classificacao, setClassificacao] = useState([]);
  const [classificacaoOptions, setClassificacaoOptions] = useState([]);
  const [pais, setPais] = useState([]);
  const [paisOptions, setPaisOptions] = useState([]);
  const [dataDeLancamento, setDataDeLancamento] = useState("");
  const [dataDeEnvio, setdataDeEnvio] = useState("");
  const [chamadas, setchamadas] = useState([]);
  const [chamadasOptions, setchamadasOptions] = useState([]);
  const [avaliacao, setavaliacao] = useState([]);
  const avaliacaoOptions = [
    {
      label: intl.formatMessage({ id: "avaliados" }),
      value: true,
    },
    {
      label: intl.formatMessage({ id: "nao-avaliados" }),
      value: false,
    },
  ];
  const [jogos, setjogos] = useState("");

  const [games, setGames] = useState([]);
  const [gamesVisible, setGamesVisible] = useState([]);

  useEffect(() => {
    loadGames();
  }, []);

  useEffect(() => {
    var filtered = games;

    if (status.length != 0) {
      status.forEach((status) => {
        if (status.value == "finalist") {
          filtered = games.filter((item) => {
            if (item.finalist != null) {
              if (item.finalist.length != 0) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        } else if (status.value == "awards") {
          filtered = games.filter((item) => {
            if (item.award != null) {
              if (item.award.length != 0) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      });
    }

    if (categoriasDeFinalista.length != 0) {
      let cateFinalistaFilter = [];
      categoriasDeFinalista.forEach((category) => {
        cateFinalistaFilter.push(category.value);
      });
      filtered = filtered.filter((item) => {
        if (item.finalist != null) {
          return cateFinalistaFilter.some((el) => item.finalist.includes(el));
        } else {
          return false;
        }
      });
    }

    if (categoriasDeVencedores.length != 0) {
      let cateVencedoresFilter = [];
      categoriasDeVencedores.forEach((category) => {
        cateVencedoresFilter.push(category.value);
      });
      filtered = filtered.filter((item) => {
        if (item.award != null) {
          return cateVencedoresFilter.some((el) => item.award.includes(el));
        } else {
          return false;
        }
      });
    }

    if (plataformas.length != 0) {
      let platFilter = [];
      plataformas.forEach((category) => {
        platFilter.push(category.value);
      });

      filtered = filtered.filter((item) => {
        if (item.form123 != null) {
          return platFilter.some((el) => item.form123.Plataformas.includes(el));
        }
      });
    }

    if (genero.length != 0) {
      let generoFilter = [];
      genero.forEach((category) => {
        generoFilter.push(category.value);
      });

      filtered = filtered.filter((item) => {
        if (item.form123 != null) {
          return generoFilter.some((el) => getGeneroList(item).includes(el));
        }
      });
    }

    if (classificacao.length != 0) {
      let classificacaoFilter = [];
      classificacao.forEach((category) => {
        classificacaoFilter.push(category.value);
      });

      filtered = filtered.filter((item) => {
        if (item.form123 != null) {
          return classificacaoFilter.some((el) =>
            item.form123["Faixa Etária"].includes(el)
          );
        }
      });
    }

    if (pais.length != 0) {
      let paisFilter = [];
      pais.forEach((category) => {
        paisFilter.push(category.value);
      });

      filtered = filtered.filter((item) => {
        if (item.form123 != null) {
          return paisFilter.some((el) =>
            item.form123["País do estúdio que desenvolveu o jogo"].includes(el)
          );
        }
      });
    }

    if (chamadas.length != 0) {
      let chamadasFilter = [];
      chamadas.forEach((category) => {
        chamadasFilter.push(category.value);
      });

      filtered = filtered.filter((item) => {
        return chamadasFilter.some((el) => item.event.name.includes(el));
      });
    }

    if (avaliacao.length != 0) {
      let avaliacaoFilter = [];
      avaliacao.forEach((category) => {
        avaliacaoFilter.push(category.value);
      });

      filtered = filtered.filter((item) => {
        return avaliacaoFilter.some((el) => item.rating == el);
      });
    }

    if (jogos != "") {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(jogos.toLowerCase())
      );
    }

    setGamesVisible(filtered);
  }, [
    categoriasDeFinalista,
    categoriasDeVencedores,
    status,
    plataformas,
    genero,
    classificacao,
    pais,
    dataDeLancamento,
    dataDeEnvio,
    chamadas,
    avaliacao,
    jogos,
  ]);

  function loadGames() {
    let url = "/api/v1/inscritions/games";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        console.log("games", itens);
        setGames(itens);
        setGamesVisible(itens);
        setCategoriasDeFinalistaOptions(getCategoria(itens));
        setCategoriasDeVencedoresOptions(getCategoria(itens));
        setPlataformasOptions(getPlatforms(itens));
        setClassificacaoOptions(getClassficação(itens));
        setPaisOptions(getPais(itens));
        setchamadasOptions(getEvents(itens));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  const getCategoria = (games) => {
    let categorias = [];
    let categoriasOptions = [];
    games.forEach((game) => {
      game.event.categoria_avaliacao.forEach((category) => {
        if (!categorias.includes(category)) {
          categorias.push(category);
          categoriasOptions.push({ label: category, value: category });
        }
      });
      game.event.categoria_extras.forEach((category) => {
        if (!categorias.includes(category)) {
          categorias.push(category);
          categoriasOptions.push({ label: category, value: category });
        }
      });
    });

    return categoriasOptions;
  };

  const getPlatforms = (games) => {
    let categorias = [];
    let categoriasOptions = [];
    games.forEach((game) => {
      if (game.form123) {
        let platforms = game.form123.Plataformas.split(",");
        platforms.forEach((category) => {
          if (!categorias.includes(category)) {
            categorias.push(category);
            categoriasOptions.push({ label: category, value: category });
          }
        });
      }
    });

    return categoriasOptions;
  };

  const getClassficação = (games) => {
    let categorias = [];
    let categoriasOptions = [];
    games.forEach((game) => {
      if (game.form123) {
        if (!categorias.includes(game.form123["Faixa Etária"])) {
          categorias.push(game.form123["Faixa Etária"]);
          categoriasOptions.push({
            label: game.form123["Faixa Etária"],
            value: game.form123["Faixa Etária"],
          });
        }
      }
    });

    return categoriasOptions;
  };

  const getPais = (games) => {
    let categorias = [];
    let categoriasOptions = [];
    games.forEach((game) => {
      if (game.form123) {
        if (
          !categorias.includes(
            game.form123["País do estúdio que desenvolveu o jogo"]
          )
        ) {
          categorias.push(
            game.form123["País do estúdio que desenvolveu o jogo"]
          );
          categoriasOptions.push({
            label: game.form123["País do estúdio que desenvolveu o jogo"],
            value: game.form123["País do estúdio que desenvolveu o jogo"],
          });
        }
      }
    });

    return categoriasOptions;
  };

  const getEvents = (games) => {
    let categorias = [];
    let categoriasOptions = [];
    games.forEach((game) => {
      if (!categorias.includes(game.event.name)) {
        categorias.push(game.event.name);
        categoriasOptions.push({
          label: game.event.name,
          value: game.event.name,
        });
      }
    });

    return categoriasOptions;
  };

  const renderFilter = () => {
    return (
      <form className="form-custom content-filter mb-3">
        <div className="filter">
          <div className="form-row">
            <div className="col-12">
              <h2 className="card-title">
                <FormattedMessage id="form.filterBy" />
              </h2>
            </div>
            {filterActive.includes("categoria-de-finalista") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="categorias-de-finalista" />
                </label>
                <MultiSelect
                  options={categoriasDeFinalistaOptions}
                  value={categoriasDeFinalista}
                  onChange={setCategoriasDeFinalista}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("categoria-de-vencedores") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="categorias-de-vencedores" />
                </label>
                <MultiSelect
                  options={categoriasDeVencedoresOptions}
                  value={categoriasDeVencedores}
                  onChange={setCategoriasDeVencedores}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("status") && (
              <div className="form-group ccol-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="status" />
                </label>
                <MultiSelect
                  options={statusOptions}
                  value={status}
                  onChange={setStatus}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("plataformas") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="plataformas" />
                </label>
                <MultiSelect
                  options={plataformasOptions}
                  value={plataformas}
                  onChange={setPlataformas}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("genero") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="genero" />
                </label>
                <MultiSelect
                  options={generoOptions}
                  value={genero}
                  onChange={setGenero}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("classificacao") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="classificacao" />
                </label>
                <MultiSelect
                  options={classificacaoOptions}
                  value={classificacao}
                  onChange={setClassificacao}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("pais") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="pais" />
                </label>
                <MultiSelect
                  options={paisOptions}
                  value={pais}
                  onChange={setPais}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("data-de-lancamento") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="data-de-lancamento" />
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  placeholder="Data de Lançamento"
                  value={dataDeLancamento}
                  onChange={(e) => {
                    setDataDeLancamento(e);
                  }}
                />
              </div>
            )}

            {filterActive.includes("data-de-envio") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="data-de-envio" />
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  placeholder="Data de Envio"
                  value={dataDeEnvio}
                  onChange={(e) => {
                    setdataDeEnvio(e);
                  }}
                />
              </div>
            )}

            {filterActive.includes("chamadas") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="chamadas" />
                </label>
                <MultiSelect
                  options={chamadasOptions}
                  value={chamadas}
                  onChange={setchamadas}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("avaliacao") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="avaliacao" />
                </label>
                <MultiSelect
                  options={avaliacaoOptions}
                  value={avaliacao}
                  onChange={setavaliacao}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </div>
            )}

            {filterActive.includes("jogos") && (
              <div className="form-group col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>
                  <FormattedMessage id="jogos" />
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Nome do jogo"
                  value={jogos}
                  onChange={(e) => {
                    setjogos(e.value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    );
  };

  function getGeneroList(game) {
    let genero = [];
    if (game.form123) {
      if (game.form123["Gênero(s)-Arcade"] == "yes") {
        genero.push("Gênero(s)-Arcade");
      }
      if (game.form123["Gênero(s)-Arena"] == "yes") {
        genero.push("Gênero(s)-Arena");
      }
      if (game.form123["Gênero(s)-Auto-runner"] == "yes") {
        genero.push("Gênero(s)-Auto-runner");
      }
      if (game.form123["Gênero(s)-Aventura"] == "yes") {
        genero.push("Gênero(s)-Aventura");
      }
      if (game.form123["Gênero(s)-Aventura Tática"] == "yes") {
        genero.push("Gênero(s)-Aventura Tática");
      }
      if (game.form123["Gênero(s)-Ação"] == "yes") {
        genero.push("Gênero(s)-Ação");
      }
      if (game.form123["Gênero(s)-Baseado em Texto"] == "yes") {
        genero.push("Gênero(s)-Baseado em Texto");
      }
      if (game.form123["Gênero(s)-Battle Royale"] == "yes") {
        genero.push("Gênero(s)-Battle Royale");
      }
      if (game.form123["Gênero(s)-Beat Em Up"] == "yes") {
        genero.push("Gênero(s)-Beat Em Up");
      }
      if (game.form123["Gênero(s)-Cartas"] == "yes") {
        genero.push("Gênero(s)-Cartas");
      }
      if (game.form123["Gênero(s)-Casual"] == "yes") {
        genero.push("Gênero(s)-Casual");
      }
      if (game.form123["Gênero(s)-Caça ao Tesouro"] == "yes") {
        genero.push("Gênero(s)-Caça ao Tesouro");
      }
      if (game.form123["Gênero(s)-Corrida"] == "yes") {
        genero.push("Gênero(s)-Corrida");
      }
      if (game.form123["Gênero(s)-Dedução Social"] == "yes") {
        genero.push("Gênero(s)-Dedução Social");
      }
      if (game.form123["Gênero(s)-Educacional"] == "yes") {
        genero.push("Gênero(s)-Educacional");
      }
      if (game.form123["Gênero(s)-Esporte"] == "yes") {
        genero.push("Gênero(s)-Esporte");
      }
      if (game.form123["Gênero(s)-Estratégia"] == "yes") {
        genero.push("Gênero(s)-Estratégia");
      }
      if (game.form123["Gênero(s)-FPS"] == "yes") {
        genero.push("Gênero(s)-FPS");
      }
      if (game.form123["Gênero(s)-Família"] == "yes") {
        genero.push("Gênero(s)-Família");
      }
      if (game.form123["Gênero(s)-Fantasia"] == "yes") {
        genero.push("Gênero(s)-Fantasia");
      }
      if (game.form123["Gênero(s)-Horror/Terror"] == "yes") {
        genero.push("Gênero(s)-Horror/Terror");
      }
      if (game.form123["Gênero(s)-Luta"] == "yes") {
        genero.push("Gênero(s)-Luta");
      }
      if (game.form123["Gênero(s)-Narrativa"] == "yes") {
        genero.push("Gênero(s)-Narrativa");
      }
      if (game.form123["Gênero(s)-Party Game"] == "yes") {
        genero.push("Gênero(s)-Party Game");
      }
      if (game.form123["Gênero(s)-Plataforma"] == "yes") {
        genero.push("Gênero(s)-Plataforma");
      }
      if (game.form123["Gênero(s)-Point and Click"] == "yes") {
        genero.push("Gênero(s)-Point and Click");
      }
      if (game.form123["Gênero(s)-Puzzle"] == "yes") {
        genero.push("Gênero(s)-Puzzle");
      }
      if (game.form123["Gênero(s)-RPG"] == "yes") {
        genero.push("Gênero(s)-RPG");
      }
      if (game.form123["Gênero(s)-Realidade Virtual"] == "yes") {
        genero.push("Gênero(s)-Realidade Virtual");
      }
      if (game.form123["Gênero(s)-Ritmo"] == "yes") {
        genero.push("Gênero(s)-Ritmo");
      }
      if (game.form123["Gênero(s)-Roguelite"] == "yes") {
        genero.push("Gênero(s)-Roguelite");
      }
      if (game.form123["Gênero(s)-Side-Scrolling"] == "yes") {
        genero.push("Gênero(s)-Side-Scrolling");
      }
      if (game.form123["Gênero(s)-Simulação"] == "yes") {
        genero.push("Gênero(s)-Simulação");
      }
      if (game.form123["Gênero(s)-Sobrevivência"] == "yes") {
        genero.push("Gênero(s)-Sobrevivência");
      }
      if (game.form123["Gênero(s)-Social Game"] == "yes") {
        genero.push("Gênero(s)-Social Game");
      }
      if (game.form123["Gênero(s)-Tabuleiro"] == "yes") {
        genero.push("Gênero(s)-Tabuleiro");
      }
      if (game.form123["Gênero(s)-Tiro"] == "yes") {
        genero.push("Gênero(s)-Tiro");
      }
      if (game.form123["Gênero(s)-Trivia"] == "yes") {
        genero.push("Gênero(s)-Trivia");
      }
      if (game.form123["Gênero(s)-Type'Em Up"] == "yes") {
        genero.push("Gênero(s)-Type'Em Up");
      }
      if (game.form123["Gênero(s)-Visual Novel"] == "yes") {
        genero.push("Gênero(s)-Visual Novel");
      }
    }
    return genero.length ? genero.toString() : "---";
  }

  const renderJogos = () => {
    return (
      <div className="event-list games">
        <div className="row">
          <div className="col-12">
            {gamesVisible.map((game, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 col-12">
                    <div className="image-event">
                      <img src={game.image} alt="" />
                    </div>
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-9 col-12">
                    <div className="info">
                      <div className="row w-100">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h2>{game.name}</h2>
                          <p>{game.description}</p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.award" />
                          </h3>
                          <p>
                            {game.award !== null
                              ? game.award.length != 0
                                ? game.award.toString()
                                : "---"
                              : "---"}
                          </p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.finalist" />
                          </h3>
                          <p>
                            {game.finalist !== null
                              ? game.finalist.length != 0
                                ? game.finalist.toString()
                                : "---"
                              : "---"}
                          </p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.publisher" />
                          </h3>
                          <p>---</p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.platforms" />
                          </h3>
                          <p>
                            {game.form123 ? game.form123.Plataformas : "---"}
                          </p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.genre" />
                          </h3>
                          <p>{getGeneroList(game)}</p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.country" />
                          </h3>
                          <p>
                            {game.form123
                              ? game.form123[
                                  "País do estúdio que desenvolveu o jogo"
                                ]
                              : "---"}
                          </p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3>
                            <FormattedMessage id="dashboard.game.classification" />
                          </h3>
                          <p>
                            {game.form123
                              ? game.form123["Faixa Etária"]
                              : "---"}
                          </p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 rating">
                          <h3>
                            <FormattedMessage id="dashboard.game.release-date" />
                          </h3>
                          <p>
                            {moment(game.release_date).format("DD/MMMM/YYYY")}
                          </p>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 rating">
                          <h3>
                            <FormattedMessage id="dashboard.game.send-date" />
                          </h3>
                          <p>{moment(game.created).format("DD/MMMM/YYYY")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="modal fade in alert-message-modal"
      id="modalTesteFilter"
      // tabindex="-1"
      role="dialog"
      aria-labelledby="modalTesteFilterLabel"
      aria-hidden="true"
      style={{ maxWidth: "720px" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="testFilterLabel">
              <FormattedMessage id="teste-de-filtro" />
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ textAlign: "start" }}>
            {filterActive.length != 0 && renderFilter()}
            <h4 className="card-title">
              <FormattedMessage id="jogos" />
            </h4>
            {renderJogos()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTesteFilter;
