import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import GamesRatedAdmin from "./template/admin";
import GamesRatedSelecionadorFechado from "./template/selecionadorFechado";
import GamesRatedJuriFechado from "./template/juriFechado";

function GameRatedDetail(props) {
  const intl = useIntl();
  const history = useHistory();
  const user = jwt_decode(localStorage.getItem("token"));
  const { state } = useLocation();
  const [game, setGame] = useState(null);

  useEffect(() => {
    console.log("game", state.game);
    if (state) {
      setGame(state.game);
    } else {
      history.push("/");
    }
  }, []);

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link to={"/games-rated"} title="Jogos Avaliados">
                <FormattedMessage id="app.menu.games-rated" />
              </Link>
            </li>

            <li className="breadcrumb-item active">
              <FormattedMessage id="avaliação-do" />
              {game?.user.profile.name}
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderRating() {
    if (game != null) {
      return <GamesRatedAdmin game={game} />;
      // if (user.type === 10 || user.type === 2) {
      //   return <GamesRatedAdmin game={game} />;
      // } else if (user.type === 7) {
      //   return <GamesRatedSelecionadorFechado game={game} />;
      // } else {
      //   return <GamesRatedJuriFechado game={game} />;
      // }
    }
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="game-detail">
                  <header>
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                        <h1 className="card-title">
                          <FormattedMessage id="avaliação-do" />
                          {game?.user.profile.name}
                        </h1>
                      </div>
                    </div>
                  </header>
                  <div className="row">
                    <div className="col-12">
                      <div className="event-list games avaliacao">
                        {renderRating()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(GameRatedDetail));
