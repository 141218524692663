import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router";
import withAuth from "../../components/auth";
import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

function InscriptionsForm({ intl }) {
  const ref = React.useRef();
  const { id } = useParams();
  const history = useHistory();
  const [iframeUrl, setIframeUrl] = useState("");

  const getUserDatas = async () => {
    try {
      const requestConfig = {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      let url = `/api/v1/inscription/${id}`;
      const responseInscription = await axios.get(url, requestConfig);
      const inscription = responseInscription.data;

      url = `/api/v1/profile`;
      const responseProfile = await axios.get(url, requestConfig);
      const profile = responseProfile.data;

      if (inscription.user_id != profile.id) {
        history.push(`/inscriptions`);
        return;
      }

      url = `/api/v1/event/${inscription.event_id}`;
      const responseEvent = await axios.get(url, requestConfig);
      const event = responseEvent.data;
      let iframeUrl = "";

      if (inscription.form123edit)
        iframeUrl = `${inscription.form123edit}&id=${id}`;
      else if (profile.categoriaUser === "estudio")
        iframeUrl = event.form123_estudio;
      else if (profile.categoriaUser === "publisher")
        iframeUrl = event.form123_publisher;
      else iframeUrl = event.form123_autonomo;

      iframeUrl = iframeUrl.replaceAll("{{id}}", String(id));
      iframeUrl = iframeUrl.replaceAll("{{email}}", profile.email);
      iframeUrl = iframeUrl.replaceAll("{{name}}", inscription.name);
      iframeUrl = iframeUrl.replaceAll(
        "{{description}}",
        inscription.description
      );
      iframeUrl = iframeUrl.replaceAll(
        "{{releaseDate}}",
        moment(inscription.release_date).format("DD/MM/YYYY")
      );

      iframeUrl += `&language=${intl?.locale}`;

      setIframeUrl(iframeUrl);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.open("/", "_self");
      } else {
        history.push(`/inscriptions`);
      }
    }
  };

  useEffect(() => {
    getUserDatas();
  }, []);

  return (
    <div className="content-body">
      <div className="container-fluid">
        <iframe
          ref={ref}
          id="contactform123"
          name="contactform123"
          allowtransparency="true"
          style={{
            height: "calc(100vh)",
            width: "100%",
          }}
          marginWidth="0"
          marginHeight="0"
          frameBorder="0"
          src={iframeUrl}
        >
          <p>
            Your browser does not support iframes. The contact form cannot be
            displayed. Please use another contact method (phone, fax etc)
          </p>
        </iframe>
      </div>
    </div>
  );
}

export default injectIntl(withRouter(withAuth(InscriptionsForm)));
