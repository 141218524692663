import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

function InscriptionsList({ intl }) {
  const history = useHistory();
  const [itens, setItens] = useState([]);
  const [itensVisible, setItensVisible] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [eventoSelecionado, setEventoSelecionado] = useState(-1);
  const [statusSelecionado, setStatusSelecionado] = useState(-1);

  useEffect(() => {
    loadInscriptions();
    loadEvents();
  }, []);

  useEffect(() => {
    let inscricoes = itens;
    if (eventoSelecionado !== -1) {
      inscricoes = inscricoes.filter((item) => {
        if (eventoSelecionado === item.event?.id.toString()) {
          return item;
        }
      });
    }

    if (statusSelecionado !== -1) {
      inscricoes = inscricoes.filter((item) => {
        if (statusSelecionado === item.status.toString()) {
          return item;
        }
      });
    }
    setItensVisible(inscricoes);
  }, [eventoSelecionado, statusSelecionado, itens]);

  function loadEvents() {
    let url = "/api/v1/events";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        setEventos(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadInscriptions(params) {
    let url = "/api/v1/inscriptions";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        setItens(res.data);
        setItensVisible(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function getItemUrl(item) {
    return `/inscription/${item.id}`;
  }

  function renderList() {
    return itensVisible.map((item, key) => {
      return (
        <div className="row" key={key}>
          <div className="col-12">
            <Link to={getItemUrl(item)}>
              <div className="row">
                <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                  <div className="image-event">
                    {item.game
                      && item.game.picture
                      && (
                      <img src={`/api/v1/media/image/${item.game?.picture}`} alt="" />
                    )}
                  </div>
                </div>
                <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                  <div className="info">
                    <div className="row w-100">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.game" />
                        </h3>
                        <p>{item.game?.title}</p>
                      </div>
                      <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.inscriptionDate" />
                        </h3>
                        <p>{item.event?.name}</p>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.releaseDate" />
                        </h3>
                        <p>---</p>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.status" />
                        </h3>
                        <p>
                          <i className="fa fa-circle-o text-warning f-s-12 m-r-10"></i>{" "}
                          {getLabelStatus(item.status)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.menu.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.menu.inscriptions" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function getLabelStatus(status) {
    let label = "";

    if (status === 0) {
      label = intl.formatMessage({ id: "inscriptions.status.notSent" });
    }

    if (status === 1) {
      label = intl.formatMessage({ id: "inscriptions.status.sent" });
    }

    if (status === 2) {
      label = intl.formatMessage({ id: "inscriptions.status.underReview" });
    }

    if (status === 3) {
      label = intl.formatMessage({ id: "inscriptions.status.inUpdate" });
    }

    if (status === 10) {
      label = intl.formatMessage({ id: "inscriptions.status.selectorsReview" });
    }

    if (status === 11) {
      label = intl.formatMessage({ id: "inscriptions.status.selectorsReviewed" });
    }

    if (status === 20) {
      label = intl.formatMessage({ id: "inscriptions.status.finalist" });
    }

    if (status === -10) {
      label = intl.formatMessage({ id: "inscriptions.status.notSelected" });
    }

    if (status === 30) {
      label = intl.formatMessage({ id: "inscriptions.status.juryReview" });
    }

    if (status === 31) {
      label = intl.formatMessage({ id: "inscriptions.status.juryReviwed" });
    }

    if (status === 100) {
      label = intl.formatMessage({ id: "inscriptions.status.winner" });
    }
    return label;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function renderFilter() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="form.filterBy" />:
                      </h2>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.inscriptionDate" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={eventoSelecionado}
                        onChange={(e) => {
                          setEventoSelecionado(e.target.value);
                        }}
                      >
                        <FormattedMessage id="inscriptions.list.selectEvent">
                          {(message) => (
                            <option value={-1} disabled>
                              {message}
                            </option>
                          )}
                        </FormattedMessage>
                        {eventos.length !== 0 &&
                          eventos.map((evento, key) => {
                            return (
                              <option key={key} value={evento.id}>
                                {evento.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.status" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={statusSelecionado}
                        onChange={(e) => {
                          setStatusSelecionado(e.target.value);
                        }}
                      >
                        <FormattedMessage id="inscriptions.list.selectStatus">
                          {(message) => (
                            <option value={-1} selected disabled>
                              {message}
                            </option>
                          )}
                        </FormattedMessage>
                        <FormattedMessage id="inscriptions.status.notSent">
                          {(message) => <option value={0}>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="inscriptions.status.underReview">
                          {(message) => <option value={2}>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="inscriptions.status.finalist">
                          {(message) => <option value={20}>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="inscriptions.status.notSelected">
                          {(message) => <option value={-10}>{message}</option>}
                        </FormattedMessage>
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.releaseDate" />
                      </label>
                      <input
                        type="date"
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilter()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="app.menu.inscriptions" />
                </h4>
                <div className="event-list">{renderList()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(InscriptionsList));
