import React, { Component } from "react";
import DragAndDrop from "./DragAndDrop";
import { injectIntl } from "react-intl"

class FileList extends Component {
  state = {
    files: [],
  };

  componentDidMount() {
    const files = this.props.files;

    if (files) this.setState({ files });
  }

  onChangeFile = (e) => {
    const reader = new FileReader();

    if (!this.handleDrop(e.target.files))
      e.target.value = null;
  };

  handleDrop = (files) => {
    const accept = this.props.accept ?? "*";

    if (accept !== "*") {
      let acceptList = accept.split(",");
      acceptList = acceptList.map((a) => a.trim().toLowerCase());

      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        //const ext = file.name.split(".").pop();
        const type = file.type;

        if (!acceptList.includes(type.toLowerCase())) {
          const message = this.props.intl.formatMessage({ id: "fileInputDragDrop.invalidFile" }, { file: file.name });
          alert(message);
          return false;
        }
      }
    }


    let fileList = this.state.files;

    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
      fileList.push(files[i]);
    }

    const limit = this.props.limit ?? 1;

    if (fileList.length > limit)
      fileList = fileList.slice(fileList.length - limit);

    this.setState({ files: fileList });

    const e = {
      target: {
        tagName: "FileList",
        files: fileList,
        name: this.props.name,
        value: fileList[0].name,
      },
    };

    this.props.onChange?.(e);
    return true;
  };

  render() {
    return (
      <div className="fileInputDrargDrop">
        <input
          type="file"
          className="form-control form-control-sm"
          id="fileInput"
          accept={this.props.accept ?? "*"}
          onChange={this.onChangeFile}
        />
        <label htmlFor="fileInput" className="w-100">
          <DragAndDrop handleDrop={this.handleDrop}>
            {/* <section className="col-12">
              {this.state.files.map((file, i) => (
                <div key={i}>{file.name}</div>
              ))}
              {(!Array.isArray(this.state.files) ||
                !this.state.files.length) && (
                <div>Escolha arquivos ou arraste aqui</div>
              )}
            </section> */}
          </DragAndDrop>
        </label>
      </div>
    );
  }
}

export default injectIntl(FileList);
