import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
const functions = [
  { label: "AI", value: "AI" },
  { label: "Analista", value: "Analista" },
  { label: "Animador 2D", value: "Animador 2D" },
  { label: "Animador 3D", value: "Animador 3D" },
  { label: "Artista 2D", value: "Artista 2D" },
  { label: "Artista 3D", value: "Artista 3D" },
  { label: "Áudio / Som", value: "Áudio / Som" },
  { label: "Backend", value: "Backend" },
  { label: "Build", value: "Build" },
  { label: "Business Development", value: "Business Development" },
  { label: "Cinemática", value: "Cinemática" },
  { label: "Community manager", value: "Community manager" },
  { label: "Concept Artist", value: "Concept Artist" },
  { label: "Customer support", value: "Customer support" },
  { label: "Data Integration", value: "Data Integration" },
  { label: "Desenvolvedor(a)", value: "Desenvolvedor(a)" },
  { label: "Design", value: "Design" },
  { label: "Design Gráfico", value: "Design Gráfico" },
  { label: "Direção de Arte", value: "Direção de Arte" },
  { label: "Edição de Vídeo", value: "Edição de Vídeo" },
  { label: "Engenheiro", value: "Engenheiro" },
  { label: "Engine", value: "Engine" },
  { label: "Front-end", value: "Front-end" },
  { label: "FX", value: "FX" },
  { label: "Game Designer", value: "Game Designer" },
  { label: "Gerente de Produto", value: "Gerente de Produto" },
  { label: "Ilustrador(a)", value: "Ilustrador(a)" },
  { label: "IT Support", value: "IT Support" },
  { label: "Level Design", value: "Level Design" },
  { label: "Lighting", value: "Lighting" },
  { label: "Localization", value: "Localization" },
  { label: "Marketing", value: "Marketing" },
  { label: "Mídias Digitais", value: "Mídias Digitais" },
  { label: "Mobile", value: "Mobile" },
  { label: "Modelagem", value: "Modelagem" },
  { label: "Produtor(a)", value: "Produtor(a)" },
  { label: "Produtor(a) de Conteúdo", value: "Produtor(a) de Conteúdo" },
  { label: "Programador(a) Júnior", value: "Programador(a) Júnior" },
  { label: "Programador(a) Pleno", value: "Programador(a) Pleno" },
  { label: "Programador(a) Sênior", value: "Programador(a) Sênior" },
  { label: "Prop", value: "Prop" },
  { label: "Redes Sociais", value: "Redes Sociais" },
  { label: "Rendering", value: "Rendering" },
  { label: "Rigging", value: "Rigging" },
  { label: "Roteirista", value: "Roteirista" },
  { label: "Técnico", value: "Técnico" },
  { label: "Tester / QA", value: "Tester / QA" },
  { label: "UI / UX", value: "UI / UX" },
  { label: "User Acquisition", value: "User Acquisition" },
  { label: "Vendas", value: "Vendas" },
];
function MultiSelectorFunctions(props) {
  const [selected, setSelected] = useState([]);
  const [isSetText, setIsSetText] = useState(true);

  useEffect(() => {
    if (selected.length !== 0) {
      let values = [];
      selected.forEach((item) => {
        values.push(item.value);
      });
      props.onSet(values);
    } else {
      props.onSet(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (isSetText
      && props.initialValue
      && props.initialValue.length != 0) {
      let values = [];
      props.initialValue.forEach((value) => {
        functions.forEach((item) => {
          if (item.value === value) values.push(item);
        });
      });
      setSelected(values);
      setIsSetText(false);
    }
  }, [props.initialValue]);

  return (
    <MultiSelect
      options={functions}
      value={selected}
      onChange={setSelected}
      labelledBy="Select"
      hasSelectAll={false}
    />
  );
}

export default MultiSelectorFunctions;
