import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl } from "react-intl";
import jwt_decode from "jwt-decode";
import DashboardAdmin from "./template/admin";
import DashboardUser from "./template/user";

function Dashboard() {
  const user = jwt_decode(localStorage.getItem("token"));
  return user.type == 10 ? <DashboardAdmin /> : <DashboardUser />;
}

export default injectIntl(withAuth(Dashboard));
