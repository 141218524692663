import axios from 'axios'
import { injectIntl } from "react-intl"
import React, { Component } from "react"

class Genres extends Component {

    constructor(props, children) {
        super(props)

        this.state = {
            loading: true,
            itens: []
        }

        this.ignoreGenres = [43, 44]
    }

    
    

    componentDidMount() {
        let locale = this.props.intl.locale
        
        if (locale.includes("-"))
            locale = locale.split("-")[0]

        let url = `/api/v1/games/genres/${locale}`

        axios.get(url, {headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            let itens = Array.isArray(res.data) ? res.data : []

            itens = itens.filter((item) => {
                return !this.ignoreGenres.includes(item.id)
            })
            
            this.setState({itens, loading: false})
        }).catch( (error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                window.open('/', "_self");
            }

            this.setState({itens: [], loading: false})
        })
    }

    render() {
        if (this.state.loading)
            return ""

        const children = React.cloneElement(this.props.children, {genres: this.state.itens});

        return children
    }

}


export default injectIntl(Genres)