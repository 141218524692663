/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { MultiSelect } from "react-multi-select-component";

const schema = yup.object().shape({
  name: yup.string().required(),
  usuarios: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
  jogos: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
});

const schemaJuriFechado = yup.object().shape({
  name: yup.string().required(),
  usuarios: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
  jogos: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
  cat_avaliadas: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
});

function TeamNew(props) {
  const intl = useIntl();
  const history = useHistory();
  const [isErrorRequest, setIsErrorRequest] = useState(false);
  const [eventos, setEventos] = useState([]);
  const [jogos, setJogos] = useState([]);
  const [jogosVisible, setJogosVisible] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersVisible, setUsersVisible] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isClickButton, setIsClickButton] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [userCode, setUserCode] = useState([]);
  const [gamesCode, setGamesCode] = useState([]);
  const [groupUser, setGroupUser] = useState(7);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(groupUser == 7 ? schema : schemaJuriFechado),
    defaultValues: useMemo(() => {
      return {
        usuarios: [],
        jogos: [],
        cat_avaliadas: [],
        group_user: 7,
      };
    }, [userCode, gamesCode]),
  });

  const watchGroup_user = watch("group_user");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setGamesCode(value.jogos);
      setUserCode(value.usuarios);
      setGroupUser(value.group_user);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!isFirstLoad) {
      const usuarios = [];
      const jogosVisible = [];
      if (users.length) {
        users.forEach((item) => {
          if (item.type == watchGroup_user)
            usuarios.push({ label: item.profile.name, value: item.id });
        });
        jogos.forEach((item) => {
          if (4 == watchGroup_user) {
            if (item.finalist) {
              jogosVisible.push({ label: item.label, value: item.value });
            }
          } else {
            jogosVisible.push({ label: item.label, value: item.value });
          }
        });
        setUsersVisible(usuarios);
        setJogosVisible(jogosVisible);
        setValue("usuarios", []);
        setValue("jogos", []);
      }
    }
  }, [watchGroup_user]);

  useEffect(() => {
    loadChamadas();
    loadUsers();
  }, []);

  useEffect(() => {
    if (eventos.length !== 0 && users.length !== 0 && isFirstLoad) {
      setIsFirstLoad(false);
    }
  }, [eventos, users]);

  function loadChamadas() {
    let url = "/api/v1/events/inscriptions";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        const e = res.data;
        const categoriesOptions = [];
        if (e.length) {
          const categories = e[0].categoria_avaliacao.concat(
            e[0].categoria_extras
          );
          if (categories.length) {
            categories.forEach((item) => {
              categoriesOptions.push({
                label: item,
                value: item,
              });
            });
          }
          const inscriptions = [];
          if (e[0].inscriptions.length) {
            e[0].inscriptions.forEach((item) => {
              let label = item.name;
              if (item.equipes != 0)
                label += ` ${getLabelsTeams(item.equipes)}`;
              inscriptions.push({
                label: label,
                value: item.id,
                finalist: isFinalist(item),
              });
            });
            setJogosVisible(inscriptions);
            setCategories(categoriesOptions);
            setValue("evento_id", e[0].id);
          }
          console.log("inscriptions", inscriptions);
          setJogos(inscriptions);
        }
        console.log("weventos", e);
        setEventos(e);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function isFinalist(game) {
    if (game.finalist == null) {
      return false;
    } else {
      if (game.finalist.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  function getLabelRole(roleId) {
    switch (roleId) {
      case 0:
        return "Master";
      case 2:
        return "Admin";
      case 1:
        return "Desenvolvedor";
        break;
      case 3:
        return "Júri Aberto";
      case 4:
        return "Júri Fechado";
      case 5:
        return "Observador";
      case 6:
        return "Selecionador Aberto";
      case 7:
        return "Selecionador Fechado";
    }
  }

  function getLabelsTeams(equipes) {
    var roles = [];
    var texto = "";
    equipes.forEach((item) => {
      if (!roles.includes(item.group_user)) roles.push(item.group_user);
    });

    roles.forEach((role) => {
      texto += "(" + getLabelRole(role) + ": ";
      equipes.forEach((item, index) => {
        if (role == item.group_user) {
          texto += item.name;
          if (equipes.length - 1 != index) texto += ", ";
        }
      });
      texto += ") ";
    });

    return texto;
  }

  function loadUsers() {
    let url = "/api/v1/users/juri-selecionado-fechado/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        console.log("usuarios", itens);
        if (itens.length) {
          const usuarios = [];
          itens.forEach((item) => {
            if (item.type === 7)
              usuarios.push({ label: item.profile.name, value: item.id });
          });
          setUsersVisible(usuarios);
        }
        setUsers(itens);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function changeEvents(event) {
    let games = getJogosByIdEvent(event.target.value);

    const inscriptions = [];
    const categoriesOptions = [];
    if (games.inscriptions.length) {
      const categories = games.categoria_avaliacao.concat(
        games.categoria_extras
      );
      if (categories.length) {
        categories.forEach((item) => {
          categoriesOptions.push({
            label: item,
            value: item,
          });
        });
      }
      games.inscriptions.forEach((item) => {
        inscriptions.push({ label: item.name, value: item.id });
      });
      setCategories(categoriesOptions);
      setJogos(inscriptions);
      setValue("jogos", []);
      setValue("cat_avaliadas", []);
    }
  }

  function changeUsers(event) {
    const usuarios = [];
    console.log("users", users);
    if (users.length) {
      users.forEach((item) => {
        if (item.type == event.target.value)
          usuarios.push({ label: item.profile.name, value: item.id });
      });
      setUsersVisible(usuarios);
      setValue("usuarios", []);
    }
  }

  function getJogosByIdEvent(id) {
    return eventos.filter((event) => event.id == id)[0];
  }

  const onSubmit = (data) => {
    if (!isClickButton) {
      setIsClickButton(true);
      const dados = new FormData();
      let usuarios = [];
      data.usuarios.forEach((element) => {
        usuarios.push(element.value);
      });
      let jogos = [];
      data.jogos.forEach((element) => {
        jogos.push(element.value);
      });
      let cat_avaliadas = [];
      if (data.cat_avaliadas.length != 0) {
        data.cat_avaliadas.forEach((element) => {
          cat_avaliadas.push(element.value);
        });
      }
      dados.append("evento_id", data.evento_id);
      dados.append("name", data.name);
      dados.append("group_user", data.group_user);
      dados.append("usuarios", usuarios);
      dados.append("jogos", jogos);
      dados.append("cat_avaliadas", cat_avaliadas);
      dados.append("codes", JSON.stringify(data.codes));
      let url = `/api/v1/teams/create/`;
      axios
        .post(url, dados, {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setIsErrorRequest(false);
          setIsClickButton(false);
          history.push(`/teams/`);
        })
        .catch((e) => {
          setIsErrorRequest(true);
          setIsClickButton(false);
          console.log("e", e.response);
        });
    }
  };

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/teams"} title="Usuários">
                <FormattedMessage id="app.breadcrumb.teams" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.teams.new" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">Nova Equipe</h1>

                {isFirstLoad ? (
                  <p>
                    <FormattedMessage id="loading" />
                  </p>
                ) : (
                  <div className="basic-form">
                    <form
                      className="form-custom"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        <div
                          className={`form-group col-12 ${
                            errors?.name ? "input-error" : ""
                          }`}
                        >
                          <label>Nome da equipe</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            {...register("name")}
                          />
                        </div>
                        <div className={`form-group col-md-6`}>
                          <label>Grupo de usuários</label>
                          <select
                            className="form-control form-control-sm"
                            onChange={changeUsers}
                            {...register("group_user")}
                          >
                            {/* <option value={0}>{intl.formatMessage({
                              id: "master",
                            })}</option>
                            <option value={2}>{intl.formatMessage({
                              id: "admin",
                            })}</option>
                            <option value={1}>{intl.formatMessage({
                              id: "desenvolvedor",
                            })}</option>
                            <option value={3}>{intl.formatMessage({
                              id: "juri-aberto",
                            })}</option> */}
                            <option value={7}>
                              {intl.formatMessage({
                                id: "selecionador-fechado",
                              })}
                            </option>
                            <option value={4}>
                              {intl.formatMessage({
                                id: "juri-fechado",
                              })}
                            </option>
                            {/* <option value={5}>{intl.formatMessage({
                              id: "observador",
                            })}</option>
                            <option value={6}>{intl.formatMessage({
                              id: "selecionador-aberto",
                            })}</option> */}
                          </select>
                        </div>
                        <div
                          className={`form-group col-md-6 ${
                            errors?.usuarios ? "input-error" : ""
                          }`}
                        >
                          <label>Usuários</label>
                          <Controller
                            control={control}
                            name="usuarios"
                            render={({ field: { onChange, value } }) => (
                              <MultiSelect
                                options={usersVisible}
                                value={value}
                                onChange={onChange}
                                labelledBy="Select"
                                hasSelectAll={false}
                              />
                            )}
                          />
                        </div>
                        <div className={`form-group col-md-6`}>
                          <label>
                            <FormattedMessage id="app.menu.calls" />
                          </label>
                          <select
                            className="form-control form-control-sm"
                            onChange={changeEvents}
                            {...register("evento_id")}
                          >
                            {eventos.length &&
                              eventos.map((chamada, index) => {
                                return (
                                  <option value={chamada.id} key={index}>
                                    {chamada.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {watchGroup_user == 4 && (
                          <div
                            className={`form-group col-md-6 ${
                              errors?.cat_avaliadas ? "input-error" : ""
                            }`}
                          >
                            <label>Categorias avaliadas</label>
                            <Controller
                              control={control}
                              name="cat_avaliadas"
                              render={({ field: { onChange, value } }) => (
                                <MultiSelect
                                  options={categories}
                                  value={value}
                                  onChange={onChange}
                                  labelledBy="Select"
                                  hasSelectAll={false}
                                />
                              )}
                            />
                          </div>
                        )}

                        <div
                          className={`form-group col-md-${
                            watchGroup_user == 4 ? "12" : "6"
                          } ${errors?.jogos ? "input-error" : ""}`}
                        >
                          <label>
                            {4 == watchGroup_user ? "Jogos finalista" : "Jogos"}
                          </label>
                          <Controller
                            control={control}
                            name="jogos"
                            render={({ field: { onChange, value } }) => (
                              <MultiSelect
                                options={jogosVisible}
                                value={value}
                                onChange={onChange}
                                labelledBy="Select"
                                hasSelectAll={false}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {userCode != undefined && gamesCode != undefined && (
                        <>
                          {userCode.length != 0 && gamesCode.length != 0 && (
                            <>
                              <div
                                className="form-row"
                                style={{ marginTop: 10 }}
                              >
                                {userCode.map((user, indexUser) => {
                                  return (
                                    <div key={indexUser} className={`col-12`}>
                                      <h2 className="card-title">
                                        {user.label}
                                      </h2>

                                      {gamesCode.map((game, indexGame) => {
                                        return (
                                          <div
                                            key={indexGame}
                                            className="form-group"
                                          >
                                            <label>{game.label}</label>
                                            <input
                                              type="text"
                                              class="form-control form-control-sm"
                                              {...register(
                                                `codes.user_${user.value}.inscription_${game.value}.code`
                                              )}
                                              placeholder="Digite o código da steam"
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      )}

                      <div className="form-row">
                        <div className="form-group content-btn col-12 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={(e) => {
                              e.preventDefault();

                              history.push("/teams");
                            }}
                          >
                            <FormattedMessage id="component.my-account-form.btn-cancel" />
                          </button>

                          <button type="submit" className="btn btn-primary">
                            <FormattedMessage id="component.my-account-form.btn-save" />
                          </button>
                        </div>
                      </div>
                      {isErrorRequest && (
                        <div className="form-group col-12 alert">
                          {/* <div className="alert alert-success">Dados salvos com sucesso!</div> */}
                          <div className="alert alert-danger">
                            Falha ao criar equipe
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(TeamNew));
