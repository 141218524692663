import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl } from "react-intl";
import jwt_decode from "jwt-decode";

function Reports() {
  const [totalGames, setTotalGames] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    loadTotalGames();
    loadTotalUsers();
  }, []);

  function loadTotalGames() {
    let url = "/api/v1/inscritions/total-games";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        const e = res.data;
        setTotalGames(e);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadTotalUsers() {
    let url = "/api/v1/users/total";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        const e = res.data;
        setTotalUsers(e);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"} title="Dashboard">
                  <FormattedMessage id="app.breadcrumb.dashboard" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.reports" />
              </li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center m-t-10">
                  <i className="icon-check f-s-50 text-primary"></i>
                  <h2 className="m-t-15 m-b-0">
                    {totalGames} <FormattedMessage id="dashboard.games" />
                  </h2>
                  <p className="f-s-12">
                    <FormattedMessage id="inscritos-em-nosso-portal" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center m-t-10">
                  <i className="icon-bubble f-s-50 text-primary"></i>
                  <h2 className="m-t-15 m-b-0">
                    {totalUsers} <FormattedMessage id="app.menu.users" />
                  </h2>
                  <p className="f-s-12">
                    <FormattedMessage id="usuarios-ativos" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-left">
                  <i className="icon ion-ios-paper-outline f-s-50 text-success m-b-5"></i>
                  <h4 className="m-b-5">
                    <FormattedMessage id="relatorio-de-jogos" />
                  </h4>
                  <p className="m-b-5">
                    <FormattedMessage id="exporte-as-informacoes-sobre-os-jogos-inscritos" />
                  </p>
                  <Link
                    to={"/games-reports"}
                    className="btn btn-sm btn-success"
                    title="Visualizar"
                  >
                    <FormattedMessage id="visualizar" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-left">
                  <i className="icon ion-ios-paper-outline f-s-50 text-success m-b-5"></i>
                  <h4 className="m-b-5">
                    <FormattedMessage id="relatorio-de-usuarios" />
                  </h4>
                  <p className="m-b-5">
                    <FormattedMessage id="exporte-as-informacoes-sobre-os-usuarios" />
                  </p>
                  <Link
                    to={"/users-reports"}
                    className="btn btn-sm btn-success"
                    title="Visualizar"
                  >
                    <FormattedMessage id="visualizar" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-left">
                  <i className="icon ion-ios-paper-outline f-s-50 text-success m-b-5"></i>
                  <h4 className="m-b-5">
                    <FormattedMessage id="relatorio-de-jures" />
                  </h4>
                  <p className="m-b-5">
                    <FormattedMessage id="exporte-as-informacoes-sobre-os-juries" />
                  </p>
                  <Link
                    to={"/juries-reports"}
                    className="btn btn-sm btn-success"
                    title="Visualizar"
                  >
                    <FormattedMessage id="visualizar" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(Reports));
