import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm, RegisterOptions } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const geneteValidate = (game) => {
  var validate = {};

  const formSchema = {
    nota: yup.string().required(),
    share: yup.boolean().required(),
    comment: yup.string().required(),
  };

  const gameSchemaGenerate = (id) => {
    return {
      [`game_${id}`]: yup.object().shape(formSchema),
    };
  };

  game.team.cat_avaliadas.forEach((cat) => {
    validate[`${cat}`] = yup
      .object()
      .shape(gameSchemaGenerate(game.inscription.id));
  });

  return validate;
};

function GamesRatedJuriFechado({ game }) {
  const intl = useIntl();
  const schemaJuriFechado = yup.object().shape(geneteValidate(game));
  const [isShow, setIsShow] = useState(true);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaJuriFechado),
  });

  const onSubmit = (data) => {
    console.log("voltar");
  };

  useEffect(() => {
    Object.keys(game.rating_juri).forEach(function (key) {
      Object.keys(game.rating_juri[key]).forEach(function (keyGame) {
        game.rating_juri[key][keyGame] = {
          comment: `${game.rating_juri[key][keyGame].comment}`,
          nota: parseInt(game.rating_juri[key][keyGame].nota),
          share: `${game.rating_juri[key][keyGame].share}`,
        };
      });
      setValue(key, game.rating_juri[key]);
    });
  }, []);

  return (
    isShow && (
      <div className={`row`}>
        <div className="col-12">
          <div className="row">
            <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
              <Link
                to={{
                  pathname: `/games/detail/${game.inscription.id}`,
                  state: {
                    game: game.inscription,
                  },
                }}
                title="Detalhe Jogo"
              >
                <div className="image-event">
                  <img
                    src={game.inscription.image}
                    alt={game.inscription.name}
                  />
                </div>
              </Link>
            </div>
            <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
              <div className="info">
                <div className="row w-100">
                  <Link
                    to={{
                      pathname: `/games/detail/${game.inscription.id}`,
                      state: {
                        game: game.inscription,
                      },
                    }}
                    title="Detalhe Jogo"
                  >
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h3>{game.inscription.name}</h3>
                      <p>{game.inscription.description}</p>
                    </div>
                  </Link>
                  <div className="col-12">
                    <form
                      className="form-custom"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        {game.team.cat_avaliadas.map((cat, index) => {
                          return (
                            <>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.[cat]?.[
                                      `game_${game.inscription.id}`
                                    ]?.nota
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />
                                    {cat}
                                  </h3>
                                  <div className="container-slider">
                                    <input
                                      type="range"
                                      min="0"
                                      max="5"
                                      step="1"
                                      className="slider slider-range"
                                      {...register(
                                        `${cat}.game_${game.inscription.id}.nota`,
                                        {
                                          value: 0,
                                        }
                                      )}
                                      disabled={true}
                                    />
                                    <span className="slider-range-value">
                                      {console.log(watch(`${cat}`))}
                                      {watch(
                                        `${cat}.game_${game.inscription.id}.nota`
                                      )}
                                    </span>
                                    <i className="fa fa-star btn-outline-warning"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.[cat]?.[
                                      `game_${game.inscription.id}`
                                    ]?.share
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                  </h3>

                                  <div className="content-radio">
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={true}
                                        disabled={true}
                                        {...register(
                                          `${cat}.game_${game.inscription.id}.share`,
                                          {
                                            value: "false",
                                          }
                                        )}
                                      />
                                      <FormattedMessage id="sim" />
                                    </div>
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={false}
                                        disabled={true}
                                        {...register(
                                          `${cat}.game_${game.inscription.id}.share`
                                        )}
                                      />
                                      <FormattedMessage id="nao" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.[cat]?.[
                                      `game_${game.inscription.id}`
                                    ]?.comment
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deixe-seu-comentario" />
                                  </h3>
                                  <textarea
                                    placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                    className="form-control form-control-sm feedback-categoria"
                                    {...register(
                                      `${cat}.game_${game.inscription.id}.comment`
                                    )}
                                    disabled={true}
                                  ></textarea>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      <div className="form-row">
                        <div className="form-group content-btn col-12 d-flex justify-content-end">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              history.push("/games-rated");
                            }}
                          >
                            <FormattedMessage id="account.login.previous" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default injectIntl(GamesRatedJuriFechado);
