import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import NoImageChamada from "../../../assets/images/v2/event/image-event-test.jpg";

function DashboardUser() {
  const intl = useIntl();
  const locale = intl.locale;
  const [totalGames, setTotalGames] = useState(0);
  const [lastGames, setLastGames] = useState([]);
  const [eventos, setEventos] = useState([]);

  useEffect(() => {
    loadGames();
    loadChamadas();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function loadGames() {
    let url = "/api/v1/inscriptions";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data.slice(0, 5);
        setTotalGames(itens.length);
        setLastGames(itens);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadChamadas() {
    let url = "/api/v1/events/inscriptions";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let eventos = res.data.filter((e) => e?.active);
        setEventos(eventos);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function traducaoSiglaLocale(locale1, locale2) {
    let l1 = locale1;
    let l2 = locale2;

    if (locale1.indexOf("-") >= 0) l1 = locale1.split("-")[0];

    if (locale2.indexOf("-") >= 0) l2 = locale2.split("-")[0];

    return l1 == l2;
  }

  function getLabelStatus(status) {
    let label = "";

    if (status === 0) {
      label = intl.formatMessage({ id: "inscriptions.status.notSent" });
    }

    if (status === 1) {
      label = intl.formatMessage({ id: "inscriptions.status.sent" });
    }

    if (status === 2) {
      label = intl.formatMessage({ id: "inscriptions.status.underReview" });
    }

    if (status === 3) {
      label = intl.formatMessage({ id: "inscriptions.status.inUpdate" });
    }

    if (status === 10) {
      label = intl.formatMessage({ id: "inscriptions.status.selectorsReview" });
    }

    if (status === 11) {
      label = intl.formatMessage({ id: "inscriptions.status.selectorsReviewed" });
    }

    if (status === 20) {
      label = intl.formatMessage({ id: "inscriptions.status.finalist" });
    }

    if (status === -10) {
      label = intl.formatMessage({ id: "inscriptions.status.notSelected" });
    }

    if (status === 30) {
      label = intl.formatMessage({ id: "inscriptions.status.juryReview" });
    }

    if (status === 31) {
      label = intl.formatMessage({ id: "inscriptions.status.juryReviwed" });
    }

    if (status === 100) {
      label = intl.formatMessage({ id: "inscriptions.status.winner" });
    }
    return label;
  }

  function renderList() {

    if (!lastGames) return null;

    return lastGames.map((item, key) => {
      // const currentEvent = eventos?.find(
      //   (evento) => evento.id === item.event_id
      // );

      // if (currentEvent?.active) {
      return (
        <div className="row" key={key}>
          <div className="col-12">
            <Link to={`/inscription/${item.id}`}>
              <div className="row">
                <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                  <div className="image-event">
                    {item.game?.picture && (
                      <img src={`/api/v1/media/image/${item.game.picture}`} alt="" />
                    )}
                    {!item.game?.picture && (
                      <img src="/images/event/image-event-test.jpg" alt="" />
                    )}
                  </div>
                </div>
                <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                  <div className="info">
                    <div className="row w-100">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.game" />
                        </h3>
                        <p>{item.game?.title}</p>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.inscriptionDate" />
                        </h3>
                        <p>{item.event?.name}</p>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.releaseDate" />
                        </h3>
                        <p>
                          {moment(item.release_date).format("DD/MMMM/YYYY")}
                        </p>
                      </div>
                      <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.status" />
                        </h3>
                        <p>
                          <i className="fa fa-circle-o text-warning f-s-12 m-r-10"></i>{" "}
                          {getLabelStatus(item.status)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
      // }
    });
  }


  function renderNoEvents() {
    if (eventos.length === 0) {
      return (
        <div className="row">
          <div className="col-12">
            <h3>
              <FormattedMessage id="dashboard.event.empty-calls" />
            </h3>
          </div>
        </div>
      );
    }
  }

  function renderFirstEvent() {
    if (eventos.length === 0) return null;

    const evento = eventos[0];

    var url = `#`;

      if (moment(evento.deadline).diff(moment()) > 0)
        url = `/inscription/new/${evento.id}`;

    return (
      <Link to={url} title="Detalhe Chamada">
        <div className="card">
          <div className="card-body">
            <div className="content-chamada">
              <div className="row">
                <div className="col-12">
                  <figure className="m-b-0">
                    <img
                        src={evento.image ? evento.image : NoImageChamada}
                        alt={evento.name}
                      />
                  </figure>
                </div>
                <div className="col-xxl-11 col-xl-11 col-lg-10 col-md-8 col-sm-6 col-12">
                  <div className="row">
                    <div className="col-12 text-chamada">
                      <h1 className="card-title">{evento.name}</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 text-chamada">
                      <h4>
                        <FormattedMessage id="inscriptions.game.deadline" />
                      </h4>
                      <p>
                        {moment(evento.deadline).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="col-6 text-chamada">
                      <h4><FormattedMessage id="inscriptions.game.limit" /></h4>
                      <p>
                        {moment(evento.limit_existence_game).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  function renderOthersEvent() {
    return eventos.map((evento, index) => {
      let localeData = evento.translations.filter((e) =>
        traducaoSiglaLocale(e.locale, locale)
      );
      localeData = localeData.length > 0 ? localeData[0] : null;

      var url = `#`;

      if (moment(evento.deadline).diff(moment()) > 0)
        url = `/inscription/new/${evento.id}`;

      if (index === 0) {
        return null
      }

      return (
        <div className="row" key={`event_${index}`}>
          <div className="col-12">
            <Link to={url} title="Detalhe Chamada">
              <div className="row">
                <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                  <div className="image-event">
                    <img
                      src={
                        evento.image
                          ? evento.image
                          : NoImageChamada
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xxl-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                  <div className="info">
                    <div className="row w-100">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.event.title" />
                        </h3>
                        <p>
                          <i className="fa fa-circle-o text-success f-s-12 m-r-10"></i>{" "}
                          {evento.name}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.event.deadline" />
                        </h3>
                        {moment(evento.deadline).format(
                          "DD/MMMM/YYYY"
                        )}
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.game.limit" />
                        </h3>
                        <p style={{ fontWeight: "bold" }}>
                          {moment(
                            evento.limit_existence_game
                          ).format("DD/MMMM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )
    })


    

    
  }

  function renderEvents() {
    return (
      <>
        {renderNoEvents()}
        <div className="row">
          <div className="col-12 col-md-4" style={{borderRight: "1px solid rgba(120, 130, 140, 0.13)"}}>
            {renderFirstEvent()}
          </div>
          <div className="col-12 col-md-8">
            {renderOthersEvent()}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center m-t-10">
                  <i className="icon-game-controller f-s-50 text-primary"></i>
                  <h2 className="m-t-15 m-b-0">
                    {totalGames} <FormattedMessage id="dashboard.games" />
                  </h2>
                  <p className="f-s-12">
                    <FormattedMessage id="dashboard.games.inscriptions" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center m-t-10">
                  <i className="icon-check f-s-50 text-primary"></i>
                  <h2 className="m-t-15 m-b-0">
                    {eventos.length} <FormattedMessage id="dashboard.calls" />
                  </h2>
                  <p className="f-s-12">
                    <FormattedMessage id="dashboard.calls.length" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="dashboard.calls" />
                </h4>

                <div className="event-list row">
                  {renderEvents()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="dashboard.game.title" />
                </h4>
                <div className="event-list games">{renderList()}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Chamadas */}
      </div>
    </div>
  );
}

export default injectIntl(withAuth(DashboardUser));
