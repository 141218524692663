import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import NoImageChamada from "../../assets/images/v2/jogos/img-thumb-jogo-boom.jpg";
import jwt_decode from "jwt-decode";

function GameRatedList() {
  const [games, setGames] = useState([]);
  const [gamesVisible, setGamesVisible] = useState([]);
  const [nivelUser, setNivelUser] = useState(-1);
  const [status, setStatus] = useState(-1);
  const [event, setEvent] = useState(-1);
  const [eventList, setEventList] = useState([]);
  const user = jwt_decode(localStorage.getItem("token"));
  const intl = useIntl();

  useEffect(() => {
    loadChamadas();
    loadEvents();
  }, []);

  useEffect(() => {
    var filtered = games;

    if (nivelUser != -1) {
      filtered = filtered.filter((item) => item.user.type == nivelUser);
    }

    if (event != -1) {
      filtered = filtered.filter((item) => item.event_id == event);
    }

    if (status != -1) {
      if (status == "finalist") {
        filtered = filtered.filter((item) => {
          if (item.inscription.finalist != null) {
            if (item.inscription.finalist.length != 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      } else if (status == "awards") {
        filtered = filtered.filter((item) => {
          if (item.inscription.award != null) {
            if (item.inscription.award.length != 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      }
    }

    setGamesVisible(filtered);
  }, [nivelUser, event, status]);

  function loadChamadas() {
    var url = "";

    if (user.type == 10 || user.type == 2 || user.type == 7) {
      url = "/api/v1/rating/rated/games-admin/";
    }

    if (user.type == 4) {
      url = "/api/v1/rating/rated/games-juri-fechado/";
    }

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        console.log("Jogos Avaliados", res.data);
        setGames(res.data);
        setGamesVisible(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadEvents() {
    let url = "/api/v1/events";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        setEventList(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.menu.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.menu.games-rated" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderFilter() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="calls.registered.filter.title" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="app.menu.calls" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={event}
                        onChange={({ target }) => setEvent(target.value)}
                      >
                        <option value={-1} selected disabled hidden>
                          Todos as chamadas
                        </option>
                        {eventList.length != 0 &&
                          eventList.map((e, i) => {
                            return (
                              <option key={i} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.status" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={status}
                        onChange={({ target }) => setStatus(target.value)}
                      >
                        <option value={-1}>Todos</option>
                        <option value={"finalist"}>Finalistas</option>
                        <option value={"awards"}>
                          {intl.formatMessage({
                            id: "ganhadores",
                          })}
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="nivel-usuario" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={nivelUser}
                        onChange={({ target }) => setNivelUser(target.value)}
                      >
                        <option value={-1}>Todos</option>
                        <option value={4}>Juri fechado</option>
                        <option value={7}>
                          {intl.formatMessage({
                            id: "selecionador-fechado",
                          })}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderList() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                <FormattedMessage id="app.menu.games-rated" />
              </h4>
              <div className="event-list games">
                {gamesVisible.length ? (
                  gamesVisible.map((game, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <Link
                            to={{
                              pathname: "/games-rated/detail",
                              state: {
                                game,
                              },
                            }}
                            title="Detalhe Jogo"
                          >
                            <div className="row">
                              <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                                <div className="image-event">
                                  <img
                                    src={
                                      game.inscription.image
                                        ? game.inscription.image
                                        : NoImageChamada
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                                <div className="info">
                                  <div className="row w-100">
                                    {game.inscription.award !== null &&
                                      game.inscription.award.length != 0 && (
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.award" />
                                          </h3>
                                          <p>
                                            {game.inscription.award.toString()}
                                          </p>
                                        </div>
                                      )}

                                    {game.inscription.finalist !== null &&
                                      game.inscription.finalist.length != 0 && (
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <FormattedMessage id="calls.inscriptions.event.finalist.label" />
                                          <p>
                                            {game.inscription.finalist.toString()}
                                          </p>
                                        </div>
                                      )}
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                      <FormattedMessage id="app.menu.calls" />
                                      <p>{game.event.name}</p>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <h3>{game.inscription.name}</h3>
                                      <p>{game.inscription.description}</p>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <h3>
                                        <FormattedMessage id="avaliador" />
                                      </h3>
                                      <p>{game.user.profile.name}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="row">
                    <div class="col-12">
                      <FormattedMessage id="calls.inscriptions.event.finalist.empty" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilter()}
        {renderList()}
      </div>
    </div>
  );
}

export default injectIntl(withAuth(GameRatedList));
