import { FormattedMessage } from "react-intl";
import moment from "moment";

const calculateColSizes = (parameters) => {
    if (!parameters.col_sm) parameters.col_sm = parameters.col;
  
    if (!parameters.col_md) parameters.col_md = parameters.col_sm;
  
    if (!parameters.col_lg) parameters.col_lg = parameters.col_md;
  
    if (!parameters.col_xl) parameters.col_xl = parameters.col_lg;
  }

export const renderTextValue = (parameters = {}) => {
    const defaultValues = {
        label: "",
        type: "text",
        col: 12,
        className: "",
        ignoreLocaleLabel: false,
        ignoreLocaleValue: false,
        link_download: false,
    }

    parameters = Object.assign(defaultValues, parameters)
    calculateColSizes(parameters)

    let value = parameters.value

    if (value && parameters.type === "date") {
        value = moment(value).format("DD/MM/YYYY")
    }

    if (parameters.options && !Array.isArray(parameters.value)) {
        const valueFinded = parameters.options.find((option) => option.value === parameters.value)

        if (valueFinded && !parameters.ignoreLocaleValue) {
            value = (
                <FormattedMessage id={valueFinded.label} />
            )
        }
        else if (valueFinded && parameters.ignoreLocaleValue) {
            value = valueFinded.label
        }
    }
    else if (parameters.options && Array.isArray(parameters.value)) {
        value = parameters.value.map((valueItem, index) => {
            const valueFinded = parameters.options.find((option) => option.value === valueItem)
            if (valueFinded) {
                if (parameters.ignoreLocaleValue) {
                    return (
                        <div key={`value_${parameters.label}_${index}`} >{valueFinded.label}<span className="comma">, </span></div>
                    )
                }
                else {
                    return (
                        <div key={`value_${parameters.label}_${index}`}><FormattedMessage id={valueFinded.label} /><span className="comma">, </span></div>
                    )
                }
            }
            else {
                return valueItem
            }
        })
    }

    return (
        <div className={`form-group col-${parameters.col} col-sm-${parameters.col_sm} col-md-${parameters.col_md} col-lg-${parameters.col_lg} col-xl-${parameters.col_xl} ${parameters.className}`}>
            <label className={`input-label${
                    parameters.required ? " input-required" : ""
            }`}>
                <strong>
                    {parameters.ignoreLocaleLabel ? parameters.label : <FormattedMessage id={parameters.label} />}
                </strong>
            </label>
            <br/>
            <span className="readonly-value">
                {parameters.link && (
                    <a href={parameters.link} target="_blank" download={parameters.link_download}>
                        {value}
                    </a>
                )}
                {!parameters.link && (
                    <>
                        {value}
                    </>
                )}
            </span>
        </div>
    )
}

export default renderTextValue