import BaseFrame from './BaseFrame'
import { renderTextArea,
    renderListOptionsInput } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameGameDetails extends BaseFrame {
    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.details" /></h2>

                <div className="row">
                    {renderTextArea({
                        name: "requirements",
                        label: "inscriptions.details.requirements",
                        description: "inscriptions.details.requirements.description",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "requirements"),
                        required: true,
                        col: 12
                    })}

                    {renderListOptionsInput({
                        name: "players",
                        label: "inscriptions.details.players",
                        description: "inscriptions.details.players.description",
                        col: 12,
                        option_col: 12,
                        required: true,
                        valueType: "int",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "players"),
                        options: [
                            { value: 1, label: "single_player"},
                            { value: 2, label: "multiplayer"},
                            { value: 3, label: "server"},
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "genres",
                        label: "inscriptions.details.genres",
                        description: "inscriptions.details.genres.description",
                        col: 12,
                        option_col: 4,
                        required: true,
                        valueType: "int",
                        translation: false,
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "genres"),
                        options: this.props.genres?.map(item => {
                            return {
                                value: item.id,
                                label: item.title
                            }
                        })
                    })}

                    {renderTextArea({
                        name: "controls",
                        label: "inscriptions.details.controls",
                        description: "inscriptions.details.controls.description",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "controls"),
                        required: true,
                        col: 12
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameGameDetails)