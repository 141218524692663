import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl, IntlFormatters } from "react-intl";

import "./Account.css";

import imgLogo from "../../assets/images/v2/logo-big-new-preto.svg";
import imgVoltar from "../../assets/images/v2/arrow-left.svg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      name: "",
      token: "",
      email: "",
      password: "",
      message: "",
      isLoading: false,
    };
  }

  onChangeField = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    this.setState({
      ...this.state,
      isLoading: true,
    });
    axios
      .post("/api/v1/account/login/", { email, password })
      .then((result) => {
        console.log(result);
        if (result.status === 200 && result.data.auth === true) {
          localStorage.setItem("token", result.data.token);
          this.setState({
            ...this.state,
            message: "",
            isLoading: false,
          });
          window.open("/", "_self");
        } else {
          this.setState({
            ...this.state,
            message: "account.userPassIncorrect",
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        console.log("e", e);
        this.setState({
          ...this.state,
          message: "account.userPassIncorrect",
          isLoading: false,
        });
      });
  };

  forgotPassword = (e) => {
    e.preventDefault();
  };

  loginRender = () => {
    const { formatMessage } = this.props.intl;

    return (
      <form className="m-t-30" onSubmit={this.onLogin}>
        <FormattedMessage id="account.login.input-label-email">
          {(message) => (
            <div className="form-group">
              <label>{message}</label>
              <input
                type="email"
                name="email"
                className="form-control form-control-sm"
                placeholder={formatMessage({
                  id: "account.login.input-placeholder-email",
                })}
                value={this.state.email}
                onChange={this.onChangeField}
              />
            </div>
          )}
        </FormattedMessage>
        <FormattedMessage id="account.login.input-label-password">
          {(message) => (
            <div className="form-group">
              <label>{message}</label>
              <input
                type="password"
                name="password"
                className="form-control form-control-sm"
                placeholder={formatMessage({
                  id: "account.login.input-placeholder-password",
                })}
                value={this.state.password}
                onChange={this.onChangeField}
              />
            </div>
          )}
        </FormattedMessage>

        <div className="form-group col-12 alert">
          {this.state.message && (
            <div className="alert alert-danger">
              <FormattedMessage id={this.state.message} />
            </div>
          )}
        </div>

        <div className="text-center m-b-15 m-t-15">
          {this.state.isLoading ? (
            <div
              className="btn btn-primary"
              style={{ position: "relative", height: "40px" }}
            >
              <svg className="circular" viewBox="25 25 50 50">
                <circle
                  className="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary">
              <FormattedMessage id="account.login.btn-enter" />
            </button>
          )}
        </div>
        <div className="form-row">
          <div className="form-group col-md-12 text-center">
            <Link to={"forgot-password"} title="Esqueci minha senha">
              <FormattedMessage id="account.home.forgot-password" />
            </Link>
          </div>
          <div className="form-group col-md-12 text-center">
            <Link to={"register"} title="Não tem uma conta? Cadastre-se aqui!">
              <FormattedMessage id="account.home.btn-register" />
            </Link>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div className="login-screen">
        <div className="login-bg h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="col-xl-6">
                <div className="form-input-content">
                  <div className="card">
                    <div className="card-body">
                      <header>
                        <div className="btn-back">
                          <Link to={"/"} title="Voltar">
                            <img src={imgVoltar} alt="Voltar" />
                            <FormattedMessage id="account.login.previous" />
                          </Link>
                        </div>
                        <div className="logo text-center">
                          <a href="/">
                            <img src={imgLogo} alt="BIG Festival" />
                          </a>
                        </div>
                        <h1 className="text-center m-t-30 card-title">
                          <FormattedMessage id="account.login.title" />
                        </h1>
                      </header>
                      {this.loginRender()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Login);
