import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import RatingGameSelecionador from "./Game";
import RatingGameJuriAberto from "./Game/juriAberto";
import RatingGameJuriFechado from "./Game/juriFechado";
import Modal132 from "./Game/modal123";

function Rating(props) {
  const intl = useIntl();
  const history = useHistory();
  const [games, setGames] = useState([]);
  const [game, setGame] = useState(null);
  const userType = jwt_decode(localStorage.getItem("token"));
  console.log("userType", userType);

  useEffect(() => {
    loadGames();
  }, []);

  function loadGames() {
    let url = "/api/v1/rating/teams/";
    if (userType.type === 4) {
      url += "juri-closed";
    } else {
      url += "selection-closed";
    }

    console.log("url", url);

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        console.log("itens", itens);
        setGames(itens);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.rating" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function ratingGames(game, index) {
    if (userType.type === 3) {
      return <RatingGameJuriAberto game={game} key={index} />;
    } else if (userType.type === 4) {
      return (
        <RatingGameJuriFechado
          game={game}
          key={index}
          openModal={(game) => {
            setGame(game);
            // eslint-disable-next-line no-undef
            $("#modal123").modal("show");
          }}
        />
      );
    } else {
      return <RatingGameSelecionador game={game} key={index} />;
    }
  }

  return (
    <>
      <div className="content-body">
        <div className="container-fluid">
          {renderBreadcrumb()}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="game-detail">
                    <header>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                          <h1 className="card-title">
                            {userType.type === 7 && (
                              <FormattedMessage id="rating.title.selection-closed" />
                            )}
                            {userType === 4 && (
                              <FormattedMessage id="rating.title.juri-closed" />
                            )}
                          </h1>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 avaliacao-game">
                          <h2>
                            <FormattedMessage id="rating.title.selection-games" />
                          </h2>
                        </div>
                      </div>
                    </header>
                    {/* Lista Jogos */}
                    <div className="row">
                      <div className="col-12">
                        <div className="event-list games avaliacao">
                          {games.length !== 0 ? (
                            games.map((game, index) => {
                              return ratingGames(game, index);
                            })
                          ) : (
                            <div className="row">
                              <div className="col-12">
                                <h2>
                                  <FormattedMessage id="nenhum-jogo-cadastrado" />
                                </h2>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal132 game={game} />
    </>
  );
}

export default injectIntl(withAuth(Rating));
