import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";

import NoImageChamada from "../../assets/images/v2/event/image-event-test.jpg";

function RegisteredCalls() {
  const intl = useIntl();
  const [eventos, setEventos] = useState([]);
  const [eventosEncerrados, setEventosEncerrados] = useState([]);

  useEffect(() => {
    loadChamadas();
  }, []);

  function loadChamadas() {
    let url = "/api/v1/events";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let abertos = [];
        let encerrados = [];
        console.log("res.data", res.data);
        res.data.forEach(async (chamada) => {
          if (
            moment(chamada.deadline).format("YYYY-MM-DD") <=
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            encerrados.push(chamada);
          } else {
            abertos.push(chamada);
          }
        });

        setEventos(abertos);
        setEventosEncerrados(encerrados);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.calls-registers" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderFilter() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="calls.registered.filter.title" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="calls.registered.filter.event" />
                      </label>
                      <select className="form-control form-control-sm" id="">
                        <option value="" selected disabled hidden>
                          Selecione o evento
                        </option>
                        <option>BIG Festival 2021</option>
                        <option>BIG Festival 2022</option>
                        <option>BIG Festival 2023</option>
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.status" />
                      </label>
                      <select className="form-control form-control-sm" id="">
                        <option value="" selected disabled hidden>
                          Selecione o status do evento
                        </option>
                        <option>Inscrições abertas</option>
                        <option>Inscrições encerradas</option>
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="dashboard.event.deadline" />
                      </label>
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        placeholder="Deadline"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderOpenCalls() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                <FormattedMessage id="calls.registered.calls.open" />
              </h4>
              <div className="event-list">
                {/* <!-- ITEM LIST --> */}
                {eventos.length != 0 ? (
                  eventos.map((evento, index) => {
                    return (
                      <div class="row" key={index}>
                        <div class="col-12">
                          <Link
                            to={`/call/${evento.id}`}
                            title="Detalhe Chamada"
                          >
                            <div class="row">
                              <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                                <div class="image-event">
                                  <img
                                    src={
                                      evento.image
                                        ? evento.image
                                        : NoImageChamada
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div class="col-xxl-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                                <div class="info">
                                  <div class="row w-100">
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                      <h3>
                                        <FormattedMessage id="dashboard.event.title" />
                                      </h3>
                                      <p>
                                        <i class="fa fa-circle-o text-success f-s-12 m-r-10"></i>{" "}
                                        {evento.name}
                                      </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                      <h3>
                                        <FormattedMessage id="dashboard.event.deadline" />
                                      </h3>
                                      {moment(evento.deadline).format(
                                        "DD/MMMM/YYYY"
                                      )}
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                      <h3>
                                        <FormattedMessage id="inscriptions.game.limit" />
                                      </h3>
                                      <p style={{ fontWeight: "bold" }}>
                                        {moment(
                                          evento.limit_existence_game
                                        ).format("DD/MMMM/YYYY")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="row">
                    <div class="col-12">
                      <h3>
                        <FormattedMessage id="calls.registered.calls.empty" />
                      </h3>
                    </div>
                  </div>
                )}
                {/* <!-- END ITEM LIST --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderCloseCalls() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                <FormattedMessage id="calls.registered.calls.closed" />
              </h4>
              <div className="event-list">
                {/* <!-- ITEM LIST --> */}
                {eventosEncerrados.length != 0 ? (
                  eventosEncerrados.map((evento, index) => {
                    return (
                      <div class="row" key={index}>
                        <div class="col-12">
                          <Link
                            to={`/call/${evento.id}`}
                            title="Detalhe Chamada"
                          >
                            <div class="row">
                              <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                                <div class="image-event">
                                  <img
                                    src={
                                      evento.image
                                        ? evento.image
                                        : NoImageChamada
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div class="col-xxl-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                                <div class="info">
                                  <div class="row w-100">
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                      <h3>
                                        <FormattedMessage id="dashboard.event.title" />
                                      </h3>
                                      <p>
                                        <i class="fa fa-circle-o text-danger f-s-12 m-r-10"></i>{" "}
                                        {evento.name}
                                      </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                      <h3>
                                        <FormattedMessage id="dashboard.event.deadline" />
                                      </h3>
                                      {moment(evento.deadline).format(
                                        "DD/MMMM/YYYY"
                                      )}
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                      <h3>
                                        <FormattedMessage id="inscriptions.game.limit" />
                                      </h3>
                                      <p style={{ fontWeight: "bold" }}>
                                        {moment(
                                          evento.limit_existence_game
                                        ).format("DD/MMMM/YYYY")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="row">
                    <div class="col-12">
                      <h3>
                        <FormattedMessage id="calls.registered.calls.empty" />
                      </h3>
                    </div>
                  </div>
                )}
                {/* <!-- END ITEM LIST --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilter()}
        {renderOpenCalls()}
        {renderCloseCalls()}
      </div>
    </div>
  );
}

export default injectIntl(withAuth(RegisteredCalls));
