import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { MultiSelect } from "react-multi-select-component";
import withAuth from "../../components/auth";
import { Link, useHistory, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const schema = yup.object().shape({
  imagemEventoUrl: yup.string().required("Imagem do Evento é obrigatório"),
  nomeEvento: yup.string().required("Nome do Evento é obrigatório"),
  categoriaAvaliacao: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
  categoriaExtras: yup
    .array()
    .required("Categorias de Extras é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de Extras"),
  deadline: yup.date().required("Deadline é obrigatório"),
  editingPeriod: yup.date().required("Periodo de edição é obrigatório"),
  limiteExistenciaJogo: yup.date().required("Deadline é obrigatório"),
    description_pt: yup.string().required("Descrição PT é obrigatório"),
    description_en: yup.string().required("Descrição EN é obrigatório"),
});

function CallsNew() {
  const [imagemEvento, setImagemEvento] = useState("");
  const [optionsCatExtras, setOptionsCatExtras] = useState([]);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [limiteExistenciaJogoPicker, setLimiteExistenciaJogoPicker] =
    useState();
  const [deadlinePicker, setDeadlinePicker] = useState();
  const [dateEditPicker, setDateEditPicker] = useState();
  const watchImagemEvento = watch("imagemEvento");
  const intl = useIntl();

  const onSubmit = (data) => {
    console.log(data);

    const dados = new FormData();

    let categoria_avaliacao = [];
    data.categoriaAvaliacao.forEach((element) => {
      categoria_avaliacao.push(element.value);
    });

    let categoria_extras = [];
    data.categoriaExtras.forEach((element) => {
      categoria_extras.push(element.value);
    });

    console.log("categoria_avaliacao", categoria_avaliacao);
    console.log("categoria_extras", categoria_extras);

    let deadline = moment(data.deadline)
    deadline = deadline.add(23, 'hours').add(59, 'minutes').add(59, 'seconds')

    dados.append("imagemEvento", data.imagemEvento[0]);
    dados.append("imagemEventoUrl", data.imagemEventoUrl);
    dados.append("nomeEvento", data.nomeEvento);
    dados.append("categoriaAvaliacao", categoria_avaliacao);
    dados.append("categoriaExtras", categoria_extras);
    dados.append("deadline", deadline);
    dados.append("editingPeriod", data.editingPeriod);
    dados.append("limiteExistenciaJogo", data.limiteExistenciaJogo);
    /*dados.append("form123Publisher", data.form123Publisher);
    dados.append("form123Studio", data.form123Studio);
    dados.append("form123Autonomo", data.form123Autonomo);*/

    let translations = [];

    translations.push({
      locale: "pt",
      description: data.description_pt,
    });

    translations.push({
      locale: "en",
      description: data.description_en,
    });

    dados.append("translations", JSON.stringify(translations));

    let url = `/api/v1/event/create`;

    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          var id = res.data.id;
          history.push(`/call/${id}`);
        } else {
          alert("Falha ao criar chamada");
          console.log("Não salvou", res);
        }
      });
  };

  useEffect(() => {
    if (watchImagemEvento && watchImagemEvento.length) {
      let files = watchImagemEvento;
      var idxDot = files[0].name.lastIndexOf(".") + 1;
      var extFile = files[0].name
        .substr(idxDot, files[0].name.length)
        .toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        setImagemEvento(URL.createObjectURL(files[0]));
        setValue("imagemEventoUrl", URL.createObjectURL(files[0]));
      } else {
        setValue("imagemEvento", "");
        alert(intl.formatMessage({ id: "calls.edit.fail.validate-img" }));
      }
    }
  }, [watchImagemEvento]);

  useEffect(() => {
    if (limiteExistenciaJogoPicker)
      setValue(
        "limiteExistenciaJogo",
        moment(limiteExistenciaJogoPicker).format("YYYY-MM-DD")
      );
  }, [limiteExistenciaJogoPicker]);

  useEffect(() => {
    if (deadlinePicker)
      setValue("deadline", moment(deadlinePicker).format("YYYY-MM-DD"));
  }, [deadlinePicker]);

  useEffect(() => {
    if (dateEditPicker)
      setValue("editingPeriod", moment(dateEditPicker).format("YYYY-MM-DD"));
  }, [dateEditPicker]);

  const renderForm = () => {
    return (
      <form className="form-custom" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div class="form-row">
            <div
              className={`form-group col-md-12 ${
                errors?.imagemEventoUrl ? "input-error" : ""
              }`}
            >
              {imagemEvento !== "" && (
                <div className="thumb-event">
                  <figure>
                    <img src={imagemEvento} alt="Imagem do Jogo" />
                  </figure>
                </div>
              )}
              <label>
                <FormattedMessage id="calls.registered.new" />
              </label>
              <input
                type="file"
                className="form-control form-control-sm"
                accept="image/png, image/jpeg"
                {...register("imagemEvento")}
              />
              <input type={"hidden"} {...register("imagemEventoUrl")} />
            </div>

            <div
              class={`form-group col-md-6 ${
                errors?.nomeEvento ? "input-error" : ""
              }`}
            >
              <label>
                <FormattedMessage id="calls.registered.new.name-event" />
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                {...register("nomeEvento")}
              />
            </div>
            <div
              class={`form-group col-6 ${
                errors?.categoriaAvaliacao ? "input-error" : ""
              }`}
            >
              <label>
                <FormattedMessage id="calls.registered.new.category-rating" />
              </label>
              <Controller
                control={control}
                name="categoriaAvaliacao"
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    options={[
                      { label: "Gameplay", value: "Gameplay" },
                      { label: "Narrativa", value: "Narrativa" },
                      { label: "Arte", value: "Arte" },
                      { label: "Inovação", value: "Inovação" },
                    ]}
                    value={value ? value : []}
                    onChange={onChange}
                    labelledBy="Select"
                    hasSelectAll={false}
                  />
                )}
              />
            </div>
            {/* ---- */}
            <div
              class={`form-group col-3 ${
                errors?.categoriaExtras ? "input-error" : ""
              }`}
            >
              <label>
                <FormattedMessage id="calls.registered.new.category-extras" />
              </label>
              <Controller
                control={control}
                name="categoriaExtras"
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    options={optionsCatExtras}
                    value={value ? value : []}
                    onChange={onChange}
                    labelledBy="Select"
                    hasSelectAll={false}
                    isCreatable={true}
                  />
                )}
              />
            </div>
            <div
              class={`form-group col-3 ${
                errors?.limiteExistenciaJogo ? "input-error" : ""
              }`}
            >
              <label>
                <FormattedMessage id="calls.registered.new.limit-exist-game" />
              </label>
              {/* <input
                type="date"
                class="form-control form-control-sm"
                placeholder="Limite máximo de existência do jogo"
                {...register("limiteExistenciaJogo")}
              /> */}
              <DatePicker
                selected={limiteExistenciaJogoPicker}
                onChange={(date) => setLimiteExistenciaJogoPicker(date)}
                dateFormat="dd/MMMM/yyyy"
                className="form-control form-control-sm"
                placeholderText={"dd/MM/yyyy"}
              />
            </div>
            <div
              class={`form-group col-md-3 ${
                errors?.deadline ? "input-error" : ""
              }`}
            >
              <label>
                <FormattedMessage id="dashboard.event.deadline" />
              </label>
              {/* <input
                type="date"
                class="form-control form-control-sm"
                placeholder="Deadline"
                {...register("deadline")}
              /> */}
              <DatePicker
                selected={deadlinePicker}
                onChange={(date) => setDeadlinePicker(date)}
                dateFormat="dd/MMMM/yyyy"
                className="form-control form-control-sm"
                placeholderText={"dd/MM/yyyy"}
              />
            </div>
            <div
              class={`form-group col-md-3 ${
                errors?.editingPeriod ? "input-error" : ""
              }`}
            >
              <label>
                <FormattedMessage id="dashboard.event.dateEdit" />
              </label>
              <DatePicker
                selected={dateEditPicker}
                onChange={(date) => setDateEditPicker(date)}
                dateFormat="dd/MMMM/yyyy"
                className="form-control form-control-sm"
                placeholderText={"dd/MM/yyyy"}
              />
            </div>
            {/* -- */}
            {Object.keys(errors).length !== 0 && (
              <div className="form-group col-12 alert">
                <div className="alert alert-danger">
                  <FormattedMessage id="form.validate.fillAllFields" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <Tabs defaultActiveKey="pt" id="language-tab">
            <Tab eventKey="pt" title="Português">
              <div className="form-row">
                <div
                  class={`form-group col-md-12 ${
                    errors?.description_pt ? "input-error" : ""
                  }`}
                >
                  <label>
                    <FormattedMessage id="calls.registered.new.description" />
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    {...register("description_pt")}
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="en" title="Inglês">
            <div className="form-row">
                <div
                  class={`form-group col-md-12 ${
                    errors?.description_pt ? "input-error" : ""
                  }`}
                >
                  <label>
                    <FormattedMessage id="calls.registered.new.description" />
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    {...register("description_en")}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="form-row">
          <div className="form-group content-btn col-12 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();

                history.push("/registered-calls");
              }}
            >
              <FormattedMessage id="form.buttons.cancel" />
            </button>
            <button type="submit" className="btn btn-primary">
              <FormattedMessage id="component.my-account-form.btn-save" />
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderList = () => {
    return (
      <div className="card-body">
        <h1 className="card-title">
          <FormattedMessage id="calls.registered.new.new-call" />
        </h1>
        <div className="basic-form">{renderForm()}</div>
      </div>
    );
  };

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="calls.registered.new.welcome" />
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"} title="Dashboard">
                  <FormattedMessage id="app.breadcrumb.dashboard" />
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/registered-calls"} title="Chamadas">
                  <FormattedMessage id="app.breadcrumb.calls" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.calls-new" />
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">{renderList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withRouter(withAuth(CallsNew)));
