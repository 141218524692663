import { Component } from "react";
import axios from "axios";
import { useIntl } from "react-intl";

class Base extends Component {
  onChangeField = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  validURL(str) {
    var regexp =
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
    return !!regexp.test(str);
  }

  validateDate(dateStart, dateEnd) {
    var startDate = new Date(dateStart);
    var endDate = new Date(dateEnd);

    return startDate <= endDate;
  }

  onChangeCurrentField = (e) => {
    this.setState({
      currentStudio: {
        ...this.state.currentStudio,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeCurrentFieldAutoComplete = (name, value) => {
    this.setState({
      currentStudio: {
        ...this.state.currentStudio,
        [name]: value,
      },
    });
  };

  onChangeCurrentGameField = (e) => {
    var state = this.state.currentStudio;
    state.games[e.target.getAttribute("data-idGame")][e.target.name] =
      e.target.value;
    this.setState({
      currentStudio: state,
    });
  };

  onChangeCurrentGameFieldAutoComplete = (name, value, index) => {
    var state = this.state.currentStudio;
    state.games[index][name] = value;
    this.setState({
      currentStudio: state,
    });
  };

  onChangeStudioField = (e) => {
    var state = this.state.studios;
    state[e.target.getAttribute("data-indexStudio")][e.target.name] =
      e.target.value;

    this.setState({
      studios: state,
    });
  };

  onChangeStudioFieldAutoComplete = (name, value, index) => {
    var state = this.state.studios;
    state[index][name] = value;

    this.setState({
      studios: state,
    });
  };

  onChangeStudioGameField = (e) => {
    var state = this.state.studios;
    state[e.target.getAttribute("data-indexStudio")].games[
      e.target.getAttribute("data-idGame")
    ][e.target.name] = e.target.value;
    this.setState({
      studios: state,
    });
  };

  onChangeStudioGameFieldAutoComplete = (name, value, indexStudio, idGame) => {
    var state = this.state.studios;
    state[indexStudio].games[idGame][name] = value;
    this.setState({
      studios: state,
    });
  };

  onChangeCurrentStudioFunctions = (e) => {
    this.setState({
      currentStudio: {
        ...this.state.currentStudio,
        functions: [...this.state.currentStudio.functions, e.target.value],
      },
    });
  };

  onChangeCurrentStudioFunctionsMulti = (value) => {
    this.setState({
      currentStudio: {
        ...this.state.currentStudio,
        functions: value,
      },
    });
  };

  onChangeStudiosFunctions = (e) => {
    var studios = this.state.studios;
    studios[e.target.getAttribute("data-index")].functions = [
      ...studios[e.target.getAttribute("data-index")].functions,
      e.target.value,
    ];
    this.setState({
      studios: studios,
    });
  };

  removeCurrentSudioFunctions = (e) => {
    e.preventDefault();

    var removeFc = this.state.currentStudio.functions;
    removeFc.splice(removeFc.indexOf(e.target.getAttribute("data-fc")), 1);

    this.setState({
      currentStudio: {
        ...this.state.currentStudio,
        functions: removeFc,
      },
    });
  };

  onChangeCurrentStudioGamesPlatforms = (e) => {
    var currentStudio = this.state.currentStudio;
    currentStudio.games[e.target.getAttribute("data-index")].platforms = [
      ...currentStudio.games[e.target.getAttribute("data-index")].platforms,
      e.target.value,
    ];
    this.setState({ currentStudio: currentStudio });
  };

  onChangeStudioGamesPlatforms = (e) => {
    var studio = this.state.studios;
    studio[e.target.getAttribute("data-index")].games[
      e.target.getAttribute("data-indexGame")
    ].platforms = [
      ...studio[e.target.getAttribute("data-index")].games[
        e.target.getAttribute("data-indexGame")
      ].platforms,
      e.target.value,
    ];
    this.setState({ studio: studio });
  };

  removeCurrentSudioGamesPlatforms = (e) => {
    e.preventDefault();

    var removeFc = this.state.currentStudio.games;
    removeFc[e.target.getAttribute("data-index")].platforms.splice(
      removeFc[e.target.getAttribute("data-index")].platforms.indexOf(
        e.target.getAttribute("data-fc")
      ),
      1
    );

    this.setState({
      currentStudio: {
        ...this.state.currentStudio,
        games: removeFc,
      },
    });
  };

  removeSudioGamesPlatforms = (e) => {
    e.preventDefault();

    var removeFc = this.state.studios;
    removeFc[e.target.getAttribute("data-index")].games[
      e.target.getAttribute("data-indexGame")
    ].platforms.splice(
      removeFc[e.target.getAttribute("data-index")].games[
        e.target.getAttribute("data-indexGame")
      ].platforms.indexOf(e.target.getAttribute("data-fc")),
      1
    );

    this.setState({
      studios: removeFc,
    });
  };

  removeSudiosFunctions = (e) => {
    e.preventDefault();

    var removeFc = this.state.studios;
    removeFc[e.target.getAttribute("data-index")].functions.splice(
      removeFc.indexOf(e.target.getAttribute("data-fc")),
      1
    );

    this.setState({
      removeFc: removeFc,
    });
  };

  addOrRemoveCurrentGame = (index, add) => {
    if (add) {
      this.setState({
        currentStudio: {
          ...this.state.currentStudio,
          games: [
            ...this.state.currentStudio.games,
            { name: "", link: "", platforms: [] },
          ],
        },
      });
    } else {
      var games = this.state.currentStudio.games;
      games.splice(index, 1);
      this.setState({
        currentStudio: {
          ...this.state.currentStudio,
          games: games,
        },
      });
    }
  };

  addOrRemoveStudioGames = (indexGame, index, add) => {
    var studios = this.state.studios;
    if (add) {
      studios[index].games = [
        ...studios[index].games,
        { name: "", link: "", platforms: [] },
      ];
    } else {
      studios[index].games.splice(indexGame, 1);
    }

    this.setState({
      studios: studios,
    });
  };

  addOrRemoveStudio = (add, index) => {
    if (add) {
      this.setState({
        studios: [
          ...this.state.studios,
          {
            name: "",
            dateStart: "",
            dateEnd: "",
            site: "",
            functions: [],
            games: [{ name: "", link: "", platforms: [] }],
          },
        ],
      });
    } else {
      var studios = this.state.studios;
      studios.splice(index, 1);
      this.setState({
        studios: studios,
      });
    }
  };

  onRegister = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.setState({ message: "" });
      const dados = new FormData();
      dados.append("imagemProfile", this.state.imageUserProfile);
      dados.append("categoriaUser", this.state.categoriaUser);
      dados.append("name", this.state.name);
      dados.append("email", this.state.email);
      dados.append("password", this.state.password);
      dados.append("phoneWhatsapp", this.state.phoneWhatsapp);
      dados.append("country", this.state.country);
      dados.append("state", this.state.state);
      dados.append("city", this.state.city);
      dados.append("currentCompany", JSON.stringify(this.state.currentStudio));
      dados.append("company", JSON.stringify(this.state.studios));

      axios
        .post("/api/v1/account/register/", dados, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          if (result.status === 200 && result.data.auth === true) {
            localStorage.setItem("token", result.data.token);
            window.open("/inscriptions/chamadas", "_self");
          } else if (result.status === 409) {
            this.setState({
              message: "account.userAlreadyExists",
              validate: true,
            });
          } else {
            this.setState({
              message: "account.userPassIncorrect",
              validate: true,
            });
          }
        }).catch (error => {
          if (error.response.status === 409) {
            this.setState({
              message: "account.userAlreadyExists",
              validate: true,
            });
          }
        })

    } else {
      this.setState({ message: "register.empty_field", validate: true });
    }
  };

  onRegisterEdit = (e) => {
    e.preventDefault();
    if (this.validateEdit()) {
      this.setState({ message: "" });
      const dados = new FormData();
      dados.append("imagemProfile", this.state.imageUserProfile);
      dados.append("categoriaUser", this.state.categoriaUser);
      dados.append("name", this.state.name);
      dados.append("email", this.state.email);
      dados.append("password", this.state.password);
      dados.append("phoneWhatsapp", this.state.phoneWhatsapp);
      dados.append("country", this.state.country);
      dados.append("state", this.state.state);
      dados.append("city", this.state.city);
      dados.append("currentCompany", JSON.stringify(this.state.currentStudio));
      dados.append("company", JSON.stringify(this.state.studios));

      axios
        .post("/api/v1/account/register/edit", dados, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": localStorage.getItem("token"),
          },
        })
        .then((result) => {
          if (result.status === 200) {
            this.setState({ message: "Dados salvos com sucesso!" });
          } else {
            this.setState({ message: "Algo deu errado!" });
          }
        });
    } else {
      this.setState({ message: "register.empty_field", validate: true });
    }
  };

  isValidateCurrentGames = () => {
    var retorno = true;
    this.state.currentStudio.games.forEach((game) => {
      if (game.name !== "" || game.link !== "" || game.platforms.length !== 0) {
        if (game.name === "") retorno = false;
        if (!this.validURL(game.link)) retorno = false;
        if (game.platforms.length === 0) retorno = false;
      }
    });
    return retorno;
  };

  isValidateStudioGames = (game) => {
    var retorno = true;
    if (game.name !== "" || game.link !== "" || game.platforms.length !== 0) {
      if (game.name === "") retorno = false;
      if (!this.validURL(game.link)) retorno = false;
      if (game.platforms.length === 0) retorno = false;
    }
    return retorno;
  };

  isValidateStudios = () => {
    var retorno = true;
    if (this.state.studios.length !== 0) {
      this.state.studios.forEach((studio) => {
        if (
          studio.name !== "" ||
          studio.dateStart !== "" ||
          studio.dateEnd !== "" ||
          studio.site !== "" ||
          studio.functions.length !== 0
        ) {
          if (studio.name === "") retorno = false;
          if (!this.validateDate(studio.dateStart, studio.dateEnd))
            retorno = false;
          if (!this.validURL(studio.site)) retorno = false;
          if (studio.functions.length === 0) retorno = false;

          studio.games.forEach((game) => {
            if (
              game.name !== "" ||
              game.link !== "" ||
              game.platforms.length !== 0
            ) {
              if (game.name === "") retorno = false;
              if (!this.validURL(game.link)) retorno = false;
              if (game.platforms.length === 0) retorno = false;
            }
          });
        }
      });
    }
    return retorno;
  };

  validate = () => {
    var retorno = true;
    if (
      this.state.categoriaUser === "estudio" ||
      this.state.categoriaUser === "publisher"
    ) {
      if (this.state.name === "") retorno = false;

      if (this.state.email === "") retorno = false;

      if (this.state.password === "") retorno = false;

      if (this.state.password !== this.state.passwordConfirm) retorno = false;

      if (this.state.phoneWhatsapp === "") retorno = false;

      if (this.state.country === "") retorno = false;

      if (this.state.state === "") retorno = false;

      if (this.state.city === "") retorno = false;

      if (this.state.imageUserProfile === null) retorno = false;

      if (this.state.currentStudio.name === "") retorno = false;

      if (this.state.currentStudio.date === "") retorno = false;

      if (!this.validURL(this.state.currentStudio.site)) retorno = false;

      if (this.state.currentStudio.functions.length === 0) retorno = false;

      if (!this.isValidateCurrentGames()) retorno = false;
      if (this.state.terms === false) retorno = false;

      if (!this.isValidateStudios()) retorno = false;
    } else {
      if (this.state.name === "") retorno = false;

      if (this.state.email === "") retorno = false;

      if (this.state.password === "") retorno = false;

      if (this.state.password !== this.state.passwordConfirm) retorno = false;

      if (this.state.phoneWhatsapp === "") retorno = false;

      if (this.state.country === "") retorno = false;

      if (this.state.state === "") retorno = false;

      if (this.state.city === "") retorno = false;

      if (this.state.terms === false) retorno = false;

      if (this.state.imageUserProfile === null) retorno = false;

      if (!this.isValidateCurrentGames()) retorno = false;
    }

    return retorno;
  };

  validateEdit = () => {
    var retorno = true;
    if (
      this.state.categoriaUser === "estudio" ||
      this.state.categoriaUser === "publisher"
    ) {
      if (this.state.name === "") retorno = false;

      if (this.state.email === "") retorno = false;

      if (this.state.phoneWhatsapp === "") retorno = false;

      if (this.state.country === "") retorno = false;

      if (this.state.state === "") retorno = false;

      if (this.state.city === "") retorno = false;

      if (this.state.imageUserProfile === null) retorno = false;

      if (this.state.currentStudio.name === "") retorno = false;

      if (this.state.currentStudio.date === "") retorno = false;

      if (this.state.currentStudio.site === "") retorno = false;

      if (this.state.currentStudio.functions.length === 0) retorno = false;
    } else {
      if (this.state.name === "") retorno = false;

      if (this.state.email === "") retorno = false;

      if (this.state.password === "") retorno = false;

      if (this.state.password !== this.state.passwordConfirm) retorno = false;

      if (this.state.phoneWhatsapp === "") retorno = false;

      if (this.state.country === "") retorno = false;

      if (this.state.state === "") retorno = false;

      if (this.state.city === "") retorno = false;

      if (this.state.imageUserProfile === null) retorno = false;
    }

    return retorno;
  };

  onChangeFile(e) {
    let files = e.target.files;
    var idxDot = files[0].name.lastIndexOf(".") + 1;
    var extFile = files[0].name
      .substr(idxDot, files[0].name.length)
      .toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      files[0].url = URL.createObjectURL(files[0]);
      this.setState({ imageUserProfile: files[0] });
    } else {
      e.target.value = "";
      const { formatMessage } = this.props.intl;
      alert(formatMessage({ id: "calls.edit.fail.validate-img" }));
    }
  }
}

export default Base;
