import BaseFrame from './BaseFrame'
import { renderTextInput, 
    renderListOptionsInput,
    renderTextArea, 
    renderFileUpload,
    renderSelect} from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"
import moment, { max } from 'moment'

class FrameGameData extends BaseFrame {
    constructor(props) {
        super(props)

        this.state = {
            show_file_picture: false,
            show_file_thumbnail: false,
        }
    }

    onValueChanged = () => {
        let stateChanged = false
    
        const show_file_picture = (this.props.inscription.game?.picture)
        const show_file_thumbnail = (this.props.inscription.game?.thumbnail)

        stateChanged = stateChanged || this.state.show_file_picture !== show_file_picture
        stateChanged = stateChanged || this.state.show_file_thumbnail !== show_file_thumbnail

        if (stateChanged)
            this.setState({ show_file_picture, show_file_thumbnail })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.gamedata" /></h2>

                <div className="row">
                    {renderTextInput({
                        name: "title",
                        label: "inscriptions.gamedata.title",
                        description: "inscriptions.gamedata.title.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "title"),
                        col_md: 6
                    })}

                    {renderSelect({
                        name: "age",
                        label: "inscriptions.gamedata.age",
                        placeholder: "inscriptions.gamedata.age.placeholder",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "age"),
                        options: [
                            {value: 0, label: "age.everyone"},
                            {value: 10, label: "age.10"},
                            {value: 12, label: "age.12"},
                            {value: 14, label: "age.14"},
                            {value: 16, label: "age.16"},
                            {value: 18, label: "age.18"}
                        ],
                        col_md: 6
                    })}

                    {renderTextInput({
                        name: "releasedate",
                        label: "inscriptions.gamedata.releasedate",
                        type: "date",
                        required: true,
                        min: moment(this.props.event?.limit_existence_game).format("YYYY-MM-DD"),
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "releasedate"),
                        col_md: 6
                    })}

                    {renderListOptionsInput({
                        name: "releasetype",
                        label: "inscriptions.gamedata.gamerelease",
                        type: "radio",
                        col_md: 6,
                        option_col: 12,
                        required: true,
                        valueType: "int",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "releasetype"),
                        options: [
                            {value: 1, label: "inscriptions.gamedata.gamerelease.exact"},
                            {value: 2, label: "inscriptions.gamedata.gamerelease.estimated"},
                        ]
                    })}

                    <div className='col-12 col-md-6 mb-4'>
                        {!this.state.show_file_thumbnail && renderFileUpload({
                            name: "thumbnail",
                            label: "inscriptions.gamedata.thumbnail",
                            accept: "image/png, image/jpeg",
                            onChange: (e) => this.onChangeFileFieldModel("game", e, "inscriptions", "thumbnail"),
                            value: this.fieldValueModel("game", "thumbnail"),
                            required: true
                        })}
                        {this.state.show_file_thumbnail && (
                            <div className="row">
                                <label className="input-label col-12">
                                    <FormattedMessage id="inscriptions.gamedata.thumbnail" />
                                    <span className="input-required">*</span>
                                </label>
                                <div className='col-12'>
                                    <img className='inscriptions_picture' 
                                        src={`/api/v1/media/image/${this.props.inscription.game?.thumbnail}`}
                                        style={{maxWidth: "calc(100% - 48px)"}} />
                                        
                                    <button type='button' 
                                        className='btn btn-danger btn-sm ml-2 file-delete' 
                                        onClick={() => this.fileDelete(this.props.inscription.game?.thumbnail,
                                            "game", "thumbnail")}>X</button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='col-12 col-md-6 mb-4'>
                        {!this.state.show_file_picture && renderFileUpload({
                            name: "picture",
                            label: "inscriptions.gamedata.picture",
                            accept: "image/png, image/jpeg",
                            onChange: (e) => this.onChangeFileFieldModel("game", e, "inscriptions", "picture"),
                            value: this.fieldValueModel("game", "picture"),
                            required: true
                        })}
                        {this.state.show_file_picture && (
                            <div className="row">
                                <label className="input-label col-12">
                                    <FormattedMessage id="inscriptions.gamedata.picture" />
                                    <span className="input-required">*</span>
                                </label>
                                <div className='col-12'>
                                    <img className='inscriptions_picture' 
                                        src={`/api/v1/media/image/${this.props.inscription.game?.picture}`}
                                        style={{maxWidth: "calc(100% - 48px)"}} />
                                        
                                    <button type='button' 
                                        className='btn btn-danger btn-sm ml-2 file-delete' 
                                        onClick={() => this.fileDelete(this.props.inscription.game?.picture,
                                            "game", "picture")}>X</button>
                                </div>
                            </div>
                        )}
                    </div>

                    {renderTextInput({
                        name: "presskit",
                        label: "inscriptions.gamedata.presskit",
                        description: "inscriptions.gamedata.presskit.description",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "presskit"),
                        required: false,
                    })}

                    {renderTextInput({
                        name: "trailer",
                        label: "inscriptions.gamedata.trailer",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "trailer"),
                        required: true,
                    })}

                    {renderTextInput({
                        name: "video",
                        label: "inscriptions.gamedata.video",
                        description: "inscriptions.gamedata.video.description",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "video"),
                        required: false,
                    })}

                    {renderTextArea({
                        name: "synopsis",
                        label: "inscriptions.gamedata.synopsis",
                        description: "inscriptions.gamedata.synopsis.description",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "synopsis"),
                        required: true,
                        maxLength: 500,
                    })}


                </div>
            </div>
        )
    }
}

export default injectIntl(FrameGameData)