import axios from "axios";
import { IntlProvider } from "react-intl";
import localeData from "./locales/languages.json";

//import ReactGA from 'react-ga'
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
// import $ from "jquery";

import "./App.css";
import "./big.css";

// import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Account/Login";
import ForgotPassword from "./pages/Account/ForgotPassword";
import DiscordCallback from "./components/Discord/DiscordCallback";
//import Redirect from "./pages/Redirect";
import Register from "./pages/Account/Register";
import Account from "./pages/Account";
import EditInscription from "./pages/Inscriptions/Edit";
import InscriptionsList from "./pages/Inscriptions/List";
import InscriptionsNew from "./pages/Inscriptions/New";
import InscriptionsForm from "./pages/Inscriptions/Form";
import InscriptionsFormResult from "./pages/Inscriptions/FormResult";
import InscriptionsChamadas from "./pages/Inscriptions/Chamadas";
import CallsNew from "./pages/Calls/New";
import CallsEdit from "./pages/Calls/Edit";
import CallsFinalistWinners from "./pages/Calls/FinalistWinners";
import CallsSelectors from "./pages/Calls/EventSelectors";
import CallsJuries from "./pages/Calls/EventJuries";
import CallsMarkWinners from "./pages/Calls/Events";
import CallsMarkFinalist from "./pages/Calls/EventsFinalist";
import CallsInscriptionsEvent from "./pages/Calls/InscriptionsEvent";
import CallsInscriptionsEventFinalist from "./pages/Calls/InscriptionsEventFinalist";
import ListIncriptionsEvent from "./pages/Calls/ListIncriptionsEvent";
import GamesList from "./pages/Games/List";
import GameRatedList from "./pages/GamesRated/List";
import GameRatedDetail from "./pages/GamesRated/Detail";
import GameDetail from "./pages/Games/Detail";
import GameRating from "./pages/Games/Rating";
import MyAccount from "./pages/MyAccount";
import RegisteredCalls from "./pages/Calls/RegisteredCalls";
import Rating from "./pages/Rating";
import Users from "./pages/Users";
import UserNew from "./pages/Users/new";
import UserEdit from "./pages/Users/edit";
import Teams from "./pages/Teams";
import TeamNew from "./pages/Teams/new";
import TeamEdit from "./pages/Teams/edit";
import Reports from "./pages/Reports";
import UsersReports from "./pages/Reports/users";
import GamesReports from "./pages/Reports/games";
import GamesPendingEvents from "./pages/GamesPending/events";
import GamesPendingList from "./pages/GamesPending/";
import NotFoundPage from "./pages/NotFoundPage";
import Filters from "./pages/Filters";
import FilterNew from "./pages/Filters/new";
import FilterEdit from "./pages/Filters/edit";
import Languages from "./pages/Languages";
import LanguageNew from "./pages/Languages/new";
import LanguageEdit from "./pages/Languages/edit";
import JuryEvaluation from "./pages/Rating/juryEvaluation";
import JuriesReport from "./pages/Reports/juries";
import Alerts from "./pages/Alerts";

// import Whatsapp from "./pages/Tools/Whatsapp";
//import LogoDiscord from "./assets/images/v2/logo-discord-white.svg";

import DiscordLogin from "./components/Discord";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [messages, setMessages] = useState({});

  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  useEffect(() => {
    const validate = () => {
      let url = "/api/v1/account/validate";

      axios
        .get(url, {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLogged(true);
          }
        })
        .catch((error) => {
          localStorage.removeItem("token");
          setIsLogged(false);

          if (window.location.pathname !== "/") window.location.href = "/";
        });
    };
    validate();
    setMessages(
      localeData[languageWithoutRegionCode] ||
        localeData[language] ||
        localeData.en
    );

    if (localStorage.getItem("idioma")) {
      loadIdioma();
    }
  }, []);

  const loadIdioma = async () => {
    let url = `/api/v1/language/${localStorage.getItem("idioma")}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("res.data", res.data);
          setMessages(res.data.terms);
        }
      });
  };

  const renderLogin = () => {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path={["/"]} render={Account} />
            <Route exact path={["/login/"]} render={Login} />
            <Route
              exact
              path={["/discord/callback/"]}
              render={DiscordCallback}
            />
            <Route exact path={["/register/"]} render={Register} />
            <Route exact path={["/forgot-password/"]} render={ForgotPassword} />
          </Switch>
        </Router>
      </div>
    );
  };

  const renderSystem = () => {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route
              exact
              path={["/"]}
              render={(matchProps) => (
                <Dashboard {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games/"]}
              render={(matchProps) => (
                <GamesList {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games-rated/"]}
              render={(matchProps) => (
                <GameRatedList {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games-rated/detail/"]}
              render={(matchProps) => (
                <GameRatedDetail {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games/detail/:id"]}
              render={(matchProps) => (
                <GameDetail {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games/rating/:id"]}
              render={(matchProps) => (
                <GameRating {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/rating"]}
              render={(matchProps) => <Rating {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/jury/final-evaluation"]}
              render={(matchProps) => <JuryEvaluation {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/registered-calls/"]}
              render={(matchProps) => (
                <RegisteredCalls {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/new-call/"]}
              render={(matchProps) => <CallsNew {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/call/finalist-winners/"]}
              render={(matchProps) => (
                <CallsFinalistWinners {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/selectors/"]}
              render={(matchProps) => (
                <CallsSelectors {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/juries/"]}
              render={(matchProps) => (
                <CallsJuries {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/mark-winners/"]}
              render={(matchProps) => (
                <CallsMarkWinners {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/mark-winners/:id"]}
              render={(matchProps) => (
                <CallsInscriptionsEvent {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/mark-finalist/"]}
              render={(matchProps) => (
                <CallsMarkFinalist {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/mark-finalist/:id"]}
              render={(matchProps) => (
                <CallsInscriptionsEventFinalist {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/inscriptions/:id"]}
              render={(matchProps) => (
                <ListIncriptionsEvent {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/call/:id"]}
              render={(matchProps) => (
                <CallsEdit {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscriptions/"]}
              render={(matchProps) => (
                <InscriptionsList {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscription/new/:event_id"]}
              render={(matchProps) => (
                <InscriptionsNew {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscription"]}
              render={(matchProps) => (
                <InscriptionsChamadas {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscription/:id"]}
              render={(matchProps) => (
                <EditInscription {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscription/form/:id"]}
              render={(matchProps) => (
                <InscriptionsForm {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscription/form/result/:id"]}
              render={(matchProps) => (
                <InscriptionsFormResult {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/inscriptions/chamadas/"]}
              render={(matchProps) => (
                <InscriptionsChamadas {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games-pending/"]}
              render={(matchProps) => (
                <GamesPendingEvents {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/games-pending/:chamada"]}
              render={(matchProps) => (
                <GamesPendingList {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/my-account/"]}
              render={(matchProps) => (
                <MyAccount {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/users/"]}
              render={(matchProps) => <Users {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/reports/"]}
              render={(matchProps) => <Reports {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/games-reports/"]}
              render={(matchProps) => (
                <GamesReports {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/users-reports/"]}
              render={(matchProps) => (
                <UsersReports {...matchProps.match.params} />
              )}
            />

            <Route
              exact
              path={["/juries-reports/"]}
              render={(matchProps) => (
                <JuriesReport {...matchProps.match.params} />
              )}
            />


            <Route
              exact
              path={["/new-user/"]}
              render={(matchProps) => <UserNew {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/user/:id"]}
              render={(matchProps) => <UserEdit {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/teams/"]}
              render={(matchProps) => <Teams {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/new-team/"]}
              render={(matchProps) => <TeamNew {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/team/:id"]}
              render={(matchProps) => <TeamEdit {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/filters/"]}
              render={(matchProps) => <Filters {...matchProps.match.params} />}
            />
            <Route
              exact
              path={["/new-filter/"]}
              render={(matchProps) => (
                <FilterNew {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/filter/:id"]}
              render={(matchProps) => (
                <FilterEdit {...matchProps.match.params} />
              )}
            />

            <Route
              exact
              path={["/settings/languages"]}
              render={(matchProps) => (
                <Languages {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/settings/languages/new-language/"]}
              render={(matchProps) => (
                <LanguageNew {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/settings/languages/:id"]}
              render={(matchProps) => (
                <LanguageEdit {...matchProps.match.params} />
              )}
            />
            <Route
              exact
              path={["/settings/alerts"]}
              render={(matchProps) => <Alerts {...matchProps.match.params} />}
            />

            <Route
              render={(matchProps) => (
                <NotFoundPage {...matchProps.match.params} />
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  };

  const afterOpenDiscordGroup = () => {
    // eslint-disable-next-line no-undef
    $("#modalAlert").modal("hide");
    localStorage.setItem("discord_guild", "true");
  }

  const renderModalDiscord = () => {
    return (
      <div
        className="modal fade in alert-message-modal"
        id="modalAlert"
        // tabindex="-1"
        role="dialog"
        aria-labelledby="modalAlertLabel"
        aria-hidden="true"
        onLoad={async () => {
          if (window.location.pathname !== "/my-account") {
            let profile = await axios.get("/api/v1/profile", {
              headers: {
                "x-access-token": localStorage.getItem("token"),
                "Cache-Control": "no-cache",
                Pragma: "no-cache",
                Expires: "0",
              },
            });
            if (!profile.data.discordId) {
              console.log("profile", profile);
              // eslint-disable-next-line no-undef
              $("#modalAlert").modal("show");
            }
            else {
              const hasBigGuild = localStorage.getItem("discord_guild");

              if (hasBigGuild !== "true") {
                // eslint-disable-next-line no-undef
                $(".discord-message").text("Conecte no grupo da gamescom latam para ter acesso a todas as funções do portal");
                // eslint-disable-next-line no-undef
                $("#modalAlert").modal("show");
                // eslint-disable-next-line no-undef
                $(".enter-server-discord").show();
                // eslint-disable-next-line no-undef
                $(".login-discord").hide();
              }
            }
          }
        }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="testFilterLabel">
                Importante
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <i className="mdi mdi-alert"></i>
              <p className="discord-message">
                Entre com sua conta do Discord para ter acesso a todas as
                funções do portal
              </p>
              <div className="login-discord text-center">
                <DiscordLogin
                  connect={true}
                  labelButton={"Conectar meu Discord"}
                />
              </div>
              <div className="enter-server-discord" style={{ display: "none" }}>
                <a
                  href="https://discord.gg/bigfestival"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary" onClick={afterOpenDiscordGroup}>
                    Entrar no grupo do Discord
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <IntlProvider locale={language} messages={messages}>
      {!isLogged ? renderLogin() : renderSystem()}
      {isLogged && renderModalDiscord()}
    </IntlProvider>
  );
}

export default App;
