import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm, RegisterOptions } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  gameplay: yup.string().required(),
  gameplayShare: yup.boolean().required(),
  gameplayComment: yup.string().required(),
  narrativa: yup.string().required(),
  narrativaShare: yup.boolean().required(),
  narrativaComment: yup.string().required(),
  arte: yup.string().required(),
  arteShare: yup.boolean().required(),
  arteComment: yup.string().required(),
  som: yup.string().required(),
  somShare: yup.boolean().required(),
  somComment: yup.string().required(),
  inovacao: yup.string().required(),
  inovacaoShare: yup.boolean().required(),
  inovacaoComment: yup.string().required(),
});

function GamesRatedSelecionadorFechado({ game }) {
  const intl = useIntl();
  const [isShow, setIsShow] = useState(true);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchNotaGameplay = watch("notaGameplay");
  const watchNotaNarrativa = watch("notaNarrativa");
  const watchNotaArte = watch("notaArte");
  const watchNotaSom = watch("notaSom");
  const watchNotaInovacao = watch("notaInovacao");

  const onSubmit = (data) => {
    console.log("voltar");
  };

  useEffect(() => {
    setValue("notaGameplay", "0");
    setValue("notaNarrativa", "0");
    setValue("notaArte", "0");
    setValue("notaSom", "0");
    setValue("notaInovacao", "0");
    // if (game.length != 0) {
    setValue("gameplay", `${game.gameplay}`);
    setValue("gameplayShare", `${game.gameplayShare}`);
    setValue("gameplayComment", `${game.gameplayComment}`);
    setValue("narrativa", `${game.narrativa}`);
    setValue("narrativaShare", `${game.narrativaShare}`);
    setValue("narrativaComment", `${game.narrativaComment}`);
    setValue("arte", `${game.arte}`);
    setValue("arteShare", `${game.arteShare}`);
    setValue("arteComment", `${game.arteComment}`);
    setValue("som", `${game.som}`);
    setValue("somShare", `${game.somShare}`);
    setValue("somComment", `${game.somComment}`);
    setValue("inovacao", `${game.inovacao}`);
    setValue("inovacaoShare", `${game.inovacaoShare}`);
    setValue("inovacaoComment", `${game.inovacaoComment}`);
    setValue("likeGameplay", game.likeGameplay ? game.likeGameplay : false);
    setValue("likeNarrativa", game.likeNarrativa ? game.likeNarrativa : false);
    setValue("likeArte", game.likeArte ? game.likeArte : false);
    setValue("likeSom", game.likeSom ? game.likeSom : false);
    setValue("likeInovacao", game.likeInovacao ? game.likeInovacao : false);

    setValue("notaGameplay", `${game.notaGameplay ? game.notaGameplay : "0"}`);
    setValue("notaGameplayShare", `${game.notaGameplayShare}`);
    setValue(
      "notaGameplayComentario",
      `${game.notaGameplayComentario ? game.notaGameplayComentario : ""}`
    );
    setValue(
      "notaNarrativa",
      `${game.notaNarrativa ? game.notaNarrativa : "0"}`
    );
    setValue("notaNarrativaShare", `${game.notaNarrativaShare}`);
    setValue(
      "notaNarrativaComentario",
      `${game.notaNarrativaComentario ? game.notaNarrativaComentario : ""}`
    );
    setValue("notaArte", `${game.notaArte ? game.notaArte : "0"}`);
    setValue("notaArteShare", `${game.notaArteShare}`);
    setValue(
      "notaArteComentario",
      `${game.notaArteComentario ? game.notaArteComentario : ""}`
    );
    setValue("notaSom", `${game.notaSom ? game.notaSom : "0"}`);
    setValue("notaSomShare", `${game.notaSomShare}`);
    setValue(
      "notaSomComentario",
      `${game.notaSomComentario ? game.notaSomComentario : ""}`
    );
    setValue("notaInovacao", `${game.notaInovacao ? game.notaInovacao : "0"}`);
    setValue("notaInovacaoShare", `${game.notaInovacaoShare}`);
    setValue(
      "notaInovacaoComentario",
      `${game.notaInovacaoComentario ? game.notaInovacaoComentario : ""}`
    );
    // }
  }, []);

  return (
    isShow && (
      <div className={`row`}>
        <div className="col-12">
          <div className="row">
            <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
              <Link
                to={{
                  pathname: `/games/detail/${game.inscription.id}`,
                  state: {
                    game: game.inscription,
                  },
                }}
                title="Detalhe Jogo"
              >
                <div className="image-event">
                  <img
                    src={game.inscription.image}
                    alt={game.inscription.name}
                  />
                </div>
              </Link>
            </div>
            <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
              <div className="info">
                <div className="row w-100">
                  <Link
                    to={{
                      pathname: `/games/detail/${game.inscription.id}`,
                      state: {
                        game: game.inscription,
                      },
                    }}
                    title="Detalhe Jogo"
                  >
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h3>{game.inscription.name}</h3>
                      <p>{game.inscription.description}</p>
                    </div>
                  </Link>
                  <div className="col-12">
                    <form
                      className="form-custom"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        {game.user.type == 4 ? (
                          <>
                            <div className="form-row">
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaGameplay ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />{" "}
                                    <FormattedMessage id="games.list.filter.assessment-categories.gameplay" />
                                  </h3>
                                  <div className="container-slider">
                                    <input
                                      type="range"
                                      min="0"
                                      max="5"
                                      step="1"
                                      className="slider slider-range"
                                      {...register("notaGameplay", {
                                        value: 0,
                                      })}
                                      disabled={true}
                                    />
                                    <span className="slider-range-value">
                                      {watchNotaGameplay}
                                    </span>
                                    <i className="fa fa-star btn-outline-warning"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaGameplayShare
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                  </h3>

                                  <div className="content-radio">
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={true}
                                        {...register("notaGameplayShare", {
                                          value: "false",
                                        })}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="sim" />
                                    </div>
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={false}
                                        {...register("notaGameplayShare")}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="nao" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaGameplayComentario
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deixe-seu-comentario" />
                                  </h3>
                                  <textarea
                                    placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                    className="form-control form-control-sm feedback-categoria"
                                    {...register("notaGameplayComentario")}
                                    disabled={true}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaNarrativa ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />{" "}
                                    <FormattedMessage id="games.list.filter.assessment-categories.narrative" />
                                  </h3>
                                  <div className="container-slider">
                                    <input
                                      type="range"
                                      min="0"
                                      max="5"
                                      step="1"
                                      className="slider slider-range"
                                      {...register("notaNarrativa", {
                                        value: 0,
                                      })}
                                      disabled={true}
                                    />
                                    <span className="slider-range-value">
                                      {watchNotaNarrativa}
                                    </span>
                                    <i className="fa fa-star btn-outline-warning"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaNarrativaShare
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                  </h3>

                                  <div className="content-radio">
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={true}
                                        {...register("notaNarrativaShare", {
                                          value: "false",
                                        })}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="sim" />
                                    </div>
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={false}
                                        {...register("notaNarrativaShare")}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="nao" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaNarrativaComentario
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deixe-seu-comentario" />
                                  </h3>
                                  <textarea
                                    placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                    className="form-control form-control-sm feedback-categoria"
                                    {...register("notaNarrativaComentario")}
                                    disabled={true}
                                  ></textarea>
                                </div>
                              </div>
                              {/* Arte */}
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaArte ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />{" "}
                                    <FormattedMessage id="games.list.filter.assessment-categories.art" />
                                  </h3>
                                  <div className="container-slider">
                                    <input
                                      type="range"
                                      min="0"
                                      max="5"
                                      step="1"
                                      className="slider slider-range"
                                      {...register("notaArte", { value: 0 })}
                                      disabled={true}
                                    />
                                    <span className="slider-range-value">
                                      {watchNotaArte}
                                    </span>
                                    <i className="fa fa-star btn-outline-warning"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaArteShare ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                  </h3>

                                  <div className="content-radio">
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={true}
                                        {...register("notaArteShare", {
                                          value: "false",
                                        })}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="sim" />
                                    </div>
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={false}
                                        {...register("notaArteShare")}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="nao" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaArteComentario
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deixe-seu-comentario" />
                                  </h3>
                                  <textarea
                                    placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                    className="form-control form-control-sm feedback-categoria"
                                    {...register("notaArteComentario")}
                                    disabled={true}
                                  ></textarea>
                                </div>
                              </div>
                              {/* Fim Arte */}
                              {/* Som */}
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaSom ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />{" "}
                                    <FormattedMessage id="games.list.filter.assessment-categories.sound" />
                                  </h3>
                                  <div className="container-slider">
                                    <input
                                      type="range"
                                      min="0"
                                      max="5"
                                      step="1"
                                      className="slider slider-range"
                                      {...register("notaSom", { value: 0 })}
                                      disabled={true}
                                    />
                                    <span className="slider-range-value">
                                      {watchNotaSom}
                                    </span>
                                    <i className="fa fa-star btn-outline-warning"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaSomShare ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                  </h3>

                                  <div className="content-radio">
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={true}
                                        {...register("notaSomShare", {
                                          value: "false",
                                        })}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="sim" />
                                    </div>
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={false}
                                        {...register("notaSomShare")}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="nao" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaSomComentario
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deixe-seu-comentario" />
                                  </h3>
                                  <textarea
                                    placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                    className="form-control form-control-sm feedback-categoria"
                                    {...register("notaSomComentario")}
                                    disabled={true}
                                  ></textarea>
                                </div>
                              </div>
                              {/* Fim Som */}
                              {/* inovacao */}
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaInovacao ? "input-error" : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />{" "}
                                    <FormattedMessage id="games.list.filter.assessment-categories.innovation" />
                                  </h3>
                                  <div className="container-slider">
                                    <input
                                      type="range"
                                      min="0"
                                      max="5"
                                      step="1"
                                      className="slider slider-range"
                                      {...register("notaInovacao", {
                                        value: 0,
                                      })}
                                      disabled={true}
                                    />
                                    <span className="slider-range-value">
                                      {watchNotaInovacao}
                                    </span>
                                    <i className="fa fa-star btn-outline-warning"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaInovacaoShare
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                  </h3>

                                  <div className="content-radio">
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={true}
                                        {...register("notaInovacaoShare", {
                                          value: "false",
                                        })}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="sim" />
                                    </div>
                                    <div className="radio">
                                      <input
                                        type="radio"
                                        className="form-control form-control-sm"
                                        name="categoria-extra"
                                        value={false}
                                        {...register("notaInovacaoShare")}
                                        disabled={true}
                                      />
                                      <FormattedMessage id="nao" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <div
                                  className={`${
                                    errors?.notaInovacaoComentario
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <h3>
                                    <FormattedMessage id="deixe-seu-comentario" />
                                  </h3>
                                  <textarea
                                    placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                    className="form-control form-control-sm feedback-categoria"
                                    {...register("notaInovacaoComentario")}
                                    disabled={true}
                                  ></textarea>
                                </div>
                              </div>
                              {/* Fim inovacao */}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={`form-group col-md-12 mb-4`}>
                              <div
                                className={`${
                                  errors?.gameplay ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation." />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("gameplay")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("gameplay")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("gameplay")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.gameplayShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("gameplayShare", {
                                        value: "false",
                                      })}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("gameplayShare")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.gameplayComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder="Comentário"
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("gameplayComment")}
                                  disabled={true}
                                />
                              </div>
                            </div>

                            <div className={`form-group col-md-12 mb-4`}>
                              <div
                                className={`${
                                  errors?.narrativa ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.narrative" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("narrativa")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("narrativa")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("narrativa")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.narrativaShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("narrativaShare", {
                                        value: "false",
                                      })}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("narrativaShare")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.narrativaComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder="Comente"
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("narrativaComment")}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            {/* Arte */}
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.arte ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.art" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("arte")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("arte")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("arte")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.arteShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("arteShare", {
                                        value: "false",
                                      })}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("arteShare")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.arteComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder="Comente"
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("arteComment")}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            {/* Fim Arte */}
                            {/* Som */}
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.som ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.sound" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("som")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("som")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("som")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.somShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("somShare", {
                                        value: "false",
                                      })}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("somShare")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.somComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder="Comente"
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("somComment")}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            {/* Fim Som */}
                            {/* inovacao */}
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.inovacao ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="games.detail.curators-evaluation.innovation" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Sim"}
                                      {...register("inovacao")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Não"}
                                      {...register("inovacao")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={"Talvez"}
                                      {...register("inovacao")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="talvez" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.inovacaoShare ? "input-error" : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>
                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={true}
                                      {...register("inovacaoShare", {
                                        value: "false",
                                      })}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      value={false}
                                      {...register("inovacaoShare")}
                                      disabled={true}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${
                                  errors?.inovacaoComment ? "input-error" : ""
                                }`}
                              >
                                <textarea
                                  placeholder="Comente"
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register("inovacaoComment")}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            {/* Fim inovacao */}
                          </>
                        )}
                      </div>

                      <div className="form-row">
                        <div className="form-group content-btn col-12 d-flex justify-content-end">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              history.push("/games-rated");
                            }}
                          >
                            <FormattedMessage id="account.login.previous" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default injectIntl(GamesRatedSelecionadorFechado);
