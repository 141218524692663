import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyGame extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.gamedata.title",
                        value: this.getValue(this.props.game?.title),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("title")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.age",
                        value: this.getValue(this.props.game?.age),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("age"),
                        options: [
                            {value: 0, label: "age.everyone"},
                            {value: 10, label: "age.10"},
                            {value: 12, label: "age.12"},
                            {value: 14, label: "age.14"},
                            {value: 16, label: "age.16"},
                            {value: 18, label: "age.18"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.releasedate",
                        type: "date",
                        value: this.getValue(this.props.game?.releasedate),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("releasedate")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.gamerelease.label",
                        value: this.getValue(this.props.game?.releasetype),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("releasetype"),
                        options: [
                            {value: 1, label: "inscriptions.gamedata.gamerelease.exact"},
                            {value: 2, label: "inscriptions.gamedata.gamerelease.estimated"},
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.thumbnail",
                        value: this.getValue(this.props.game?.thumbnail),
                        link: "/api/v1/media/image/" + this.getValue(this.props.game?.thumbnail),
                        link_download: true,
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("thumbnail")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.picture",
                        value: this.getValue(this.props.game?.picture),
                        link: "/api/v1/media/image/" + this.getValue(this.props.game?.picture),
                        link_download: true,
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("picture")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.presskit",
                        value: this.getValue(this.props.game?.presskit),
                        link: this.getValue(this.props.game?.presskit),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("presskit")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.video",
                        value: this.getValue(this.props.game?.video),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("video")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.trailer",
                        value: this.getValue(this.props.game?.trailer),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("trailer")
                    })}
                    {renderTextValue({
                        label: "inscriptions.gamedata.synopsis",
                        value: this.getValue(this.props.game?.synopsis),
                        col: 12,
                        col_md: 6,
                        className: this.getClassName("synopsis")
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyGame)