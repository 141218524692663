import React from "react";
import { FormattedMessage } from "react-intl";

function ModalAvaliar({ users }) {
  return (
    <div
      className="modal fade in alert-message-modal"
      id="modalAvaliar"
      // tabindex="-1"
      role="dialog"
      aria-labelledby="modalAvaliarLabel"
      aria-hidden="true"
      style={{ maxWidth: "460px" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="testFilterLabel">
              <FormattedMessage id="calls.inscriptions.event.finalist.button.users-who-have-yet-to-rate" />
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="list-group"
              style={{
                rowGap: "15px",
                // minHeight: "200px",
                textAlign: "left",
              }}
            >
              <table className="table verticle-middle table-lista-big">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormattedMessage id="app.menu.users" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="app.menu.team" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="calls.inscriptions.event.finalist.category" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.length != 0 &&
                    users.map((team, key) => {
                      return team.usersWhoHaveYetToRate.map((user, keyUser) => {
                        return (
                          <tr key={keyUser}>
                            <td>{user.profile.name}</td>
                            <td>{team.name}</td>
                            <td>{team.cat_avaliadas.toString()}</td>
                          </tr>
                        );
                      });
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAvaliar;
