import React from "react"
import { withRouter } from "react-router"
import withAuth from "../../components/auth"


function EventJuries() {

  return (
    <div className="content-body">
      <div className="container-fluid">
        <list-selectors int-type="1" />
      </div>
    </div>
  );
}

export default withRouter(withAuth(EventJuries))