import BaseFrame from './BaseFrame'
import { renderTextInput, 
    renderListOptionsInput,
    renderFileUpload,
    renderCheckBox } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameTerms extends BaseFrame {

    constructor(props) {
        super(props)

        this.state = {
            show_ownershippermit: false,
            show_parentalconsent: false,
            show_file_parentalconsent: false
        }
    }

    onValueChanged = () => {
        let stateChanged = false
        
        const show_ownershippermit = this.props.inscription.term?.ownership === false
        const show_parentalconsent = this.props.inscription.term?.majority === false
        const show_file_parentalconsent = (this.props.inscription.term?.parentalconsent)

        stateChanged = stateChanged || this.state.show_ownershippermit !== show_ownershippermit
        stateChanged = stateChanged || this.state.show_parentalconsent !== show_parentalconsent
        stateChanged = stateChanged || this.state.show_file_parentalconsent !== show_file_parentalconsent

        if (stateChanged)
            this.setState({
                show_ownershippermit,
                show_parentalconsent,
                show_file_parentalconsent
            })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.terms.title" /></h2>

                <div className="row">
                    {renderCheckBox({
                        name: "acceptterms",
                        label: "inscriptions.terms.acceptterms",
                        onChange: (e) => this.onChangeFieldModel("term", e),
                        value: this.fieldValueModel("term", "acceptterms"),
                        description_html: 
                            <span>
                                <FormattedMessage id="inscriptions.terms.iread" />
                                <FormattedMessage id="link.rules">{(link) =>(
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <FormattedMessage id="inscriptions.terms.ruleslink" />
                                    </a>
                                )}
                                </FormattedMessage>
                                
                                <FormattedMessage id="inscriptions.terms.withTerms" />
                                <FormattedMessage id="link.terms">{(link) =>(
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <FormattedMessage id="inscriptions.terms.termslink" />
                                    </a>
                                )}</FormattedMessage>
                                <FormattedMessage id="inscriptions.terms.andpolicy" />
                                <FormattedMessage id="link.privacy">{(link) =>(
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <FormattedMessage id="inscriptions.terms.policylink" />
                                    </a>
                                )}</FormattedMessage>
                                <FormattedMessage id="inscriptions.terms.comapany" />
                            </span>
                        ,
                        required: true
                    })}


                    {renderCheckBox({
                        name: "rules",
                        label: "inscriptions.terms.rules",
                        onChange: (e) => this.onChangeFieldModel("term", e),
                        value: this.fieldValueModel("term", "rules"),
                        description_html: 
                            <span>
                                <FormattedMessage id="inscriptions.terms.iread" />
                                <FormattedMessage id="link.rules">{(link) =>(
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <FormattedMessage id="inscriptions.terms.ruleslink" />
                                    </a>
                                )}
                                </FormattedMessage>
                                <FormattedMessage id="inscriptions.terms.rulesOf" />
                            </span>
                        ,
                        required: true
                    })}  


                    {renderListOptionsInput({
                        name: "ownership",
                        label: "inscriptions.terms.ownership",
                        type: "radio",
                        col: 12,
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("term", e),
                        value: this.fieldValueModel("term", "ownership"),
                        options: [
                            {value: true, label: "inscriptions.terms.ownership.owned"},
                            {value: false, label: "inscriptions.terms.ownership.authorized"}
                        ]
                    })}

                    <div className="col-12 col-md-6">
                        <div className="row">
                            {this.state.show_ownershippermit && renderTextInput({
                                name: "ownershipauthorized",
                                label: "inscriptions.terms.ownership.authorized.ownershipauthorized",
                                required: true,
                                onChange: (e) => this.onChangeFieldModel("term", e),
                                value: this.fieldValueModel("term", "ownershipauthorized"),
                            })}
                        </div>
                    </div>

                    {renderListOptionsInput({
                        name: "majority",
                        label: "inscriptions.terms.majority",
                        type: "radio",
                        col: 12,
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("term", e),
                        value: this.fieldValueModel("term", "majority"),
                        options: [
                            {value: true, label: "inscriptions.terms.majority.yes"},
                            {value: false, label: "inscriptions.terms.majority.no"}
                        ]
                    })}

                    <div className="col-12 col-md-6">
                        <div className="row">
                        
                            {this.state.show_parentalconsent 
                            && !this.state.show_file_parentalconsent
                            && renderFileUpload({
                                name: "parentalconsent",
                                label: "inscriptions.terms.parentalconsent",
                                onChange: (e) => this.onChangeFileFieldModel("term", e, "inscriptions", "parentalconsent"),
                                value: this.fieldValueModel("term", "parentalconsent"),
                                accept: "image/png, image/jpeg, application/pdf",
                                description_html: (
                                    <span>
                                        <FormattedMessage id="inscriptions.terms.parentalconsent.description.before" />
                                        <a href="https://112983445-113097907550718929.preview.editmysite.com/uploads/1/1/2/9/112983445/autorizacao_para_menores.docx" target="_blank" rel="noreferrer">
                                            <FormattedMessage id="inscriptions.terms.parentalconsent.description.link" />
                                        </a>
                                        <FormattedMessage id="inscriptions.terms.parentalconsent.description.after" />
                                    </span>
                                ),
                                required: true
                            })}

                            {this.state.show_parentalconsent 
                            && this.state.show_file_parentalconsent
                            && (
                                <div className="row">
                                    <label className="input-label col-12">
                                        <FormattedMessage id="inscriptions.terms.parentalconsent" />
                                        <span className="input-required">*</span>
                                    </label>
                                    <div className='col-12'>
                                        <a href={`/api/v1/media/image/${this.props.inscription.term?.parentalconsent}`} download={true}>
                                            {this.props.inscription.term?.parentalconsent}
                                        </a>
                                        <button type='button' 
                                            className='btn btn-danger btn-sm ml-2 file-delete' 
                                            onClick={() => this.fileDelete(this.props.inscription.term?.parentalconsent,
                                                "term", "parentalconsent")}>X</button>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameTerms)