export const templateLanguage = {
  "account.home.welcome": "Welcome to BIG Games",
  "account.home.login-password": "Login with email and password",
  "account.home.btn-register": "Don't have an account? Register here!",
  "account.home.forgot-password": "I forgot my password!",
  "account.login.input-label-email": "Login",
  "account.login.input-placeholder-email": "Enter your email",
  "account.login.input-label-password": "Password",
  "account.login.input-placeholder-password": "Password",
  "account.login.btn-enter": "Login",
  "account.login.previous": "Back",
  "account.login.title": "Access with email and password",
  "account.register.title": "Register on our portal",
  "component.my-account-form.input-label-image": "Profile image*",
  "component.my-account-form.input-placeholder-image": "No file selected",
  "component.my-account-form.input-label-permission": "Permission",
  "component.my-account-form.input-placeholder-permission": "Permission",
  "component.my-account-form.input-label-name": "Name*",
  "component.my-account-form.input-placeholder-name": "Enter your name",
  "component.my-account-form.input-label-email": "Email*",
  "component.my-account-form.input-placeholder-email": "Enter your email",
  "component.my-account-form.input-label-password": "Password*",
  "component.my-account-form.input-placeholder-password": "Enter your password",
  "component.my-account-form.input-label-password-confirm": "Confirm Password*",
  "component.my-account-form.input-placeholder-password-confirm":
    "Confirm your password",
  "component.my-account-form.input-label-phone-whatsapp": "Mobile/WhatsApp*",
  "component.my-account-form.input-placeholder-phone-whatsapp":
    "Enter your mobile number",
  "component.my-account-form.input-label-country": "Country*",
  "component.my-account-form.input-label-state": "State*",
  "component.my-account-form.input-label-city": "City*",
  "component.my-account-form.input-placeholder-city": "Enter your city",
  "component.my-account-form.input-label-discord-connect": "Connect my Discord",
  "component.my-account-form.input-label-discord-connected":
    "Disconnect Discord",
  "component.my-account-form.input-label-category-user":
    "If you are an independent professional, see the options below",
  "component.my-account-form.input-label-category-user-studio":
    "I represent a studio",
  "component.my-account-form.input-label-category-user-publisher":
    "I represent a publisher",
  "component.my-account-form.input-label-category-user-self-employed":
    "I am a self-employed developer",
  "component.my-account-form.subtitle-current-experience": "Current experience",
  "component.my-account-form.description-current-experience-attention":
    "ATTENTION:",
  "component.my-account-form.description-current-experience":
    "Do not enter the games that will be sent to the open calls, only the history of old games already made.",
  "component.my-account-form.terms-1": "I agree with the ",
  "component.my-account-form.terms-2": "Terms of Service",
  "component.my-account-form.terms-3": "and with the ",
  "component.my-account-form.terms-4": "Privacy Policy",
  "component.my-account-form.terms-5": "of the service.",
  "component.my-account-form.btn-cancel": "Cancel",
  "component.my-account-form.btn-register": "Register",
  "component.my-account-form.btn-save": "Save",
  "component.my-account-form.input-label-date": "Since",
  "component.my-account-form.input-label-site": "Website",
  "component.my-account-form.input-placeholder-site":
    "Inform the studio website",
  "component.my-account-form.input-label-current-jobs": "Current roles*",
  "component.my-account-form.title-games-release":
    "Games released that you've worked on",
  "component.my-account-form.input-label-link-video": "Video link of the game",
  "component.my-account-form.input-placeholder-link-video":
    "Insert the video link",
  "component.my-account-form.input-label-platform-game": "Game platforms",
  "component.my-account-form.input-select-platform-game":
    "Select game platform(s)",
  "component.my-account-form.btn-remove-game": "Remove game",
  "component.my-account-form.btn-add-game": "Add Game",
  "component.my-account-form.title-previous-experience": "Previous experience",
  "component.my-account-form.title-previous-experience-description":
    "It's important to know your background!",
  "component.my-account-form.add": "Add",
  "component.my-account-form.remove": "Remove",
  "component.my-account-form.studio": "studio",
  "component.my-account-form.publisher": "publisher",
  "component.my-account-form.title-previous-experience-description-2":
    "Tell us more about your experience",
  "component.my-account-form.input-label-prev-jobs": "Previous jobs*",
  "component.my-account-form.select-option-prev-jobs":
    "Inform the roles you played within the studio",
  "component.my-account-form.input-year-job": "Year worked",
  "component.my-account-form.input-year-job-de": "From",
  "component.my-account-form.input-year-job-ate": "Until",
  "account.forgot-password.input-label-login": "Login",
  "account.forgot-password.input-placeholder-login": "Enter your email",
  "account.forgot-password.btn-send": "Send",
  "account.forgot-password.input-label-email": "Email",
  "account.forgot-password.input-label-code": "Code",
  "account.forgot-password.input-placeholder-code":
    "Enter the code received by email",
  "account.forgot-password.input-label-new-password": "New password",
  "account.forgot-password.input-placeholder-new-password":
    "Enter new password",
  "account.forgot-password.btn-changed-password": "Change Password",
  android: "Android",
  browser: "Browser",
  iOS: "iOS",
  "nintendo.switch": "Nintendo Switch",
  "pc.personal.computer": "PC (Personal Computer)",
  playstation: "Playstation",
  steam: "Steam",
  xbox: "Xbox",
  "component.auto-complete-games.title": "Game",
  "component.auto-complete-games.placeholder":
    "Type at least 3 characters to see suggestions",
  "component.auto-complete-studios.prev": "previous",
  "component.auto-complete-studios.current": "current*",
  "component.auto-complete-studios.placeholder.text":
    "Type at least 3 characters to see suggestions",
  "component.discord.bt-connect": "Join with Discord",
  "app.welcome-1": "Hello",
  "app.welcome-2": "welcome",
  "app.menu.dashboard": "Dashboard",
  "app.menu.games": "Games",
  "app.menu.mark-winners": "Mark Winners",
  "app.menu.finalist-winners": "Winners and Finalists",
  "app.menu.registered-calls": "Existing Calls",
  "app.menu.registered-new-call": "New Call",
  "app.menu.inscriptions": "My submissions",
  "app.menu.inscriptions-calls": "Calls",
  "app.menu.inscriptions-all": "All My Submissions",
  "app.breadcrumb.dashboard": "Dashboard",
  "dashboard.games": "games",
  "dashboard.games.inscriptions": "Registered in our portal",
  "dashboard.calls": "Calls",
  "dashboard.calls.length": "Total calls",
  "dashboard.event.title": "Event",
  "dashboard.event.type": "Type",
  "dashboard.event.deadline": "Deadline",
  "dashboard.event.inscriptions": "Submissions",
  "dashboard.event.send-length": "submission sent",
  "dashboard.event.empty-calls": "No registered calls",
  "dashboard.game.title": "Latest Games",
  "dashboard.game.award": "Award",
  "dashboard.game.finalist": "Finalist",
  "dashboard.game.publisher": "Publisher",
  "dashboard.game.platforms": "Platforms",
  "dashboard.game.genre": "Genre",
  "dashboard.game.country": "Country",
  "dashboard.game.classification": "Classification",
  "dashboard.game.release-date": "Release Date",
  "dashboard.game.send-date": "Date sent",
  "dashboard.game.general-note": "General grade",
  "dashboard.game.commercial-potential": "Commercial Potential",
  "inscriptions.calls": "Submitted to calls",
  "inscriptions.game.limit": "We accept games released from",
  "inscriptions.edit.validate.image": "Game Image is required",
  "inscriptions.edit.validate.name": "Game name is required",
  "inscriptions.edit.validate.deadline": "Deadline is required",
  "inscriptions.edit.validate.description": "Game description is required",
  "inscriptions.edit.validate.date": "Invalid release date for this call",
  "inscriptions.deadline": "Inscriptions closed.",
  "inscriptions.edit.validate.imageType":
    "Only jpg/jpeg and png files are allowed!",
  "inscriptions.edit.response.fail": "Failed to save submission",
  "inscriptions.edit.field.image": "Game Image",
  "inscriptions.edit.field.image.placeholder":
    "(Square format, minimum 300px by 300px)",
  "inscriptions.edit.field.name": "Game Name",
  "inscriptions.edit.field.description": "Game Description",
  "inscriptions.edit.field.description.placeholder":
    "(Maximum number of characters: 300)",
  "inscriptions.edit.field.releaseDate": "Release date",
  "inscriptions.edit.buttons.editDetails": "Edit details",
  "inscriptions.edit.buttons.save": "Save this screen",
  "inscriptions.edit.new": "New Submission",
  "inscriptions.edit.title": "Submit your game",
  "inscriptions.list.game": "Game",
  "inscriptions.list.inscriptionDate": "Submitted to",
  "inscriptions.list.releaseDate": "Release date",
  "inscriptions.list.lastView": "Last view",
  "inscriptions.list.status": "Status",
  "inscriptions.list.selectEvent": "Select event",
  "inscriptions.list.selectStatus": "Select submission status",
  "inscriptions.status.notSent": "Not sent",
  "inscriptions.status.underReview": "Under Review",
  "inscriptions.status.finalist": "Finalist",
  "inscriptions.status.notSelected": "Not selected",
  "inscriptions.status.reviewGame": "Please update game information",
  "form.validate.fillAllFields": "Fill in all fields!",
  "form.buttons.cancel": "Cancel",
  "form.buttons.continue": "Continue",
  "form.filterBy": "Filter by",
  "form.orderBy": "Ordering by",
  "form.order.date.ascending": "Most recent",
  "form.order.date.descending": "Least recent",
  "component.header.my-account": "My Account",
  "component.header.logout": "Logout",
  "games.detail.menu-game.download": "Download",
  "games.detail.menu-game.historic": "History",
  "games.detail.menu-game.contact": "Contacts",
  "games.detail.menu-game.rate": "Rate",
  "games.detail.menu-game.results": "Results",
  "games.detail.game.platforms": "Platforms",
  "games.detail.game.genre": "Genre",
  "games.detail.game.country": "Country",
  "games.detail.game.studio": "Studio",
  "games.detail.game.publisher": "Publisher",
  "games.detail.game.country-publisher": "Publisher Country",
  "games.detail.game.classification": "Classification",
  "games.detail.game.number-of-players": "Number of Players",
  "games.detail.game.release-date": "Release Date",
  "games.detail.curators-evaluation": "Curators evaluation",
  "games.detail.curators-evaluation.gameplay": "Gameplay:",
  "games.detail.curators-evaluation.narrative": "Narrative:",
  "games.detail.curators-evaluation.art": "Art:",
  "games.detail.curators-evaluation.sound": "Sound:",
  "games.detail.curators-evaluation.innovation": "Innovation:",
  "games.detail.jurys-evaluation": "Jury Evaluation",
  "games.detail.jurys-evaluation.general": "General:",
  "games.detail.commercial-valuation": "Commercial Evaluation",
  "games.detail.commercial-valuation.general": "General:",
  "games.detail.event-acceptances": "Event Acceptances",
  "games.detail.event-specific-categories": "Event-specific categories",
  "games.list.filter.title": "Filter by:",
  "games.list.filter.assessment-categories": "Evaluation Categories",
  "games.list.filter.assessment-categories.gameplay": "Gameplay",
  "games.list.filter.assessment-categories.narrative": "Narrative",
  "games.list.filter.assessment-categories.art": "Art",
  "games.list.filter.assessment-categories.sound": "Sound",
  "games.list.filter.assessment-categories.innovation": "Innovation",
  "games.list.filter.extra-categories": "Extra Categories",
  "games.list.filter.status": "Status",
  "games.list.filter.platforms": "Platforms",
  "games.list.filter.genre": "Genre",
  "games.list.filter.classification": "Classification",
  "games.list.filter.country": "Country",
  "games.list.filter.release-date": "Release Date",
  "games.list.filter.send-date": "Date sent",
  "games.list.filter.calls": "Calls",
  "games.list.filter.rating": "Rating",
  "games.list.filter.games": "Games",
  "games.list.filter.order-by": "Ordering by:",
  "register.empty_field": "Please fill in all required fields!",
  "forgot-password.fail-forgot":
    "We couldn't find this email in our database. Please try another email.",
  "inscriptions.edit.new-edit": "Edit Submission",
  "app.menu.rating": "Avaliações",
  "app.breadcrumb.rating": "Avaliações",
  "rating.title.selection-closed": "Avaliação Selecionador Fechado",
  "rating.title.selection-games": "Selecionador de jogos",
  "app.menu.games-rated": "Jogos Avaliados",
  "app.menu.calls": "Chamadas",
  "app.menu.mark-finalist": "Marcar Finalista",
  "app.menu.users": "Usuários",
  "app.menu.users-all": "Todos os Usuários",
  "app.menu.users-new": "Novo Usuário",
  "app.menu.team": "Equipe",
  "app.menu.teams-all": "Todas as Equipes",
  "app.menu.teams-new": "Nova Equipe",
  "app.menu.reports": "Relatórios",
  "app.breadcrumb.calls-registers": "Chamadas Cadastradas",
  "calls.registered.filter.title": "Filtrar por:",
  "calls.registered.filter.event": "Evento:",
  "calls.registered.calls.open": "Chamadas Abertas",
  "calls.registered.calls.empty": "Nenhuma chamada cadastrada",
  "calls.registered.calls.closed": "Chamadas Encerradas",
  "calls.registered.new": "Imagem do evento",
  "calls.registered.new.name-event": "Nome do evento",
  "calls.registered.new.category-rating": "Categorias de Avaliação",
  "calls.registered.new.category-extras": "Categorias de Extras",
  "calls.registered.new.limit-exist-game":
    "Limite máximo de existência do jogo",
  "calls.registered.new.url-123-form-publisher": "URL 123 Form Publisher",
  "calls.registered.new.url-123-form-studio": "URL 123 Form Studio",
  "calls.registered.new.url-123-form-autonomous": "URL 123 Form Autônomo",
  "calls.registered.new.new-call": "Nova Chamada",
  "calls.registered.new.welcome": "Olá, bem-vindo(a)",
  "app.breadcrumb.calls": "Chamadas",
  "app.breadcrumb.calls-new": "Nova Chamada",
  "app.breadcrumb.mark-finalist": "Marcar Finalista",
  loading: "Carregando",
  "calls.inscriptions.event.finalist.filter-label": "Filtrar por:",
  "calls.inscriptions.event.finalist.filter-name": "Nome",
  "calls.inscriptions.event.finalist.name.placeholder": "Nome do jogo",
  "calls.inscriptions.event.finalist.category": "Categoria",
  "calls.inscriptions.event.finalist.title": "Marcar Finalistas",
  "calls.inscriptions.event.finalist.filter-order": "Ordenando por:",
  "calls.inscriptions.event.finalist.award": "Prêmio",
  "calls.inscriptions.event.finalist.label": "Finalista",
  "calls.inscriptions.event.finalist.category.label": "Categorias",
  "calls.inscriptions.event.finalist.button.remove-title-invalid":
    "Remover título de inválido",
  "calls.inscriptions.event.finalist.button.add-title-invalid":
    "Inválidar Jogo",
  "calls.inscriptions.event.finalist.button.remove-title-finalist":
    "Remover título de inválido",
  "calls.inscriptions.event.finalist.button.add-title-finalist":
    "Marcar Finalista",
  "calls.inscriptions.event.finalist.button.users-who-have-yet-to-rate":
    "Quem falta avaliar",
  "calls.inscriptions.event.finalist.empty": "Nenhuma inscrição cadastrada",
  "app.menu.games-pending": "Jogos Pendentes",
  "games-pending.list.date-create": "Data de inscrição",
  "games-pending.list.button.email": "E-mail",
  "games-pending.list.button.discord": "Discord",
  "account.userPassIncorrect": "Usuário ou Senha Incorretos",
  "users.edit.response.fail": "Falha ao editar usuário.",
  "users.edit.response.fail.role": "Apenas usuário Master podem realizar.",
  "calls.modal.finalist.remove": "Remover ",
  "calls.modal.finalist.marcar": "Marcar ",
  "calls.modal.finalist.finalista": "Finalista",
  "calls.modal.finalist.vencedor": "Vencedor",
  "calls.edit.fail.update-call": "Falha ao atualizar chamada",
  "rating.save": "Avaliação realizada com sucesso!",
  "rating.save.fail": "Erro ao realizar a avaliação",
  "calls.edit.fail.validate-img":
    "Somente arquivos jpg/jpeg e png são permitidos!",
  "categoria-vencedores": "Categoria Vencedores",
  "avaliacoes-da-equipe": "Avaliações da equipe",
  "remover-titulo-de-vencedor": "Remover título de Vencedor",
  "123-form": "123 Form",
  sim: "Sim",
  nao: "Não",
  talvez: "Talvez",
  "deseja-compartilhar-esse-comentario-com-o-desenvolvedor?":
    "Deseja compartilhar esse comentário com o desenvolvedor?",
  "gostou-da": "Gostou da ",
  "deixe-seu-like!": "Deixe seu like!",
  gostei: "Gostei",
  ver: "Ver",
  "avalie-de-1-a-5-sua-experiencia-com-a-categoria:":
    "Avalie de 1 a 5 sua experiência com a categoria:",
  "deixe-seu-comentario": "Deixe seu comentário",
  "demo-online,-Aceleração,-discord": "Demo online, Aceleração, Discord",
  investimento: "Investimento",
  editar: "Editar",
  "avaliação-do": "Avaliação do ",
  "nivel-usuario": "Nível Usuário",
  avaliador: "Avaliador",
  "meus-dados": "Meus dados",
  "nenhum-jogo-cadastrado": "Nenhum jogo cadastrado",
  "todos-os-jogos": "Todos os jogos",
  ganhadores: "Ganhadores",
  "jogos-invalidos": "Jogos Inválidos",
  chamada: "Chamada",
  acoes: "Ações",
  "nao-existe-jogos": "Não existe jogos",
  "exportar-relatorio": "Exportar Relatório",
  "nome-do-responsavel": "Nome do responsável",
  "avaliacoes-dos-selecionadores": "Avaliações dos selecionadores",
  "avaliacoes-dos-juris": "Avaliações dos juris",
  "categorias-finalista": "Categorias finalista",
  "categorias-vencedoras": "Categorias vencedoras",
  "link-para-detalhes-do-jogo-no-123-form":
    "Link para detalhes do jogo no 123 form",
  "inscritos-em-nosso-portal": "Inscritos em nosso portal",
  "usuarios-ativos": "Usuários ativos",
  "relatorio-de-jogos": "Relatório de jogos",
  "exporte-as-informacoes-sobre-os-jogos-inscritos":
    "Exporte as informações sobre os jogos inscritos",
  visualizar: "Visualizar",
  "relatorio-de-usuarios": "Relatório de usuários",
  "exporte-as-informacoes-sobre-os-usuarios":
    "Exporte as informações sobre os usuários",
  avaliados: "Avaliados",
  "nao-avaliados": "Não avaliados",
  "selecione-o-nivel-de-permissao": "Selecione o nível de permissão",
  master: "Master",
  admin: "Admin",
  desenvolvedor: "Desenvolvedor",
  "juri-aberto": "Júri Aberto",
  "juri-fechado": "Júri Fechado",
  observador: "Observador",
  "selecionador-aberto": "Selecionador Aberto",
  "selecionador-fechado": "Selecionador Fechado",
  "nao-existe-usuarios": "Não Existe usuários",
  "chamadas-em-que-inscreveu-jogos": "Chamadas em que inscreveu jogos",
  "quantos-jogos-finalistas": "Quantos jogos finalistas",
  "quantos-jogos-ganhadores": "Quantos jogos ganhadores",
  "onde-trabalha": "Onde trabalha",
  "data-inicio": "Data início",
  "data-termino": "Data término",
  "qual-funcao": "Qual função",
  "link-do-local": "Link do local",
  "onde-trabalhou": "Onde trabalhou",
  "Jogos que já fez": "Jogos que já fez",
  "Editar Equipe": "Editar Equipe",
  "app.breadcrumb.teams": "Teams",
  "app.breadcrumb.teams.new": "New team",
  "app.breadcrumb.teams.edit": "Edit team",
  "dashboard.event.empty-calls-inscriptions":
    "Nenhuma chamada para se inscrever no momento.",
  "page.filters.new-filter": "Novo filtro",
  acoes: "Ações",
  "app.menu.filters-all": "Todos os Filtros",
  "app.breadcrumb.filters": "Filtros",
  "page.filters.role.permission": "Permissão",
  "app.filters.label.status": "Status",
  "selecione-o-status": "Selecione o status",
  "selecione-o-status-active": "Ativo",
  "selecione-o-status-deactivate": "Inativo",
  "app.filters.label.empty": "Não existe filtros",
  "app.filters.table.name-filter": "Nome do Filtro",
  "app.filters.table.role": "Permissão",
  "app.filters.table.status": "Status",
  "app.filters.table.actions": "Ações",
  "app.filters.new.label.group-role": "Grupo de usuários",
  "selecione-os-filtros-que-serao-exibidos":
    "Selecione os filtros que serão exibidos",
  "categorias-de-finalista": "Categorias de Finalista",
  "categorias-de-vencedores": "Categorias de Vencedores",
  status: "Status",
  plataformas: "Plataformas",
  genero: "Genêro",
  classificacao: "Classificação",
  pais: "País",
  "data-de-lancamento": "Data de Lançamento",
  "data-de-envio": "Data de Envio",
  chamadas: "Chamadas",
  avaliacao: "Avaliação",
  jogos: "Jogos",
  "delete.filter": "Tem certeza que deseja deletar este filtro",
  "teste-de-filtro": "Teste de filtro",
  "testar-filtro": "Testar filtro",
  "todos-os-idiomas": "Todos os Idiomas",
  "novo-idioma": "Novo idioma",
  idiomas: "Idiomas",
  idioma: "Idioma",
  configuracoes: "Configurações",
  alertas: "Alertas",
  "nenhum-idioma-cadastrado": "Nenhum idioma cadastrado",
  "nome-do-idioma": "Nome do idioma",
  "status-do-idioma": "Status do idioma",
  "upload-os-termos": "Upload os termos",
  "download-dos-termos": "Download dos termos",
  "delete.language": "Tem certeza que deseja deletar este idioma",
};
