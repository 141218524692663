import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import logo from "../../assets/images/v2/logo.svg";
import { Link, useHistory } from "react-router-dom";
// import { Container } from './styles';

function Header() {
  const history = useHistory();
  //const intl = useIntl();

  return (
    <div className="header">
      <div className="nav-header">
        <div className="brand-logo">
          <Link to={"/"} title="BIG">
            <img src={logo} alt="BIG" />
          </Link>
        </div>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line"></span> <span className="line"></span>{" "}
            <span className="line"></span>
          </div>
        </div>
      </div>
      <div className="header-content">
        <div className="header-right">
          <ul>
            <li className="icons">
              <a href="#">
                <i className="mdi mdi-account f-s-20" aria-hidden="true"></i>
              </a>
              <div className="drop-down dropdown-profile animated bounceInDown">
                <div className="dropdown-content-body">
                  <ul>
                    <li>
                      <Link to="/my-account" title="Minha Conta">
                        <i className="mdi mdi-settings"></i>{" "}
                        <span>
                          <FormattedMessage id="component.header.my-account" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/logout"}
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.removeItem("token");
                          window.open("/", "_self");
                        }}
                        title="Sair"
                      >
                        <i className="icon-power"></i>{" "}
                        <span>
                          <FormattedMessage id="component.header.logout" />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Header);
