import React, { useEffect, useState } from "react";
import axios from "axios";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";

function AutoCompleteGames(props) {
  const [games, setGames] = useState([]);
  const [text, setText] = useState("");
  const [isSetText, setIsSetText] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const loadStudies = async () => {
      const response = await axios.get("/api/v1/games", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      setGames(response.data);
    };
    loadStudies();
  }, []);

  useEffect(() => {
    if (props.multiple) {
      props.onSet(props.name, text, props.indexStudio, props.idGame);
    } else {
      props.onSet(props.name, text, props.index);
    }
  }, [text]);

  useEffect(() => {
    if (isSetText && props.initialValue != "") {
      setText(props.initialValue);
      setIsSetText(false);
    }
  }, [props.initialValue]);

  const onSuggestHandler = (text) => {
    setText(text);
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 2) {
      matches = games.filter((studio) => {
        const regex = new RegExp(`${text}`, "gi");
        return studio.name.match(regex);
      });
      setSuggestions(matches);
    } else {
      setSuggestions([]);
    }
    setText(text);
  };

  return (
    <div className={`col-6 mb-3 ${props.isError ? "input-error" : ""}`}>
      <label>
        <FormattedMessage id="component.auto-complete-games.title" />
      </label>
      <input
        type="text"
        className="form-control form-control-sm"
        placeholder={intl.formatMessage({
          id: "component.auto-complete-games.placeholder",
        })}
        onChange={(e) => onChangeHandler(e.target.value)}
        value={text}
        onBlur={() => {
          setTimeout(() => {
            setSuggestions([]);
          }, 200);
        }}
      />
      {suggestions &&
        suggestions.map((suggestion, i) => (
          <div
            key={i}
            className="suggestion col-md-12 justify-content-md-center"
            onClick={() => onSuggestHandler(suggestion.name)}
          >
            {suggestion.name}
          </div>
        ))}
    </div>
  );
}

export default injectIntl(AutoCompleteGames);
