import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyDetails extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.details.players",
                        value: this.getValue(this.props.game?.players),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("players"),
                        options: [
                            { value: 1, label: "single_player"},
                            { value: 2, label: "multiplayer"},
                            { value: 3, label: "server"},
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.details.genres",
                        value: this.getValue(this.props.game?.genres),
                        col: 6,
                        col_md: 3,
                        ignoreLocaleValue: true,
                        className: this.getClassName("genres"),
                        options: this.props.genres?.map(item => {
                            return {
                                value: item.id,
                                label: item.title
                            }
                        })
                    })}
                    {renderTextValue({
                        label: "inscriptions.details.requirements",
                        value: this.getValue(this.props.game?.requirements),
                        col: 12,
                        col_md: 6,
                        className: this.getClassName("requirements")
                    })}
                    {renderTextValue({
                        label: "inscriptions.details.controls",
                        value: this.getValue(this.props.game?.controls),
                        col: 12,
                        col_md: 6,
                        className: this.getClassName("controls")
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyDetails)