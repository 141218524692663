import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyStudio extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.publisher.studio",
                        value: this.getValue(this.props.studio?.name),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("name")
                    })}
                    {renderTextValue({
                        label: "inscriptions.publisher.studio.country",
                        value: this.getValue(this.props.studio?.country),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("country")
                    })}
                    {(this.props.studio?.country === "Brasil"
                        || this.props.studio?.country === "Brazil") && renderTextValue({
                        label: "inscriptions.publisher.studio.state",
                        value: this.getValue(this.props.studio?.state),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("state")
                    })}
                    {(this.props.studio?.country === "Brasil"
                        || this.props.studio?.country === "Brazil") && renderTextValue({
                        label: "inscriptions.publisher.studio.city",
                        value: this.getValue(this.props.studio?.city),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("city")
                    })}
                    {renderTextValue({
                        label: "inscriptions.publisher.studio.members",
                        value: this.getValue(this.props.studio?.members),
                        col: 12,
                        col_md: 6,
                        className: this.getClassName("members")
                    })}
                    {renderTextValue({
                        label: "inscriptions.publisher.publishertype",
                        value: this.getValue(this.props.studio?.publishertype),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("publishertype"),
                        options: [
                            {value: 1, label: "yes"},
                            {value: 0, label: "no"},
                            {value: 2, label: "negociation"}
                        ]
                    })}
                    {(this.props.studio?.publishertype !== 0) && renderTextValue({
                        label: "inscriptions.publisher.publishercountry",
                        value: this.getValue(this.props.studio?.publishercountry),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("publishercountry")
                    })}
                    {renderTextValue({
                        label: "inscriptions.publisher.studioemails",
                        value: this.getValue(this.props.studio?.emails),
                        col: 6,
                        col_md: 4,
                        className: this.getClassName("emails")
                    })}
                    {renderTextValue({
                        label: "inscriptions.publisher.studiophone.label",
                        value: this.getValue(this.props.studio?.phones),
                        col: 6,
                        col_md: 4,
                        className: this.getClassName("phones")
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyStudio)