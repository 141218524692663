import React, { Component } from "react";
import { withRouter } from "react-router";
import withAuth from "../../components/auth";
import { injectIntl } from "react-intl";
import "react-datepicker/dist/react-datepicker.css";


class InscriptionsNew extends Component {
  
  constructor(props) {
    super(props)

    this.event_id = props.match.params.event_id
    window._big.onInscriptionCreated = this.inscriptionCreated
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
    window._big.onInscriptionCreated = null
  }

  inscriptionCreated = (inscriptionId) => {
    setTimeout(() => {
      this.props.history.push(`/inscription/${inscriptionId}`)
    }, 500)
  };

  render() {

    return (
      <div className="content-body">
        <div className="container-fluid">
          <new-inscription event-id={this.event_id} />
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(withAuth(InscriptionsNew)));
