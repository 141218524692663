import axios from 'axios'
import moment from 'moment'
import { Component } from 'react'

class BaseFrame extends Component {

    componentDidMount() {
        this.onValueChanged()
    }

    onValueChanged = () => {
    }

    onChangeFieldFile = (e) => {
        const data = new FormData()

        const name = e.target.files[0].name
        const url = `/api/v1/inscription/upload/${this.props.inscription.id}/${e.target.name}`

        data.append('file', e.target.files[0])
        data.append('filename', name)

        fetch(url, {
            method: 'POST',
            headers: {
                'x-access-token': localStorage.getItem("token")
            },
            body: data
        }).then((response) => {
            console.log("upload completed!")
            e.target.name += "file"
            this.onChangeFieldInscriptions(e)
        })
    }

    onChangeFieldModel = (key, e) => {
        if (!e || !e.target)
            return

        if (!(key in this.props.inscription) || !(this.props.inscription[key])) {
            this.props.inscription[key] = {}
        }

        let item = this.props.inscription[key]
        this.changeFieldValue(e, item)
        this.props.onModelChanged?.()
        this.props.onInscriptionsChanged?.()
    }

    onChangeFileFieldModel = (key, e, type, item) => {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append('files', e.target.files[i]);
        }

        const url = `/api/v1/media/upload/${type}/${item}/${this.props.inscription.id}`

        axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-access-token': localStorage.getItem("token")
            }
          }).then((response) => {
            const eModel = {
                target: {
                    name: e.target.name,
                    tagName: "FileList",
                    value: response.data,
                }
            }

            this.onChangeFieldModel(key, eModel)
          }).catch((error) => {
            console.log(error)
          });
    }

    changeFieldValue = (e, item) => {
        let value = ""

        if (item === null)
            item = {}

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        else if (e.target.tagName === "SELECT" && e.target.multiple) {
            value = [];
            for (let option of e.target.selectedOptions) {
                value.push(option.value);
            };
        }
        else if (e.target.type === "date") {
            value = moment(`${e.target.value} 12:00:00`).format("YYYY-MM-DD HH:mm:ss")
        }
        else {
            value = parseInt(e.target.value)

            if (isNaN(value))
                value = e.target.value
        }

        item[e.target.name] = value;
        this.onValueChanged()

        return item;
    }

    fieldValueModel(modelKey, key) {
        if (!(modelKey in this.props.inscription))
            return ""

        return this.fieldValue(key, this.props.inscription[modelKey])
    }

    fieldValueProfile(key) {
        return this.fieldValue(key, this.props.profile)
    }

    fieldValueInscription(key) {
        return this.fieldValue(key, this.props.inscription)
    }

    fieldValue(key, item) {
        if (!item)
            return ""

        if (key in item)
            return item[key]
        
        return "";
    }

    async fileDelete(image, key, name) {
        try {
            const url = `/api/v1/media/image/${image}`

            await axios.delete(url, { headers: {
                'x-access-token': localStorage.getItem("token")
            }})

            const eModel = {
                target: {
                    name: name,
                    tagName: "FileListDelete",
                    value: null,
                }
            }

            console.log(eModel)
            this.onChangeFieldModel(key, eModel)
        } catch (error) {
            console.log(error)
        }
    }
}

export default BaseFrame