import BaseFrame from './BaseFrame'
import {    renderTextInput, 
            renderCountryPhoneCode,
            renderListOptionsInput } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameProfile extends BaseFrame {
    
    constructor(props) {
        super(props)

        this.state = {
            show_typecommunication: false
        }
    }

    onValueChanged = () => {
        let stateChanged = false
        
        const show_typecommunication = Array.isArray(this.props.inscription.profile?.typecommunication)
                                    && this.props.inscription.profile?.typecommunication?.includes(-1)

        stateChanged = stateChanged || this.state.show_typecommunication !== show_typecommunication
        
        if (stateChanged)
            this.setState({
                show_typecommunication
            })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.profile.title" /></h2>

                <div className="row">
                    {renderTextInput({
                        name: "name",
                        label: "inscriptions.profile.name",
                        description: "inscriptions.profile.name.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("profile", e),
                        value: this.fieldValueModel("profile", "name"),
                        col_md: 6
                    })}

                    {renderTextInput({
                        name: "email",
                        label: "inscriptions.profile.email",
                        description: "inscriptions.profile.email.description",
                        onChange: (e) => this.onChangeFieldModel("profile", e),
                        value: this.fieldValueModel("profile", "email"),
                        required: true,
                        col_md: 6
                    })}

                    <div className="col-12 col-md-6">
                        <div className="row">
                            {renderCountryPhoneCode({
                                name: "countrycode",
                                label: "inscriptions.profile.countryphone",
                                placeholder: "select",
                                onChange: (e) => this.onChangeFieldModel("profile", e),
                                value: this.fieldValueModel("profile", "countrycode"),
                                required: true,
                                col: 5
                            })}
                            {renderTextInput({
                                name: "phone",
                                label: "inscriptions.profile.phone",
                                description: "inscriptions.profile.phone.description",
                                onChange: (e) => this.onChangeFieldModel("profile", e),
                                value: this.fieldValueModel("profile", "phone"),
                                required: true,
                                col: 7
                            })}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row">
                            {renderListOptionsInput({
                                name: "typecommunication",
                                label: "inscriptions.profile.typecommunication",
                                col: 12,
                                option_col: 4,
                                valueType: "int",
                                required: true,
                                onChange: (e) => this.onChangeFieldModel("profile", e),
                                value: this.fieldValueModel("profile", "typecommunication"),
                                options: [
                                    { value: 1, label: "whatsapp" },
                                    { value: 2, label: "telegram" },
                                    { value: 3, label: "signal" },
                                    { value: 4, label: "none" },
                                    { value: -1, label: "other" }
                                ]
                            })}
                            {
                                this.state.show_typecommunication &&
                                renderTextInput({
                                    name: "othertypecommunication",
                                    onChange: (e) => this.onChangeFieldModel("profile", e),
                                    value: this.fieldValueModel("profile", "othercommunication"),
                                })
                            }
                        </div>
                    </div>

                    {renderTextInput({
                        name: "position",
                        label: "inscriptions.profile.position",
                        onChange: (e) => this.onChangeFieldModel("profile", e),
                        value: this.fieldValueModel("profile", "position"),
                        col_md: 6
                    })}

                    {renderTextInput({
                        name: "discord",
                        label: "inscriptions.profile.discord",
                        onChange: (e) => this.onChangeFieldModel("profile", e),
                        value: this.fieldValueModel("profile", "discord"),
                        col_md: 6
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameProfile)