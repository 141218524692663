import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

function Modal132Game({ game }) {
  const intl = useIntl();
  return (
    <div
      className="modal fade in "
      id="modal123"
      // tabindex="-1"
      role="dialog"
      aria-labelledby="modal123Label"
      aria-hidden="true"
      style={{
        overflow: "hidden",
        maxHeight: "95%",
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="testFilterLabel">
              <FormattedMessage id="123-form" />
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="list-group"
              style={{
                rowGap: "15px",
                // minHeight: "200px",
                textAlign: "left",
              }}
            >
              {game != null && (
                <iframe
                  id="contactform123"
                  name="contactform123"
                  allowtransparency="true"
                  style={{
                    height: "calc(100vh)",
                    width: "100%",
                    paddingBottom: "25%",
                  }}
                  marginWidth="0"
                  marginHeight="0"
                  frameBorder="0"
                  src={game.form123edit}
                >
                  <p>
                    Your browser does not support iframes. The contact form
                    cannot be displayed. Please use another contact method
                    (phone, fax etc)
                  </p>
                </iframe>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal132Game;
