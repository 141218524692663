import React, { useEffect } from "react";
import { injectIntl } from "react-intl";

function DiscordCallback(props) {
  useEffect(() => {
    const params = window.location.hash.substring(1);
    if (window.opener) {
      window.opener.postMessage(params);
      window.close();
    } else {
      alert("No opener");
    }
  }, []);

  return <></>;
}

export default injectIntl(DiscordCallback);
