import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import jwt_decode from "jwt-decode";
import ReactExport from "react-export-excel";
import { Country, State, City } from "country-state-city";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function UsersReports() {
  const [users, setUsers] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [roleFilters, setRoleFilters] = useState(-1);
  const [orderBy, setOrderBy] = useState("a-z");
  const [usersVisible, setUsersVisile] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    var filtered = users;
    if (searchUserName != "") {
      filtered = filtered.filter(({ profile }) =>
        profile.name.toLowerCase().includes(searchUserName.toLowerCase())
      );
    }

    if (roleFilters != -1) {
      filtered = filtered.filter(({ type }) => type == roleFilters);
    }

    setUsersVisile(filtered);
  }, [searchUserName, roleFilters]);

  function sortBy(arr, o = 1) {
    return [...arr].sort(
      (a, b) => a.profile.name.localeCompare(b.profile.name) * o
    );
  }

  function loadUsers() {
    let url = "/api/v1/users/reports";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let filtered = res.data;
        console.log("usuarios", filtered);
        setUsers(filtered);
        setUsersVisile(filtered);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function getLabelRole(roleId) {
    switch (roleId) {
      case 0:
        return "Master";
      case 2:
        return "Admin";
      case 1:
        return "Desenvolvedor";
        break;
      case 3:
        return "Júri Aberto";
      case 4:
        return "Júri Fechado";
      case 5:
        return "Observador";
      case 6:
        return "Selecionador Aberto";
      case 7:
        return "Selecionador Fechado";
    }
  }

  function howManyFinalistGames(games) {
    var gamesFinalistLength = 0;

    games.forEach((game) => {
      if (game.finalist != null) {
        gamesFinalistLength = gamesFinalistLength + game.finalist.length;
      }
    });

    return gamesFinalistLength;
  }

  function howManyWinningGames(games) {
    var gamesWinningLength = 0;

    games.forEach((game) => {
      if (game.award != null) {
        gamesWinningLength = gamesWinningLength + game.award.length;
      }
    });

    return gamesWinningLength;
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"} title="Dashboard">
                  <FormattedMessage id="app.breadcrumb.dashboard" />
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/"} title="Reports">
                  <FormattedMessage id="app.breadcrumb.reports" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.reports-users" />
              </li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <form className="form-custom content-filter">
                  <div className="filter">
                    <div className="form-row">
                      <div className="col-12">
                        <h2 className="card-title">
                          <FormattedMessage id="calls.registered.filter.title" />
                        </h2>
                      </div>
                      <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={searchUserName}
                          onChange={({ target }) =>
                            setSearchUserName(target.value)
                          }
                          placeholder="Nome do usuário"
                        />
                      </div>
                      <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="component.my-account-form.input-label-permission" />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          value={roleFilters}
                          onChange={({ target }) =>
                            setRoleFilters(target.value)
                          }
                        >
                          <option value={-1}>
                            {intl.formatMessage({
                              id: "selecione-o-nivel-de-permissao",
                            })}
                          </option>
                          <option value={0}>
                            {intl.formatMessage({
                              id: "master",
                            })}
                          </option>
                          <option value={2}>
                            {intl.formatMessage({
                              id: "admin",
                            })}
                          </option>
                          <option value={1}>
                            {intl.formatMessage({
                              id: "desenvolvedor",
                            })}
                          </option>
                          <option value={3}>
                            {intl.formatMessage({
                              id: "juri-aberto",
                            })}
                          </option>
                          <option value={4}>
                            {intl.formatMessage({
                              id: "juri-fechado",
                            })}
                          </option>
                          <option value={5}>
                            {intl.formatMessage({
                              id: "observador",
                            })}
                          </option>
                          <option value={6}>
                            {intl.formatMessage({
                              id: "selecionador-aberto",
                            })}
                          </option>
                          <option value={7}>
                            {intl.formatMessage({
                              id: "selecionador-fechado",
                            })}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="app.menu.users-all" />
                </h1>
                {isLoading ? (
                  <p>
                    <FormattedMessage id="loading" />
                  </p>
                ) : (
                  <>
                    <form className="form-custom content-filter">
                      <div className="filter">
                        <div className="form-row justify-content-between">
                          <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12"></div>
                          <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label>
                              <FormattedMessage id="calls.inscriptions.event.finalist.filter-order" />
                            </label>
                            <select
                              className="form-control form-control-sm"
                              value={orderBy}
                              onChange={({ target }) =>
                                setOrderBy(target.value)
                              }
                            >
                              <option value={"a-z"}>A - Z</option>
                              <option value={"z-a"}>Z - A</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="table-responsive">
                      {usersVisible.length != 0 ? (
                        <table className="table verticle-middle table-lista-big">
                          <thead>
                            <tr>
                              <th scope="col">
                                <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="games-pending.list.button.email" />
                              </th>
                              <th scope="col">
                                <FormattedMessage id="component.my-account-form.input-label-permission" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortBy(
                              usersVisible,
                              orderBy == "a-z" ? 1 : -1
                            ).map((user, index) => {
                              return (
                                <tr key={index}>
                                  <td>{user.profile.name}</td>
                                  <td>{user.login}</td>
                                  <td>{getLabelRole(user.type)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p>
                          <FormattedMessage id="nao-existe-usuarios" />
                        </p>
                      )}
                    </div>

                    {usersVisible.length != 0 && (
                      <div className="form-row">
                        <div className="form-group col-12">
                          <ExcelFile
                            name={"users"}
                            element={
                              <button className="btn btn-primary">
                                <FormattedMessage id="exportar-relatorio" />
                              </button>
                            }
                          >
                            <ExcelSheet
                              data={usersVisible}
                              name="Usuários Relatório"
                            >
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "calls.inscriptions.event.finalist.filter-name",
                                })}
                                value={(col) => col.profile.name}
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "account.forgot-password.input-label-email",
                                })}
                                value={(col) => col.login}
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "component.my-account-form.input-label-phone-whatsapp",
                                })}
                                value={(col) =>
                                  `${
                                    col.profile.country &&
                                    Country.getCountryByCode(
                                      col.profile.country
                                    ).phonecode
                                  } ${
                                    col.profile.phoneWhatsapp &&
                                    col.profile.phoneWhatsapp
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "component.my-account-form.input-label-permission",
                                })}
                                value={(col) => getLabelRole(col.type)}
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "dashboard.game.country",
                                })}
                                value={(col) =>
                                  `${
                                    col.profile.country &&
                                    Country.getCountryByCode(
                                      col.profile.country
                                    ).name
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "component.my-account-form.input-label-state",
                                })}
                                value={(col) =>
                                  `${
                                    col.profile.state &&
                                    State.getStateByCode(col.profile.state)
                                      .isoCode
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "component.my-account-form.input-label-city",
                                })}
                                value={(col) => `${col.profile.city}`}
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "chamadas-em-que-inscreveu-jogos",
                                })}
                                value={(col) => `${col.chamadas.toString()}`}
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "quantos-jogos-finalistas",
                                })}
                                value={(col) =>
                                  `${howManyFinalistGames(col.inscriptions)}`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "quantos-jogos-ganhadores",
                                })}
                                value={(col) =>
                                  `${howManyWinningGames(col.inscriptions)}`
                                }
                              />
                              <ExcelColumn
                                label="Onde trabalha"
                                value={(col) =>
                                  `${
                                    col.currentCompany &&
                                    col.currentCompany.name
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "data-inicio",
                                })}
                                value={(col) =>
                                  `${
                                    col.currentCompany &&
                                    moment(
                                      col.currentCompany?.dados[0]?.startdate
                                    ).format("DD-MM-YYYY")
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "data-termino",
                                })}
                                value={(col) =>
                                  `${
                                    col.currentCompany &&
                                    moment(
                                      col.currentCompany?.dados[0]?.startdate
                                    ).format("DD-MM-YYYY")
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "qual-funcao",
                                })}
                                value={(col) =>
                                  `${
                                    col.currentCompany &&
                                    col.currentCompany?.dados[0]?.functions.toString()
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "link-do-local",
                                })}
                                value={(col) =>
                                  `${
                                    col.currentCompany &&
                                    col.currentCompany?.site
                                  }`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "onde-trabalhou",
                                })}
                                value={(col) =>
                                  `${JSON.stringify(col.ondeTrabalhou)}`
                                }
                              />
                              <ExcelColumn
                                label={intl.formatMessage({
                                  id: "Jogos que já fez",
                                })}
                                value={(col) => `${JSON.stringify(col.games)}`}
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(UsersReports));
