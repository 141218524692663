import BaseFrame from './BaseFrame'
import { renderListOptionsInput } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameFestival extends BaseFrame {

    constructor(props) {
        super(props)

        this.state = {
            show_demoplatform: false
        }
    }

    onValueChanged = () => {
        let stateChanged = false
        
        const show_demoplatform = this.props.inscription.festival?.demo === true

        stateChanged = stateChanged || this.state.show_demoplatform !== show_demoplatform

        if (stateChanged)
            this.setState({
                show_demoplatform
            })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.festival" /></h2>
                <div className="row">
                    {renderListOptionsInput({
                        name: "demo",
                        label: "inscriptions.festival.demo",
                        description_html: (
                            <p>
                                <FormattedMessage id="inscriptions.festival.demo.description.before" />
                                 <a href="https://games.bigfestival.com.br/2021/games/" target='_blank'>
                                    <FormattedMessage id="inscriptions.festival.demo.description.link" />
                                </a>
                                 <FormattedMessage id="inscriptions.festival.demo.description.after" />
                            </p>
                        ),
                        type: "radio",
                        col_md: 12,
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("festival", e),
                        value: this.fieldValueModel("festival", "demo"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {this.state.show_demoplatform && renderListOptionsInput({
                        name: "demoplatform",
                        label: "inscriptions.festival.demoplatform",
                        type: "radio",
                        col_md: 12,
                        option_col: 4,
                        required: true,
                        valueType: "string",
                        onChange: (e) => this.onChangeFieldModel("festival", e),
                        value: this.fieldValueModel("festival", "demoplatform"),
                        options: [
                            { value: "Android", label: "Android"},
                            { value: "PC (GameJolt)", label: "PC (GameJolt)"},
                            { value: "Viveport", label: "Viveport"},
                            { value: "Apple Arcade", label: "Apple Arcade"},
                            { value: "PC (Itch.io)", label: "PC (Itch.io)"},
                            { value: "Xbox Game Pass", label: "Xbox Game Pass"},
                            { value: "Apple iOS", label: "Apple iOS"},
                            { value: "PC (Microsoft)", label: "PC (Microsoft)"},
                            { value: "Xbox Console", label: "Xbox Console"},
                            { value: "Nintendo Switch", label: "Nintendo Switch"},
                            { value: "PC (Steam)", label: "PC (Steam)"},
                            { value: "Online (Browser)", label: "Online (Browser)"},
                            { value: "Playstation", label: "Playstation"},
                            { value: "PC (Executable)", label: "PC (Executable)"}
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "acceptsdiscord",
                        label: "inscriptions.festival.availablemembers",
                        description: "inscriptions.festival.availablemembers.description",
                        type: "radio",
                        col_md: 12,
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("festival", e),
                        value: this.fieldValueModel("festival", "acceptsdiscord"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderListOptionsInput({
                        name: "availabilityshowcase",
                        label: "inscriptions.festival.showcase",
                        description: "inscriptions.festival.showcase.description",
                        type: "radio",
                        col_md: 12,
                        option_col: 12,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("festival", e),
                        value: this.fieldValueModel("festival", "availabilityshowcase"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameFestival)