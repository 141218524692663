import React from "react";
import jwt_decode from "jwt-decode";
import { useHistory, useParams } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl } from "react-intl";
import ModalContato from "./modalContato";

function GameDetail(props) {
  const intl = useIntl();
  const history = useHistory();
  const user = jwt_decode(localStorage.getItem("token"));
  
  let { id } = useParams();  

  return (
    <>
      <game-detail game-id={id} />
    </>
  );
}

export default injectIntl(withAuth(GameDetail));
