/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";

function Users(props) {
  const intl = useIntl();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [usersVisible, setUsersVisible] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [roleFilters, setRoleFilters] = useState(-1);
  const [orderBy, setOrderBy] = useState("a-z");

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    var filtered = users;
    if (searchName != "") {
      filtered = filtered.filter((item) =>  {
          if (typeof item.profile.name === 'string'){
            return item.profile.name.toLowerCase().includes(searchName.toLowerCase())
          }
        }
      );
    }

    if (roleFilters != -1) {
      filtered = filtered.filter(({ type }) => type == roleFilters);
    }

    setUsersVisible(filtered);
  }, [searchName, roleFilters, users]);

  function sortBy(arr, o = 1) {
    return [...arr].sort(
      (a, b) => a.profile.name.localeCompare(b.profile.name) * o
    );
  }

  function loadUsers() {
    let url = "/api/v1/users/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        console.log("itens", itens);
        setUsers(itens);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.users" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function getLabelRole(roleId) {
    switch (roleId) {
      case 10:
        return "Admin"
      case 1:
        return "Usuário"
      case 5:
        return "Júri"
    }
  }

  function renderFilters() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="calls.registered.filter.title" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={searchName}
                        onChange={({ target }) => setSearchName(target.value)}
                        placeholder="Nome do usuário"
                      />
                    </div>

                    <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="component.my-account-form.input-label-permission" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={roleFilters}
                        onChange={({ target }) => setRoleFilters(target.value)}
                      >
                        <option value={-1}>
                          {intl.formatMessage({
                            id: "selecione-o-nivel-de-permissao",
                          })}
                        </option>
                        <option value={1}>
                          {intl.formatMessage({
                            id: "user",
                          })}
                        </option>
                        <option value={5}>
                          {intl.formatMessage({
                            id: "jury",
                          })}
                        </option>
                        <option value={10}>
                          {intl.formatMessage({
                            id: "admin",
                          })}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderUsers() {
    return (
      <div className="table-responsive">
        {usersVisible.length ? (
          <table className="table verticle-middle table-lista-big">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                </th>
                <th scope="col">
                  <FormattedMessage id="games-pending.list.button.email" />
                </th>
                <th scope="col">
                  <FormattedMessage id="component.my-account-form.input-label-permission" />
                </th>
                <th scope="col" className="table-right">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {sortBy(usersVisible, orderBy == "a-z" ? 1 : -1).map(
                (user, index) => {
                  return (
                    <tr key={index}>
                      <td>{user.profile.name}</td>
                      <td>{user.login}</td>
                      <td>{getLabelRole(user.type)}</td>
                      <td className="table-right">
                        <span>
                          <Link
                            to={`/user/${user.id}`}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Editar"
                          >
                            <i className="fa fa-pencil color-muted m-r-5"></i>{" "}
                          </Link>
                          <Link
                            to={`/user/remove/${user.id}`}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Remover"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                window.confirm(
                                  intl.formatMessage({
                                    id: "delete.user",
                                  })
                                ) == true
                              ) {
                                axios
                                  .delete(`/api/v1/user/${user.id}`, {
                                    headers: {
                                      "x-access-token":
                                        localStorage.getItem("token"),
                                    },
                                  })
                                  .then((res) => {
                                    loadUsers();
                                  })
                                  .catch((error) => {
                                    if (error.response.status === 401) {
                                      localStorage.removeItem("token");
                                      window.open("/", "_self");
                                    }
                                  });
                              } else {
                                console.log("não mexe");
                              }
                            }}
                          >
                            <i class="fa fa-close color-danger"></i>{" "}
                          </Link>
                        </span>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <h2>
            <FormattedMessage id="loading" />
          </h2>
        )}

        {/* <div className="bootstrap-pagination">
                    <nav>
                      <ul className="pagination pagination-sm justify-content-end">
                        <li className="page-item disabled">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div> */}
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilters()}

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="app.menu.users-all" />
                </h1>

                <form className="form-custom content-filter">
                  <div className="filter">
                    <div className="form-row justify-content-between">
                      <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                        {/* <label>Exibindo:</label>
                        <select className="form-control form-control-sm" id="">
                          <option>10 itens</option>
                          <option>20 itens</option>
                          <option>50 itens</option>
                          <option>100 itens</option>
                        </select> */}
                      </div>
                      <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                        <label>
                          <FormattedMessage id="calls.inscriptions.event.finalist.filter-order" />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          value={orderBy}
                          onChange={({ target }) => setOrderBy(target.value)}
                        >
                          <option value={"a-z"}>A - Z</option>
                          <option value={"z-a"}>Z - A</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>

                {renderUsers()}

                <div className="form-row">
                  <div className="form-group col-12">
                    <Link
                      to={"/new-user"}
                      className="btn btn-primary"
                      title="Novo Usuário"
                    >
                      Novo Usuário
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(Users));
