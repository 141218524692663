import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import BannherEvento1 from "../../assets/images/v2/banner-chamadas-2022.jpg";

function CallsMarkFinalist() {
  const [eventos, setEventos] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    let url = "/api/v1/events/";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        console.log("eventos", res.data);
        setEventos(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }, []);

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"} title="Dashboard">
                  <FormattedMessage id="app.menu.dashboard" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.mark-finalist" />
              </li>
            </ol>
          </div>
        </div>
        <div className="row chamadas">
          {eventos.length !== 0 &&
            eventos.map((evento, key) => {
              return (
                <div className="col-lg-6" key={key}>
                  <div className="card">
                    <div className="card-body">
                      <Link
                        to={`/call/mark-finalist/${evento.id}`}
                        title="BIG Festival 2021"
                      >
                        <div className="content-chamada">
                          <figure>
                            <img
                              src={evento.image ?? BannherEvento1}
                              alt="BIG Festival 2021"
                            />
                          </figure>
                          <div className="text-chamada">
                            <h3>{evento.name}</h3>
                            <h4>
                              <FormattedMessage id="calls.registered.new.limit-exist-game" />
                            </h4>
                            <p>
                              {moment(evento.limit_existence_game).format(
                                "DD/MMMM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(CallsMarkFinalist));
