import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm, RegisterOptions } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({});

function RatingGameJuriAberto({ game }) {
  const intl = useIntl();
  const [isShow, setIsShow] = useState(true);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const dados = new FormData();

    dados.append("likeGameplay", data.likeGameplay);
    dados.append("likeNarrativa", data.likeNarrativa);
    dados.append("likeArte", data.likeArte);
    dados.append("likeSom", data.likeSom);
    dados.append("likeInovacao", data.likeInovacao);
    dados.append("event_id", game.event_id);
    dados.append("game_id", game.id);

    let url = `/api/v1/rating/game/${game.id}`;

    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsShow(false);
          alert(intl.formatMessage({ id: "rating.save" }));
        } else {
          alert(intl.formatMessage({ id: "rating.save.fail" }));
          console.log("Não salvou", res);
        }
      })
      .catch((e) => {
        if (e.response.status == 411) {
          alert(intl.formatMessage({ id: "inscriptions.edit.validate.date" }));
        } else {
          alert(intl.formatMessage({ id: "inscriptions.edit.response.fail" }));
        }
        console.log("e", e.response);
      });
  };

  useEffect(() => {
    if (game.rating.length != 0) {
      setValue("likeGameplay", `${game.rating[0].likeGameplay}`);
      setValue("likeNarrativa", `${game.rating[0].likeNarrativa}`);
      setValue("likeArte", `${game.rating[0].likeArte}`);
      setValue("likeSom", `${game.rating[0].likeSom}`);
      setValue("likeInovacao", `${game.rating[0].likeInovacao}`);
    }
  }, []);

  return (
    isShow && (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
              <Link
                to={{
                  pathname: `/games/detail/${game.id}`,
                  state: {
                    game,
                  },
                }}
                title="Detalhe Jogo"
              >
                <div className="image-event">
                  <img src={game.image} alt={game.name} />
                </div>
              </Link>
            </div>
            <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
              <div className="info">
                <div className="row w-100">
                  <Link
                    to={{
                      pathname: `/games/detail/${game.id}`,
                      state: {
                        game,
                      },
                    }}
                    title="Detalhe Jogo"
                  >
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h3>{game.name}</h3>
                      <p>{game.description}</p>
                    </div>
                  </Link>
                  <div className="col-12">
                    <form
                      className="form-custom"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        <div class="form-group col-md-12 mb-4">
                          <h3>
                            <FormattedMessage id="gostou-da" />
                            <span class="categoria">
                              <FormattedMessage id="games.list.filter.assessment-categories.gameplay" />
                            </span>
                            ?<FormattedMessage id="deixe-seu-like!" />
                          </h3>
                          <div class="custom-checkbox">
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              name="gostei"
                              {...register("likeGameplay", { value: "false" })}
                            />
                            <FormattedMessage id="gostei" />
                          </div>
                        </div>

                        <div class="form-group col-md-12 mb-4">
                          <h3>
                            <FormattedMessage id="gostou-da" />
                            <span class="categoria">
                              <FormattedMessage id="games.list.filter.assessment-categories.narrative" />
                            </span>
                            ?<FormattedMessage id="deixe-seu-like!" />
                          </h3>
                          <div class="custom-checkbox">
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              name="gostei"
                              {...register("likeNarrativa", { value: "false" })}
                            />
                            <FormattedMessage id="gostei" />
                          </div>
                        </div>

                        <div class="form-group col-md-12 mb-4">
                          <h3>
                            <FormattedMessage id="gostou-da" />
                            <span class="categoria">
                              <FormattedMessage id="games.list.filter.assessment-categories.art" />
                            </span>
                            ?<FormattedMessage id="deixe-seu-like!" />
                          </h3>
                          <div class="custom-checkbox">
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              name="gostei"
                              {...register("likeArte", { value: "false" })}
                            />
                            <FormattedMessage id="gostei" />
                          </div>
                        </div>

                        <div class="form-group col-md-12 mb-4">
                          <h3>
                            <FormattedMessage id="gostou-da" />
                            <span class="categoria">
                              <FormattedMessage id="games.list.filter.assessment-categories.sound" />
                            </span>
                            ?<FormattedMessage id="deixe-seu-like!" />
                          </h3>
                          <div class="custom-checkbox">
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              name="gostei"
                              {...register("likeSom", { value: "false" })}
                            />
                            <FormattedMessage id="gostei" />
                          </div>
                        </div>

                        <div class="form-group col-md-12 mb-4">
                          <h3>
                            <FormattedMessage id="gostou-da" />
                            <span class="categoria">
                              <FormattedMessage id="games.list.filter.assessment-categories.innovation" />
                            </span>
                            ?<FormattedMessage id="deixe-seu-like!" />
                          </h3>
                          <div class="custom-checkbox">
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              name="gostei"
                              {...register("likeInovacao", { value: "false" })}
                            />
                            <FormattedMessage id="gostei" />
                          </div>
                        </div>
                      </div>
                      {game.rating.length === 0 && (
                        <div className="form-row">
                          <div className="form-group content-btn col-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                              <FormattedMessage id="games.detail.menu-game.rate" />
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default injectIntl(RatingGameJuriAberto);
