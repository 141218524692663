import { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";


function Interop() {
  const history = useHistory();

  useEffect(() => {
    window._big.navigation = navigation
  })

  const navigation = (url) => {
    history.push(url);
  }

  return ""
}

export default injectIntl(Interop);
