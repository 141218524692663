import { Component } from 'react'

class BaseReadonlyFrame extends Component {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    getClassName(name) {
        return this.highlight.includes(name) ? "highlight" : ""
    }

    getValue(value) {
        if (value !== undefined 
            && value !== null
            && value !== "")
            return value
        else
            return this.props.intl?.formatMessage({id: "pending"})
    }
}

export default BaseReadonlyFrame