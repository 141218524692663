import React, { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { FormattedMessage, useIntl } from "react-intl";

function ModalWinners({
  finalist,
  inscription,
  remove,
  reload,
  catVencedores,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    let newList = [];
    let newSelected = [];
    if (inscription != null) {
      inscription.catHabilitadas.forEach((category) => {
        if (remove) {
          if (!isShowCategoryRemover(category)) {
            newList.push({ label: category, value: category });
          }
        } else {
          if (!isShowCategory(category)) {
            newList.push({ label: category, value: category });
          }
        }
      });
    }

    setList(newList);
    setSelected(newSelected);
    setIsOpen(true);
  }, [inscription, remove, isOpen]);

  const onSubmitWinner = (category) => {
    setIsLoading(true);

    const dados = new FormData();

    dados.append("id", inscription.id);
    dados.append("category", JSON.stringify(selected));

    let url = remove
      ? `/api/v1/inscription/remove-award`
      : `/api/v1/inscription/set-award`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        reload();
        setIsOpen(false);
        // eslint-disable-next-line no-undef
        $("#modalFinalist").modal("hide");
      });
    console.log("finalista", inscription);
  };

  const isShowCategory = (category) => {
    return catVencedores.includes(category);
  };

  const isShowCategoryRemover = (category) => {
    return !catVencedores.includes(category);
  };

  return (
    <div
      className="modal fade in alert-message-modal"
      id="modalFinalist"
      // tabindex="-1"
      role="dialog"
      aria-labelledby="modalFinalistLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="testFilterLabel">
              {remove ? (
                <FormattedMessage id="calls.modal.finalist.remove" />
              ) : (
                <FormattedMessage id="calls.modal.finalist.marcar" />
              )}
              <FormattedMessage id="calls.modal.finalist.vencedor" />
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="list-group"
              style={{
                rowGap: "15px",
                // minHeight: "200px",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <div style={{ position: "relative", height: "40px" }}>
                  <svg className="circular" viewBox="25 25 50 50">
                    <circle
                      className="path"
                      cx="50"
                      cy="50"
                      r="20"
                      fill="none"
                      stroke-width="3"
                      stroke-miterlimit="10"
                    />
                  </svg>
                </div>
              ) : (
                <>
                  <MultiSelect
                    options={list}
                    value={selected}
                    onChange={setSelected}
                    labelledBy={intl.formatMessage({
                      id: "calls.inscriptions.event.finalist.category",
                    })}
                    hasSelectAll={false}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      if (selected.length == 0) {
                        // eslint-disable-next-line no-undef
                        $("#modalFinalist").modal("hide");
                      } else {
                        onSubmitWinner();
                      }
                    }}
                  >
                    {remove ? (
                      <FormattedMessage id="calls.modal.finalist.remove" />
                    ) : (
                      <FormattedMessage id="calls.modal.finalist.marcar" />
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalWinners;
