/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";

function Filters(props) {
  const intl = useIntl();
  const [roleFilters, setRoleFilters] = useState(-1);
  const [isStatus, setIsStatus] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [filters, setFilters] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState([]);

  useEffect(() => {
    loadFilters();
  }, []);

  function loadFilters() {
    let url = "/api/v1/filters/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        setFilters(itens);
        setFiltersVisible(itens);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.filters" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderFilters() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="calls.registered.filter.title" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="component.my-account-form.input-label-permission" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={roleFilters}
                        onChange={({ target }) => setRoleFilters(target.value)}
                      >
                        <option value={-1}>
                          {intl.formatMessage({
                            id: "selecione-o-nivel-de-permissao",
                          })}
                        </option>
                        <option value={0}>
                          {intl.formatMessage({
                            id: "master",
                          })}
                        </option>
                        <option value={2}>
                          {intl.formatMessage({
                            id: "admin",
                          })}
                        </option>
                        <option value={1}>
                          {intl.formatMessage({
                            id: "desenvolvedor",
                          })}
                        </option>
                        <option value={3}>
                          {intl.formatMessage({
                            id: "juri-aberto",
                          })}
                        </option>
                        <option value={4}>
                          {intl.formatMessage({
                            id: "juri-fechado",
                          })}
                        </option>
                        <option value={5}>
                          {intl.formatMessage({
                            id: "observador",
                          })}
                        </option>
                        <option value={6}>
                          {intl.formatMessage({
                            id: "selecionador-aberto",
                          })}
                        </option>
                        <option value={7}>
                          {intl.formatMessage({
                            id: "selecionador-fechado",
                          })}
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="app.filters.label.status" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={isStatus}
                        onChange={({ target }) => setIsStatus(target.value)}
                      >
                        <option value={-1}>
                          {intl.formatMessage({
                            id: "selecione-o-nivel-de-permissao",
                          })}
                        </option>
                        <option value={true}>
                          {intl.formatMessage({
                            id: "selecione-o-status-active",
                          })}
                        </option>
                        <option value={false}>
                          {intl.formatMessage({
                            id: "selecione-o-status-deactivate",
                          })}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getLabelRole(roleId) {
    switch (roleId) {
      case 0:
        return "Master";
      case 2:
        return "Admin";
      case 1:
        return "Desenvolvedor";
        break;
      case 3:
        return "Júri Aberto";
      case 4:
        return "Júri Fechado";
      case 5:
        return "Observador";
      case 6:
        return "Selecionador Aberto";
      case 7:
        return "Selecionador Fechado";
    }
  }

  function renderFiltersList() {
    return (
      <div className="table-responsive">
        {filtersVisible.length ? (
          <table className="table verticle-middle table-lista-big">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="app.filters.table.name-filter" />
                </th>
                <th scope="col">
                  <FormattedMessage id="app.filters.table.role" />
                </th>
                <th scope="col">
                  <FormattedMessage id="app.filters.table.status" />
                </th>
                <th scope="col" class="table-right">
                  <FormattedMessage id="app.filters.table.actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {filtersVisible.map((filter, index) => {
                return (
                  <tr key={index}>
                    <td>{filter.name}</td>
                    <td>{getLabelRole(filter.group_user)}</td>
                    <td>
                      {filter.active ? (
                        <FormattedMessage id="selecione-o-status-active" />
                      ) : (
                        <FormattedMessage id="selecione-o-status-deactivate" />
                      )}
                    </td>
                    <td className="table-right">
                      <span>
                        <Link
                          to={`/filter/${filter.id}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Editar"
                        >
                          <i className="fa fa-pencil color-muted m-r-5"></i>{" "}
                        </Link>
                        <Link
                          to={`/delete/${filter.id}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remover"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                intl.formatMessage({
                                  id: "delete.filter",
                                })
                              ) == true
                            ) {
                              axios
                                .delete(`/api/v1/filter/${filter.id}`, {
                                  headers: {
                                    "x-access-token":
                                      localStorage.getItem("token"),
                                  },
                                })
                                .then((res) => {
                                  loadFilters();
                                })
                                .catch((error) => {
                                  if (error.response.status === 401) {
                                    localStorage.removeItem("token");
                                    window.open("/", "_self");
                                  }
                                });
                            } else {
                              console.log("não mexe");
                            }
                          }}
                        >
                          <i className="fa fa-close color-danger"></i>
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2>
            <FormattedMessage id="app.filters.label.empty" />
          </h2>
        )}
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilters()}

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="app.menu.filters-all" />
                </h1>
                {isLoading ? (
                  <p>
                    <FormattedMessage id="loading" />
                  </p>
                ) : (
                  <>
                    {renderFiltersList()}
                    <div className="form-row">
                      <div className="form-group col-12">
                        <Link
                          to={"/new-filter"}
                          className="btn btn-primary"
                          title="Novo Usuário"
                        >
                          <FormattedMessage id="page.filters.new-filter" />
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(Filters));
