import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyFestival extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.festival.demo.label",
                        value: this.getValue(this.props.festival?.demo),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("demo"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.festival.demoplatform.label",
                        value: this.getValue(this.props.festival?.demoplatform),
                        col: 6,
                        col_md: 3,
                        ignoreLocaleValue: true,
                        className: this.getClassName("demoplatform"),
                        options: [
                            { value: "Android", label: "Android"},
                            { value: "PC (GameJolt)", label: "PC (GameJolt)"},
                            { value: "Viveport", label: "Viveport"},
                            { value: "Apple Arcade", label: "Apple Arcade"},
                            { value: "PC (Itch.io)", label: "PC (Itch.io)"},
                            { value: "Xbox Game Pass", label: "Xbox Game Pass"},
                            { value: "Apple iOS", label: "Apple iOS"},
                            { value: "PC (Microsoft)", label: "PC (Microsoft)"},
                            { value: "Xbox Console", label: "Xbox Console"},
                            { value: "Nintendo Switch", label: "Nintendo Switch"},
                            { value: "PC (Steam)", label: "PC (Steam)"},
                            { value: "Online (Browser)", label: "Online (Browser)"},
                            { value: "Playstation", label: "Playstation"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.festival.acceptsdiscord",
                        value: this.getValue(this.props.festival?.acceptsdiscord),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("acceptsdiscord"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.festival.showcase.label",
                        value: this.getValue(this.props.festival?.availabilityshowcase),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("availabilityshowcase"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyFestival)