import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import RatingGameSelecionadorGame from "./Game";
import RatingGameJuriFechadoGame from "./Game/juriFechado";
import Modal132Game from "./Game/modal123";

function GameRating(props) {
  const intl = useIntl();
  const history = useHistory();
  const user = jwt_decode(localStorage.getItem("token"));
  const { state } = useLocation();
  const [game, setGame] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categoriasAvaliadas, setCategoriasAvaliadas] = useState([]);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [itensVisible, setItensVisible] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    loadGame();
  }, []);

  function loadGame() {
    let url = `/api/v1/rating/games/${id}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let g = res.data;
        if (g.rating) {
          setGame(g[0]);
        } else {
          history.push("/games");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        } else {
          history.push("/games");
        }
      });
  }

  function getGenero(game) {
    let genero = [];
    if (game.form123) {
      if (game.form123["Gênero(s)-Arcade"] == "yes") {
        genero.push("Gênero(s)-Arcade");
      }
      if (game.form123["Gênero(s)-Arena"] == "yes") {
        genero.push("Gênero(s)-Arena");
      }
      if (game.form123["Gênero(s)-Auto-runner"] == "yes") {
        genero.push("Gênero(s)-Auto-runner");
      }
      if (game.form123["Gênero(s)-Aventura"] == "yes") {
        genero.push("Gênero(s)-Aventura");
      }
      if (game.form123["Gênero(s)-Aventura Tática"] == "yes") {
        genero.push("Gênero(s)-Aventura Tática");
      }
      if (game.form123["Gênero(s)-Ação"] == "yes") {
        genero.push("Gênero(s)-Ação");
      }
      if (game.form123["Gênero(s)-Baseado em Texto"] == "yes") {
        genero.push("Gênero(s)-Baseado em Texto");
      }
      if (game.form123["Gênero(s)-Battle Royale"] == "yes") {
        genero.push("Gênero(s)-Battle Royale");
      }
      if (game.form123["Gênero(s)-Beat Em Up"] == "yes") {
        genero.push("Gênero(s)-Beat Em Up");
      }
      if (game.form123["Gênero(s)-Cartas"] == "yes") {
        genero.push("Gênero(s)-Cartas");
      }
      if (game.form123["Gênero(s)-Casual"] == "yes") {
        genero.push("Gênero(s)-Casual");
      }
      if (game.form123["Gênero(s)-Caça ao Tesouro"] == "yes") {
        genero.push("Gênero(s)-Caça ao Tesouro");
      }
      if (game.form123["Gênero(s)-Corrida"] == "yes") {
        genero.push("Gênero(s)-Corrida");
      }
      if (game.form123["Gênero(s)-Dedução Social"] == "yes") {
        genero.push("Gênero(s)-Dedução Social");
      }
      if (game.form123["Gênero(s)-Educacional"] == "yes") {
        genero.push("Gênero(s)-Educacional");
      }
      if (game.form123["Gênero(s)-Esporte"] == "yes") {
        genero.push("Gênero(s)-Esporte");
      }
      if (game.form123["Gênero(s)-Estratégia"] == "yes") {
        genero.push("Gênero(s)-Estratégia");
      }
      if (game.form123["Gênero(s)-FPS"] == "yes") {
        genero.push("Gênero(s)-FPS");
      }
      if (game.form123["Gênero(s)-Família"] == "yes") {
        genero.push("Gênero(s)-Família");
      }
      if (game.form123["Gênero(s)-Fantasia"] == "yes") {
        genero.push("Gênero(s)-Fantasia");
      }
      if (game.form123["Gênero(s)-Horror/Terror"] == "yes") {
        genero.push("Gênero(s)-Horror/Terror");
      }
      if (game.form123["Gênero(s)-Luta"] == "yes") {
        genero.push("Gênero(s)-Luta");
      }
      if (game.form123["Gênero(s)-Narrativa"] == "yes") {
        genero.push("Gênero(s)-Narrativa");
      }
      if (game.form123["Gênero(s)-Party Game"] == "yes") {
        genero.push("Gênero(s)-Party Game");
      }
      if (game.form123["Gênero(s)-Plataforma"] == "yes") {
        genero.push("Gênero(s)-Plataforma");
      }
      if (game.form123["Gênero(s)-Point and Click"] == "yes") {
        genero.push("Gênero(s)-Point and Click");
      }
      if (game.form123["Gênero(s)-Puzzle"] == "yes") {
        genero.push("Gênero(s)-Puzzle");
      }
      if (game.form123["Gênero(s)-RPG"] == "yes") {
        genero.push("Gênero(s)-RPG");
      }
      if (game.form123["Gênero(s)-Realidade Virtual"] == "yes") {
        genero.push("Gênero(s)-Realidade Virtual");
      }
      if (game.form123["Gênero(s)-Ritmo"] == "yes") {
        genero.push("Gênero(s)-Ritmo");
      }
      if (game.form123["Gênero(s)-Roguelite"] == "yes") {
        genero.push("Gênero(s)-Roguelite");
      }
      if (game.form123["Gênero(s)-Side-Scrolling"] == "yes") {
        genero.push("Gênero(s)-Side-Scrolling");
      }
      if (game.form123["Gênero(s)-Simulação"] == "yes") {
        genero.push("Gênero(s)-Simulação");
      }
      if (game.form123["Gênero(s)-Sobrevivência"] == "yes") {
        genero.push("Gênero(s)-Sobrevivência");
      }
      if (game.form123["Gênero(s)-Social Game"] == "yes") {
        genero.push("Gênero(s)-Social Game");
      }
      if (game.form123["Gênero(s)-Tabuleiro"] == "yes") {
        genero.push("Gênero(s)-Tabuleiro");
      }
      if (game.form123["Gênero(s)-Tiro"] == "yes") {
        genero.push("Gênero(s)-Tiro");
      }
      if (game.form123["Gênero(s)-Trivia"] == "yes") {
        genero.push("Gênero(s)-Trivia");
      }
      if (game.form123["Gênero(s)-Type'Em Up"] == "yes") {
        genero.push("Gênero(s)-Type'Em Up");
      }
      if (game.form123["Gênero(s)-Visual Novel"] == "yes") {
        genero.push("Gênero(s)-Visual Novel");
      }
    }
    return genero.length ? genero.toString() : "---";
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            {user.type == 10 && (
              <li className="breadcrumb-item">
                <Link to={"/games"} title="Jogos">
                  <FormattedMessage id="app.breadcrumb.games" />
                </Link>
              </li>
            )}
            <li className="breadcrumb-item">
              <Link
                to={`/games/detail/${
                  game ? game?.id : <FormattedMessage id="loading" />
                }`}
                title="Jogos"
              >
                {game ? game?.name : <FormattedMessage id="loading" />}
              </Link>
            </li>
            <li className="breadcrumb-item active">Avaliar</li>
          </ol>
        </div>
      </div>
    );
  }

  function ratingGames() {
    if (user.type === 4) {
      return (
        <RatingGameJuriFechadoGame
          game={game}
          openModal={(game) => {
            setGame(game);
            // eslint-disable-next-line no-undef
            $("#modal123").modal("show");
          }}
        />
      );
    } else {
      return <RatingGameSelecionadorGame game={game} />;
    }
  }

  function renderRating() {
    return (
      <div className="row event-list">
        <div className="col-12">
          <div className="info">
            <div className="basic-form">
              <div className="form-row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h3>{game.name}</h3>
                      <p>{game.description}</p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="dashboard.game.platforms" />
                      </h3>
                      <p>{game.form123 ? game.form123.Plataformas : "---"}</p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="dashboard.game.genre" />
                      </h3>
                      <p>{getGenero(game)}</p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="dashboard.game.country" />
                      </h3>
                      <p>
                        {game.form123
                          ? game.form123[
                              "País do estúdio que desenvolveu o jogo"
                            ]
                          : "---"}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="games.detail.game.studio" />
                      </h3>
                      <p>
                        {game.form123
                          ? game.form123["Estúdio responsável pelo Jogo"]
                          : "---"}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="games.detail.game.publisher" />
                      </h3>
                      <p>
                        {game.form123
                          ? game.form123["Nome da publisher, caso possua"]
                          : "---"}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="games.detail.game.country-publisher" />
                      </h3>
                      <p>
                        {game.form123
                          ? game.form123["País da publisher"]
                          : "---"}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="dashboard.game.classification" />
                      </h3>
                      <p>
                        {game.form123 ? game.form123["Faixa Etária"] : "---"}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="dashboard.game.country" />
                      </h3>
                      <p>
                        {game.form123
                          ? game.form123[
                              "País do estúdio que desenvolveu o jogo"
                            ]
                          : "---"}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <h3>
                        <FormattedMessage id="dashboard.game.release-date" />
                      </h3>
                      <p>{moment(game.release_date).format("DD/MMMM/YYYY")}</p>
                    </div>
                    <div className="col-12">
                      <div className="div"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="event-list games avaliacao">
                  {ratingGames()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-body">
        <div className="container-fluid">
          {renderBreadcrumb()}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="game-detail">
                    <header>
                      <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                          <h1 className="card-title">
                            <FormattedMessage id="games.detail.menu-game.rate" />
                          </h1>
                        </div>
                      </div>
                    </header>
                    {isLoading ? (
                      <p>
                        <FormattedMessage id="loading" />
                      </p>
                    ) : (
                      renderRating()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal132Game game={game} />
    </>
  );
}

export default injectIntl(withAuth(GameRating));
