/* eslint-disable no-undef */
import { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

function Sidebar() {
  const location = useLocation();
  const user = jwt_decode(localStorage.getItem("token"));

  useEffect(() => {
    $("#menu").metisMenu();

    $(".nk-nav-scroll").slimscroll({
      position: "left",
      size: "5px",
      height: "100%",
      color: "transparent",
    });

    $(function () {
      for (
        var nk = window.location,
          o = $("ul#menu a")
            .filter(function () {
              return this.href == nk;
            })
            .addClass("active")
            .parent()
            .addClass("active");
        ;

      ) {
        if (!o.is("li")) break;
        o = o.parent().addClass("in").parent().addClass("active");
      }
    });
  }, []);

  useEffect(() => {
    
  }, []);

  return (
    <div
      className="nk-sidebar"
      onLoad={async () => {
        $("#menu").metisMenu();

        $(".nk-nav-scroll").slimscroll({
          position: "left",
          size: "5px",
          height: "100%",
          color: "transparent",
        });
      }}
    >
      <div className="nk-nav-scroll">
        <ul className="metismenu" id="menu">
          <li>
            <Link
              to={"/"}
              onClick={() => {
                if (window.screen.width <= 767) {
                  document.body.classList.toggle("mini-nav");
                }
              }}
            >
              <i className=" mdi mdi-view-dashboard"></i>{" "}
              <span className="nav-text">
                <FormattedMessage id={"app.menu.dashboard"} />
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/games"}
              onClick={() => {
                if (window.screen.width <= 767) {
                  document.body.classList.toggle("mini-nav");
                }
              }}
            >
              <i className="mdi mdi-google-controller"></i>{" "}
              <span className="nav-text">
                <FormattedMessage id={"app.menu.games"} />
              </span>
            </Link>
          </li>
          {user.type === 7 || user.type === 4 ? (
            <li>
              <Link to={"/rating"}>
                <i className="mdi mdi-trophy"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id={"app.menu.rating"} />
                </span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {user.type === 10 || user.type === 2 ? (
            <li>
              <Link
                className="has-arrow"
                to="/calls"
                onClick={(e) => e.preventDefault()}
                aria-expanded="false"
              >
                <i className="mdi mdi-table-edit"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id={"app.menu.calls"} />
                </span>
              </Link>
              <ul aria-expanded="false">
              <li>
                  <Link
                    to={"/registered-calls"}
                    title="Chamadas Cadastradas"
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                  >
                    <FormattedMessage id="app.menu.registered-calls" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/new-call"}
                    title="Nova Chamada"
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                  >
                    <FormattedMessage id="app.menu.registered-new-call" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/call/mark-finalist/"}
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                    title="Mark finalist"
                  >
                    <FormattedMessage id={"app.menu.mark-finalist"} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/call/mark-winners/"}
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                    title="Todas as Chamadas"
                  >
                    <FormattedMessage id={"app.menu.mark-winners"} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/call/finalist-winners"
                    title="Vencedores e Finalistas"
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                  >
                    <FormattedMessage id={"app.menu.finalist-winners"} />
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {user.type !== 5 && (
            <li>
              <Link
                className="has-arrow"
                to={"/inscriptions"}
                onClick={(e) => e.preventDefault()}
                aria-expanded="false"
              >
                <i className="mdi mdi-gamepad"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id="app.menu.inscriptions" />
                </span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link
                    to={"/inscriptions/chamadas"}
                    title="Chamadas"
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                  >
                    <FormattedMessage id="app.menu.inscriptions-calls" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/inscriptions"}
                    title="Todos os jogos"
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                  >
                    <FormattedMessage id="app.menu.inscriptions-all" />
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {user.type === 10 || user.type === 2 ? (
            <li>
              <Link
                className="has-arrow"
                to={"/users"}
                title="Todos os jogos"
                aria-expanded="false"
                onClick={(e) => e.preventDefault()}
              >
                <i className="mdi mdi-account-multiple"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id="app.menu.users" />
                </span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to={"/users"} title="Todos os Usuários">
                    <FormattedMessage id="app.menu.users-all" />
                  </Link>
                </li>
                <li>
                  <Link to={"/new-user"} title="Novo Usuário">
                    <FormattedMessage id="app.menu.users-new" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/call/selectors/"}
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                    title="Selecionadores"
                  >
                    <FormattedMessage id={"app.menu.selectors"} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/call/juries/"}
                    onClick={() => {
                      if (window.screen.width <= 767) {
                        document.body.classList.toggle("mini-nav");
                      }
                    }}
                    title="Juris"
                  >
                    <FormattedMessage id={"app.menu.juries"} />
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {user.type === 10 || user.type === 2 ? (
            <li>
              <Link
                className="has-arrow"
                to={"/filters"}
                title="Todos os filtros"
                aria-expanded="false"
                onClick={(e) => e.preventDefault()}
              >
                <i className="mdi mdi-account-multiple"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id="app.breadcrumb.filters" />
                </span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to={"/filters"} title="Todos os Filtros">
                    <FormattedMessage id="app.menu.filters-all" />
                  </Link>
                </li>
                <li>
                  <Link to={"/new-filter"} title="Novo Filtro">
                    <FormattedMessage id="page.filters.new-filter" />
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to={"/filter/"} title="Edita Filtro">
                    Hide
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {user.type === 10 || user.type === 2 ? (
            <li>
              <Link to={"/reports"} title="Relatórios">
                <i className="mdi mdi-chart-bar"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id="app.menu.reports" />
                </span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {user.type === 10 || user.type === 2 ? (
            <li>
              <Link
                className="has-arrow"
                to={"/settings"}
                aria-expanded="false"
                onClick={(e) => e.preventDefault()}
              >
                <i className="mdi mdi-settings"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id="configuracoes" />
                </span>
              </Link>
              <ul aria-expanded="true">
                <li>
                  <Link to={"/settings/alerts"} title="Alertas">
                    <FormattedMessage id="alertas" />
                  </Link>
                </li>
                <li>
                  <Link to={"/settings/languages"} title="Idiomas">
                    <FormattedMessage id="idiomas" />
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link
                    to={"/settings/languages/new-language/"}
                    title="Idiomas"
                  >
                    <FormattedMessage id="idiomas" />
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {user.type === 5 && (
            <li>
              <Link
                to={"/jury/final-evaluation"}
              >
                <i className="mdi mdi-clipboard-check"></i>{" "}
                <span className="nav-text">
                  <FormattedMessage id={"app.menu.final-evaluation"} />
                </span>
              </Link>
            </li>
          )}

        </ul>
      </div>
    </div>
  );
}

export default injectIntl(Sidebar);
