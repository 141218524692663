import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import withAuth from "../../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import NoImageChamada from "../../../assets/images/v2/event/image-event-test.jpg";

function DashboardAdmin() {
  const intl = useIntl();
  const locale = intl.locale;
  const [totalGames, setTotalGames] = useState(0);
  const [lastGames, setLastGames] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [isLoadingGames, setIsLoadingGames] = useState(true);

  useEffect(() => {
    loadGames();
    loadChamadas();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function loadGames() {
    return;
    let url = "/api/v1/inscritions/games/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data.reverse().slice(0, 5);
        console.log("itens", itens);
        setTotalGames(itens.length);
        setLastGames(itens);
        setIsLoadingGames(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function traducaoSiglaLocale(locale1, locale2) {
    let l1 = locale1;
    let l2 = locale2;

    if (locale1.indexOf("-") >= 0) l1 = locale1.split("-")[0];

    if (locale2.indexOf("-") >= 0) l2 = locale2.split("-")[0];

    return l1 == l2;
  }

  function loadChamadas() {
    let url = "/api/v1/events";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        console.log("eventos", res.data);
        setEventos(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col p-0">
            <h4>
              <FormattedMessage id="app.welcome-1" />,{" "}
              <span>
                <FormattedMessage id="app.welcome-2" />
              </span>
            </h4>
          </div>
          <div className="col p-0">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center m-t-10">
                  <i className="icon-game-controller f-s-50 text-primary"></i>
                  <h2 className="m-t-15 m-b-0">
                    {totalGames} <FormattedMessage id="dashboard.games" />
                  </h2>
                  <p className="f-s-12">
                    <FormattedMessage id="dashboard.games.inscriptions" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="text-center m-t-10">
                  <i className="icon-check f-s-50 text-primary"></i>
                  <h2 className="m-t-15 m-b-0">
                    {eventos.length} <FormattedMessage id="dashboard.calls" />
                  </h2>
                  <p className="f-s-12">
                    <FormattedMessage id="dashboard.calls.length" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">
                  <FormattedMessage id="dashboard.calls" />
                </h4>

                <div class="event-list">
                  {eventos.length != 0 ? (
                    eventos.map((evento, index) => {
                      let localeData = evento.translations.filter((e) =>
                        traducaoSiglaLocale(e.locale, locale)
                      );
                      localeData = localeData.length > 0 ? localeData[0] : null;
                      return (
                        <div class="row" key={index}>
                          <div class="col-12">
                            <Link
                              to={`/call/${evento.id}`}
                              title="Detalhe Chamada"
                            >
                              <div class="row">
                                <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                                  <div class="image-event">
                                    <img
                                      src={
                                        evento.image
                                          ? evento.image
                                          : NoImageChamada
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div class="col-xxl-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                                  <div class="info">
                                    <div class="row w-100">
                                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <h3>
                                          <FormattedMessage id="dashboard.event.title" />
                                        </h3>
                                        <p>
                                          <i class="fa fa-circle-o text-success f-s-12 m-r-10"></i>{" "}
                                          {evento.name}
                                        </p>
                                      </div>
                                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <h3>
                                          <FormattedMessage id="dashboard.event.deadline" />
                                        </h3>
                                        {moment(evento.deadline).format(
                                          "DD/MMMM/YYYY"
                                        )}
                                      </div>
                                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <h3>
                                          <FormattedMessage id="inscriptions.game.limit" />
                                        </h3>
                                        <p style={{ fontWeight: "bold" }}>
                                          {moment(
                                            evento.limit_existence_game
                                          ).format("DD/MMMM/YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div class="row">
                      <div class="col-12">
                        <h3>
                          <FormattedMessage id="dashboard.event.empty-calls" />
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="dashboard.game.title" />
                </h4>
                <div className="event-list games">
                  {isLoadingGames ? (
                    <FormattedMessage id="loading" />
                  ) : (
                    <>
                      {lastGames.map((game, index) => {
                        return (
                          <div className="row" key={index}>
                            <div className="col-12">
                              <Link
                                to={{
                                  pathname: `/games/detail/${game.id}`,
                                  state: {
                                    game,
                                  },
                                }}
                                title="Detalhe Jogo"
                              >
                                <div className="row">
                                  <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                                    <div className="image-event">
                                      <img src={game.image} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                                    <div className="info">
                                      <div className="row w-100">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                          <h2>{game.name}</h2>
                                          <p>{game.description}</p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.award" />
                                          </h3>
                                          <p>
                                            {game.award !== null
                                              ? game.award.length != 0
                                                ? game.award.toString()
                                                : "---"
                                              : "---"}
                                          </p>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.finalist" />
                                          </h3>
                                          <p>
                                            {game.finalist !== null
                                              ? game.finalist.length != 0
                                                ? game.finalist.toString()
                                                : "---"
                                              : "---"}
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.publisher" />
                                          </h3>
                                          <p>---</p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.platforms" />
                                          </h3>
                                          <p>
                                            {game.form123
                                              ? game.form123.Plataformas
                                              : "---"}
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.genre" />
                                          </h3>
                                          <p>---</p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.country" />
                                          </h3>
                                          <p>
                                            {game.form123
                                              ? game.form123[
                                                  "País do estúdio que desenvolveu o jogo"
                                                ]
                                              : "---"}
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.classification" />
                                          </h3>
                                          <p>
                                            {game.form123
                                              ? game.form123["Faixa Etária"]
                                              : "---"}
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.release-date" />
                                          </h3>
                                          <p>
                                            {moment(game.release_date).format(
                                              "DD/MMMM/YYYY"
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.send-date" />
                                          </h3>
                                          <p>
                                            {moment(game.created).format(
                                              "DD/MMMM/YYYY"
                                            )}
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 rating">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.general-note" />
                                          </h3>
                                          <p>
                                            <i className="fa fa-star btn-outline-warning"></i>
                                            <span>---</span>
                                          </p>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 rating">
                                          <h3>
                                            <FormattedMessage id="dashboard.game.commercial-potential" />
                                          </h3>
                                          <p>
                                            <i className="fa fa-star btn-outline-warning"></i>
                                            <span>---</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Chamadas */}
      </div>
    </div>
  );
}

export default injectIntl(withAuth(DashboardAdmin));
