import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameReadonlyTerms extends BaseReadonlyFrame {

    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.terms.acceptterms",
                        value: this.getValue(this.props.term?.acceptterms),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("acceptterms"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderTextValue({
                        label: "inscriptions.terms.rules",
                        value: this.getValue(this.props.term?.rules),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("rules"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderTextValue({
                        label: "inscriptions.terms.ownership.label",
                        value: this.getValue(this.props.term?.ownership),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("ownership"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {(this.props.term?.ownership === false) && renderTextValue({
                        label: "inscriptions.terms.ownership.authorized.label",
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("ownershipauthorized"),
                        value: this.getValue(this.props.term?.ownershipauthorized)
                    })}

                    {renderTextValue({
                        label: "inscriptions.terms.majority.label",
                        value: this.getValue(this.props.term?.ownership),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("ownership"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {this.props.term?.ownership === false && renderTextValue({
                        label: "inscriptions.terms.parentalconsent",
                        value: this.getValue(this.props.term?.parentalconsent),
                        link: "/api/v1/media/image/" + this.getValue(this.props.term?.parentalconsent),
                        link_download: true,
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("parentalconsent")
                    })}

                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyTerms)