import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyOthers extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.others.interestmeeting.label",
                        value: this.getValue(this.props.festival?.interestmeeting),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("interestmeeting"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.others.interestmeetingdiscount.label",
                        value: this.getValue(this.props.festival?.interestmeetingdiscount),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("interestmeetingdiscount"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyOthers)