import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router";
import withAuth from "../../components/auth";
import { Link } from "react-router-dom";
import MyAccountForm from "../../components/MyAccountForm";

class MyAccount extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content-body">
        <div className="container-fluid">
          <div className="row page-titles">
            <div className="col p-0">
              <h4>
                <FormattedMessage id="app.welcome-1" />,{" "}
                <span>
                  <FormattedMessage id="app.welcome-2" />
                </span>
              </h4>
            </div>
            <div className="col p-0">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"} title="Dashboard">
                    <FormattedMessage id="app.menu.dashboard" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  <FormattedMessage id="component.header.my-account" />
                </li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h1 className="card-title">
                    <FormattedMessage id="meus-dados" />
                  </h1>
                  <div className="basic-form">
                    <MyAccountForm register={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(withAuth(MyAccount)));
