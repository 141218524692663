import React, { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { FormattedMessage, useIntl } from "react-intl";

function ModalFinalistWinners({
  finalist,
  events,
  extras,
  inscription,
  remove,
  reload,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    let newList = [];
    let newSelected = [];
    if (events.length !== 0) {
      events.forEach((category) => {
        if (remove) {
          if (!isShowCategoryRemover(category)) {
            newList.push({ label: category, value: category });
          }
        } else {
          if (!isShowCategory(category)) {
            newList.push({ label: category, value: category });
          }
        }
      });
    }

    if (extras.length !== 0) {
      extras.forEach((category) => {
        if (remove) {
          if (!isShowCategoryRemover(category)) {
            newList.push({ label: category, value: category });
          }
        } else {
          if (!isShowCategory(category)) {
            newList.push({ label: category, value: category });
          }
        }
      });
    }
    setList(newList);
    setSelected(newSelected);
    setIsOpen(true);
  }, [inscription, remove, isOpen]);

  const onSubmitFinalist = () => {
    setIsLoading(true);

    const dados = new FormData();

    dados.append("id", inscription.id);
    dados.append("category", JSON.stringify(selected));

    let url = remove
      ? `/api/v1/inscription/remove-finalist`
      : `/api/v1/inscription/set-finalist`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsLoading(false);
        reload();
        setIsOpen(false);
        // eslint-disable-next-line no-undef
        $("#modalFinalist").modal("hide");
      });
    console.log("finalista", inscription);
  };

  const onSubmitWinner = (category) => {
    setIsLoading(true);

    const dados = new FormData();

    dados.append("id", inscription.id);
    dados.append("category", category);

    let url = remove
      ? `/api/v1/inscription/remove-award`
      : `/api/v1/inscription/set-award`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        reload();
        // eslint-disable-next-line no-undef
        $("#modalFinalist").modal("hide");
      });
    console.log("finalista", inscription);
  };

  const isShowCategory = (category) => {
    let retorno = false;
    if (inscription) {
      if (finalist) {
        if (inscription.finalist) {
          retorno = inscription.finalist.includes(category);
        }
      } else {
        if (inscription.award !== null) {
          retorno = inscription.award.includes(category);
        }
      }
    }
    return retorno;
  };

  const isShowCategoryRemover = (category) => {
    let retorno = false;
    if (inscription) {
      if (finalist) {
        if (inscription.finalist) {
          retorno = inscription.finalist.includes(category);
        }
      } else {
        if (inscription.award !== null) {
          retorno = inscription.award.includes(category);
        }
      }
    }
    return !retorno;
  };

  return (
    <div
      className="modal fade in alert-message-modal"
      id="modalFinalist"
      // tabindex="-1"
      role="dialog"
      aria-labelledby="modalFinalistLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="testFilterLabel">
              {remove ? (
                <FormattedMessage id="calls.modal.finalist.remove" />
              ) : (
                <FormattedMessage id="calls.modal.finalist.marcar" />
              )}
              {finalist ? (
                <FormattedMessage id="calls.modal.finalist.finalista" />
              ) : (
                <FormattedMessage id="calls.modal.finalist.vencedor" />
              )}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="list-group"
              style={{
                rowGap: "15px",
                // minHeight: "200px",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <div style={{ position: "relative", height: "40px" }}>
                  <svg className="circular" viewBox="25 25 50 50">
                    <circle
                      className="path"
                      cx="50"
                      cy="50"
                      r="20"
                      fill="none"
                      stroke-width="3"
                      stroke-miterlimit="10"
                    />
                  </svg>
                </div>
              ) : (
                <>
                  <MultiSelect
                    options={list}
                    value={selected}
                    onChange={setSelected}
                    labelledBy={intl.formatMessage({
                      id: "calls.inscriptions.event.finalist.category",
                    })}
                    hasSelectAll={false}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      if (selected.length == 0) {
                        // eslint-disable-next-line no-undef
                        $("#modalFinalist").modal("hide");
                      } else {
                        onSubmitFinalist();
                      }
                    }}
                  >
                    {remove ? (
                      <FormattedMessage id="calls.modal.finalist.remove" />
                    ) : (
                      <FormattedMessage id="calls.modal.finalist.marcar" />
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalFinalistWinners;
