import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

import NoImageChamada from "../../assets/images/v2/jogos/img-thumb-jogo-boom.jpg";

function CallsFinalistWinners() {
  const [games, setGames] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    loadChamadas();
  }, []);

  function loadChamadas() {
    axios
      .get("/api/v1/inscritions/finalist-winners/", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        console.log("Finalistar e Vencedores", res.data);
        setGames(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.menu.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.menu.finalist-winners" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderFilter() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="games.list.filter.title" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="games.list.filter.calls" />
                      </label>
                      <select className="form-control form-control-sm" id="">
                        <option value="" selected disabled hidden>
                          Todos as chamadas
                        </option>
                        <option>BIG Festival 2021</option>
                        <option>BIG Festival 2022</option>
                        <option>BIG Festival 2023</option>
                      </select>
                    </div>
                    <div className="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.status" />
                      </label>
                      <select className="form-control form-control-sm" id="">
                        <option value="" selected disabled hidden>
                          Selecione o status
                        </option>
                        <option>Todos</option>
                        <option>Finalistas</option>
                        <option>Ganhadores</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderList() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                <FormattedMessage id="app.menu.finalist-winners" />
              </h4>

              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="games.list.filter.order-by" />
                      </label>
                      <select className="form-control form-control-sm" id="">
                        <option>Mais recente</option>
                        <option>Mais antigo</option>
                        <option>A - Z</option>
                        <option>Z - A</option>
                        <option>Por nota</option>
                        <option>Por potencial comercial</option>
                        <option>Por nota + potencial comercial</option>
                        <option>Por data de lançamento</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>

              <div className="event-list games">
                {games.length ? (
                  games.map((game, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <Link
                            to={{
                              pathname: "/games/detail",
                              state: {
                                game,
                              },
                            }}
                            title="Detalhe Jogo"
                          >
                            <div className="row">
                              <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                                <div className="image-event">
                                  <img
                                    src={
                                      game.image ? game.image : NoImageChamada
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                                <div className="info">
                                  <div className="row w-100">
                                    {game.award !== null &&
                                      game.award.length != 0 && (
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <h3>
                                            <FormattedMessage id="calls.inscriptions.event.finalist.award" />
                                          </h3>
                                          <p>{game.award.toString()}</p>
                                        </div>
                                      )}

                                    {game.finalist !== null &&
                                      game.finalist.length != 0 && (
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <FormattedMessage id="calls.inscriptions.event.finalist.label" />
                                          <p>{game.finalist.toString()}</p>
                                        </div>
                                      )}
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                      <FormattedMessage id="app.menu.calls" />
                                      <p>{game.event.name}</p>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <h3>{game.name}</h3>
                                      <p>{game.description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="row">
                    <div class="col-12">
                      <FormattedMessage id="calls.inscriptions.event.finalist.empty" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilter()}
        {renderList()}
      </div>
    </div>
  );
}

export default injectIntl(withAuth(CallsFinalistWinners));
