import axios from "axios";
import { withRouter } from "react-router";
import withAuth from "../../components/auth";
import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

function InscriptionsFormResult() {
  const { id } = useParams();
  const history = useHistory();

  const updateEditUrl = async () => {
    try {
      const requestConfig = {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      let url = `/api/v1/profile`;
      const responseProfile = await axios.get(url, requestConfig);
      const profile = responseProfile.data;

      url = `/api/v1/inscription/${id}`;
      const responseInscription = await axios.get(url, requestConfig);
      const inscription = responseInscription.data;

      if (inscription.user_id !== profile.id) {
        history.push(`/inscriptions`);
        return;
      }

      const params = new URLSearchParams(document.location.search);
      const editUrl = params.get("editUrl");
      inscription.form123edit = editUrl;
      inscription.evento = inscription.event_id;

      const updateInscription = await axios.post(
        url,
        inscription,
        requestConfig
      );
      history.push(`/inscriptions`);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.open("/", "_self");
      } else {
        history.push(`/inscriptions`);
      }
    }
  };

  useEffect(() => {
    updateEditUrl();
  }, []);

  return (
    <div className="content-body">
      <div className="container-fluid">
        <p>Loading...</p>
      </div>
    </div>
  );
}

export default injectIntl(withRouter(withAuth(InscriptionsFormResult)));
