import axios from 'axios'
import React, { Component } from 'react'
import withAuth from '../../components/auth'
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl"

import FrameFestival from './FrameFestival'
import FrameGameAccess from './FrameGameAccess'
import FrameGameData from './FrameGameData'
import FrameGameDetails from './FrameGameDetails'
import FrameOtherSupport from './FrameOtherSupport'
import FramePlatform from './FramePlatform'
import FrameProfile from './FrameProfile'
import FrameStudioPublisher from './FrameStudioPublisher'
import FrameSend from './FrameSend'
import FrameTerms from './FrameTerms'

import Genres from '../../components/Genre'
import moment from 'moment/moment';



class InscriptionEdit extends Component {

    constructor(props) {
        super(props)

        this.profileChanged = false
        this.inscriptionsChanged = false
        this.timer = 0

        this.state = {
            loading: true,
            frame: 0,
            inscription: {},
            event: {},
            profile: {},
            view: this.props.view
        }
    }

    async componentDidMount() {
        try {
            const inscription = await this.getInscription()
            const event = await this.getEvent(inscription?.event_id)

            this.setState({ loading: false, inscription, event })
        }
        catch(ex) {

        }
        this.startAutoSave()
    }

    async getInscription() {
        try {
            if (!this.props.id)
                return {}

            let url = `/api/v1/inscription/${this.props.id}`

            const response = await axios.get(url, { headers: {
                'x-access-token': localStorage.getItem("token")
            }})

            return response.data
        } catch (error) {
            return {
                id: this.props.id
            }
        }
    }

    async getEvent(eventId) {
        try {
            let id = eventId || this.state.inscription?.event_id
            console.log("id", id)

            if (id) {
                let url = `/api/v1/event/${id}`

                const response = await axios.get(url, { headers: {
                    'x-access-token': localStorage.getItem("token")
                }})
    
                return response.data
            }

        } catch (error) {
            return null
        }
    }

    canEditEvent() {
        if (!this.state.event || !this.state.inscription)
            return false

        if (this.state.view)
            return false

        if (this.state.inscription.status != 0 
            && this.state.inscription.status != 3
            && this.state.inscription.status != 20)
            return false

        const currentDate = moment()
        let deadline = moment(this.state.event.deadline)
        deadline.add(1, 'days')

        return currentDate.isBetween(this.state.event.startdate, deadline)
    }

    canBackToEdit() {
        if (!this.state.event || !this.state.inscription)
            return false

        if (this.state.inscription.status === 0
            || this.state.inscription.status > 2)
            return false

        const currentDate = moment()
        return currentDate.isBetween(this.state.event.startdate, this.state.event.deadline)
    }

    componentWillUnmount() {
        this.stopAutoSave()
    }

    moveFrame = (frame) => {
        this.setState({ frame })
        this.autoSave()
    }

    moveFramePrevious = () => {
        this.moveFrame(this.state.frame - 1)
    }

    moveFrameNext = () => {
        this.moveFrame(this.state.frame + 1)
    }

    onInscriptionsChanged = () => {
        this.inscriptionsChanged = true
    }

    startAutoSave = () => {
        if (this.timer === 0)
            this.timer = setInterval(this.autoSave, 60000)
    }

    stopAutoSave = () => {
        clearInterval(this.timer)
    }

    autoSave = () => {
        if (this.inscriptionsChanged)
            this.saveInscription()
    }

    saveInscription= () => {
        this.inscriptionsChanged = false

        let url = `/api/v1/inscription/${this.state.inscription.id}`

        axios.post(url, this.state.inscription, { headers: {
            'x-access-token': localStorage.getItem("token")
        }}).then((res) => {
            if (res.status === 200) {
                console.log("Inscription atualizado")
            } else {
                this.inscriptionsChanged = true
                console.log("Não salvou", res)
            }
        })
    }

    renderLoading() {
        return <p>Loading...</p>
    }

    renderForm() {
        return (
            <form onSubmit={this.onSave}>
                <div className="row">
                    <div className="col-12 text-right">
                        <button type="button" disabled={this.state.frame <= 0} className="btn btn-primary m-2" onClick={this.moveFramePrevious}><FormattedMessage id="previous" /></button>
                        <button type="button" disabled={this.state.frame >= 9} className="btn btn-primary" onClick={this.moveFrameNext}><FormattedMessage id="next" /></button>
                    </div>
                </div>

                <div className="stepProgressContainer">
                    <div className={`stepProgressItem ${this.state.frame === 0 ? 'active' : ''}`} onClick={() => this.moveFrame(0)}>
                        <div className="stepProgressNumber">1</div>
                        <div className="stepProgressName">1 - <FormattedMessage id="inscriptions.send.profile" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 1 ? 'active' : ''}`} onClick={() => this.moveFrame(1)}>
                        <div className="stepProgressNumber">2</div>
                        <div className="stepProgressName">2 - <FormattedMessage id="inscriptions.send.terms" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 2 ? 'active' : ''}`} onClick={() => this.moveFrame(2)}>
                        <div className="stepProgressNumber">3</div>
                        <div className="stepProgressName">3 - <FormattedMessage id="inscriptions.send.publisher" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 3 ? 'active' : ''}`} onClick={() => this.moveFrame(3)}>
                        <div className="stepProgressNumber">4</div>
                        <div className="stepProgressName">4 - <FormattedMessage id="inscriptions.send.gamedata" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 4 ? 'active' : ''}`} onClick={() => this.moveFrame(4)}>
                        <div className="stepProgressNumber">5</div>
                        <div className="stepProgressName">5 - <FormattedMessage id="inscriptions.send.platform" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 5 ? 'active' : ''}`} onClick={() => this.moveFrame(5)}>
                        <div className="stepProgressNumber">6</div>
                        <div className="stepProgressName">6 - <FormattedMessage id="inscriptions.send.details" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 6 ? 'active' : ''}`} onClick={() => this.moveFrame(6)}>
                        <div className="stepProgressNumber">7</div>
                        <div className="stepProgressName">7 - <FormattedMessage id="inscriptions.send.access" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 7 ? 'active' : ''}`} onClick={() => this.moveFrame(7)}>
                        <div className="stepProgressNumber">8</div>
                        <div className="stepProgressName">8 - <FormattedMessage id="inscriptions.send.festival" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 8 ? 'active' : ''}`}  onClick={() => this.moveFrame(8)}>
                        <div className="stepProgressNumber">9</div>
                        <div className="stepProgressName">9 - <FormattedMessage id="inscriptions.send.others" /></div>
                    </div>
                    <div className={`stepProgressItem ${this.state.frame === 9 ? 'active' : ''}`} onClick={() => this.moveFrame(9)}>
                        <div className="stepProgressNumber">10</div>
                        <div className="stepProgressName">10 - <FormattedMessage id="inscriptions.send.title" /></div>
                    </div>
                </div>

                

                {this.state.frame === 0 && <FrameProfile inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 1 && <FrameTerms inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 2 && <FrameStudioPublisher inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 3 && <FrameGameData inscription={this.state.inscription} event={this.state.event} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 4 && <FramePlatform inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 5 && <Genres><FrameGameDetails inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /></Genres>}
                {this.state.frame === 6 && <FrameGameAccess inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 7 && <FrameFestival inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 8 && <FrameOtherSupport inscription={this.state.inscription} onInscriptionsChanged={this.onInscriptionsChanged} /> }
                {this.state.frame === 9 && <FrameSend inscription={this.state.inscription} event={this.state.event} moveFrame={this.moveFrame} canEdit={true} /> }

                <div className="row">
                    <div className="col-12 text-right">
                        <button type="button" disabled={this.state.frame <= 0} className="btn btn-primary m-2" onClick={this.moveFramePrevious}><FormattedMessage id="previous" /></button>
                        <button type="button" disabled={this.state.frame >= 9} className="btn btn-primary" onClick={this.moveFrameNext}><FormattedMessage id="next" /></button>
                    </div>
                </div>
            </form>
        )
    }

    renderReadonlyView() {
        return (
            <form>
                <FrameSend inscription={this.state.inscription} canEdit={false}  canBackStatus={this.canBackToEdit()} />
            </form>
        )
    }

    render() {
        return (
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row page-titles">
                        <div className="col p-0">
                            <h4>
                            <FormattedMessage id="app.welcome-1" />,{" "}
                            <span>
                                <FormattedMessage id="app.welcome-2" />
                            </span>
                            </h4>
                        </div>
                        <div className="col p-0">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={"/"} title="Dashboard">
                                    <FormattedMessage id="app.menu.dashboard" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={"/inscriptions"} title="Minhas Inscrições">
                                    <FormattedMessage id="app.menu.inscriptions" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <FormattedMessage id="inscriptions.edit.new" />
                            </li>
                            </ol>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="content-title">
                                        <h1><FormattedMessage id="inscriptions.editTitle" /></h1>
                                    </div>
                                    
                                    {this.state.loading && this.renderLoading()}

                                    {!this.state.loading &&
                                    this.state.event && (
                                        <event-call event-id={this.state.event.id} />
                                    )}

                                    {!this.state.loading 
                                    && this.canEditEvent()
                                    && this.renderForm()}

                                    {!this.state.loading
                                    && !this.canEditEvent()
                                    && this.renderReadonlyView()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withAuth(InscriptionEdit))