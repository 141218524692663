import React, { useEffect, useState } from "react";
import axios from "axios";
import { injectIntl, useIntl } from "react-intl";

function AutoCompleteStudios(props) {
  const [studies, setStudies] = useState([]);
  const [text, setText] = useState("");
  const [isSetText, setIsSetText] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const loadStudies = async () => {
      const response = await axios.get("/api/v1/companies", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      setStudies(response.data);
    };
    loadStudies();
  }, []);

  useEffect(() => {
    if (props.anterior) {
      props.onSet(props.v, text, props.index);
    } else {
      props.onSet(props.v, text);
    }
  }, [text]);

  useEffect(() => {
    if (isSetText && props.initialValue != "") {
      setText(props.initialValue);
      setIsSetText(false);
    }
  }, [props.initialValue]);

  const onSuggestHandler = (text) => {
    setText(text);
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 2) {
      matches = studies.filter((studio) => {
        const regex = new RegExp(`${text}`, "gi");
        return studio.name.match(regex);
      });
      setSuggestions(matches);
    } else {
      setSuggestions([]);
    }
    setText(text);
  };

  return (
    <div
      className={`${
        props.register
          ? `form-group col-12 ${props.isError ? "input-error" : ""}`
          : `form-group col-md-6 ${props.isError ? "input-error" : ""}`
      }`}
    >
      <label>
        {props.anterior ? (
          <>
            {props.categoriaUser === "estudio" ? "Estúdio" : "Publisher"}{" "}
            {intl.formatMessage({ id: "component.auto-complete-studios.prev" })}
          </>
        ) : (
          <>
            {props.categoriaUser === "estudio" ? "Estúdio" : "Publisher"}{" "}
            {intl.formatMessage({
              id: "component.auto-complete-studios.current",
            })}
          </>
        )}
      </label>
      <input
        type="text"
        className="form-control form-control-sm"
        placeholder={intl.formatMessage({
          id: "component.auto-complete-studios.placeholder.text",
        })}
        onChange={(e) => onChangeHandler(e.target.value)}
        value={text}
        onBlur={() => {
          setTimeout(() => {
            setSuggestions([]);
          }, 200);
        }}
      />
      {suggestions &&
        suggestions.map((suggestion, i) => (
          <div
            key={i}
            className="suggestion col-md-12 justify-content-md-center"
            onClick={() => onSuggestHandler(suggestion.name)}
          >
            {suggestion.name}
          </div>
        ))}
    </div>
  );
}

export default injectIntl(AutoCompleteStudios);
