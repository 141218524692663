import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm, RegisterOptions } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  gameplay: yup.string().required(),
  gameplayShare: yup.boolean().required(),
  gameplayComment: yup.string().required(),
  narrativa: yup.string().required(),
  narrativaShare: yup.boolean().required(),
  narrativaComment: yup.string().required(),
  arte: yup.string().required(),
  arteShare: yup.boolean().required(),
  arteComment: yup.string().required(),
  som: yup.string().required(),
  somShare: yup.boolean().required(),
  somComment: yup.string().required(),
  inovacao: yup.string().required(),
  inovacaoShare: yup.boolean().required(),
  inovacaoComment: yup.string().required(),
});

function RatingGameSelecionador({ game }) {
  const intl = useIntl();
  const [isShow, setIsShow] = useState(true);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = jwt_decode(localStorage.getItem("token"));

  const onSubmit = (data) => {
    console.log(data);
    const dados = new FormData();

    dados.append("gameplay", data.gameplay);
    dados.append("gameplayShare", data.gameplayShare);
    dados.append("gameplayComment", data.gameplayComment);
    dados.append("narrativa", data.narrativa);
    dados.append("narrativaShare", data.narrativaShare);
    dados.append("narrativaComment", data.narrativaComment);
    dados.append("arte", data.arte);
    dados.append("arteShare", data.arteShare);
    dados.append("arteComment", data.arteComment);
    dados.append("som", data.som);
    dados.append("somShare", data.somShare);
    dados.append("somComment", data.somComment);
    dados.append("inovacao", data.inovacao);
    dados.append("inovacaoShare", data.inovacaoShare);
    dados.append("inovacaoComment", data.inovacaoComment);
    // dados.append("categoriaExtra", data.categoriaExtra);
    // dados.append("categoriaExtraShare", data.categoriaExtraShare);
    // dados.append("categoriaExtraComment", data.categoriaExtraComment);
    dados.append("event_id", game.event_id);
    dados.append("game_id", game.id);
    dados.append("team_id", game.team.id);
    dados.append("type_user", user.type);

    let url = `/api/v1/rating/game/${game.id}`;

    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsShow(false);
          alert(intl.formatMessage({ id: "rating.save" }));
        } else {
          alert(intl.formatMessage({ id: "rating.save.fail" }));
          console.log("Não salvou", res);
        }
      })
      .catch((e) => {
        if (e.response.status == 411) {
          alert(intl.formatMessage({ id: "inscriptions.edit.validate.date" }));
        } else {
          alert(intl.formatMessage({ id: "inscriptions.edit.response.fail" }));
        }
        console.log("e", e.response);
      });
  };

  useEffect(() => {
    if (game.rating.length != 0) {
      setValue("gameplay", game.rating[0].gameplay);
      setValue("gameplayShare", `${game.rating[0].gameplayShare}`);
      setValue("gameplayComment", game.rating[0].gameplayComment);
      setValue("narrativa", game.rating[0].narrativa);
      setValue("narrativaShare", `${game.rating[0].narrativaShare}`);
      setValue("narrativaComment", game.rating[0].narrativaComment);
      setValue("arte", game.rating[0].arte);
      setValue("arteShare", `${game.rating[0].arteShare}`);
      setValue("arteComment", game.rating[0].arteComment);
      setValue("som", game.rating[0].som);
      setValue("somShare", `${game.rating[0].somShare}`);
      setValue("somComment", game.rating[0].somComment);
      setValue("inovacao", game.rating[0].inovacao);
      setValue("inovacaoShare", `${game.rating[0].inovacaoShare}`);
      setValue("inovacaoComment", game.rating[0].inovacaoComment);
      // setValue("categoriaExtra", game.rating[0].categoriaExtra);
      // setValue("categoriaExtraShare", `${game.rating[0].categoriaExtraShare}`);
      // setValue("categoriaExtraComment", game.rating[0].categoriaExtraComment);
    }
  }, []);

  return (
    isShow && (
      <div className={`row ${game.rating.length !== 0 ? "rating-hide" : ""}`}>
        <div className="col-12">
          <div className="row">
            <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
              <Link
                to={{
                  pathname: `/games/detail/${game.id}`,
                  state: {
                    game,
                  },
                }}
                title="Detalhe Jogo"
              >
                <div className="image-event">
                  <img src={game.image} alt={game.name} />
                </div>
              </Link>
            </div>
            <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
              <div className="info">
                <div className="row w-100">
                  <Link
                    to={{
                      pathname: `/games/detail/${game.id}`,
                      state: {
                        game,
                      },
                    }}
                    title="Detalhe Jogo"
                  >
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h3>{game.name}</h3>
                      <p>{game.description}</p>
                    </div>
                  </Link>
                  <div className="col-12">
                    <form
                      className="form-custom"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-row">
                        <div className={`form-group col-md-12 mb-4`}>
                          <div
                            className={`${
                              errors?.gameplay ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="games.detail.curators-evaluation.gameplay" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Sim"}
                                  {...register("gameplay")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Não"}
                                  {...register("gameplay")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Talvez"}
                                  {...register("gameplay")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="talvez" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.gameplayShare ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={true}
                                  {...register("gameplayShare", {
                                    value: "false",
                                  })}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={false}
                                  {...register("gameplayShare")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.gameplayComment ? "input-error" : ""
                            }`}
                          >
                            <textarea
                              placeholder={
                                watch(`gameplayShare`) == "true"
                                  ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                  : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                              }
                              className="form-control form-control-sm feedback-categoria"
                              {...register("gameplayComment")}
                              disabled={game.rating.length !== 0}
                            />
                          </div>
                        </div>
                        <div className={`form-group col-md-12 mb-4`}>
                          <div
                            className={`${
                              errors?.narrativa ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="games.detail.curators-evaluation.narrative" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Sim"}
                                  {...register("narrativa")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Não"}
                                  {...register("narrativa")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Talvez"}
                                  {...register("narrativa")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="talvez" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.narrativaShare ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={true}
                                  {...register("narrativaShare", {
                                    value: "false",
                                  })}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={false}
                                  {...register("narrativaShare")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.narrativaComment ? "input-error" : ""
                            }`}
                          >
                            <textarea
                              placeholder={
                                watch(`narrativaShare`) == "true"
                                  ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                  : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                              }
                              className="form-control form-control-sm feedback-categoria"
                              {...register("narrativaComment")}
                              disabled={game.rating.length !== 0}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-12 mb-4">
                          <div
                            className={`${errors?.arte ? "input-error" : ""}`}
                          >
                            <h3>
                              <FormattedMessage id="games.detail.curators-evaluation.art" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Sim"}
                                  {...register("arte")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Não"}
                                  {...register("arte")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Talvez"}
                                  {...register("arte")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="talvez" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.arteShare ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={true}
                                  {...register("arteShare", {
                                    value: "false",
                                  })}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={false}
                                  {...register("arteShare")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.arteComment ? "input-error" : ""
                            }`}
                          >
                            <textarea
                              placeholder={
                                watch(`arteShare`) == "true"
                                  ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                  : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                              }
                              className="form-control form-control-sm feedback-categoria"
                              {...register("arteComment")}
                              disabled={game.rating.length !== 0}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-12 mb-4">
                          <div
                            className={`${errors?.som ? "input-error" : ""}`}
                          >
                            <h3>
                              <FormattedMessage id="games.detail.curators-evaluation.sound" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Sim"}
                                  {...register("som")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Não"}
                                  {...register("som")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Talvez"}
                                  {...register("som")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="talvez" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.somShare ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={true}
                                  {...register("somShare", {
                                    value: "false",
                                  })}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={false}
                                  {...register("somShare")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.somComment ? "input-error" : ""
                            }`}
                          >
                            <textarea
                              placeholder={
                                watch(`somShare`) == "true"
                                  ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                  : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                              }
                              className="form-control form-control-sm feedback-categoria"
                              {...register("somComment")}
                              disabled={game.rating.length !== 0}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-12 mb-4">
                          <div
                            className={`${
                              errors?.inovacao ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="games.detail.curators-evaluation.innovation" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Sim"}
                                  {...register("inovacao")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Não"}
                                  {...register("inovacao")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={"Talvez"}
                                  {...register("inovacao")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="talvez" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.inovacaoShare ? "input-error" : ""
                            }`}
                          >
                            <h3>
                              <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                            </h3>
                            <div className="content-radio">
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={true}
                                  {...register("inovacaoShare", {
                                    value: "false",
                                  })}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="sim" />
                              </div>
                              <div className="radio">
                                <input
                                  type="radio"
                                  className="form-control form-control-sm"
                                  value={false}
                                  {...register("inovacaoShare")}
                                  disabled={game.rating.length !== 0}
                                />
                                <FormattedMessage id="nao" />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              errors?.inovacaoComment ? "input-error" : ""
                            }`}
                          >
                            <textarea
                              placeholder={
                                watch(`inovacaoShare`) == "true"
                                  ? "Este comentário será compartilhado com o desenvolvedor do jogo"
                                  : "Este comentário não será compartilhado com o desenvolvedor do jogo"
                              }
                              className="form-control form-control-sm feedback-categoria"
                              {...register("inovacaoComment")}
                              disabled={game.rating.length !== 0}
                            />
                          </div>
                        </div>
                      </div>
                      {game.rating.length === 0 && (
                        <div className="form-row">
                          <div className="form-group content-btn col-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                              <FormattedMessage id="games.detail.menu-game.rate" />
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default injectIntl(RatingGameSelecionador);
