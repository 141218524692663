/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  game_release_date: yup.number().required(),
  game_release_date_type: yup.string().required(),
  developer_work_history: yup.number().required(),
  developer_work_history_type: yup.string().required(),
});

function Alerts(props) {
  const intl = useIntl();
  const [isFeedback, setIsFeedback] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      game_release_date: 0,
      game_release_date_type: "month",
      developer_work_history: 0,
      developer_work_history_type: "month",
    },
  });

  const watchGameReleaseDateType = watch("game_release_date_type");
  const watchDeveloperWorkHistoryType = watch("developer_work_history_type");

  useEffect(() => {
    loadAlert();
  }, []);

  function loadAlert() {
    let url = "/api/v1/alerts/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;

        if (itens.length != 0) {
          setValue("game_release_date", itens[0].game_release_date);
          setValue("game_release_date_type", itens[0].game_release_date_type);
          setValue("developer_work_history", itens[0].developer_work_history);
          setValue(
            "developer_work_history_type",
            itens[0].developer_work_history_type
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="alertas" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  const onSubmit = (data) => {
    const dados = new FormData();
    dados.append("game_release_date", data.game_release_date);
    dados.append("game_release_date_type", data.game_release_date_type);
    dados.append("developer_work_history", data.developer_work_history);
    dados.append(
      "developer_work_history_type",
      data.developer_work_history_type
    );

    let url = `/api/v1/alerts/update`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsFeedback(true);
      });
  };

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="alertas" />
                </h1>
                <div class="basic-form">
                  <form class="form-custom" onSubmit={handleSubmit(onSubmit)}>
                    <div class="form-row botton-line">
                      <div class="form-group col-12">
                        <label>
                          <FormattedMessage id="lembrete-sobre-a-data-de-lancamento-do-jogo" />
                        </label>
                      </div>
                      <div class="form-group col-6">
                        <input
                          type="number"
                          min="0"
                          class="form-control form-control-sm"
                          {...register("game_release_date")}
                        />
                      </div>
                      <div class="form-group col-6">
                        <select
                          class="form-control form-control-sm"
                          {...register("game_release_date_type")}
                        >
                          <option
                            selected={watchGameReleaseDateType == "minute"}
                            value={"minute"}
                          >
                            {intl.formatMessage({
                              id: "Minuto(s)",
                            })}
                          </option>
                          <option
                            selected={watchGameReleaseDateType == "hour"}
                            value={"hour"}
                          >
                            {intl.formatMessage({
                              id: "Hora(s)",
                            })}
                          </option>
                          <option
                            selected={watchGameReleaseDateType == "day"}
                            value={"day"}
                          >
                            {intl.formatMessage({
                              id: "Dia(s)",
                            })}
                          </option>
                          <option
                            selected={watchGameReleaseDateType == "month"}
                            value={"month"}
                          >
                            {intl.formatMessage({
                              id: "Mes(es)",
                            })}
                          </option>
                          <option
                            selected={watchGameReleaseDateType == "year"}
                            value={"year"}
                          >
                            {intl.formatMessage({
                              id: "Ano(s)",
                            })}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row botton-line">
                      <div class="form-group col-12">
                        <label>
                          <FormattedMessage id="historico-de-trabalho-do-desenvolvedor" />
                        </label>
                      </div>
                      <div class="form-group col-6">
                        <input
                          type="number"
                          min="0"
                          class="form-control form-control-sm"
                          {...register("developer_work_history")}
                        />
                      </div>
                      <div class="form-group col-6">
                        <select
                          class="form-control form-control-sm"
                          {...register("developer_work_history_type")}
                        >
                          <option
                            selected={watchDeveloperWorkHistoryType == "minute"}
                            value={"minute"}
                          >
                            {intl.formatMessage({
                              id: "Minuto(s)",
                            })}
                          </option>
                          <option
                            selected={watchDeveloperWorkHistoryType == "hour"}
                            value={"hour"}
                          >
                            {intl.formatMessage({
                              id: "Hora(s)",
                            })}
                          </option>
                          <option
                            selected={watchDeveloperWorkHistoryType == "day"}
                            value={"day"}
                          >
                            {intl.formatMessage({
                              id: "Dia(s)",
                            })}
                          </option>
                          <option
                            selected={watchDeveloperWorkHistoryType == "month"}
                            value={"month"}
                          >
                            {intl.formatMessage({
                              id: "Mes(es)",
                            })}
                          </option>
                          <option
                            selected={watchDeveloperWorkHistoryType == "year"}
                            value={"year"}
                          >
                            {intl.formatMessage({
                              id: "Ano(s)",
                            })}
                          </option>
                        </select>
                      </div>
                    </div>

                    {isFeedback && (
                      <div className="form-group col-12 alert">
                        {/* <div className="alert alert-success">Dados salvos com sucesso!</div> */}
                        <div className="alert alert-success">
                          <FormattedMessage id="atualizado-com-sucesso" />
                        </div>
                      </div>
                    )}

                    <div class="form-row">
                      <div class="form-group content-btn col-12 d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          <FormattedMessage id="component.my-account-form.btn-save" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(Alerts));
