import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyAccess extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.access.access",
                        value: this.getValue(this.props.gameAccess?.accesstype),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("accesstype"),
                        options: [
                            {value: 0, label: "inscriptions.access.access.key"},
                            {value: 10, label: "inscriptions.access.access.download"},
                            {value: 20, label: "inscriptions.access.access.url"},
                        ]
                    })}
                    {(this.props.gameAccess?.accesstype === 0) && renderTextValue({
                        label: "inscriptions.access.keycodes",
                        value: this.getValue(this.props.gameAccess?.keycodes),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("keycodes")
                    })}
                    {(this.props.gameAccess?.accesstype === 0) && renderTextValue({
                        label: "inscriptions.access.storecode",
                        value: this.getValue(this.props.gameAccess?.storecode),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("storecode")
                    })}
                    {(this.props.gameAccess?.accesstype >= 10) && renderTextValue({
                        label: "inscriptions.access.gameurl",
                        value: this.getValue(this.props.gameAccess?.gameurl),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("gameurl")
                    })}
                    {renderTextValue({
                        label: "inscriptions.access.information",
                        value: this.getValue(this.props.gameAccess?.information),
                        col: 12,
                        col_md: 6,
                        className: this.getClassName("information")
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyAccess)