import BaseFrame from './BaseFrame'
import { renderTextInput,
    renderSelectCountry,
    renderListOptionsInput,
    renderSelectStates,
    renderTextArea } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FrameStudioPublisher extends BaseFrame {

    constructor(props) {
        super(props)

        this.state = {
            show_brazil: false,
            show_publisher: false,
        }
    }

    onValueChanged = () => {
        let stateChanged = false
        
        const show_brazil = this.props.inscription.studio?.country === "Brasil"
        const show_publisher = this.props.inscription.studio?.publishertype >= 1

        stateChanged = stateChanged || this.state.show_brazil !== show_brazil
        stateChanged = stateChanged || this.state.show_publisher !== show_publisher

        if (stateChanged)
            this.setState({
                show_brazil,
                show_publisher
            })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.publisher" /></h2>
                <div className="row">
                    {renderTextInput({
                        name: "name",
                        label: "inscriptions.publisher.studio",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("studio", e),
                        value: this.fieldValueModel("studio", "name"),
                    })}
                    {renderSelectCountry({
                        name: "country",
                        label: "inscriptions.publisher.studio.country",
                        description: "inscriptions.publisher.studio.country.description",
                        placeholder: "select",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("studio", e),
                        value: this.fieldValueModel("studio", "country"),
                        col_md: 4
                    })}
                    <div className="col-12 col-md-8">
                        <div className="row">
                            {this.state.show_brazil && renderSelectStates({
                                name: "state",
                                label: "inscriptions.publisher.studio.state",
                                placeholder: "select",
                                required: true,
                                onChange: (e) => this.onChangeFieldModel("studio", e),
                                value: this.fieldValueModel("studio", "state"),
                                col_md: 6
                            })}
                            {this.state.show_brazil && renderTextInput({
                                name: "city",
                                label: "inscriptions.publisher.studio.city",
                                required: true,
                                onChange: (e) => this.onChangeFieldModel("studio", e),
                                value: this.fieldValueModel("studio", "city"),
                                col_md: 6
                            })}
                        </div>
                    </div>

                    {renderTextArea({
                        name: "members",
                        label: "inscriptions.publisher.studio.members",
                        description: "inscriptions.publisher.studio.members.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("studio", e),
                        value: this.fieldValueModel("studio", "members"),
                    })}

                    {renderListOptionsInput({
                        name: "publishertype",
                        label: "inscriptions.publisher.publishertype",
                        type: "radio",
                        col_md: 3,
                        option_col: 12,
                        required: true,
                        valueType: "int",
                        onChange: (e) => this.onChangeFieldModel("studio", e),
                        value: this.fieldValueModel("studio", "publishertype"),
                        options: [
                            {value: 1, label: "yes"},
                            {value: 0, label: "no"},
                            {value: 2, label: "negociation"}
                        ]
                    })}

                    <div className="col-12 col-md-9">
                        <div className="row">
                            {this.state.show_publisher && renderTextInput({
                                name: "publisher",
                                required: true,
                                label: "inscriptions.publisher.publisher",
                                onChange: (e) => this.onChangeFieldModel("studio", e),
                                value: this.fieldValueModel("studio", "publisher"),
                                col_md: 7
                            })}
                            {this.state.show_publisher && renderSelectCountry({
                                name: "publishercountry",
                                label: "inscriptions.publisher.publishercountry",
                                placeholder: "select",
                                required: true,
                                onChange: (e) => this.onChangeFieldModel("studio", e),
                                value: this.fieldValueModel("studio", "publishercountry"),
                                col_md: 5
                            })}
                        </div>
                    </div>
                    
                    {renderTextArea({
                        name: "emails",
                        label: "inscriptions.publisher.studioemails",
                        description: "inscriptions.publisher.studioemails.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("studio", e),
                        value: this.fieldValueModel("studio", "emails"),
                        col_md: 6
                    })}
                    {renderTextArea({
                        name: "phones",
                        label: "inscriptions.publisher.studiophone",
                        description: "inscriptions.publisher.studiophone.description",
                        required: true,
                        onChange: (e) => this.onChangeFieldModel("studio", e),
                        value: this.fieldValueModel("studio", "phones"),
                        col_md: 6
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameStudioPublisher)