/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { MultiSelect } from "react-multi-select-component";
import ModalTesteFilter from "./modalTesteFilter";

const schema = yup.object().shape({
  name: yup.string().required(),
  usuarios: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
  filters_active: yup
    .array()
    .required("Categorias de Avaliação é obrigatório")
    .min(1, "Selecione ao menos 1 categoria de avaliação"),
});

function FilterEdit(props) {
  const intl = useIntl();
  const history = useHistory();
  let { id } = useParams();
  const [users, setUsers] = useState([]);
  const [usersVisible, setUsersVisible] = useState([]);
  const [isClickButton, setIsClickButton] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isUsersLoaded, setIsUsersLoaded] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      usuarios: [],
      filters_active: [],
      active: "true",
    },
  });

  const watchGroup_user = watch("group_user");
  const watch_filters_active = watch("filters_active");

  useEffect(() => {
    if (!isFirstLoad) {
      const usuarios = [];
      if (users.length) {
        users.forEach((item) => {
          if (item.type == watchGroup_user)
            usuarios.push({ label: item.profile.name, value: item.id });
        });
        setUsersVisible(usuarios);
        setValue("usuarios", []);
      }
    }
  }, [watchGroup_user]);

  useEffect(() => {
    if (users.length != 0 && isUsersLoaded) {
      getFilter();
    }
  }, [users, isUsersLoaded]);

  useEffect(() => {
    loadUsers();
  }, []);

  function loadUsers() {
    let url = "/api/v1/users/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        if (itens.length) {
          const usuarios = [];
          itens.forEach((item) => {
            if (item.type === 0)
              usuarios.push({ label: item.profile.name, value: item.id });
          });
          setUsersVisible(usuarios);
        }
        setUsers(itens);
        setIsUsersLoaded(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  const getFilter = async () => {
    try {
      let url = `/api/v1/filter/${id}`;

      const response = await axios.get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      console.log("response", response);
      setValue("name", response.data[0].name);
      setValue("group_user", response.data[0].group_user);
      setValue("active", response.data[0].active.toString());
      setValue("filters_active", response.data[0].filters_active);

      // setar Users
      const usuarios = [];
      const usuariosSelected = [];
      if (users.length) {
        users.forEach((item) => {
          if (item.type === response.data[0].type) {
            usuarios.push({ label: item.profile.name, value: item.id });
            if (response.data[0].users_id.includes(item.id)) {
              usuariosSelected.push({
                label: item.profile.name,
                value: item.id,
              });
            }
          }
        });
        setUsersVisible(usuarios);
        setValue("usuarios", usuariosSelected);
      }
      // Fim setar Users
      setIsFirstLoad(false);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.open("/", "_self");
      } else {
        history.push(`/filters`);
      }
    }
  };

  function changeUsers(event) {
    const usuarios = [];
    if (users.length) {
      users.forEach((item) => {
        if (item.type == event.target.value)
          usuarios.push({ label: item.profile.name, value: item.id });
      });
      setUsersVisible(usuarios);
      setValue("usuarios", []);
    }
  }

  const onSubmit = (data) => {
    if (!isClickButton) {
      setIsClickButton(true);
      const dados = new FormData();
      let usuarios = [];
      data.usuarios.forEach((element) => {
        usuarios.push(element.value);
      });

      dados.append("name", data.name);
      dados.append("group_user", data.group_user);
      dados.append("usuarios", usuarios);
      dados.append("active", data.active == "true" ? true : false);
      dados.append("filters_active", data.filters_active);
      let url = `/api/v1/filter/edit/${id}`;
      axios
        .post(url, dados, {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setIsClickButton(false);
          history.push(`/filters/`);
        })
        .catch((e) => {
          setIsClickButton(false);
          console.log("e", e.response);
        });
    }
  };

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/teams"} title="Usuários">
                <FormattedMessage id="app.breadcrumb.teams" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.breadcrumb.teams.new" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-body">
        <div className="container-fluid">
          {renderBreadcrumb()}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h1 className="card-title">
                    <FormattedMessage id="page.filters.new-filter" />
                  </h1>

                  {isFirstLoad ? (
                    <p>
                      <FormattedMessage id="loading" />
                    </p>
                  ) : (
                    <div className="basic-form">
                      <form
                        className="form-custom"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="form-row">
                          <div
                            className={`form-group col-6 ${
                              errors?.name ? "input-error" : ""
                            }`}
                          >
                            <label>
                              <FormattedMessage id="app.filters.table.name-filter" />
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              {...register("name")}
                            />
                          </div>
                          <div className={`form-group col-6`}>
                            <label>
                              <FormattedMessage id="app.filters.table.status" />
                            </label>
                            <div
                              className="form-row"
                              style={{
                                gap: "20px",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="radio"
                                    class="form-control form-control-sm"
                                    value={true}
                                    {...register("active")}
                                  />
                                  <FormattedMessage id="selecione-o-status-active" />
                                </div>
                              </div>
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="radio"
                                    class="form-control form-control-sm"
                                    value={false}
                                    {...register("active")}
                                  />
                                  <FormattedMessage id="selecione-o-status-deactivate" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`form-group col-md-6`}>
                            <label>
                              <FormattedMessage id="app.filters.new.label.group-role" />
                            </label>
                            <select
                              className="form-control form-control-sm"
                              onChange={changeUsers}
                              {...register("group_user")}
                            >
                              <option value={0}>
                                {intl.formatMessage({
                                  id: "master",
                                })}
                              </option>
                              <option value={2}>
                                {intl.formatMessage({
                                  id: "admin",
                                })}
                              </option>
                              <option value={1}>
                                {intl.formatMessage({
                                  id: "desenvolvedor",
                                })}
                              </option>
                              <option value={3}>
                                {intl.formatMessage({
                                  id: "juri-aberto",
                                })}
                              </option>
                              <option value={7}>
                                {intl.formatMessage({
                                  id: "selecionador-fechado",
                                })}
                              </option>
                              <option value={4}>
                                {intl.formatMessage({
                                  id: "juri-fechado",
                                })}
                              </option>
                              <option value={5}>
                                {intl.formatMessage({
                                  id: "observador",
                                })}
                              </option>
                              <option value={6}>
                                {intl.formatMessage({
                                  id: "selecionador-aberto",
                                })}
                              </option>
                            </select>
                          </div>
                          <div
                            className={`form-group col-md-6 ${
                              errors?.usuarios ? "input-error" : ""
                            }`}
                          >
                            <label>
                              <FormattedMessage id="app.menu.users" />
                            </label>
                            <Controller
                              control={control}
                              name="usuarios"
                              render={({ field: { onChange, value } }) => (
                                <MultiSelect
                                  options={usersVisible}
                                  value={value}
                                  onChange={onChange}
                                  labelledBy="Select"
                                  hasSelectAll={false}
                                />
                              )}
                            />
                          </div>

                          <div
                            class={`form-row botton-line ${
                              errors?.filters_active ? "input-error" : ""
                            }`}
                          >
                            <div class="col-12">
                              <h2 class="card-title">
                                <FormattedMessage id="selecione-os-filtros-que-serao-exibidos" />
                              </h2>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="categoria-de-finalista"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="categorias-de-finalista" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="categoria-de-vencedores"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="categorias-de-vencedores" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="status"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="status" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="plataformas"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="plataformas" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="genero"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="genero" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="classificacao"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="classificacao" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="pais"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="pais" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="data-de-lancamento"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="data-de-lancamento" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="data-de-envio"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="data-de-envio" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="chamadas"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="chamadas" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="avaliacao"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="avaliacao" />
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                              <div class="content-radio">
                                <div class="radio">
                                  <input
                                    type="checkbox"
                                    class="form-control form-control-sm"
                                    value="jogos"
                                    {...register("filters_active")}
                                  />
                                  <FormattedMessage id="jogos" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group content-btn col-12 d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={(e) => {
                                e.preventDefault();

                                history.push("/filters");
                              }}
                            >
                              <FormattedMessage id="component.my-account-form.btn-cancel" />
                            </button>

                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                // eslint-disable-next-line no-undef
                                $("#modalTesteFilter").modal("show");
                              }}
                            >
                              <FormattedMessage id="testar-filtro" />
                            </button>

                            <button type="submit" className="btn btn-primary">
                              <FormattedMessage id="component.my-account-form.btn-save" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalTesteFilter filterActive={watch_filters_active} />
    </>
  );
}

export default injectIntl(withAuth(FilterEdit));
