import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import { useForm, RegisterOptions } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const geneteValidate = (game) => {
  var validate = {};

  const formSchema = {
    nota: yup.string().required(),
    share: yup.boolean().required(),
    comment: yup.string().required(),
  };

  const gameSchemaGenerate = (id) => {
    return {
      [`game_${id}`]: yup.object().shape(formSchema),
    };
  };

  game.team.cat_avaliadas.forEach((cat) => {
    validate[`${cat}`] = yup.object().shape(gameSchemaGenerate(game.id));
  });

  return validate;
};

function RatingGameJuriFechadoGame({ game, openModal }) {
  const intl = useIntl();
  const schema = yup.object().shape(geneteValidate(game));
  const [isShow, setIsShow] = useState(true);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = jwt_decode(localStorage.getItem("token"));

  const onSubmit = (data) => {
    console.log("data", data);
    const dados = new FormData();
    dados.append("ratingJuri", JSON.stringify(data));
    dados.append("event_id", game.event_id);
    dados.append("game_id", game.id);
    dados.append("team_id", game.team.id);
    dados.append("type_user", user.type);

    let url = `/api/v1/rating/game/${game.id}`;

    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsShow(false);
          alert(intl.formatMessage({ id: "rating.save" }));
        } else {
          alert(intl.formatMessage({ id: "rating.save.fail" }));
          console.log("Não salvou", res);
        }
      })
      .catch((e) => {
        if (e.response.status == 411) {
          alert(intl.formatMessage({ id: "inscriptions.edit.validate.date" }));
        } else {
          alert(intl.formatMessage({ id: "inscriptions.edit.response.fail" }));
        }
        console.log("e", e.response);
      });
  };

  return (
    <div className={`row`}>
      <div className="col-12">
        <div className="row">
          <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
            <Link
              to={{
                pathname: `/games/detail/${game.id}`,
                state: {
                  game,
                },
              }}
              title="Detalhe Jogo"
            >
              <div className="image-event">
                <img src={game.image} alt={game.name} />
              </div>
            </Link>
          </div>
          <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
            <div className="info">
              <div className="row w-100">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <Link
                    to={{
                      pathname: `/games/detail/${game.id}`,
                      state: {
                        game,
                      },
                    }}
                    title="Detalhe Jogo"
                  >
                    <h3>{game.name}</h3>
                    <p>{game.description}</p>
                  </Link>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <h3>
                    <FormattedMessage id="account.forgot-password.input-label-code" />
                  </h3>
                  <p>
                    {
                      game.team.codes[`user_${user.id}`][
                        `inscription_${game.id}`
                      ].code
                    }
                  </p>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <h3>
                    <FormattedMessage id="123-form" />
                  </h3>
                  <button
                    type="button"
                    onClick={() => {
                      openModal(game);
                    }}
                    className="btn btn-primary"
                  >
                    <FormattedMessage id="ver" />
                  </button>
                </div>
                <div className="col-12">
                  <form
                    className="form-custom"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-row">
                      {game.team.cat_avaliadas.map((cat, index) => {
                        return (
                          <>
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.[cat]?.[`game_${game.id}`]?.nota
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="avalie-de-1-a-5-sua-experiencia-com-a-categoria:" />
                                  {cat}
                                </h3>
                                <div className="container-slider">
                                  <input
                                    type="range"
                                    min="0"
                                    max="5"
                                    step="1"
                                    className="slider slider-range"
                                    {...register(
                                      `${cat}.game_${game.id}.nota`,
                                      {
                                        value: 0,
                                      }
                                    )}
                                  />
                                  <span className="slider-range-value">
                                    {watch(`${cat}.game_${game.id}.nota`)}
                                  </span>
                                  <i className="fa fa-star btn-outline-warning"></i>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.[cat]?.[`game_${game.id}`]?.share
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deseja-compartilhar-esse-comentario-com-o-desenvolvedor?" />
                                </h3>

                                <div className="content-radio">
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      name="categoria-extra"
                                      value={true}
                                      {...register(
                                        `${cat}.game_${game.id}.share`,
                                        {
                                          value: "false",
                                        }
                                      )}
                                    />
                                    <FormattedMessage id="sim" />
                                  </div>
                                  <div className="radio">
                                    <input
                                      type="radio"
                                      className="form-control form-control-sm"
                                      name="categoria-extra"
                                      value={false}
                                      {...register(
                                        `${cat}.game_${game.id}.share`
                                      )}
                                    />
                                    <FormattedMessage id="nao" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-12 mb-4">
                              <div
                                className={`${
                                  errors?.[cat]?.[`game_${game.id}`]?.comment
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <h3>
                                  <FormattedMessage id="deixe-seu-comentario" />
                                </h3>
                                <textarea
                                  placeholder="Este comentário será compartilhado com o desenvolvedor do jogo"
                                  className="form-control form-control-sm feedback-categoria"
                                  {...register(
                                    `${cat}.game_${game.id}.comment`
                                  )}
                                ></textarea>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="form-row">
                      <div className="form-group content-btn col-12 d-flex justify-content-end">
                        <button
                          class="btn btn-outline-primary"
                          onClick={() => {
                            history.push(`/games/detail/${game.id}`);
                          }}
                        >
                          <FormattedMessage id="component.my-account-form.btn-cancel" />
                        </button>
                        <button type="submit" className="btn btn-primary">
                          <FormattedMessage id="games.detail.menu-game.rate" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(RatingGameJuriFechadoGame);
