import React, { Component } from "react";
import { Link } from "react-router-dom";
import MyAccountForm from "../../components/MyAccountForm";
import { FormattedMessage, injectIntl } from "react-intl";

import "./Account.css";

import imgLogo from "../../assets/images/v2/logo-big-new-preto.svg";
import imgVoltar from "../../assets/images/v2/arrow-left.svg";

class Register extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="login-screen">
        <div className="login-bg">
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="col-xl-8">
                <div className="form-input-content">
                  <div className="card">
                    <div className="card-body">
                      <header>
                        <div className="btn-back">
                          <Link to={"/"} title="Voltar">
                            <img src={imgVoltar} alt="Voltar" />
                            <FormattedMessage id="account.login.previous" />
                          </Link>
                        </div>
                        <div className="logo text-center">
                          <a href="/">
                            <img src={imgLogo} alt="BIG Festival" />
                          </a>
                        </div>
                        <h1 className="text-center m-t-30 card-title">
                          <FormattedMessage id="account.register.title" />
                        </h1>
                      </header>
                      <MyAccountForm register={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Register);
