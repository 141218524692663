/* eslint-disable default-case */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withAuth from "../../components/auth";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";

function Languages(props) {
  const intl = useIntl();
  const [languages, setLanguages] = useState([]);
  const [isLanguagesLoading, setIsLanguagesLoading] = useState(true);

  useEffect(() => {
    loadLanguages();
  }, []);

  const handleIdioma = (e) => {
    if (e.target.value == "-0") {
      localStorage.removeItem("idioma");
      window.location.reload();
    } else {
      localStorage.setItem("idioma", e.target.value);
      window.location.reload();
    }
  };

  function loadLanguages() {
    let url = "/api/v1/languages/";

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let itens = res.data;
        console.log("Idiomas", itens);
        setLanguages(itens);
        setIsLanguagesLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function renderChangeLanguange() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="alterar-idioma" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <select
                        class="form-control form-control-sm"
                        onChange={handleIdioma}
                      >
                        <option value="-0">
                          {intl.formatMessage({
                            id: "selecione-o-idioma",
                          })}
                        </option>
                        {languages.map((language, index) => {
                          return (
                            language.active && (
                              <option
                                key={index}
                                selected={
                                  localStorage.getItem("idioma") == language.id
                                }
                                value={language.id}
                              >
                                {language.name}
                              </option>
                            )
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="idiomas" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderLanguages() {
    return (
      <div className="table-responsive">
        {languages.length ? (
          <table className="table verticle-middle table-lista-big">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="idioma" />
                </th>
                <th scope="col">
                  <FormattedMessage id="inscriptions.list.status" />
                </th>
                <th scope="col" className="table-right">
                  <FormattedMessage id="acoes" />
                </th>
              </tr>
            </thead>
            <tbody>
              {languages.map((language, index) => {
                return (
                  <tr key={index}>
                    <td>{language.name}</td>
                    <td>
                      {language.active ? (
                        <FormattedMessage id="selecione-o-status-active" />
                      ) : (
                        <FormattedMessage id="selecione-o-status-deactivate" />
                      )}
                    </td>
                    <td className="table-right">
                      <span>
                        <Link
                          to={`/settings/languages/${language.id}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Editar"
                        >
                          <i className="fa fa-pencil color-muted m-r-5"></i>{" "}
                        </Link>
                        <Link
                          to={`/language/remove/${language.id}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remover"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                intl.formatMessage({
                                  id: "delete.language",
                                })
                              ) == true
                            ) {
                              axios
                                .delete(`/api/v1/languages/${language.id}`, {
                                  headers: {
                                    "x-access-token":
                                      localStorage.getItem("token"),
                                  },
                                })
                                .then((res) => {
                                  if (
                                    localStorage.getItem("idioma") ==
                                    language.id.toString()
                                  ) {
                                    localStorage.removeItem("idioma");
                                    window.location.reload();
                                  }
                                  loadLanguages();
                                })
                                .catch((error) => {
                                  if (error.response.status === 401) {
                                    localStorage.removeItem("token");
                                    window.open("/", "_self");
                                  }
                                });
                            } else {
                              console.log("não mexe");
                            }
                          }}
                        >
                          <i class="fa fa-close color-danger"></i>{" "}
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>
            <FormattedMessage id="nenhum-idioma-cadastrado" />
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}

        {languages.length != 0 && renderChangeLanguange()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title">
                  <FormattedMessage id="todos-os-idiomas" />
                </h1>
                {isLanguagesLoading ? (
                  <p>
                    <FormattedMessage id="loading" />
                  </p>
                ) : (
                  <>
                    {renderLanguages()}
                    <div className="form-row">
                      <div className="form-group col-12">
                        <Link
                          to={"/settings/languages/new-language/"}
                          className="btn btn-primary"
                          title="Novo Idioma"
                        >
                          <FormattedMessage id="novo-idioma" />
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(Languages));
