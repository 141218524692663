import React, { useEffect, useState } from "react";
import axios from "axios";
import { injectIntl, useIntl, FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerCustom(props) {
  const [startDate, setStartDate] = useState(
    props.valueLoad() ? new Date(props.valueLoad()) : ""
  );

  useEffect(() => {
    setTimeout(() => {
      let value = props.valueLoad();

      if (value) setStartDate(new Date(props.valueLoad()));
    }, 3000);
  }, []);

  const onDateHandler = (d) => {
    setStartDate(d);
    if (props.multiple) {
      props.onSet(moment(d).format("YYYY-MM-DD"), props.name, props.index);
    } else {
      props.onSet(moment(d).format("YYYY-MM-DD"), props.name);
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onDateHandler}
      dateFormat="dd/MMMM/yyyy"
      className="form-control form-control-sm"
      placeholderText={"dd/MM/yyyy"}
    />
  );
}

export default injectIntl(DatePickerCustom);
