import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import DiscordLogin from "../../components/Discord";
import DiscordCallback from "../../components/Discord/DiscordCallback";
import "./Account.css";
import imgLogo from "../../assets/images/v2/logo-big-new-preto.svg";
import logoDiscord from "../../assets/images/v2/logo-discord-white.svg";

function Account() {
  const onDiscord = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
  }, []);

  return (
    <div className="login-screen">
      <div className="login-bg h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-xl-6">
              <div className="form-input-content">
                <div className="card">
                  <div className="card-body">
                    <header>
                      <div className="logo text-center">
                        <a href="/">
                          <img src={imgLogo} alt="BIG Festival" />
                        </a>
                      </div>
                      <h1 className="text-center m-b-30 m-t-15 card-title">
                        <FormattedMessage id="account.home.welcome" />
                      </h1>
                    </header>
                    <form className="m-t-30">
                      <div className="login-discord text-center m-t-30">
                        <DiscordLogin />
                        <Link to="/login" className="btn btn-outline-primary">
                          <i className="mdi mdi-account"></i>
                          <p>
                            <FormattedMessage id="account.home.login-password" />
                          </p>
                        </Link>
                      </div>
                      <div className="form-row m-t-30">
                        <div className="form-group col-md-12 text-center">
                          <Link
                            to={"register"}
                            title="Não tem uma conta? Cadastre-se aqui!"
                          >
                            <FormattedMessage id="account.home.btn-register" />
                          </Link>
                        </div>
                        <div className="form-group col-md-12 text-center">
                          <Link
                            to={"forgot-password"}
                            title="Esqueci minha senha"
                          >
                            <FormattedMessage id="account.home.forgot-password" />
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Account);
