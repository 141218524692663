import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyPlatform extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.platform.educational",
                        value: this.getValue(this.props.game?.educational),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("educational"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.socialimpact",
                        value: this.getValue(this.props.game?.socialimpact),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("socialimpact"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.diversity",
                        value: this.getValue(this.props.game?.diversity),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("diversity"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.virtualreality",
                        value: this.getValue(this.props.game?.virtualreality),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("virtualreality"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.blockchain",
                        value: this.getValue(this.props.game?.blockchain),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("blockchain"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.students",
                        value: this.getValue(this.props.game?.students),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("students"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}
                    {this.props.game?.students && renderTextValue({
                        label: "inscriptions.platform.university",
                        value: this.getValue(this.props.game?.university),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("university")
                    })}
                    {this.props.game?.students && renderTextValue({
                        label: "inscriptions.game.enrollmentproof",
                        value: this.getValue(this.props.game?.enrollmentproof),
                        link: "/api/v1/media/image/" + this.getValue(this.props.game?.enrollmentproof),
                        link_download: true,
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("enrollmentproof")
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.platforms",
                        value: this.getValue(this.props.game?.platforms),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("platforms"),
                        options: [
                            { value: 1, label: "android" },
                            { value: 2, label: "browser" },
                            { value: 3, label: "ios" },
                            { value: 4, label: "switch" },
                            { value: 5, label: "pc" },
                            { value: 6, label: "playstation" },
                            { value: 7, label: "steam" },
                            { value: 8, label: "xbox" },
                            { value: -1, label: "other" },
                        ]
                    })}
                    {this.props.game?.platforms?.includes(-1) && renderTextValue({
                        label: "inscriptions.platform.platformsother",
                        value: this.getValue(this.props.game?.platformsother),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("platformsother")
                    })}
                    {renderTextValue({
                        label: "inscriptions.platform.preferredplatform.label",
                        value: this.getValue(this.props.game?.preferredplatform),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("preferredplatform"),
                        options: [
                            { value: 1, label: "android" },
                            { value: 2, label: "browser" },
                            { value: 3, label: "ios" },
                            { value: 4, label: "switch" },
                            { value: 5, label: "pc" },
                            { value: 6, label: "playstation" },
                            { value: 7, label: "steam" },
                            { value: 8, label: "xbox" },
                            { value: -1, label: "other" },
                        ]
                    })}
                    {(this.props.game?.preferredplatform === -1) && renderTextValue({
                        label: "inscriptions.platform.platformsother",
                        value: this.getValue(this.props.game?.preferredplatformother),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("preferredplatformother")
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyPlatform)