import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import withAuth from "../../components/auth";
import moment from "moment";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import NoImageChamada from "../../assets/images/v2/event/image-event-test.jpg";

function ListIncriptionsEvent() {
  const history = useHistory();
  const { id } = useParams();
  const intl = useIntl();
  const locale = intl.locale;

  const [itensVisible, setItensVisible] = useState([]);
  const [evento, setEvento] = useState(null);

  useEffect(() => {
    loadInscriptions();
    loadEvent();
  }, []);

  function loadEvent() {
    let url = `/api/v1/event/${id}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        setEvento(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadInscriptions(params) {
    let url = `/api/v1/inscriptions/event/${id}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        console.log("aqui", res.data.length);
        // if (res.data.length !== 0) {
        setItensVisible(res.data);
        // } else {
        //   history.push("/inscription");
        // }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function traducaoSiglaLocale(locale1, locale2) {
    let l1 = locale1;
    let l2 = locale2;

    if (locale1.indexOf("-") >= 0) l1 = locale1.split("-")[0];

    if (locale2.indexOf("-") >= 0) l2 = locale2.split("-")[0];

    return l1 == l2;
  }

  function getGenero(game) {
    let genero = [];
    if (game.form123) {
      if (game.form123["Gênero(s)-Arcade"] == "yes") {
        genero.push("Gênero(s)-Arcade");
      }
      if (game.form123["Gênero(s)-Arena"] == "yes") {
        genero.push("Gênero(s)-Arena");
      }
      if (game.form123["Gênero(s)-Auto-runner"] == "yes") {
        genero.push("Gênero(s)-Auto-runner");
      }
      if (game.form123["Gênero(s)-Aventura"] == "yes") {
        genero.push("Gênero(s)-Aventura");
      }
      if (game.form123["Gênero(s)-Aventura Tática"] == "yes") {
        genero.push("Gênero(s)-Aventura Tática");
      }
      if (game.form123["Gênero(s)-Ação"] == "yes") {
        genero.push("Gênero(s)-Ação");
      }
      if (game.form123["Gênero(s)-Baseado em Texto"] == "yes") {
        genero.push("Gênero(s)-Baseado em Texto");
      }
      if (game.form123["Gênero(s)-Battle Royale"] == "yes") {
        genero.push("Gênero(s)-Battle Royale");
      }
      if (game.form123["Gênero(s)-Beat Em Up"] == "yes") {
        genero.push("Gênero(s)-Beat Em Up");
      }
      if (game.form123["Gênero(s)-Cartas"] == "yes") {
        genero.push("Gênero(s)-Cartas");
      }
      if (game.form123["Gênero(s)-Casual"] == "yes") {
        genero.push("Gênero(s)-Casual");
      }
      if (game.form123["Gênero(s)-Caça ao Tesouro"] == "yes") {
        genero.push("Gênero(s)-Caça ao Tesouro");
      }
      if (game.form123["Gênero(s)-Corrida"] == "yes") {
        genero.push("Gênero(s)-Corrida");
      }
      if (game.form123["Gênero(s)-Dedução Social"] == "yes") {
        genero.push("Gênero(s)-Dedução Social");
      }
      if (game.form123["Gênero(s)-Educacional"] == "yes") {
        genero.push("Gênero(s)-Educacional");
      }
      if (game.form123["Gênero(s)-Esporte"] == "yes") {
        genero.push("Gênero(s)-Esporte");
      }
      if (game.form123["Gênero(s)-Estratégia"] == "yes") {
        genero.push("Gênero(s)-Estratégia");
      }
      if (game.form123["Gênero(s)-FPS"] == "yes") {
        genero.push("Gênero(s)-FPS");
      }
      if (game.form123["Gênero(s)-Família"] == "yes") {
        genero.push("Gênero(s)-Família");
      }
      if (game.form123["Gênero(s)-Fantasia"] == "yes") {
        genero.push("Gênero(s)-Fantasia");
      }
      if (game.form123["Gênero(s)-Horror/Terror"] == "yes") {
        genero.push("Gênero(s)-Horror/Terror");
      }
      if (game.form123["Gênero(s)-Luta"] == "yes") {
        genero.push("Gênero(s)-Luta");
      }
      if (game.form123["Gênero(s)-Narrativa"] == "yes") {
        genero.push("Gênero(s)-Narrativa");
      }
      if (game.form123["Gênero(s)-Party Game"] == "yes") {
        genero.push("Gênero(s)-Party Game");
      }
      if (game.form123["Gênero(s)-Plataforma"] == "yes") {
        genero.push("Gênero(s)-Plataforma");
      }
      if (game.form123["Gênero(s)-Point and Click"] == "yes") {
        genero.push("Gênero(s)-Point and Click");
      }
      if (game.form123["Gênero(s)-Puzzle"] == "yes") {
        genero.push("Gênero(s)-Puzzle");
      }
      if (game.form123["Gênero(s)-RPG"] == "yes") {
        genero.push("Gênero(s)-RPG");
      }
      if (game.form123["Gênero(s)-Realidade Virtual"] == "yes") {
        genero.push("Gênero(s)-Realidade Virtual");
      }
      if (game.form123["Gênero(s)-Ritmo"] == "yes") {
        genero.push("Gênero(s)-Ritmo");
      }
      if (game.form123["Gênero(s)-Roguelite"] == "yes") {
        genero.push("Gênero(s)-Roguelite");
      }
      if (game.form123["Gênero(s)-Side-Scrolling"] == "yes") {
        genero.push("Gênero(s)-Side-Scrolling");
      }
      if (game.form123["Gênero(s)-Simulação"] == "yes") {
        genero.push("Gênero(s)-Simulação");
      }
      if (game.form123["Gênero(s)-Sobrevivência"] == "yes") {
        genero.push("Gênero(s)-Sobrevivência");
      }
      if (game.form123["Gênero(s)-Social Game"] == "yes") {
        genero.push("Gênero(s)-Social Game");
      }
      if (game.form123["Gênero(s)-Tabuleiro"] == "yes") {
        genero.push("Gênero(s)-Tabuleiro");
      }
      if (game.form123["Gênero(s)-Tiro"] == "yes") {
        genero.push("Gênero(s)-Tiro");
      }
      if (game.form123["Gênero(s)-Trivia"] == "yes") {
        genero.push("Gênero(s)-Trivia");
      }
      if (game.form123["Gênero(s)-Type'Em Up"] == "yes") {
        genero.push("Gênero(s)-Type'Em Up");
      }
      if (game.form123["Gênero(s)-Visual Novel"] == "yes") {
        genero.push("Gênero(s)-Visual Novel");
      }
    }
    return genero.length ? genero.toString() : "---";
  }

  const renderEvent = () => {
    console.log("evento", evento);
    let localeData = evento.translations.filter((e) =>
      traducaoSiglaLocale(e.locale, locale)
    );
    localeData = localeData.length > 0 ? localeData[0] : null;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="content-chamada">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <figure className="m-b-0">
                      <img
                        src={evento.image ? evento.image : NoImageChamada}
                        alt={evento.name}
                      />
                    </figure>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-12 text-chamada">
                        <h1 className="card-title">{evento.name}</h1>
                      </div>
                      <div
                        className="col-12 text-chamada"
                        dangerouslySetInnerHTML={{
                          __html: localeData?.description,
                        }}
                      />
                      <div className="col-12 text-chamada">
                        <h4>
                          <FormattedMessage id="inscriptions.game.limit" />
                        </h4>
                        <p>
                          {" "}
                          {moment(evento.limit_existence_game).format(
                            "DD/MMMM/YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function renderList() {
    return itensVisible.map((game, index) => {
      return (
        <div className="row" key={index}>
          <div className="col-12">
            <Link
              to={{
                pathname: `/games/detail/${game.id}`,
                state: {
                  game,
                },
              }}
              title="Detalhe Jogo"
            >
              <div className="row">
                <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                  <div className="image-event">
                    <img src={game.image} alt="" />
                  </div>
                </div>
                <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                  <div className="info">
                    <div className="row w-100">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h2>{game.name}</h2>
                        <p>{game.description}</p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.award" />
                        </h3>
                        <p>
                          {game.award !== null
                            ? game.award.length != 0
                              ? game.award.toString()
                              : "---"
                            : "---"}
                        </p>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.finalist" />
                        </h3>
                        <p>
                          {game.finalist !== null
                            ? game.finalist.length != 0
                              ? game.finalist.toString()
                              : "---"
                            : "---"}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.publisher" />
                        </h3>
                        <p>---</p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.platforms" />
                        </h3>
                        <p>{game.form123 ? game.form123.Plataformas : "---"}</p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.genre" />
                        </h3>
                        <p>{getGenero(game)}</p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.country" />
                        </h3>
                        <p>
                          {game.form123
                            ? game.form123[
                                "País do estúdio que desenvolveu o jogo"
                              ]
                            : "---"}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.classification" />
                        </h3>
                        <p>
                          {game.form123 ? game.form123["Faixa Etária"] : "---"}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.release-date" />
                        </h3>
                        <p>
                          {moment(game.release_date).format("DD/MMMM/YYYY")}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                        <h3>
                          <FormattedMessage id="dashboard.game.send-date" />
                        </h3>
                        <p>{moment(game.created).format("DD/MMMM/YYYY")}</p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 rating">
                        <h3>
                          <FormattedMessage id="dashboard.game.general-note" />
                        </h3>
                        <p>
                          <i className="fa fa-star btn-outline-warning"></i>
                          <span>---</span>
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 rating">
                        <h3>
                          <FormattedMessage id="dashboard.game.commercial-potential" />
                        </h3>
                        <p>
                          <i className="fa fa-star btn-outline-warning"></i>
                          <span>---</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.menu.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/registered-calls"} title="Dashboard">
                <FormattedMessage id="app.menu.calls" />
              </Link>
            </li>
            <li className="breadcrumb-item active">{evento && evento.name}</li>
          </ol>
        </div>
      </div>
    );
  }

  function getLabelStatus(status) {
    let label = "";

    if (status === 0) {
      label = intl.formatMessage({ id: "inscriptions.status.notSent" });
    }

    if (status === 1) {
      label = intl.formatMessage({ id: "inscriptions.status.underReview" });
    }

    if (status === 2) {
      label = intl.formatMessage({ id: "inscriptions.status.finalist" });
    }

    if (status === 3) {
      label = intl.formatMessage({ id: "inscriptions.status.notSelected" });
    }

    if (status === 4) {
      label = intl.formatMessage({ id: "inscriptions.status.reviewGame" });
    }
    return label;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {evento && renderEvent()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="dashboard.event.inscriptions" />
                </h4>
                <div className="event-list games">{renderList()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(ListIncriptionsEvent));
