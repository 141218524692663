import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Country, State, City } from "country-state-city";
import Base from "./base";
import AutoCompleteStudios from "../AutoCompleteStudios";
import AutoCompleteGames from "../AutoCompleteGames";
import DatePickerCustom from "../DatePickerCustom";
import MultiSelectorFunctions from "../MultiSelectorFunctions";
import IconDiscord from "../../assets/images/v2/logo-discord-white.svg";
import Config from "../../config/";
import moment from "moment";

// const countries = csc.getAllCountries();
class MyAccountForm extends Base {
  constructor(props) {
    super(props);
    this.state = {
      terms: false,
      functions: [
        "AI",
        "Analista",
        "Animador 2D",
        "Animador 3D",
        "Artista 2D",
        "Artista 3D",
        "Áudio / Som",
        "Backend",
        "Build",
        "Business Development",
        "Cinemática",
        "Community manager",
        "Concept Artist",
        "Customer support",
        "Data Integration",
        "Desenvolvedor(a)",
        "Design",
        "Design Gráfico",
        "Direção de Arte",
        "Edição de Vídeo",
        "Engenheiro",
        "Engine",
        "Front-end",
        "FX",
        "Game Designer",
        "Gerente de Produto",
        "Ilustrador(a)",
        "IT Support",
        "Level Design",
        "Lighting",
        "Localization",
        "Marketing",
        "Mídias Digitais",
        "Mobile",
        "Modelagem",
        "Produtor(a)",
        "Produtor(a) de Conteúdo",
        "Programador(a) Júnior",
        "Programador(a) Pleno",
        "Programador(a) Sênior",
        "Prop",
        "Redes Sociais",
        "Rendering",
        "Rigging",
        "Roteirista",
        "Técnico",
        "Tester / QA",
        "UI / UX",
        "User Acquisition",
        "Vendas",
      ],
      discordId: null,
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phoneWhatsapp: "",
      phoneCode: "55",
      country: "BR",
      countryList: Country.getAllCountries(),
      stateList: State.getStatesOfCountry("BR"),
      state: "SP",
      city: "",
      categoriaUser: "estudio",
      message: "",
      currentStudio: {
        name: "",
        date: "",
        site: "",
        functions: [],
        games: [{ name: "", link: "", platforms: [] }],
      },
      studios: [],
      imageUserProfile: null,
      load: true,
      validate: false,
    };
  }

  componentDidMount() {
    if (!this.props.register) this.load();
  }

  async load() {
    this.setState({
      load: true,
    });
    let profile = await axios.get("/api/v1/profile", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    this.setState({
      imageUserProfile: profile.data.imageUserProfile
        ? { url: profile.data.imageUserProfile }
        : null,
      name: profile.data.name,
      email: profile.data.email,
      phoneCode: Country.getCountryByCode(
        profile.data.country ? profile.data.country : "BR"
      ).phonecode,
      phoneWhatsapp: profile.data.phoneWhatsapp
        ? profile.data.phoneWhatsapp
        : "",
      country: profile.data.country ? profile.data.country : "BR",
      stateList: State.getStatesOfCountry(
        profile.data.country ? profile.data.country : "BR"
      ),
      state: profile.data.state
        ? State.getStateByCode(profile.data.state).isoCode
        : "SP",
      city: profile.data.city ? profile.data.city : "",
      discordId: profile.data.discordId ? profile.data.discordId : null,
      userid: profile.data.id,
    });

    await this.loadCurrentStudio(profile.data);

    await this.loadStudios(profile.data);
  }

  async loadCurrentStudio(profile) {
    let currentCompany = await axios.get(
      `/api/v1/company/${profile.current_company_id}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    currentCompany = currentCompany.data;

    let gamesListbyCompany = await axios.get(
      `/api/v1/games/${currentCompany.id}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );

    let companyRelationship = await axios.get(
      `/api/v1/company-relationship/${currentCompany.id}/${profile.id}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    if (companyRelationship.data.length !== 0) {
      currentCompany.date = moment(
        companyRelationship.data[0].startdate
      ).format("YYYY-MM-DDTHH:MM");
      currentCompany.functions = companyRelationship.data[0].functions;
    }

    currentCompany.games = gamesListbyCompany.data;

    if (gamesListbyCompany.data.length !== 0) {
      gamesListbyCompany.data.forEach(async (game, index) => {
        currentCompany.games[index].link = "";
        currentCompany.games[index].platforms = [];
        let gamesRelationship = await axios.get(
          `/api/v1/game-relationship/${game.id}/${profile.id}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          }
        );
        if (gamesRelationship.data.length !== 0) {
          currentCompany.games[
            index
          ].link = `${gamesRelationship.data[0].link}`;
          currentCompany.games[index].platforms =
            gamesRelationship.data[0].platforms;
        }

        if (currentCompany.name) {
          this.setState({ currentStudio: currentCompany });
        }
      });
    }
  }

  async loadStudios(profile) {
    if (profile.company_id) {
      profile.company_id.forEach(async (company_id, index) => {
        let currentCompany = await axios.get(`/api/v1/company/${company_id}`, {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        });
        currentCompany = currentCompany.data;

        let gamesListbyCompany = await axios.get(
          `/api/v1/games/${currentCompany.id}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }
        );

        let companyRelationship = await axios.get(
          `/api/v1/company-relationship/${currentCompany.id}/${profile.id}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }
        );

        if (companyRelationship.data.length !== 0) {
          currentCompany.dateStart = moment(
            companyRelationship.data[0].startdate
          ).format("YYYY-MM-DDTHH:MM");
          currentCompany.dateEnd = moment(
            companyRelationship.data[0].enddate
          ).format("YYYY-MM-DDTHH:MM");
          currentCompany.functions = companyRelationship.data[0].functions;
        }

        // if (companyRelationship.data.length !== 0) {

        //   currentCompany.dateStart =
        //     companyRelationship.data[0].startdate.split("T")[0];
        //   currentCompany.dateEnd =
        //     companyRelationship.data[0].enddate.split("T")[0];
        //   currentCompany.functions = companyRelationship.data[0].functions;
        // }

        currentCompany.games = gamesListbyCompany.data;

        if (gamesListbyCompany.data.length !== 0) {
          gamesListbyCompany.data.forEach(async (game, index) => {
            currentCompany.games[index].link = "";
            currentCompany.games[index].platforms = [];
            let gamesRelationship = await axios.get(
              `/api/v1/game-relationship/${game.id}/${profile.id}`,
              {
                headers: {
                  "x-access-token": localStorage.getItem("token"),
                },
              }
            );
            console.log("studios games", gamesRelationship);
            if (gamesRelationship.data.length !== 0) {
              currentCompany.games[index].link = gamesRelationship.data[0].link;
              currentCompany.games[index].platforms =
                gamesRelationship.data[0].platforms;
            }

            this.setState({
              studios: [...this.state.studios, currentCompany],
            });
          });
        }

        // this.setState({
        //   studios: [...this.state.studios, currentCompany],
        // });
      });
    }
  }

  renderFormsEstudio = () => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        {this.state.categoriaUser !== "dev" && (
          <>
            <AutoCompleteStudios
              onSet={this.onChangeCurrentFieldAutoComplete}
              v={"name"}
              register={true}
              categoriaUser={this.state.categoriaUser}
              initialValue={this.state.currentStudio.name}
              isError={
                this.state.currentStudio.name == "" && this.state.validate
                  ? true
                  : false
              }
            />
            <div
              className={`${
                this.props.register
                  ? `form-group col-12 ${
                      this.state.currentStudio.date == "" && this.state.validate
                        ? "input-error"
                        : ""
                    }`
                  : `form-group col-md-6 ${
                      this.state.currentStudio.date == "" && this.state.validate
                        ? "input-error"
                        : ""
                    }`
              }`}
            >
              <label>
                <FormattedMessage id="component.my-account-form.input-label-date" />
              </label>
              {/* <input
                type="date"
                className="form-control form-control-sm"
                name="date"
                value={this.state.currentStudio.date}
                onChange={this.onChangeCurrentField}
              /> */}
              <DatePickerCustom
                onSet={(value, name) => {
                  this.setState({
                    currentStudio: {
                      ...this.state.currentStudio,
                      [name]: value,
                    },
                  });
                }}
                valueLoad={() => this.state.currentStudio.date}
                name={"date"}
                // index={index}
              />
              {/* <DatePicker
                selected={this.state.currentStudio.date}
                onChange={(d) => {
                  this.setState({
                    currentStudio: {
                      ...this.state.currentStudio,
                      date: d,
                    },
                  });
                }}
                dateFormat="dd/MMMM/yyyy"
                name="date"
                className="form-control form-control-sm"
                placeholderText={"dd/MM/yyyy"}
              /> */}
            </div>
            <div
              className={`${
                this.props.register
                  ? `form-group col-12 ${
                      !this.validURL(this.state.currentStudio.site) &&
                      this.state.validate
                        ? "input-error"
                        : ""
                    }`
                  : `form-group col-md-12 ${
                      !this.validURL(this.state.currentStudio.site) &&
                      this.state.validate
                        ? "input-error"
                        : ""
                    }`
              }`}
            >
              <label>
                <FormattedMessage id="component.my-account-form.input-label-site" />
              </label>
              <input
                type="url"
                className={`form-control form-control-sm`}
                placeholder={formatMessage({
                  id: "component.my-account-form.input-placeholder-site",
                })}
                name="site"
                value={this.state.currentStudio.site}
                onChange={this.onChangeCurrentField}
              />
            </div>
            <div
              className={`${
                this.props.register
                  ? `form-group col-12 ${
                      this.state.currentStudio?.functions?.length == 0 &&
                      this.state.validate
                        ? "input-error"
                        : ""
                    }`
                  : `form-group col-md-12 mb-4 ${
                      this.state.currentStudio?.functions?.length == 0 &&
                      this.state.validate
                        ? "input-error"
                        : ""
                    }`
              }`}
            >
              <label>
                <FormattedMessage id="component.my-account-form.input-label-current-jobs" />
              </label>
              <MultiSelectorFunctions
                onSet={this.onChangeCurrentStudioFunctionsMulti}
                initialValue={this.state.currentStudio.functions}
              />
            </div>
          </>
        )}
        <div className="form-group mt-2 bg-jogos">
          <p>
            <strong>
              <FormattedMessage id="component.my-account-form.title-games-release" />
            </strong>
          </p>
          {this.state.currentStudio.games.map((game, index) => {
            return (
              <div className="row mb-4" key={index}>
                <AutoCompleteGames
                  onSet={this.onChangeCurrentGameFieldAutoComplete}
                  name={"name"}
                  index={index}
                  initialValue={game.name}
                  isError={
                    !this.isValidateCurrentGames() &&
                    this.state.validate &&
                    game.name === ""
                      ? true
                      : false
                  }
                />
                <div
                  className={`col-6 mb-3 ${
                    !this.isValidateCurrentGames() &&
                    !this.validURL(game.link) &&
                    this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>
                    <FormattedMessage id="component.my-account-form.input-label-link-video" />
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={formatMessage({
                      id: "component.my-account-form.input-placeholder-link-video",
                    })}
                    name={"link"}
                    value={this.state.currentStudio.games[index].link}
                    data-idGame={index}
                    onChange={this.onChangeCurrentGameField}
                  />
                </div>
                <div
                  className={`col-12 mb-3 ${
                    !this.isValidateCurrentGames() &&
                    game.platforms.length == 0 &&
                    this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>
                    <FormattedMessage id="component.my-account-form.input-label-platform-game" />
                  </label>
                  {/* <MultiSelectorFunctions
                    onSet={this.onChangeCurrentStudioFunctionsMulti}
                  /> */}
                  <select
                    className="form-control form-control-sm"
                    multiple
                    style={{ height: "auto" }}
                    size="5"
                    data-index={index}
                    onChange={this.onChangeCurrentStudioGamesPlatforms}
                  >
                    <option value="" selected disabled hidden>
                      {formatMessage({
                        id: "component.my-account-form.input-select-platform-game",
                      })}
                    </option>
                    <option
                      value="Android"
                      hidden={game.platforms.includes("Android")}
                    >
                      {formatMessage({ id: "android" })}
                    </option>
                    <option
                      value="Browser"
                      hidden={game.platforms.includes("Browser")}
                    >
                      {formatMessage({ id: "browser" })}
                    </option>
                    <option value="iOS" hidden={game.platforms.includes("iOS")}>
                      {formatMessage({ id: "ios" })}
                    </option>
                    <option
                      value="Nintendo Switch"
                      hidden={game.platforms.includes("Nintendo Switch")}
                    >
                      {formatMessage({ id: "nintendo.switch" })}
                    </option>
                    <option
                      value="PC (computador pessoal)"
                      hidden={game.platforms.includes("C (computador pessoal)")}
                    >
                      {formatMessage({ id: "pc.computador.pessoal" })}
                    </option>
                    <option
                      value="Playstation"
                      hidden={game.platforms.includes("Playstation")}
                    >
                      {formatMessage({ id: "playstation" })}
                    </option>
                    <option
                      value="Steam"
                      hidden={game.platforms.includes("Steam")}
                    >
                      {formatMessage({ id: "steam" })}
                    </option>
                    <option
                      value="Xbox"
                      hidden={game.platforms.includes("Xbox")}
                    >
                      {formatMessage({ id: "xbox" })}
                    </option>
                  </select>
                </div>
                <div
                  className="col-12 mb-3 selected-functions"
                  style={{ marginTop: "0px" }}
                >
                  {Array.isArray(game.platforms) &&
                    game.platforms.map((fc, key) => {
                      return (
                        <a
                          href={fc}
                          onClick={this.removeCurrentSudioGamesPlatforms}
                          className="functions"
                          data-fc={fc}
                          data-index={index}
                          key={key}
                        >
                          {fc}
                        </a>
                      );
                    })}
                </div>
                <div className="col-12 mb-0">
                  {this.state.currentStudio.games.length !== 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => this.addOrRemoveCurrentGame(index, false)}
                      style={{ marginRight: 10 }}
                    >
                      <FormattedMessage id="component.my-account-form.btn-remove-game" />
                    </button>
                  )}
                  {this.state.currentStudio.games.length - 1 === index && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => this.addOrRemoveCurrentGame(index, true)}
                    >
                      <FormattedMessage id="component.my-account-form.btn-add-game" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {this.state.studios.length === 0 &&
          this.state.categoriaUser !== "dev" && (
            <>
              <div className={`div internal col-md-12`}>
                <hr></hr>
              </div>
              <div className="form-group col-12 mb-4">
                <h3 className="mb-2">
                  <FormattedMessage id="component.my-account-form.title-previous-experience" />
                </h3>
                <span>
                  <FormattedMessage id="component.my-account-form.title-previous-experience-description" />
                </span>
              </div>
              <div className="form-group mt-4">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.addOrRemoveStudio(true)}
                >
                  <FormattedMessage id="component.my-account-form.add" />{" "}
                  {this.state.categoriaUser === "estudio"
                    ? formatMessage({ id: "component.my-account-form.studio" })
                    : formatMessage({
                        id: "component.my-account-form.publisher",
                      })}
                </button>
              </div>
            </>
          )}

        {this.state.categoriaUser !== "dev" &&
          this.state.studios.map((studio, index) => {
            return (
              <>
                {index === 0 ? (
                  <>
                    <div className={`div internal col-md-12`}>
                      <hr></hr>
                    </div>
                    <div className="form-group col-12 mb-4">
                      <h3 className="mb-2">
                        <FormattedMessage id="component.my-account-form.title-previous-experience" />
                      </h3>
                      <span>
                        <FormattedMessage id="component.my-account-form.title-previous-experience-description" />
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group col-12 mb-4">
                      <h3 className="mb-2">
                        {this.state.categoriaUser === "estudio"
                          ? "Outro estúdio"
                          : "Outra publisher"}
                      </h3>
                      <span>
                        <FormattedMessage id="component.my-account-form.title-previous-experience-description-2" />
                      </span>
                    </div>
                  </>
                )}
                <AutoCompleteStudios
                  onSet={this.onChangeStudioFieldAutoComplete}
                  v={"name"}
                  index={index}
                  register={true}
                  anterior={true}
                  categoriaUser={this.state.categoriaUser}
                  initialValue={this.state.studios[index].name}
                  isError={
                    this.state.studios[index].name == "" && this.state.validate
                      ? true
                      : false
                  }
                />
                <div
                  className={`form-group col-12 ${
                    !this.validURL(this.state.studios[index].site) &&
                    this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>
                    <FormattedMessage id="component.my-account-form.input-label-site" />
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={formatMessage({
                      id: "component.my-account-form.input-placeholder-site",
                    })}
                    name="site"
                    value={this.state.studios[index].site}
                    onChange={this.onChangeStudioField}
                    data-indexStudio={index}
                  />
                </div>
                <div
                  className={`form-group col-md-12 mb-4 ${
                    this.state.studios[index].functions.length == 0 &&
                    this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>
                    <FormattedMessage id="component.my-account-form.input-label-prev-jobs" />
                  </label>
                  <select
                    className="form-control form-control-sm"
                    style={{ height: "auto" }}
                    multiple
                    size="5"
                    data-index={index}
                    onChange={this.onChangeStudiosFunctions}
                  >
                    <option value="" selected disabled hidden>
                      <FormattedMessage id="component.my-account-form.select-option-prev-jobs" />
                    </option>
                    {this.state.functions.map((fc, key) => {
                      return (
                        <option
                          value={fc}
                          hidden={studio.functions.includes(fc)}
                          key={key}
                        >
                          {fc}
                        </option>
                      );
                    })}
                  </select>
                  <div className="selected-functions">
                    {studio.functions.map((fc, key) => {
                      return (
                        <a
                          href={fc}
                          onClick={this.removeSudiosFunctions}
                          className="functions"
                          data-fc={fc}
                          data-index={index}
                          key={key}
                        >
                          {fc}
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className={`form-group col-md-12 mb-4`}>
                  <label>
                    <FormattedMessage id="component.my-account-form.input-year-job" />
                  </label>
                  <div className="row">
                    <div
                      className={`col-6 ${
                        !this.validateDate(
                          this.state.studios[index].dateStart,
                          this.state.studios[index].dateEnd
                        ) && this.state.validate
                          ? "input-error"
                          : ""
                      }`}
                    >
                      <small>
                        <FormattedMessage id="component.my-account-form.input-year-job-de" />
                      </small>
                      <DatePickerCustom
                        onSet={(value, name, index) => {
                          var state = this.state.studios;
                          state[index][name] = value;

                          this.setState({
                            studios: state,
                          });
                        }}
                        multiple={true}
                        name={"dateStart"}
                        index={index}
                        valueLoad={() => this.state.studios[index].dateStart}
                      />
                    </div>
                    <div
                      className={`col-6 ${
                        !this.validateDate(
                          this.state.studios[index].dateStart,
                          this.state.studios[index].dateEnd
                        ) && this.state.validate
                          ? "input-error"
                          : ""
                      }`}
                    >
                      <small>
                        <FormattedMessage id="component.my-account-form.input-year-job-ate" />
                      </small>
                      <DatePickerCustom
                        onSet={(value, name, index) => {
                          var state = this.state.studios;
                          state[index][name] = value;

                          this.setState({
                            studios: state,
                          });
                        }}
                        multiple={true}
                        name={"dateEnd"}
                        index={index}
                        valueLoad={() => this.state.studios[index].dateEnd}
                        // valueLoad={this.state.studios[index].dateEnd}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4 bg-jogos">
                  <p>
                    <strong>
                      <FormattedMessage id="component.my-account-form.title-games-release" />
                    </strong>
                  </p>
                  {studio.games.map((game, indexGame) => {
                    return (
                      <div key={indexGame}>
                        <div className="row mb-4">
                          <AutoCompleteGames
                            onSet={this.onChangeStudioGameFieldAutoComplete}
                            name={"name"}
                            indexStudio={index}
                            idGame={indexGame}
                            multiple={true}
                            initialValue={game.name}
                            isError={
                              !this.isValidateStudioGames(game) &&
                              this.state.validate &&
                              game.name === ""
                                ? true
                                : false
                            }
                          />
                          <div
                            className={`col-6 mb-3 ${
                              !this.isValidateStudioGames(game) &&
                              !this.validURL(game.link) &&
                              this.state.validate
                                ? "input-error"
                                : ""
                            }`}
                          >
                            <label>
                              <FormattedMessage id="component.my-account-form.input-label-link-video" />
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder={formatMessage({
                                id: "component.my-account-form.input-placeholder-link-video",
                              })}
                              name="link"
                              value={
                                this.state.studios[index].games[indexGame].link
                              }
                              onChange={this.onChangeStudioGameField}
                              data-indexStudio={index}
                              data-idGame={indexGame}
                            />
                          </div>
                          <div
                            className={`col-12 mb-3 ${
                              !this.isValidateStudioGames(game) &&
                              game.platforms.length == 0 &&
                              this.state.validate
                                ? "input-error"
                                : ""
                            }`}
                          >
                            <label>
                              <FormattedMessage id="component.my-account-form.input-label-platform-game" />
                            </label>
                            <select
                              className="form-control form-control-sm"
                              multiple
                              style={{ height: "auto" }}
                              size="5"
                              data-index={index}
                              data-indexGame={indexGame}
                              onChange={this.onChangeStudioGamesPlatforms}
                            >
                              <option value="" selected disabled hidden>
                                <FormattedMessage id="component.my-account-form.input-select-platform-game" />
                              </option>
                              <option
                                value="Android"
                                hidden={game.platforms.includes("Android")}
                              >
                                {formatMessage({ id: "android" })}
                              </option>
                              <option
                                value="Browser"
                                hidden={game.platforms.includes("Browser")}
                              >
                                {formatMessage({ id: "browser" })}
                              </option>
                              <option
                                value="iOS"
                                hidden={game.platforms.includes("iOS")}
                              >
                                {formatMessage({ id: "ios" })}
                              </option>
                              <option
                                value="Nintendo Switch"
                                hidden={game.platforms.includes(
                                  "Nintendo Switch"
                                )}
                              >
                                {formatMessage({ id: "nintendo.switch" })}
                              </option>
                              <option
                                value="C (computador pessoal)"
                                hidden={game.platforms.includes(
                                  "C (computador pessoal)"
                                )}
                              >
                                {formatMessage({ id: "pc.computador.pessoal" })}
                              </option>
                              <option
                                value="Playstation"
                                hidden={game.platforms.includes("Playstation")}
                              >
                                {formatMessage({ id: "playstation" })}
                              </option>
                              <option
                                value="Steam"
                                hidden={game.platforms.includes("Steam")}
                              >
                                {formatMessage({ id: "steam" })}
                              </option>
                              <option
                                value="Xbox"
                                hidden={game.platforms.includes("Xbox")}
                              >
                                {formatMessage({ id: "xbox" })}
                              </option>
                            </select>
                          </div>
                          <div
                            className="col-12 mb-3 selected-functions"
                            style={{ marginTop: "0px" }}
                          >
                            {game.platforms.map((fc, key) => {
                              return (
                                <a
                                  href={fc}
                                  onClick={this.removeSudioGamesPlatforms}
                                  className="functions"
                                  data-fc={fc}
                                  data-index={index}
                                  data-indexGame={indexGame}
                                  key={key}
                                >
                                  {fc}
                                </a>
                              );
                            })}
                          </div>
                          <div className="col-12 mb-0">
                            {studio.games.length !== 1 && (
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() =>
                                  this.addOrRemoveStudioGames(
                                    indexGame,
                                    index,
                                    false
                                  )
                                }
                                style={{ marginRight: 10 }}
                              >
                                <FormattedMessage id="component.my-account-form.btn-remove-game" />
                              </button>
                            )}
                            {studio.games.length - 1 === indexGame && (
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() =>
                                  this.addOrRemoveStudioGames(
                                    indexGame,
                                    index,
                                    true
                                  )
                                }
                              >
                                <FormattedMessage id="component.my-account-form.btn-add-game" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="form-group mt-4"
                  style={{ display: "flex", gap: "10px" }}
                >
                  {/* Remover */}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => this.addOrRemoveStudio(false, index)}
                  >
                    {`${formatMessage({
                      id: "component.my-account-form.remove",
                    })} ${
                      this.state.categoriaUser === "estudio"
                        ? formatMessage({
                            id: "component.my-account-form.studio",
                          })
                        : formatMessage({
                            id: "component.my-account-form.publisher",
                          })
                    }`}
                  </button>
                  {this.state.studios.length - 1 === index && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => this.addOrRemoveStudio(true)}
                    >
                      {`${formatMessage({
                        id: "component.my-account-form.add",
                      })} ${
                        this.state.categoriaUser === "estudio"
                          ? formatMessage({
                              id: "component.my-account-form.studio",
                            })
                          : formatMessage({
                              id: "component.my-account-form.publisher",
                            })
                      }`}
                    </button>
                  )}
                </div>
                {index !== this.state.studios.length - 1 && (
                  <div className={`div internal col-md-12`}>
                    <hr></hr>
                  </div>
                )}
              </>
            );
          })}
      </>
    );
  };

  onChangeFieldCountry = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      phoneCode: e.target.selectedOptions[0].getAttribute("data-phoneCode"),
      state: State.getStatesOfCountry(e.target.value)[0].isoCode,
      stateList: State.getStatesOfCountry(e.target.value),
    });
  };

  onAuthDiscord = async (dados) => {
    try {
      dados.userid = this.state.userid;
      const response = await axios.post(
        "/api/v1/account/login/discord/conect",
        dados
      );
      this.setState({
        discordId: dados.id,
      });
    } catch (error) {
      alert("Usuario já existe");
    }
  };

  handleMessage = async (event) => {
    let dados = event.data.split("&");
    const accessToken = dados[1].split("=")[1];

    const response = await axios.get(
      `https://discordapp.com/api/v6/users/@me`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    this.onAuthDiscord(response.data);
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="basic-form">
        <form
          className={`form-custom ${this.props.register ? "m-t-30" : ""}`}
          onSubmit={this.props.register ? this.onRegister : this.onRegisterEdit}
        >
          <div className="form-row">
            <div
              className={`form-group col-12 ${
                !this.state.imageUserProfile && this.state.validate
                  ? "input-error"
                  : ""
              }`}
            >
              {this.state.imageUserProfile && (
                <div className="thumb-user">
                  <figure>
                    <img src={this.state.imageUserProfile.url} alt="User" />
                  </figure>
                </div>
              )}
              <label>
                <FormattedMessage id="component.my-account-form.input-label-image" />
              </label>
              <input
                type="file"
                className="form-control form-control-sm"
                onChange={(e) => this.onChangeFile(e)}
                accept="image/png, image/jpeg"
                placeholder={formatMessage({
                  id: "component.my-account-form.input-placeholder-image",
                })}
              />
            </div>

            {!this.props.register && (
              <div className="form-group col-12">
                <label>
                  <FormattedMessage id="component.my-account-form.input-label-permission" />
                </label>
                <input
                  type="text"
                  name="permissao"
                  className="form-control form-control-sm"
                  placeholder={formatMessage({
                    id: "component.my-account-form.input-placeholder-permission",
                  })}
                  value={"Estúdio"}
                  disabled
                />
              </div>
            )}

            <FormattedMessage id="component.my-account-form.input-label-name">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-md-6"
                  } ${
                    this.state.name == "" && this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>{message}</label>
                  <input
                    type="name"
                    name="name"
                    className="form-control form-control-sm"
                    placeholder={formatMessage({
                      id: "component.my-account-form.input-placeholder-name",
                    })}
                    value={this.state.name}
                    onChange={this.onChangeField}
                  />
                </div>
              )}
            </FormattedMessage>
            <FormattedMessage id="component.my-account-form.input-label-email">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-md-6"
                  } ${
                    this.state.email == "" && this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>{message}</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-control-sm"
                    placeholder={formatMessage({
                      id: "component.my-account-form.input-placeholder-email",
                    })}
                    value={this.state.email}
                    onChange={this.onChangeField}
                  />
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage id="component.my-account-form.input-label-password">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-6"
                  } ${
                    this.state.password == "" && this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>{message}</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-sm"
                    value={this.state.password}
                    placeholder={formatMessage({
                      id: "component.my-account-form.input-placeholder-password",
                    })}
                    onChange={this.onChangeField}
                  />
                </div>
              )}
            </FormattedMessage>

            <div
              className={`form-group ${
                this.props.register ? "col-12" : "col-6"
              } ${
                this.state.password != this.state.passwordConfirm &&
                this.state.validate
                  ? "input-error"
                  : ""
              }`}
            >
              <label>
                <FormattedMessage id="component.my-account-form.input-label-password-confirm" />
              </label>
              <input
                type="password"
                name="passwordConfirm"
                placeholder={formatMessage({
                  id: "component.my-account-form.input-placeholder-password-confirm",
                })}
                className="form-control form-control-sm"
                value={this.state.passwordConfirm}
                onChange={this.onChangeField}
              />
            </div>

            <FormattedMessage id="component.my-account-form.input-label-phone-whatsapp">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-6"
                  } ${
                    this.state.phoneWhatsapp == "" && this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>{message}</label>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      name="phoneCode"
                      className="form-control form-control-sm"
                      value={`${this.state.phoneCode}`}
                      onChange={this.onChangeField}
                      disabled
                      style={{
                        width: "auto",
                        textAlign: "center",
                        background: "#fbfbfb",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      size={this.state.phoneCode.length}
                    />
                    <input
                      type="text"
                      name="phoneWhatsapp"
                      className="form-control form-control-sm"
                      placeholder={formatMessage({
                        id: "component.my-account-form.input-placeholder-phone-whatsapp",
                      })}
                      value={this.state.phoneWhatsapp}
                      onChange={this.onChangeField}
                    />
                  </div>
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage id="component.my-account-form.input-label-country">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-6"
                  }`}
                >
                  <label>{message}</label>
                  <select
                    className="form-control form-control-sm"
                    name="country"
                    onChange={this.onChangeFieldCountry}
                    value={this.state.country}
                  >
                    {this.state.countryList.map((pais, key) => {
                      return (
                        <option
                          value={pais.isoCode}
                          selected={this.state.country === pais.isoCode}
                          data-phoneCode={pais.phonecode}
                          key={key}
                        >
                          {pais.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage id="component.my-account-form.input-label-state">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-6"
                  }`}
                >
                  <label>{message}</label>
                  <select
                    className="form-control form-control-sm"
                    name="state"
                    onChange={this.onChangeField}
                    value={this.state.state}
                  >
                    {this.state.stateList.map((estado, key) => {
                      return (
                        <option
                          value={estado.isoCode}
                          selected={this.state.state === estado.isoCode}
                          key={key}
                        >
                          {estado.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </FormattedMessage>

            <FormattedMessage id="component.my-account-form.input-label-city">
              {(message) => (
                <div
                  className={`form-group ${
                    this.props.register ? "col-12" : "col-6"
                  } ${
                    this.state.city == "" && this.state.validate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label>{message}</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control form-control-sm"
                    placeholder={formatMessage({
                      id: "component.my-account-form.input-placeholder-city",
                    })}
                    value={this.state.city}
                    onChange={this.onChangeField}
                  />
                </div>
              )}
            </FormattedMessage>

            {!this.props.register &&
              (!this.state.discordId ? (
                <div className="form-group col-md-12 mb-0">
                  <label>Discord</label>
                  <div className="login-discord text-center">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `https://discord.com/api/oauth2/authorize?response_type=token&client_id=${Config.discord.clientId}&scope=identify email&redirect_uri=${window.location.origin}/discord/callback`,
                          "discordLogin",
                          "height=700,width=500"
                        );
                        window.addEventListener(
                          "message",
                          this.handleMessage,
                          false
                        );
                      }}
                      className="btn btn-primary m-b-15"
                    >
                      <img src={IconDiscord} alt="Discord" />
                      <p>
                        <FormattedMessage id="component.my-account-form.input-label-discord-connect" />
                      </p>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-group col-md-12 mb-0">
                  <label>Discord</label>
                  <div className="login-discord text-center">
                    <button
                      className="btn btn-primary m-b-15"
                      onClick={async (e) => {
                        e.preventDefault();
                        try {
                          const dados = new FormData();
                          dados.append("discordId", this.state.discordId);
                          await axios.post(
                            "/api/v1/account/login/discord/disconnect",
                            dados,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          this.setState({
                            discordId: null,
                          });
                        } catch (error) {
                          alert("Erro ao tentar desconectar seu discord");
                        }
                      }}
                    >
                      <img src={IconDiscord} alt="Discord" />
                      <p>
                        <FormattedMessage id="component.my-account-form.input-label-discord-connected" />
                      </p>
                    </button>
                  </div>
                </div>
              ))}

            <div className={`div internal col-md-12`}>
              <hr></hr>
            </div>

            <div
              className={`${
                this.props.register
                  ? "form-group mt-4"
                  : "form-group col-12 mt-0 mb-0"
              }`}
            >
              <p>
                <strong>
                  <FormattedMessage id="component.my-account-form.input-label-category-user" />
                </strong>
              </p>
              <div className="form-custom">
                <div
                  className={`${
                    this.props.register
                      ? "content-radio flex-column align-items-start"
                      : "content-radio"
                  }`}
                >
                  <div className="radio">
                    <input
                      type="radio"
                      className="form-control form-control-sm"
                      name="categoriaUser"
                      value="estudio"
                      onChange={this.onChangeField}
                      checked={this.state.categoriaUser === "estudio"}
                    />
                    <FormattedMessage id="component.my-account-form.input-label-category-user-studio" />
                  </div>
                  <div className="radio">
                    <input
                      type="radio"
                      className="form-control form-control-sm"
                      name="categoriaUser"
                      value="publisher"
                      onChange={this.onChangeField}
                      checked={this.state.categoriaUser === "publisher"}
                    />
                    <FormattedMessage id="component.my-account-form.input-label-category-user-publisher" />
                  </div>
                  <div className="radio">
                    <input
                      type="radio"
                      className="form-control form-control-sm"
                      name="categoriaUser"
                      value="dev"
                      onChange={this.onChangeField}
                      checked={this.state.categoriaUser === "dev"}
                    />
                    <FormattedMessage id="component.my-account-form.input-label-category-user-self-employed" />
                  </div>
                </div>
              </div>
            </div>

            <div className={`div internal col-md-12`}>
              <hr></hr>
            </div>

            <div
              className={`${
                this.props.register
                  ? "form-group mb-4"
                  : "form-group col-12 mb-4"
              }`}
            >
              <h3 className="mb-2">
                <FormattedMessage id="component.my-account-form.subtitle-current-experience" />
              </h3>
              <span>
                <strong>
                  <FormattedMessage id="component.my-account-form.description-current-experience-attention" />
                </strong>{" "}
                <FormattedMessage id="component.my-account-form.description-current-experience" />
              </span>
            </div>

            {this.renderFormsEstudio()}

            <div className="div internal col-md-12">
              <hr></hr>
            </div>
          </div>
          {this.props.register && (
            <div className="form-custom">
              <div
                className={`${
                  this.props.register
                    ? `content-radio flex-column align-items-start ${
                        this.state.terms == false && this.state.validate
                          ? "input-error"
                          : ""
                      }`
                    : "content-radio"
                }`}
              >
                <div className="radio">
                  <input
                    id="check-terms"
                    type="checkbox"
                    className="form-control form-control-sm"
                    name="terms"
                    value={this.state.terms}
                    onChange={this.onChangeField}
                  />
                  <label for="check-terms">
                  <FormattedMessage id="component.my-account-form.terms-1" />
                  <FormattedMessage id="link.terms">
                    {(link) => (
                      <a
                      href={link}
                      target={"_blank"}
                      rel="noreferrer">
                      &nbsp;
                      <FormattedMessage id="component.my-account-form.terms-2" />
                      &nbsp;
                  </a>
                  )}
                  </FormattedMessage>
                  <FormattedMessage id="component.my-account-form.terms-3" />
                  <FormattedMessage id="link.privacy">
                    {(link) => (
                      <a
                      href={link}
                      target={"_blank"}
                      rel="noreferrer">
                      &nbsp;
                      <FormattedMessage id="component.my-account-form.terms-4" />
                      &nbsp;
                  </a>
                  )}
                  </FormattedMessage>
                  <FormattedMessage id="component.my-account-form.terms-5" />
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="form-row">
            <div className="form-group content-btn col-12 d-flex justify-content-end">
              {!this.props.register && (
                <Link
                  to={"/"}
                  className="btn btn-outline-primary"
                  style={{ height: "40px" }}
                >
                  <FormattedMessage id="component.my-account-form.btn-cancel" />
                </Link>
              )}

              <button type="submit" className="btn btn-primary">
                {this.props.register
                  ? formatMessage({
                      id: "component.my-account-form.btn-register",
                    })
                  : formatMessage({
                      id: "component.my-account-form.btn-save",
                    })}
              </button>
            </div>

            {this.state.message && this.props.register && (
              <div className="form-group col-12 alert">
                {/* <div className="alert alert-success">Dados salvos com sucesso!</div> */}
                <div className="alert alert-danger">
                  <FormattedMessage id={this.state.message} />
                </div>
              </div>
            )}
            {this.state.message && !this.props.register && (
              <div className="form-group col-12 alert">
                {/* <div className="alert alert-success">Dados salvos com sucesso!</div> */}
                <div className="alert alert-success">{this.state.message}</div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default injectIntl(MyAccountForm);
