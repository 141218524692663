import React, { useState, useEffect } from "react";
import withAuth from "../../components/auth";
import { injectIntl } from "react-intl";
import jwt_decode from "jwt-decode";

function JuryEvaluation() {
    const user = jwt_decode(localStorage.getItem("token"));
  
    return (
      <div className="content-body">
        <div className="container-fluid">
          <final-evaluation-page />
        </div>
      </div>
    )
  }


export default injectIntl(withAuth(JuryEvaluation));