import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import ModalFinalistWinners from "./modalFinalistWinners";
import ModalAvaliar from "./modalAvaliar";
import jwt_decode from "jwt-decode";
import NoImageChamada from "../../assets/images/v2/jogos/img-thumb-jogo-boom.jpg";

function CallsInscriptionsEventFinalist() {
  const [eventos, setEventos] = useState([]);
  const [eventosVisible, setEventosVisible] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [categoria, setCategoria] = useState(-1);
  const [finalist, setFinalist] = useState(false);
  const [remove, setRemove] = useState(false);
  const [inscription, setInscription] = useState(null);
  const [users, setUsers] = useState([]);
  const user = jwt_decode(localStorage.getItem("token"));
  const intl = useIntl();

  let { id } = useParams();

  useEffect(() => {
    loadChamadas();
  }, []);

  useEffect(() => {
    var filtered = eventos;
    if (searchUserName != "") {
      filtered = filtered.filter((item) =>
        item.inscription.name
          .toLowerCase()
          .includes(searchUserName.toLowerCase())
      );
    }

    if (categoria != -1) {
      filtered = filtered.filter((item) => {
        if (item.inscription.finalist != null) {
          if (item.inscription.finalist.includes(categoria)) {
            console.log("aqui", item.inscription.finalist.includes(categoria));
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }

    setEventosVisible(filtered);
  }, [searchUserName, categoria]);

  function loadChamadas() {
    axios
      .get(`/api/v1/rating/rated/games/${id}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        let items = [];
        if (res.data.length != 0) {
          res.data.forEach((item) => {
            if (user.type == 10 || user.type == 2) {
              items.push(item);
            } else {
              if (item.numberOfAssessmentsMade >= item.assessmentsNeeded) {
                items.push(item);
              }
            }
          });
        }
        console.log("events", items);
        setEventos(items);
        setEventosVisible(items);
      })
      .catch((error) => {
        console.log("error", error);
        // if (error.response.status === 401) {
        //   localStorage.removeItem("token");
        //   window.open("/", "_self");
        // }
      });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.breadcrumb.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/call/mark-finalist/"} title="Marcar Finalista">
                <FormattedMessage id="app.breadcrumb.mark-finalist" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              {eventos.length
                ? eventos[0].event.name
                : intl.formatMessage({ id: "loading" })}
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function renderFilter() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="calls.inscriptions.event.finalist.filter-label" />
                      </h2>
                    </div>
                    <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="calls.inscriptions.event.finalist.filter-name" />
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={searchUserName}
                        onChange={({ target }) =>
                          setSearchUserName(target.value)
                        }
                        placeholder={intl.formatMessage({
                          id: "calls.inscriptions.event.finalist.name.placeholder",
                        })}
                      />
                    </div>
                    <div className="form-group col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="calls.inscriptions.event.finalist.category" />
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={categoria}
                        onChange={({ target }) => setCategoria(target.value)}
                      >
                        <option value={-1}>Todas</option>
                        {eventos.length &&
                          eventos[0].event.categoria_avaliacao.length &&
                          eventos[0].event.categoria_avaliacao.map((c, i) => {
                            return (
                              <option key={i} value={c}>
                                {c}
                              </option>
                            );
                          })}
                        {eventos.length &&
                          eventos[0].event.categoria_extras.length &&
                          eventos[0].event.categoria_extras.map((c, i) => {
                            return (
                              <option key={i} value={c}>
                                {c}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function onSetInvalidGame(game) {
    const dados = new FormData();
    dados.append("id", game.id);

    let url = `/api/v1/inscription/set-invalid-game`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("res", res);
        loadChamadas();
      });
  }

  function onRmInvalidGame(game) {
    const dados = new FormData();
    dados.append("id", game.id);

    let url = `/api/v1/inscription/rm-invalid-game`;
    axios
      .post(url, dados, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("res", res);
        loadChamadas();
      });
  }

  function isShowFinalist(game) {
    let retorno = true;
    let totalCategory =
      eventos[0].event.categoria_avaliacao.length +
      eventos[0].event.categoria_extras.length;

    if (game.finalist) {
      retorno = !(totalCategory === game.finalist.length);
    }

    return retorno;
  }

  function renderList() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                <FormattedMessage id="calls.inscriptions.event.finalist.title" />
              </h4>

              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                      <label>
                        <FormattedMessage id="calls.inscriptions.event.finalist.filter-order" />
                      </label>
                      <select className="form-control form-control-sm" id="">
                        <option>A - Z</option>
                        <option>Z - A</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>

              <div className="event-list games">
                {eventosVisible.length ? (
                  eventosVisible.map((game, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                              <div className="image-event">
                                <img
                                  src={
                                    game.inscription.image
                                      ? game.inscription.image
                                      : NoImageChamada
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                              <div className="info">
                                <div className="row w-100">
                                  {game.inscription.award !== null &&
                                    game.inscription.award.length != 0 && (
                                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <h3>
                                          <FormattedMessage id="calls.inscriptions.event.finalist.award" />
                                        </h3>
                                        <p>
                                          {game.inscription.award.toString()}
                                        </p>
                                      </div>
                                    )}

                                  {game.inscription.finalist !== null &&
                                    game.inscription.finalist.length != 0 && (
                                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <h3>
                                          <FormattedMessage id="calls.inscriptions.event.finalist.label" />
                                        </h3>
                                        <p>
                                          {game.inscription.finalist.toString()}
                                        </p>
                                      </div>
                                    )}

                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <h3>
                                      <FormattedMessage id="calls.inscriptions.event.finalist.category.label" />
                                    </h3>
                                    <p>
                                      {game.event.categoria_avaliacao
                                        .concat(game.event.categoria_extras)
                                        .toString()}
                                    </p>
                                  </div>
                                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h3>{game.inscription.name}</h3>
                                    <p>{game.inscription.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="content-btn col-12 d-flex justify-content-end">
                                {game.numberOfAssessmentsMade >=
                                game.assessmentsNeeded ? (
                                  <>
                                    {game.inscription.is_valid ? (
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={(e) => {
                                          onRmInvalidGame(game.inscription);
                                        }}
                                      >
                                        <FormattedMessage id="calls.inscriptions.event.finalist.button.remove-title-invalid" />
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={(e) => {
                                          onSetInvalidGame(game.inscription);
                                        }}
                                      >
                                        <FormattedMessage id="calls.inscriptions.event.finalist.button.add-title-invalid" />
                                      </button>
                                    )}
                                    {game.inscription.finalist !== null &&
                                      game.inscription.finalist.length != 0 && (
                                        <button
                                          className="btn btn-outline-primary"
                                          onClick={(e) => {
                                            setFinalist(true);
                                            setRemove(true);
                                            setInscription(game.inscription);
                                            // eslint-disable-next-line no-undef
                                            $("#modalFinalist").modal("show");
                                          }}
                                        >
                                          <FormattedMessage id="calls.inscriptions.event.finalist.button.remove-title-finalist" />
                                        </button>
                                      )}

                                    {isShowFinalist(game.inscription) &&
                                      game.inscription.is_valid == false && (
                                        <button
                                          className="btn btn-primary"
                                          onClick={(e) => {
                                            setFinalist(true);
                                            setRemove(false);
                                            setInscription(game.inscription);
                                            // eslint-disable-next-line no-undef
                                            $("#modalFinalist").modal("show");
                                          }}
                                        >
                                          <FormattedMessage id="calls.inscriptions.event.finalist.button.add-title-finalist" />
                                        </button>
                                      )}
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      setUsers(game.usersWhoHaveYetToRate);
                                      // eslint-disable-next-line no-undef
                                      $("#modalAvaliar").modal("show");
                                    }}
                                  >
                                    <FormattedMessage id="calls.inscriptions.event.finalist.button.users-who-have-yet-to-rate" />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <h3>
                        <FormattedMessage id="calls.inscriptions.event.finalist.empty" />
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {renderBreadcrumb()}
        {renderFilter()}
        {renderList()}
        <ModalFinalistWinners
          finalist={finalist}
          events={eventos.length ? eventos[0].event.categoria_avaliacao : []}
          extras={eventos.length ? eventos[0].event.categoria_extras : []}
          inscription={inscription}
          remove={remove}
          reload={loadChamadas}
        />
        <ModalAvaliar users={users} />
      </div>
    </div>
  );
}

export default injectIntl(withAuth(CallsInscriptionsEventFinalist));
