import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import withAuth from "../../components/auth";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import NoImageChamada from "../../assets/images/v2/event/image-event-test.jpg";

function GamesPendingList({ intl }) {
  const history = useHistory();
  const [itens, setItens] = useState([]);
  const [itensVisible, setItensVisible] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [evento, setEvento] = useState(null);
  let { chamada } = useParams();
  const locale = intl.locale;

  useEffect(() => {
    loadInscriptions();
    loadEvent();
  }, []);

  useEffect(() => {
    var filtered = itens;
    if (searchUserName != "") {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(searchUserName.toLowerCase())
      );
    }
    setItensVisible(filtered);
  }, [searchUserName, itens]);

  function loadEvent() {
    let url = `/api/v1/event/inscriptions/${chamada}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        setEvento(res.data[0]);
        console.log("evento", res.data[0]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function loadInscriptions(params) {
    let url = `/api/v1/inscritions/games-pending/${chamada}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        // if (res.data.length !== 0) {
        console.log(res.data);
        setItens(res.data);
        setItensVisible(res.data);
        // } else {
        //   history.push("/inscription");
        // }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.open("/", "_self");
        }
      });
  }

  function traducaoSiglaLocale(locale1, locale2) {
    let l1 = locale1;
    let l2 = locale2;

    if (locale1.indexOf("-") >= 0) l1 = locale1.split("-")[0];

    if (locale2.indexOf("-") >= 0) l2 = locale2.split("-")[0];

    return l1 == l2;
  }

  const renderEvent = () => {
    console.log("evento", evento);
    let localeData = evento.translations.filter((e) =>
      traducaoSiglaLocale(e.locale, locale)
    );
    localeData = localeData.length > 0 ? localeData[0] : null;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="content-chamada">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <figure className="m-b-0">
                      <img
                        src={evento.image ? evento.image : NoImageChamada}
                        alt={evento.name}
                      />
                    </figure>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-12 text-chamada">
                        <h1 className="card-title">{evento.name}</h1>
                      </div>
                      <div
                        className="col-12 text-chamada"
                        dangerouslySetInnerHTML={{
                          __html: localeData?.description,
                        }}
                      />
                      <div className="col-12 text-chamada">
                        <h4>
                          <FormattedMessage id="inscriptions.game.limit" />
                        </h4>
                        <p>
                          {" "}
                          {moment(evento.limit_existence_game).format(
                            "DD/MMMM/YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function renderList() {
    return itensVisible.map((item, key) => {
      return (
        <div className="row" key={key}>
          <div className="col-12">
            <Link
              to={{
                pathname: `/games/detail/${item.id}`,
                state: {
                  game: item,
                },
              }}
              title="Detalhe Jogo"
            >
              <div className="row">
                <div className="col-xx-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12">
                  <div className="image-event">
                    <img src={item.image} alt="" />
                  </div>
                </div>
                <div className="col-xx-11 col-xl-11 col-lg-10 col-md-10 col-sm-9 col-12">
                  <div className="info">
                    <div className="row w-100">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.game" />
                        </h3>
                        <p>{item.name}</p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.edit.field.description" />
                        </h3>
                        <p>{item.description}</p>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                        <h3>
                          <FormattedMessage id="games-pending.list.date-create" />
                        </h3>
                        <p>{moment(item.created).format("DD/MMMM/YYYY")}</p>
                      </div>
                      <div className="col-xxl-2 col-xl-2 col-lg-2 col-12">
                        <h3>
                          <FormattedMessage id="inscriptions.list.status" />
                        </h3>
                        <p>
                          <i className="fa fa-circle-o text-warning f-s-12 m-r-10"></i>{" "}
                          {getLabelStatus(item.status)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    });
  }

  function renderBreadcrumb() {
    return (
      <div className="row page-titles">
        <div className="col p-0">
          <h4>
            <FormattedMessage id="app.welcome-1" />,{" "}
            <span>
              <FormattedMessage id="app.welcome-2" />
            </span>
          </h4>
        </div>
        <div className="col p-0">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} title="Dashboard">
                <FormattedMessage id="app.menu.dashboard" />
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage id="app.menu.games-pending" />
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function getLabelStatus(status) {
    let label = "";

    if (status === 0) {
      label = intl.formatMessage({ id: "inscriptions.status.notSent" });
    }

    if (status === 1) {
      label = intl.formatMessage({ id: "inscriptions.status.underReview" });
    }

    if (status === 2) {
      label = intl.formatMessage({ id: "inscriptions.status.finalist" });
    }

    if (status === 3) {
      label = intl.formatMessage({ id: "inscriptions.status.notSelected" });
    }

    if (status === 4) {
      label = intl.formatMessage({ id: "inscriptions.status.reviewGame" });
    }
    return label;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function renderFilter() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form className="form-custom content-filter">
                <div className="filter">
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="card-title">
                        <FormattedMessage id="form.filterBy" />:
                      </h2>
                    </div>
                    <div className="form-group col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label>
                        <FormattedMessage id="inscriptions.list.game" />
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={searchUserName}
                        onChange={({ target }) =>
                          setSearchUserName(target.value)
                        }
                        placeholder={intl.formatMessage({
                          id: "inscriptions.edit.field.name",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container-fluid">
        {evento && renderEvent()}
        {renderBreadcrumb()}
        {renderFilter()}
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="app.menu.games-pending" />
                </h4>
                <div className="event-list">{renderList()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(withAuth(GamesPendingList));
