import BaseFrame from './BaseFrame'
import { renderTextInput, 
    renderListOptionsInput,
    renderFileUpload } from '../../components/renderControls'
import React from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

class FramePlatform extends BaseFrame {
    constructor(props) {
        super(props)

        this.state = {
            show_university: false,
            show_othersplatforms: false,
            show_otherspreferredplatform: false,
            show_file_enrollmentproof: false
        }
    }
    
    onValueChanged = () => {
        let stateChanged = false
        
        const show_university = this.props.inscription.game?.students === true
        const show_othersplatforms = Array.isArray(this.props.inscription.game?.platforms)
                                       && this.props.inscription.game?.platforms?.includes(-1)
        const show_otherspreferredplatform = this.props.inscription.game?.preferredplatform === -1
        const show_file_enrollmentproof = (this.props.inscription.game?.enrollmentproof)

        stateChanged = stateChanged || this.state.show_university !== show_university
        stateChanged = stateChanged || this.state.show_othersplatforms !== show_othersplatforms
        stateChanged = stateChanged || this.state.show_otherspreferredplatform !== show_otherspreferredplatform
        stateChanged = stateChanged || this.state.show_file_enrollmentproof !== show_file_enrollmentproof

        if (stateChanged)
            this.setState({
                show_university,
                show_othersplatforms,
                show_otherspreferredplatform,
                show_file_enrollmentproof
            })
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <h2><FormattedMessage id="inscriptions.platform" /></h2>
                <div className="row">
                    {renderListOptionsInput({
                        name: "educational",
                        label: "inscriptions.platform.educational",
                        type: "radio",
                        col_md: 6,
                        option_col: 6,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "educational"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "socialimpact",
                        label: "inscriptions.platform.socialimpact",
                        type: "radio",
                        col_md: 6,
                        option_col: 6,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "socialimpact"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "diversity",
                        label: "inscriptions.platform.diversity",
                        type: "radio",
                        col_md: 6,
                        option_col: 6,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "diversity"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "virtualreality",
                        label: "inscriptions.platform.virtualreality",
                        type: "radio",
                        col_md: 6,
                        option_col: 6,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "virtualreality"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "blockchain",
                        label: "inscriptions.platform.blockchain",
                        type: "radio",
                        col_md: 6,
                        option_col: 6,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "blockchain"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {renderListOptionsInput({
                        name: "students",
                        label: "inscriptions.platform.students",
                        type: "radio",
                        col_md: 6,
                        option_col: 6,
                        required: true,
                        valueType: "bool",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "students"),
                        options: [
                            {value: true, label: "yes"},
                            {value: false, label: "no"}
                        ]
                    })}

                    {this.state.show_university && renderTextInput({
                        name: "university",
                        label: "inscriptions.platform.university",
                        onChange: (e) => this.onChangeFieldModel("game", e),
                        value: this.fieldValueModel("game", "university"),
                    })}

                    <div className="col-12 col-md-6">
                        {this.state.show_university 
                        && !this.state.show_file_enrollmentproof
                        && renderFileUpload({
                            name: "enrollmentproof",
                            label: "inscriptions.game.enrollmentproof",
                            accept: "image/png, image/jpeg, application/pdf",
                            onChange: (e) => this.onChangeFileFieldModel("game", e, "inscriptions", "enrollmentproof"),
                            value: this.fieldValueModel("game", "enrollmentproof"),
                            
                            required: true
                        })}
                        {this.state.show_university 
                        && this.state.show_file_enrollmentproof
                        && (
                            <div className="row">
                                <label className="input-label col-12">
                                    <FormattedMessage id="inscriptions.game.enrollmentproof" />
                                    <span className="input-required">*</span>
                                </label>
                                <div className='col-12'>
                                    <a href={`/api/v1/media/image/${this.props.inscription.game?.enrollmentproof}`} download={true}>
                                        {this.props.inscription.game?.enrollmentproof}
                                    </a>
                                    <button type='button' 
                                        className='btn btn-danger btn-sm ml-2 file-delete' 
                                        onClick={() => this.fileDelete(this.props.inscription.game?.enrollmentproof,
                                            "game", "enrollmentproof")}>X</button>
                                </div>
                            </div>
                        )}
                    </div>

                    {renderListOptionsInput({
                            name: "platforms",
                            label: "inscriptions.platform.platforms",
                            description: "inscriptions.platform.platforms.description",
                            col: 12,
                            option_col: 4,
                            required: true,
                            valueType: "int",
                            onChange: (e) => this.onChangeFieldModel("game", e),
                            value: this.fieldValueModel("game", "platforms"),
                            options: [
                                {name: "platform_android", value: 1, label: "android"},
                                {name: "platform_browser", value: 2, label: "browser"},
                                {name: "platform_ios", value: 3, label: "ios"},
                                {name: "platform_switch", value: 4, label: "switch"},
                                {name: "platform_pc", value: 5, label: "pc"},
                                {name: "platform_playstation", value: 6, label: "playstation"},
                                {name: "platform_steam", value: 7, label: "steam"},
                                {name: "platform_xbox", value: 8, label: "xbox"},
                                {name: "platform_other", value: -1, label: "other"},
                            ]
                        })}
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4"></div>
                                {this.state.show_othersplatforms && renderTextInput({
                                    name: "platformsother",
                                    col: 4,
                                    onChange: (e) => this.onChangeFieldModel("game", e),
                                    value: this.fieldValueModel("game", "platformsother"),
                                })}        
                            </div>
                        </div>
                        {renderListOptionsInput({
                            name: "preferredplatform",
                            label: "inscriptions.platform.preferredplatform",
                            type: "radio",
                            col: 12,
                            option_col: 4,
                            required: true,
                            valueType: "int",
                            onChange: (e) => this.onChangeFieldModel("game", e),
                            value: this.fieldValueModel("game", "preferredplatform"),
                            options: [
                                { value: 1, label: "android"},
                                { value: 2, label: "browser"},
                                { value: 3, label: "ios"},
                                { value: 4, label: "switch"},
                                { value: 5, label: "pc"},
                                { value: 6, label: "playstation"},
                                { value: 7, label: "steam"},
                                { value: 8, label: "xbox"},
                                { value: -1, label: "other"},
                            ]
                        })}
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4"></div>
                                {this.state.show_otherspreferredplatform 
                                && renderTextInput({
                                    name: "preferredplatformother",
                                    col: 4,
                                    onChange: (e) => this.onChangeFieldModel("game", e),
                                    value: this.fieldValueModel("game", "preferredplatformother"),
                                })}        
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(FramePlatform)