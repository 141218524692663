import React from "react";
import { Redirect } from "react-router-dom";
import Interop from "./Interop/";
import Header from "./Header/";
import Footer from "./Footer/";
import Sidebar from "./Sidebar/";
import jwt_decode from "jwt-decode";

const withAuth = (Component) => {

  const AuthRoute = (props) => {
    const isAuth = !!localStorage.getItem("token");
    if (isAuth) {
      return (
        <div id="main-wrapper" className="show">
          <Interop />
          <Header />
          <Sidebar />
          <Component {...props} />
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  };

  return AuthRoute;
};

export const user = () => {
  return jwt_decode(localStorage.getItem("token"));
};

export default withAuth;
