import React, { useState } from "react";
import Config from "../../config/";
import logoDiscord from "../../assets/images/v2/logo-discord-white.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
function DiscordLogin({
  clientId = `${Config.discord.clientId}`,
  scope = "guilds+identify+email",
  render,
  redirectUri = `${window.location.origin}/discord/callback`,
  connect = false,
  labelButton = "component.discord.bt-connect",
}) {
  const handleMessage = async (event) => {
    try {

      let dados = event.data.split("&");
      const accessToken = dados[1].split("=")[1];

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      };

      const config = {
        headers: headers,
      };

      let response = await axios.get(`https://discordapp.com/api/v6/users/@me`, config)
      const guildId = "803731792841408512"
      let hasBigGuild = false

      let guildsResponse = await axios.get(`https://discord.com/api/v6/users/@me/guilds`, config)

      guildsResponse.data.forEach(guild => {
        if(guild.id === guildId) {
          hasBigGuild = true
        }
      })

      
      localStorage.setItem("discord_guild", hasBigGuild)
      response.data.hasBigGuild = hasBigGuild

      onAuthDiscord(response.data);
    }
    catch (e) {
      console.log("e", e);
    }
  };

  function onAuthDiscord(dados) {
    if (connect) {
      axios
        .get("/api/v1/profile", {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        })
        .then((usuario) => {
          dados.userid = usuario.data.id;
          axios
            .post("/api/v1/account/login/discord/conect", dados)
            .then((result) => {
              window.location.reload(true);
            })
            .catch((e) => {
              console.log("e", e);
              alert("Usuario já existe");
            });
        })
        .catch((e) => {
          console.log("e", e);
          alert("Usuario já existe");
        });
    } else {
      axios
        .post("/api/v1/account/login/discord/", dados)
        .then((result) => {
          console.log("result", result);
          if (result.status === 200 && result.data.auth === true) {
            localStorage.setItem("token", result.data.token);
            window.open("/", "_self");
          }
        })
        .catch((e) => {
          console.log("e", e);
          alert("Usuario já existe");
        });
    }
  }

  function onClick(e) {
    e.preventDefault();
    window.open(
      `https://discord.com/api/oauth2/authorize?response_type=token&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`,
      "discordLogin",
      "height=700,width=500"
    );
    window.addEventListener("message", handleMessage, false);
  }

  return (
    <button type="submit" className="btn btn-primary m-b-15" onClick={onClick}>
      <img src={logoDiscord} alt="Discord" />
      <p>
        <FormattedMessage id={labelButton} />
      </p>
    </button>
  );
}

export default injectIntl(DiscordLogin);
